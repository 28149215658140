import React from 'react';

class CustomBorderedButton extends React.Component {

    //constructor
    constructor(props) {
        super(props);
    }

    render = () => {

        //set icon
        let icon = 'las la-plus';
        if (this.props.icon) icon = this.props.icon;

        //return render
        return (
            <div
                style={this.props.style}
                className={this.props.className}
            >
                <div
                    className={'bordered rounded touchable standard-padding-2 vertical-padding-2'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                    onClick={() => {
                        if (typeof this.props.onClick === 'function') {
                            this.props.onClick();
                        }
                    }}
                >
                    <div style={{
                        flexGrow: 1
                    }}>
                        <h3>{this.props.title}</h3>
                    </div>
                    <div
                        style={{
                            width: '2.3rem',
                            height: '2.3rem',
                            borderRadius: '50%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        className={'bg-light'}
                    >
                        <i
                            style={{
                                fontWeight: '900',
                                fontSize: '1.2rem'
                            }}
                            className={icon}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default CustomBorderedButton;