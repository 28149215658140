import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomInput from "../../Components/CustomInput";
import T from "../../../Actions/TranslationAction";
import Loader from "../../Components/Loader";
import {Button} from "react-bootstrap";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import CountryAction from "../../../Actions/CountryAction";
import CustomSelect from "../../Components/CustomSelect";

class EditHospitalTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            constructionSiteId: this.props.constructionSite.id
        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update data
    updateElements = async () => {
        //make sure translations are ready
        await T.init();

        //get countries
        let countryResults = await CountryAction.getCountries();

        //set results
        let countries = [];
        for (let element of countryResults) {
            countries.push({label: await T.translateArray(element.name), value: element['@id']});
        }

        //check country
        let country = null;
        if (this.props.constructionSite && this.props.constructionSite.hospital && this.props.constructionSite.hospital.country) {
            countries.forEach(element => {
                if (this.props.constructionSite.hospital.country['@id'] === element.value) {
                    country = element;
                }
            });
        }

        //if country still null, use first
        if (country == null) {
            country = countries[0];
        }

        //get state
        this.setState(
            {
                isLoaded: true,
                constructionSite: this.props.constructionSite,
                countries: countries,
                country: country,
                name: this.props.constructionSite.hospital ? this.props.constructionSite.hospital.name : '',
                street: this.props.constructionSite.hospital ? this.props.constructionSite.hospital.street : '',
                number: this.props.constructionSite.hospital ? this.props.constructionSite.hospital.number : '',
                zip: this.props.constructionSite.hospital ? this.props.constructionSite.hospital.zip : '',
                city: this.props.constructionSite.hospital ? this.props.constructionSite.hospital.city : '',
                phone: this.props.constructionSite.hospital ? this.props.constructionSite.hospital.phone : ''
            });
    }

    //set data for form
    updateForm = async () => {
        // Update user
        let formData = {
            constructionSiteId: this.state.constructionSiteId,
            hospital: {
                name: this.state.name,
                number: this.state.number,
                zip: this.state.zip,
                city: this.state.city,
                country: this.state.country,
                phone: this.state.phone
            }
        };

        let response = await ConstructionSiteAction.edit(formData);
    }

    //render
    render = () => {
        if (this.state.isLoaded) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Edit hospital'}/></h2>
                    </div>
                    {/* Form */}
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Name nearest hospital'}/>}
                            value={this.state.name}
                            onChangeText={(text) => {
                                this.setState({name: text});
                            }}
                        />
                    </div>
                    <div
                        className={'vertical-margin-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '-3px',
                            marginRight: '-3px'
                        }}
                    >
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                            iconName={'las la-home'}
                            label={<CustomTranslation value={'KEY.Street'}/>}
                            value={this.state.street}
                            onChangeText={(text) => this.setState({street: text})}
                        />
                        <CustomInput
                            className={'standard-margin-3'}
                            iconName={'las la-home'}
                            label={<CustomTranslation value={'KEY.Number'}/>}
                            value={this.state.number}
                            onChangeText={(text) => this.setState({number: text})}
                        />
                    </div>
                    <div
                        className={'vertical-margin-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '-3px',
                            marginRight: '-3px'
                        }}
                    >
                        <CustomInput
                            className={'standard-margin-3'}
                            iconName={'las la-city'}
                            label={<CustomTranslation value={'KEY.Zip'}/>}
                            value={this.state.zip}
                            onChangeText={(text) => this.setState({zip: text})}
                        />
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                            iconName={'las la-city'}
                            label={<CustomTranslation value={'KEY.City'}/>}
                            value={this.state.city}
                            onChangeText={(text) => this.setState({city: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomSelect
                            iconName={'las la-globe'}
                            label={<CustomTranslation value={'KEY.Country'}/>}
                            options={this.state.countries}
                            selected={{
                                label: this.state.country ? this.state.country.label : '',
                                value: this.state.country ? this.state.country.value : null
                            }}
                            onSelect={(value, label) => {
                                this.setState({country: {label: label, value: value}});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-phone'}
                            label={<CustomTranslation value={'KEY.Phone'}/>}
                            inputProps={{
                                type: 'tel'
                            }}
                            value={this.state.phone}
                            onChangeText={(text) => {
                                this.setState({phone: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => {
                                this.updateForm()
                            }}
                        >
                            <CustomTranslation value={'KEY.Save hospital'}/>
                        </Button>
                    </div>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default EditHospitalTab;