/**
 * Actions for RiskAnalysis
 *
 * @author Robbe De Geyndt <robbe.degeyndt@aware.be>
 */

'use strict';

//imports
import APIAction from './APIAction';
import T from './TranslationAction';
import {toast} from 'react-hot-toast';

//define class
let RiskAnalysisAction = {

    /**
     * Get risk analysis
     *
     * @param id
     * @returns {Promise<*|Response>}
     */
    get: async function(id) {

        //get call
        let result = await APIAction.request('/task_risk_analyses/' + id, 'GET', true);

        //return
        return result;
    },

    /**
     * Create a new Risk Analysis
     *
     * @param {*} constructionSite
     * @param {*} input
     * @param {*} language
     */
    create: async function(constructionSite, input, language) {

        //create title object
        let title = {};
        title[language.code] = input.title;

        //set data
        let data = {
            construction_site_id: constructionSite.id,
            company_id: constructionSite.company.id,
            title: title
        };

        //create risk analysis
        let result = await APIAction.request('/task_risk_analyses', 'POST', true, data);

        //check if result is valid
        if ('id' in result) {
            return result;
        } else {
            //init translation
            T.init();
            let toastMessage = await T.translate('KEY.Fill in all required fields');
            //show toast
            toast.error(toastMessage);
        }
    },

    /**
     * Update risk analysis
     *
     * @param input
     * @param language
     */
    update: async function(input, language) {
        //create title
        let title = {};
        title[language.code] = input.title;

        //set data
        let data = {
            title: title
        };

        //update risk analysis
        let result = await APIAction.request('/task_risk_analyses/' + input.id, 'PATCH', true, data);

        //return
        return result;
    },

    /**
     * Clone risk analysis
     *
     * @param riskAnalysisId
     * @param constructionSiteId
     */
    clone: async function(riskAnalysisId, constructionSiteId = null) {

        //set data
        let data = {};
        if (constructionSiteId) {
            data['construction_site_id'] = constructionSiteId;
        }

        //update risk analysis
        let result = await APIAction.request('/clone_task_risk_analysis/' + riskAnalysisId, 'POST', true, data);

        //return
        return result;
    },


    /**
     * Get list of risk analyses based on construction site
     *
     * @param {*} constructionSite
     */
    list: async function(constructionSite, isFinished = null) {

        //set data
        let data = {
            construction_site_id: constructionSite.id
        };

        if (isFinished !== null) {
            data.is_finished = isFinished;
        }

        //get risk analysis based in construction sites
        let result = await APIAction.request('/task_risk_analyses', 'GET', true, data);

        //return
        return result;
    },

    /**
     * Delete
     *
     * @param riskAnalysisId
     */
    delete: async function(riskAnalysisId) {
        //call api
        let result = await APIAction.request('/task_risk_analyses/' + riskAnalysisId, 'PATCH', true, {active: false})

        //return
        return result;
    },

    /**
     * Get risk analysis item if exists
     *
     * @param {*} riskAnalysis
     * @param {*} risk
     */
    getRiskAnalysisItem: async function(riskAnalysis, risk) {

        //set data
        let data = {
            "task_risk_analysis_id": riskAnalysis.id,
            "risk_id": risk.id
        };

        //do request
        let riskAnalysisItem = await APIAction.request('/task_risk_analysis_items', 'GET', true, data);

        //check if is available
        if ((riskAnalysisItem !== null) && ('id' in riskAnalysisItem)) {
            return riskAnalysisItem;
        } else {
            return null;
        }

    },

    /**
     * Create new risk analysis item
     *
     * @param {*} riskAnalysis
     * @param {*} risk
     * @param {*} riskAnalysisItem
     * @param {*} assignment
     */
    createRiskAnalysisItem: async function(riskAnalysis, risk, riskAnalysisItem, assignment = null) {

        //check what data to use
        let data = null;
        if (riskAnalysisItem !== null) {
            //copy via deep clone
            data = JSON.parse(JSON.stringify(riskAnalysisItem));
            //remove id
            delete data['id'];
            //remove risk
            delete data['risk'];
            //set data
            data['task_risk_analysis_id'] = riskAnalysis.id;
        } else {
            //deep clone
            data = JSON.parse(JSON.stringify(risk));
            //remove id
            delete data['id'];
            //set data
            data['task_risk_analysis_id'] = riskAnalysis.id;
            data['risk_id'] = risk.id;
        }

        //if assignment is included
        if (assignment) {
            data['assignment'] = assignment;

            //include picture if available
            if (assignment.imageId) {
                data['assignment']['photoId'] = assignment.imageId;
            }
            if (assignment.image2Id) {
                data['assignment']['altPhotoId'] = assignment.image2Id;
            }
        }

        //result
        let result = null;

        //check if riskAnalysisItem exists
        if (riskAnalysisItem !== null) {
            //patch item
            result = await APIAction.request('/task_risk_analysis_items/' + riskAnalysisItem.id, 'PATCH', true, data);
        } else {
            //create new item
            result = await APIAction.request('/task_risk_analysis_items', 'POST', true, data);
        }

        //init translation
        T.init();

        //show toast
        let toastMessage = await T.translate('KEY.Action send');
        toast.success(toastMessage);
    },


    /**
     * Finish risk analysis
     *
     * @param {*} riskAnalysis
     */
    finishRiskAnalysis: async function(riskAnalysis) {

        //finish call
        let result = await APIAction.request('/finish_task_risk_analysis/' + riskAnalysis.id, 'PATCH', true, {
            id: riskAnalysis['@id']
        });

        //return
        return result;
    },

    /**
     * Set risk items recursively
     *
     * @param {*} riskAnalysisId
     * @param {*} riskId
     * @param {*} isIgnored
     */
    recursiveRiskAnalysisItem: async function(riskAnalysisId, riskId, isIgnored) {

        //set data object
        let data = {
            task_risk_analysis_id: riskAnalysisId,
            risk_id: riskId,
            is_ignored: isIgnored
        };

        //call
        let result = await APIAction.request('/task_risk_analysis_items/custom/recursive', 'POST', true, data);

        //return
        return result;
    },

    /**
     * Send RA as email
     *
     * @param {*} input
     */
    sendRiskAnalysis: async function(input) {

        //set data object
        let data = {
            emails: input.email,
            task_risk_analysis_ids: input.ra,
            email_subject: input.title,
            email_body: input.message,
            limited: input.limited,
            construction_site_id: input.constructionSiteId
        };

        //call
        let result = await APIAction.request('/send_task_risk_analyses', 'POST', true, data);

        //init translation
        T.init();

        //check what toast to send
        if (result.status === 'success') {
            //set toast
            let toastMessage = await T.translate('KEY.Message send.');
            toast.success(toastMessage);
        } else {
            //set toast
            let toastMessage = await T.translate('KEY.Message failed.');
            toast.error(toastMessage);
        }

        //signal end
        return true;
    },

    /**
     * Search risk analyses
     *
     * @param {*} companyId
     * @param {*} search
     */
    searchRiskAnalysis: async function(companyId, search) {
        //call
        let result = await APIAction.request('/search_task_risk_analyses', 'GET', true, {
            search: search,
            company_id: companyId
        });

        //return
        return result;
    },

    /**
     * Get templates for company
     *
     * @param {*} companyId
     */
    getTemplates: async function(companyId) {

        //get templates
        let templates = await APIAction.request('/task_risk_analysis_templates', 'GET', true, {company_id: companyId});

        //return
        return templates;
    },

    /**
     * Create new template for company
     *
     * @param {*} companyId
     */
    createTemplate: async function(companyId) {

        //create template
        let template = await APIAction.request('/task_risk_analysis_templates', 'POST', true, {company_id: companyId, risk_ids: []});

        //return
        return template;

    },

    /**
     * Update template with risk
     *
     * @param {*} add
     * @param {*} templateId
     * @param {*} riskId
     */
    updateTemplate: async function(add, templateId, riskId) {

        //make call
        let action = 'remove';
        if (add) action = 'add';

        let template = await APIAction.request('/task_risk_analysis_templates/' + templateId + '/risk/' + action + '/' + riskId, 'PATCH', true);

        //return
        return template;
    }
};

//export
export default RiskAnalysisAction;
