//imports
import React from 'react';
import CustomTranslation from "../../Components/CustomTranslation";
import {Spinner} from "react-bootstrap";
import RiskCheckbox from './RiskCheckbox';
import Uuid from "uuid/v1";

//define class
class Risk extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {
            spinner: this.props.spinner || false,
            clicked: false,
            collapsed: this.props.risk.collapsed
        }

        //status color
        this.statusColor = 'bg-light';
        switch (this.props.risk.taskStatus) {
            case 'in_progress':
                this.statusColor = 'bg-primary';
                break;
            case 'finished':
                this.statusColor = 'bg-success';
                break;
        }

        //set interval
        this.waitInterval = null;
    }

    //on update
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        
        if (this.props.risk.collapsed !== this.state.collapsed) {
            this.setState({
                collapsed: this.props.risk.collapsed,
                spinner: false
            });
        }
    }

    //on click
    onClick = async () => {
        clearInterval(this.waitInterval);
        this.waitInterval = setInterval(() => {
            if (typeof this.props.onClick === 'function') {

                this.setState({'spinner': true});
                this.props.onClick();
            }
            clearInterval(this.waitInterval);
        }, 250);
    }

    //render view
    render() {

        //calc value
        let value = (this.props.risk.taskStatus !== 'ignored');
        if (this.state.clicked) value = !value;

        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <div
                    className={'bordered rounded touchable'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                    onClick={(event) => {
                        if (this.state.spinner !== true) {
                            this.onClick();
                        }
                    }}
                >
                    {/* status */}
                    <div
                        className={'rounded ' + this.statusColor}
                        style={{maxWidth: 10, minWidth: 10}}
                    >

                    </div>
                    {/* title */}
                    <div
                        className={'standard-padding-2 vertical-padding-2'}
                        style={{flexGrow: 1, flexShrink: 1}}
                    >
                        <h5 style={{wordWrap: 'anywhere'}}><CustomTranslation value={this.props.risk.title} /></h5>
                    </div>
                    {/* spinner */}
                    {('spinner' in this.state) && (this.state.spinner === true) &&
                        <div
                            className={'standard-margin-2'}
                            style={{alignSelf: 'center'}}
                        >
                            <Spinner style={{height: '1rem', width: '1rem'}} animation={'border'} variant={'primary'}/>
                        </div>
                    }
                    {/* icon */}
                    {this.props.iconStyle !== 'none' &&
                        <div
                            style={{alignSelf: 'center'}}
                            className={'standard-margin-2 touchable'}
                            onClick={(event) => {
                                //trigger event
                                if (typeof this.props.onCollapse === 'function') {
                                    if (this.props.iconStyle === 'normal') {
                                        event.stopPropagation();
                                        if (('spinner' in this.props) && (this.state.spinner === true)) {

                                        } else {
                                            this.setState({spinner: true});
                                            this.props.onCollapse(!this.state.collapsed);
                                        }
                                    }
                                }
                            }}
                        >
                            <div
                                className={'bg-light'}
                                style={{
                                    height: '1.5rem',
                                    width: '1.5rem',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: '900'
                                }}
                            >
                                {this.props.iconStyle === 'edit' ?
                                    <i
                                        className={'las la-pen'}
                                    />
                                    :
                                    <i
                                        className={this.state.collapsed ? 'las la-plus' : 'las la-minus'}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {/* checkbox */}
                    <RiskCheckbox
                        style={{alignSelf: 'center'}}
                        className={'standard-margin-2'}
                        key={Uuid()}
                        onClick={() => {
                            if (typeof this.props.onCheckBoxClick === 'function' && this.state.spinner !== true) {
                                this.props.onCheckBoxClick(this.props.risk.taskStatus === 'ignored');
                                this.setState({spinner: true, clicked: true});
                            }
                        }}
                        value={value}
                        disabled={this.state.spinner}
                    />
                </div>
            </div>
        );
    }
}

//export
export default Risk;