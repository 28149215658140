import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import {Button, Modal} from "react-bootstrap";
import CustomInput from "../../Components/CustomInput";
import UserAction from "../../../Actions/UserAction";

//define class
class RegisterModal extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {
            email: '',
            email2: '',
            password: '',
            password2: '',
            firstName: '',
            lastName: '',
            phone: '',
        }

        //set vars
        this.emailOK = null;
        this.pwOK = null;
    }

    //check if everything is filled in
    isReady = () => {
        //check if all filled in
        let ready = (this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.password);

        //check if repeats correct
        //email
        if (this.state.email === '' || this.state.email2 === '') {
            ready = false;
            this.emailOK = null;
        } else {
            if (this.state.email !== this.state.email2) {
                ready = false;
                this.emailOK = false;
            } else {
                this.emailOK = true;
            }
        }
        //password
        if (this.state.password === '' || this.state.password2 === '') {
            ready = false
            this.pwOK = null;
        } else {
            if (this.state.password !== this.state.password2) {
                ready = false;
                this.pwOK = false;
            } else {
                this.pwOK = true;
            }
        }

        return ready;
    }

    //render view
    render = () => {

        //check if ready
        let ready = this.isReady();

        //set props
        let emailProps = {};
        if (this.emailOK === null) emailProps = {};
        else if (!this.emailOK) emailProps = {isInvalid: true};
        else emailProps = {isValid: true};

        let pwProps = {};
        if (this.pwOK === null) pwProps = {};
        else if (!this.pwOK) pwProps = {isInvalid: true};
        else pwProps = {isValid: true};

        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title><CustomTranslation value={'KEY.Register'}/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                                className={'vertical-padding-3'}
                            >
                                <CustomInput
                                    iconName={'las la-quote-left'}
                                    label={
                                        <React.Fragment>
                                            <CustomTranslation value={'KEY.First Name'}/>*
                                        </React.Fragment>
                                    }
                                    className={'standard-margin-3'}
                                    style={{
                                        flex: 1,
                                        marginLeft: 0
                                    }}
                                    onChangeText={(text) => {
                                        this.setState({firstName: text});
                                    }}
                                />
                                <CustomInput
                                    iconName={'las la-quote-left'}
                                    label={
                                        <React.Fragment>
                                            <CustomTranslation value={'KEY.Last Name'}/>*
                                        </React.Fragment>
                                    }
                                    className={'standard-margin-3'}
                                    style={{
                                        flex: 1,
                                        marginRight: 0
                                    }}
                                    onChangeText={(text) => {
                                        this.setState({lastName: text});
                                    }}
                                />
                            </div>
                            <div className={'vertical-padding-3'}>
                                <CustomInput
                                    iconName={'las la-at'}
                                    label={
                                        <React.Fragment>
                                            <CustomTranslation value={'KEY.Email'} />*
                                        </React.Fragment>
                                    }
                                    onChangeText={(text) => {
                                        this.setState({email: text.trim().toLowerCase()});
                                    }}
                                    inputProps={{...{type: 'email'}, ...emailProps}}
                                />
                            </div>
                            <div className={'vertical-padding-3'}>
                                <CustomInput
                                    iconName={'las la-at'}
                                    label={
                                        <React.Fragment>
                                            <CustomTranslation value={'KEY.Repeat email'} />*
                                        </React.Fragment>
                                    }
                                    onChangeText={(text) => {
                                        this.setState({email2: text.trim().toLowerCase()});
                                    }}
                                    inputProps={{...{type: 'email'}, ...emailProps}}
                                />
                            </div>
                            <div className={'vertical-padding-3'}>
                                <CustomInput
                                    iconName={'las la-mobile'}
                                    label={
                                        <React.Fragment>
                                            <CustomTranslation value={'KEY.Mobile phone'} />*
                                        </React.Fragment>
                                    }
                                    onChangeText={(text) => {
                                        this.setState({phone: text});
                                    }}
                                    inputProps={{
                                        type: 'tel'
                                    }}
                                />
                            </div>
                            <div className={'vertical-padding-3'}>
                                <CustomInput
                                    iconName={'las la-ellipsis-h'}
                                    label={
                                        <React.Fragment>
                                            <CustomTranslation value={'KEY.Password'} />*
                                        </React.Fragment>
                                    }
                                    onChangeText={(text) => {
                                        this.setState({password: text});
                                    }}
                                    inputProps={{...{type: 'password'}, ...pwProps}}
                                />
                            </div>
                            <div className={'vertical-padding-3'}>
                                <CustomInput
                                    iconName={'las la-ellipsis-h'}
                                    label={
                                        <React.Fragment>
                                            <CustomTranslation value={'KEY.Repeat password'} />*
                                        </React.Fragment>
                                    }
                                    onChangeText={(text) => {
                                        this.setState({password2: text});
                                    }}
                                    inputProps={{...{type: 'password'}, ...pwProps}}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            block
                            variant={'primary'}
                            onClick={() => {
                                UserAction.registerSubmit(this.state);
                            }}
                            disabled={!ready}
                        >
                            <CustomTranslation value={'KEY.Register new account'}/>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

//export
export default RegisterModal;