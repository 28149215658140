import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import RiskAction from "../../Actions/RiskAction";
import RiskAnalysisAction from "../../Actions/RiskAnalysisAction";
import Loader from "../Components/Loader";
import {Breadcrumb, Button, Spinner} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import CustomInput from "../Components/CustomInput";
import T from "../../Actions/TranslationAction";
import {Collapse} from "react-collapse";
import RiskLetter from "./Components/RiskLetter";
import CustomImageInput from "../Components/CustomImageInput";
import CustomCheckBox from "../Components/CustomCheckBox";
import CustomDate from "../Components/CustomDate";
import CustomSelect from "../Components/CustomSelect";
import UserAction from "../../Actions/UserAction";
import Uuid from "uuid/v1";

class RiskScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            processing: false,
            damageCollapsed: true,
            fineKinneyCollapsed: true,
            assignmentCollapsed: true,
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async (active = true) => {

        //get risk analysis
        let riskAnalysis = await RiskAnalysisAction.get(this.props.params.risk_analysis_id);
        //get risk analysis title
        let riskAnalysisTitle = await T.translateArray(riskAnalysis.title);
        //get risk item
        let riskItem = await RiskAction.getRisk(this.props.params.risk_id, riskAnalysis);
        //get prevention
        let prevention = await T.translateArray(riskItem.prevention);
        //get injuries
        let damageInjury = await T.translateArray(riskItem.damageInjury);
        //get material
        let damageMaterial = await T.translateArray(riskItem.damageMaterial);
        //get risk title
        let riskTitle = await T.translateArray(riskAnalysis.title);
        //get current language
        let currentLanguage = await T.currentLanguage();

        //get users for construction site
        let users = await UserAction.getUserListConstructionSite(riskAnalysis.constructionSite.id);

        //define user options
        let userOptions = [];

        //loop
        users.forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            userOptions.push({
                label: label,
                value: element.email
            });
        });

        let targetUser = userOptions[0];
        let loggedInUser = await UserAction.getCurrentUser();
        if (loggedInUser !== null) {
            userOptions.forEach(element => {
                if (element.value.includes(loggedInUser.email)) {
                    targetUser = element;
                }
            });
        }

        //set in state
        this.setState({
            data: {
                riskAnalysis: riskAnalysis,
                riskItem: riskItem
            },
            riskAnalysisTitle: riskAnalysisTitle,
            riskAnalysisItem: riskItem.taskItem,
            riskAnalysisItemPrevention: prevention,
            riskAnalysisItemDamageInjury: damageInjury,
            riskAnalysisItemDamageMaterial: damageMaterial,
            riskTitle: riskTitle,
            currentLanguage: currentLanguage,
            userOptions: userOptions,
            user: targetUser
        });
    }

    //data from image upload
    handleUpload = async (file, name) => {
        let newState = {};
        newState[`${name}Id`] = file.id;
        newState[`${name}ApiId`] = file['@id'];
        this.setStateAssignment(newState);
    }

    //set state for current assignment
    setStateAssignment = (object) => {
        let assignment = this.state.currentAssignment;
        assignment = {...assignment, ...object};
        this.setState({'currentAssignment': assignment});
    }

    //set data
    setStateRiskAnalysisItem = (object) => {
        let riskAnalysisItem = this.state.riskAnalysisItem;
        riskAnalysisItem = {...riskAnalysisItem, ...object};
        this.setState({riskAnalysisItem: riskAnalysisItem});
    }

    //set F&K
    setFK = (letter, value) => {
        let result = {};
        result[letter + '2'] = value;
        //set in state
        this.setStateRiskAnalysisItem(result);
    }

    render = () => {

        if (this.state.data) {

            //calculate risk number
            let riskNumber = Math.round(
                (this.state.riskAnalysisItem.E2) *
                (this.state.riskAnalysisItem.B2) *
                (this.state.riskAnalysisItem.W2) *
                10
            ) / 10;

            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            disabled={
                                this.state.processing ||
                                (
                                    this.state.currentAssignment ?
                                        (
                                            this.state.currentAssignment.concept ?
                                                false :
                                                (!(
                                                    this.state.currentAssignment.title &&
                                                    this.state.currentAssignment.riskDescription &&
                                                    this.state.currentAssignment.dateRequested &&
                                                    this.state.currentAssignment.targetUser &&
                                                    this.state.currentAssignment.immediateActionTaken &&
                                                    this.state.currentAssignment.recommendation
                                                ))
                                        ):
                                        false
                                )
                            }
                            onClick={() => {
                                this.setState({processing: true}, () => {
                                    RiskAnalysisAction.createRiskAnalysisItem(
                                        this.state.data.riskAnalysis,
                                        this.state.data.riskItem,
                                        this.state.riskAnalysisItem,
                                        this.state.currentAssignment
                                    ).then(() => {
                                        setTimeout(() => {
                                            this.setState({processing: false});
                                            window.location.href = generatePath('/risk_analysis/:id/edit#:risk_id', {id: this.state.data.riskAnalysis.id, risk_id: this.state.data.riskItem.id});
                                        }, 1000);
                                    });
                                });
                            }}
                        >
                            <CustomTranslation value={'KEY.Add'} />
                            {this.state.processing &&
                                <Spinner size={'sm'} animation={'border'} variant={'white'} style={{margin: 2}} />
                            }
                        </Button>
                    }
                >
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/construction_site/:id', {id: this.state.data.riskAnalysis.constructionSite.id})}
                            >
                                {this.state.data.riskAnalysis.constructionSite.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/risk_analysis/:id', {id: this.state.data.riskAnalysis.id})}
                            >
                                <CustomTranslation value={this.state.data.riskAnalysis.title} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/risk_analysis/:id/edit#:risk_id', {id: this.state.data.riskAnalysis.id, risk_id: this.state.data.riskItem.id})}
                            >
                                <CustomTranslation value={'KEY.Risk analysis'}/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.Risk analysis item'} />
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div>
                            <h1>
                                <CustomTranslation value={this.state.data.riskAnalysis.title}/>
                            </h1>
                        </div>

                        {/* Prevention */}
                        <div className={'vertical-padding-3'}>
                            <CustomInput
                                iconName={'las la-exclamation-triangle text-white'}
                                iconBg={'bg-primary'}
                                label={<CustomTranslation value={this.state.data.riskItem.title} />}
                                value={this.state.riskAnalysisItemPrevention}
                                inputProps={{
                                    as: 'textarea',
                                    rows: 15
                                }}
                                onChangeText={(value) => {
                                    let result = {};
                                    result[this.state.currentLanguage.code] = value;
                                    this.setStateRiskAnalysisItem({prevention: result});
                                }}
                            />
                        </div>

                        {/* Damage reporting */}
                        <div className={'vertical-padding-3'}>
                            <div
                                className={'touchable vertical-margin-3'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                                onClick={() => {
                                    this.setState({damageCollapsed: !this.state.damageCollapsed});
                                }}
                            >
                                <div className={'bg-light icon-container'}>
                                    <i className={'las la-house-damage icon'} />
                                </div>
                                <div style={{flexGrow: 1, flexShrink: 1}}>
                                    <p className={'label standard-margin-2'} style={{paddingBottom: 0}}><CustomTranslation value={'KEY.Damage report'} /></p>
                                </div>
                                <div>
                                    <i style={{fontWeight: '900', fontSize: '1rem'}} className={this.state.damageCollapsed ? 'las la-chevron-down' : 'las la-chevron-up' } />
                                </div>
                            </div>
                            <Collapse isOpened={!this.state.damageCollapsed}>
                                <div className={'bordered rounded vertical-padding-2 standard-padding-2'} style={{paddingTop: 0}}>
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Injuries'} />}
                                            value={this.state.riskAnalysisItemDamageInjury}
                                            inputProps={{
                                                as: 'textarea',
                                                rows: 3
                                            }}
                                            onChangeText={(text) => {
                                                let answer = this.state.data.riskItem.damageInjury;
                                                answer[this.state.currentLanguage.code] = text;
                                                this.setStateRiskAnalysisItem({damageInjury: answer});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Damaged material'} />}
                                            value={this.state.riskAnalysisItemDamageMaterial}
                                            inputProps={{
                                                as: 'textarea',
                                                rows: 3
                                            }}
                                            onChangeText={(text) => {
                                                let answer = this.state.data.riskItem.damageMaterial;
                                                answer[this.state.currentLanguage.code] = text;
                                                this.setStateRiskAnalysisItem({damageMaterial: answer});
                                            }}
                                        />
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        {/* F&K */}
                        <div className={'vertical-padding-3'}>
                            <div
                                className={'touchable vertical-margin-3'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                                onClick={() => {
                                    this.setState({fineKinneyCollapsed: !this.state.fineKinneyCollapsed});
                                }}
                            >
                                <div className={'bg-light icon-container'}>
                                    <i className={'las la-stream icon'} />
                                </div>
                                <div style={{flexGrow: 1, flexShrink: 1}}>
                                    <p className={'label standard-margin-2'} style={{paddingBottom: 0}}><CustomTranslation value={'KEY.Risk calculation Fine and Kinney'} /></p>
                                </div>
                                <div>
                                    <i style={{fontWeight: '900', fontSize: '1rem'}} className={this.state.fineKinneyCollapsed ? 'las la-chevron-down' : 'las la-chevron-up' } />
                                </div>
                            </div>
                            <Collapse isOpened={!this.state.fineKinneyCollapsed}>
                                <div className={'bordered rounded vertical-padding-2 standard-padding-2'}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            marginLeft: '-3px',
                                            marginRight: '-3px'
                                        }}
                                    >
                                        <div className={'vertical-padding-3 standard-padding-3'} style={{flexBasis: 250, flexGrow: 1, flexShrink: 1}}>
                                            <RiskLetter
                                                label={<CustomTranslation value={'KEY.Possible serious damage'} />}
                                                letter={'E'}
                                                value={this.state.riskAnalysisItem.E2}
                                                onPress={(value) => {
                                                    this.setFK('E', parseFloat(value));
                                                }}
                                            />
                                        </div>
                                        <div className={'vertical-padding-3 standard-padding-3'} style={{flexBasis: 250, flexGrow: 1, flexShrink: 1}}>
                                            <RiskLetter
                                                label={<CustomTranslation value={'KEY.Possible serious damage'} />}
                                                letter={'B'}
                                                value={this.state.riskAnalysisItem.B2}
                                                onPress={(value) => {
                                                    this.setFK('B', parseFloat(value));
                                                }}
                                            />
                                        </div>
                                        <div className={'vertical-padding-3 standard-padding-3'} style={{flexBasis: 250, flexGrow: 1, flexShrink: 1}}>
                                            <RiskLetter
                                                label={<CustomTranslation value={'KEY.Possible serious damage'} />}
                                                letter={'W'}
                                                value={this.state.riskAnalysisItem.W2}
                                                onPress={(value) => {
                                                    this.setFK('W', parseFloat(value));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{marginBottom: 0}}><CustomTranslation value={'KEY.Risk number:'} /> {riskNumber}</p>
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        {/* Assignment Form */}
                        <div className={'vertical-padding-3'}>
                            <div
                                className={'touchable vertical-margin-3'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                                onClick={() => {
                                    this.setState(
                                        {assignmentCollapsed: !this.state.assignmentCollapsed},
                                        () => {
                                            if (this.state.assignmentCollapsed) {
                                                this.setState({currentAssignment: null, assignmentKey: Uuid()});
                                            } else {
                                                this.setState({
                                                    currentAssignment: {
                                                        dateRequested: new Date(),
                                                        targetUser: this.state.user.value,
                                                        title: this.state.riskAnalysisTitle
                                                    }
                                                });
                                            }
                                        }
                                    );
                                }}
                            >
                                <div className={'bg-light icon-container'}>
                                    <i className={'las la-user icon'} />
                                </div>
                                <div style={{flexGrow: 1, flexShrink: 1}}>
                                    <p className={'label standard-margin-2'} style={{paddingBottom: 0}}><CustomTranslation value={'KEY.Assign a task'} /></p>
                                </div>
                                <div>
                                    <span
                                        className={'checkmark-primary bordered rounded touchable ' + (!this.state.assignmentCollapsed ? 'bg-primary' : '')}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {!this.state.assignmentCollapsed &&
                                        <i
                                            className={'las la-check text-white'}
                                            style={{
                                                fontWeight: '900'
                                            }}
                                        />
                                        }
                                    </span>
                                </div>
                            </div>
                            <Collapse isOpened={!this.state.assignmentCollapsed} key={this.state.assignmentKey}>
                                <div className={'bordered rounded vertical-padding-2 standard-padding-2'} style={{paddingTop: 0}}>
                                    <div className={'vertical-padding-3'}>
                                        <CustomImageInput
                                            label={<CustomTranslation value={'KEY.Image overview'} />}
                                            onUpload={(file) => this.handleUpload(file, 'image')}
                                            imageId={(this.state.currentAssignment && this.state.currentAssignment.imageId) ? this.state.currentAssignment.imageId : null}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomImageInput
                                            label={<CustomTranslation value={'KEY.Image detail'} />}
                                            onUpload={(file) => this.handleUpload(file, 'image2')}
                                            imageId={(this.state.currentAssignment && this.state.currentAssignment.image2Id) ? this.state.currentAssignment.image2Id : null}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Title'} />}
                                            labelAppend={this.state.currentAssignment?.concept ? '' : '*'}
                                            onChangeText={(value) => {this.setStateAssignment({title: value})}}
                                            value={this.state.riskTitle}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Description for risk analysis item'} />}
                                            labelAppend={this.state.currentAssignment?.concept ? '' : '*'}
                                            onChangeText={(text) => this.setStateAssignment({riskDescription: text})}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Actions taken (by me)'} />}
                                            labelAppend={this.state.currentAssignment?.concept ? '' : '*'}
                                            onChangeText={(text) => this.setStateAssignment({immediateActionTaken: text})}
                                            inputProps={{
                                                as: 'textarea',
                                                rows: 3
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Remarks'} />}
                                            labelAppend={this.state.currentAssignment?.concept ? '' : '*'}
                                            onChangeText={(value) => this.setStateAssignment({recommendation: value})}
                                            inputProps={{
                                                as: 'textarea',
                                                rows: 3
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomDate
                                            label={<CustomTranslation value={'KEY.Solve by'} />}
                                            labelAppend={this.state.currentAssignment?.concept ? '' : '*'}
                                            onChange={(value) => {this.setStateAssignment({dateRequested: value})}}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomCheckBox
                                            label={<CustomTranslation value={'KEY.Finished'} />}
                                            onValueChange={(value) => this.setStateAssignment({finished: value})}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomSelect
                                            label={<CustomTranslation value={'KEY.Assign to'} />}
                                            labelAppend={this.state.currentAssignment?.concept ? '' : '*'}
                                            options={this.state.userOptions}
                                            selected={this.state.user}
                                            onSelect={(value, label) => {
                                                this.setState(
                                                    {user: {value: value, label: label}},
                                                    () => {
                                                        this.setStateAssignment({targetUser: value});
                                                    }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-padding-3'}>
                                        <CustomCheckBox
                                            label={<CustomTranslation value={'KEY.Save as concept'} />}
                                            onValueChange={(value) => this.setStateAssignment({concept: value})}
                                        />
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <RiskScreen {...props} params={params}/>;
}