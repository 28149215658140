import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import { Button, Table, Modal } from "react-bootstrap";
import G from "../../../Actions/GeneralAction";
import TranslationAction from "../../../Actions/TranslationAction";
import APIAction from "../../../Actions/APIAction";
import LanguageRepository from "../../../Database/Repositories/LanguageRepository";
import fileDownload from "js-file-download";
import AssignmentAction from "../../../Actions/AssignmentAction";
import AssignmentModal from "./AssigmentModal";
import Uuid from "uuid/v1";

//define class
class ICLItem extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            showModal: false,
            items: [],
            assignmentModal: null
        };
    }

    //on load
    componentDidMount = () => {
        this.updateElements();
    }

    //update
    updateElements = async () => {

        //get assignments if exist
        let assignments = await AssignmentAction.getForInspectionChecklist(this.props.data.id);
        let assignmentsTemp = {};
        for (let [index, assignment] of Object.entries(assignments)) {
            assignmentsTemp[assignment.inspectionChecklistItem.id] = assignment;
        }
        assignments = assignmentsTemp;

        //loop
        let items = [];
        for (let [index, item] of Object.entries(this.props.items)) {
            if (item.id in assignments) {
                item['assignment'] = assignments[item.id];
            }
            items.push(item);
        }

        //set state
        this.setState({
            items: items
        });
    }

    //on assignment click
    assignmentClick = async (assignment) => {
        if (assignment) {
            let modal = <AssignmentModal key={Uuid()} data={assignment} onClose={() => this.setState({ showModal: true })} />;
            this.setState({
                assignmentModal: modal,
                showModal: false
            });
        }
    }

    //render view
    render = () => {
        return (
            <div style={this.props.style} className={this.props.className}>
                <h4><CustomTranslation value={'KEY.Inspection Checklist'} /></h4>
                {/* Table */}
                <Table striped bordered>
                    <tbody>
                        <tr>
                            <th><CustomTranslation value={'KEY.Company'} /></th>
                            <td>{this.props.data.constructionSite.company.name}</td>
                        </tr>
                        <tr>
                            <th><CustomTranslation value={'KEY.Construction Site'} /></th>
                            <td>{this.props.data.constructionSite.name}</td>
                        </tr>
                        <tr>
                            <th><CustomTranslation value={'KEY.Created by'} /></th>
                            <td>{(this.props.data.createdBy.firstName && this.props.data.createdBy.lastName) ?
                                this.props.data.createdBy.firstName + ' ' + this.props.data.createdBy.lastName :
                                this.props.data.createdBy.email
                            }</td>
                        </tr>
                        <tr>
                            <th><CustomTranslation value={'KEY.Created at'} /></th>
                            <td>{G.formatDateTime(this.props.data.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                {/* Buttons */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flexBasis: 1, flexGrow: 1, marginLeft: 0 }} className={'standard-margin-3'}>
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => this.setState({ showModal: true })}
                        >
                            <CustomTranslation value={'KEY.More'} />
                        </Button>
                    </div>
                    <div style={{ flexBasis: 1, flexGrow: 1, marginRight: 0 }} className={'standard-margin-3'}>
                        <Button
                            block
                            variant={'dark'}
                            disabled={this.state.downloadingFile}
                            onClick={() => {
                                this.setState({ downloadingFile: true }, async () => {
                                    //default file name
                                    let now = new Date();
                                    let fileName = await TranslationAction.translate('KEY.Workplace Inspection Checklist');

                                    fileName += ' ' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
                                    fileName += '.pdf';

                                    //get language
                                    let languageRepository = new LanguageRepository();
                                    let language = await languageRepository.getSelected();

                                    //request
                                    APIAction.request('/inspection_checklists/' + this.props.data.id + '/pdf', 'GET', true, { language: language.code }, true, false, 'json', true)
                                        .then(result => {
                                            fileName = fileName.replace(/['"]+/g, '');
                                            fileDownload(result, fileName, 'application/pdf');
                                            this.setState({ downloadingFile: false });
                                        });

                                });
                            }}
                        >
                            <CustomTranslation value={'KEY.Download'} />
                        </Button>
                    </div>
                </div>

                {/* Modal */}
                <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} backdrop={'static'} keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <CustomTranslation value={'KEY.Inspection Checklist'} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Full Table */}
                        <Table striped bordered>
                            <tbody>
                                {this.state.items.map((item, index) => {
                                    //if is option
                                    if (item.inspectionChecklistOption) {
                                        return (
                                            <tr>
                                                <th><CustomTranslation value={item.inspectionChecklistOption.title} /></th>
                                                <td
                                                    style={{ fontSize: '1.5rem', whiteSpace: 'nowrap' }}
                                                    className={'assignment' in item ? 'touchable' : null}
                                                    onClick={() => this.assignmentClick(item.assignment)}
                                                >
                                                    {item.status === true ?
                                                        <i className={'las la-thumbs-up text-success font-weight-bold'} /> : null
                                                    }
                                                    {item.status === false ?
                                                        <i className={'las la-thumbs-down text-primary font-weight-bold'} /> : null
                                                    }
                                                    {item.status === null ?
                                                        <i className={'las la-circle text-dark font-weight-bold'} /> : null
                                                    }
                                                    {('assignment' in item ?
                                                        <i className={'las la-angle-right'} />
                                                        :
                                                        null
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }

                                    //if is insert
                                    if (item.inspectionChecklistOptionInsert) {
                                        return (
                                            <tr>
                                                <th>{item.inspectionChecklistOptionInsert.title}</th>
                                                <td
                                                    style={{ fontSize: '1.5rem', whiteSpace: 'nowrap' }}
                                                    className={'assignment' in item ? 'touchable' : null}
                                                    onClick={() => this.assignmentClick(item.assignment)}
                                                >
                                                    {item.status === true ?
                                                        <i className={'las la-thumbs-up text-success font-weight-bold'} /> : null
                                                    }
                                                    {item.status === false ?
                                                        <i className={'las la-thumbs-down text-primary font-weight-bold'} /> : null
                                                    }
                                                    {item.status === null ?
                                                        <i className={'las la-circle text-dark font-weight-bold'} /> : null
                                                    }
                                                    {('assignment' in item ?
                                                        <i className={'las la-angle-right'} />
                                                        :
                                                        null
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return null;
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button block variant={'dark'} onClick={() => this.setState({ showModal: false })}>
                            <CustomTranslation value={'KEY.Close'} />
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Modal for assignment */}
                {this.state.assignmentModal}
            </div >
        );
    }
}

//export
export default ICLItem;
