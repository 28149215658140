import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import { Button, Table, Spinner, Modal } from "react-bootstrap";
import G from "../../../Actions/GeneralAction";
import AssignmentAction from "../../../Actions/AssignmentAction";
import TranslationAction from "../../../Actions/TranslationAction";
import APIAction from "../../../Actions/APIAction";
import AssignmentModal from "./AssigmentModal";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import LanguageRepository from "../../../Database/Repositories/LanguageRepository";
import fileDownload from "js-file-download";
import { generatePath } from "react-router-dom";
import UserAction from "../../../Actions/UserAction";

//define class
class AssignmentItem extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            isLoaded: false,
            assignmentModal: null,
            downloadingFile: false,
            downloadModal: false
        };
    }

    //on mount
    componentDidMount = () => {
        //set slug in state
        this.setState({ id: this.props.id },
            () => this.updateElements());
    }

    //on update
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.id !== prevProps.id) {
            this.setState({ id: this.props.id },
                () => this.updateElements());
        }
    }

    //update
    updateElements = async () => {
        //set loader
        this.setState({ isLoaded: false });

        //get assignment
        let assignment = await AssignmentAction.get(this.state.id);
        assignment.constructionSite = await ConstructionSiteAction.getConstructionSite(assignment.constructionSiteId);

        //get current user
        let user = await UserAction.getCurrentUser();

        //check if user has rights
        let constructionSiteRight = await UserAction.getUserRightByConstructionSiteId(assignment.constructionSiteId);

        //title
        let title = await TranslationAction.translate('KEY.Assignment');
        if (assignment.message) {
            title = <CustomTranslation value={'KEY.Risk analysis message'} />;
        } else if (assignment.lastMinuteRiskAnalysisItem) {
            title = <CustomTranslation value={'KEY.LMRA'} />;
        } else if (assignment.observation && !(assignment.observation.positiveRemark)) {
            title = <CustomTranslation value={'KEY.Observation'} />;
        } else if (assignment.observation && assignment.observation.positiveRemark) {
            title = <CustomTranslation value={'KEY.Positive Remark'} />;
        } else if (assignment.taskRiskAnalysisItem) {
            title = <CustomTranslation value={'KEY.Risk analysis item'} />;
        } else if (assignment.inspectionChecklistItem && !(assignment.inspectionChecklistItem.positiveRemark)) {
            title = <CustomTranslation value={'KEY.Inspection checklist item'} />;
        } else if (assignment.inspectionChecklistItem && assignment.inspectionChecklistItem.positiveRemark) {
            title = <CustomTranslation value={'KEY.Positive inspection checklist item'} />;
        } else if (assignment.toolbox) {
            title = <CustomTranslation value={'KEY.Toolbox'} />;
        }

        //set in state
        this.setState({
            assignment: assignment,
            isLoaded: true,
            user: user,
            constructionSiteRight: constructionSiteRight,
            title: title
        });
    }

    showModal = async () => {
        //get modal
        let assignmentModal =
            <AssignmentModal data={{ id: this.state.id }} onClose={() => { this.setState({ assignmentModal: null }) }} />;

        //set in state
        this.setState({
            assignmentModal: assignmentModal
        });
    }

    //download pdf
    downloadFile = () => {
        this.setState({ downloadingFile: true }, async () => {
            //default file name
            let now = new Date();
            let fileName = await TranslationAction.translate('KEY.Assignment');
            if (this.state.assignment.observation) {
                fileName = await TranslationAction.translate('KEY.Observation');
            }
            if (this.state.assignment.observation?.positiveRemark) {
                fileName = await TranslationAction.translate('KEY.Positive Remark');
            }
            if (this.state.assignment.toolbox) {
                fileName = await TranslationAction.translate('KEY.Toolbox');
            }
            fileName += ' ' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
            fileName += '.pdf';

            //get language
            let languageRepository = new LanguageRepository();
            let language = await languageRepository.getSelected();

            //request
            APIAction.request('/assignments/' + this.state.assignment.id + '/pdf', 'GET', true, { language: language.code }, true, false, 'json', true)
                .then(result => {
                    fileName = fileName.replace(/['"]+/g, '');
                    fileDownload(result, fileName, 'application/pdf');
                    this.setState({ downloadingFile: false });
                });
        });
    }

    //render view
    render = () => {
        if (this.state.isLoaded) {
            return (
                <div style={this.props.style} className={this.props.className}>
                    <h4>{this.state.title}</h4>
                    {/* Table */}
                    <Table striped bordered>
                        <tbody>
                            <tr>
                                <th><CustomTranslation value={'KEY.Company'} /></th>
                                <td>{this.state.assignment.constructionSite.company.name}</td>
                            </tr>
                            <tr>
                                <th><CustomTranslation value={'KEY.Construction Site'} /></th>
                                <td>{this.state.assignment.constructionSite.name}</td>
                            </tr>
                            <tr>
                                <th><CustomTranslation value={'KEY.Created by'} /></th>
                                <td>{(this.state.assignment.createdBy.firstName && this.state.assignment.createdBy.lastName) ?
                                    this.state.assignment.createdBy.firstName + ' ' + this.state.assignment.createdBy.lastName :
                                    this.state.assignment.createdBy.email
                                }</td>
                            </tr>
                            <tr>
                                <th><CustomTranslation value={'KEY.Created at'} /></th>
                                <td>{G.formatDateTime(this.state.assignment.createdAt)}</td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* Buttons */}

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flexBasis: 1, flexGrow: 1, marginLeft: 0 }} className={'standard-margin-3'}>
                            <Button
                                block
                                variant={'dark'}
                                onClick={() => this.showModal()}
                            >
                                <CustomTranslation value={'KEY.More'} />
                            </Button>
                        </div>
                        <div style={{ flexBasis: 1, flexGrow: 1, marginRight: 0 }} className={'standard-margin-3'}>
                            <Button
                                block
                                variant={'dark'}
                                disabled={this.state.downloadingFile}
                                onClick={() => {
                                    if (this.state.assignment.toolbox) {
                                        this.setState({ downloadModal: true });
                                    } else {
                                        this.downloadFile();
                                    }
                                }}
                            >
                                <CustomTranslation value={'KEY.Download'} />
                            </Button>
                        </div>
                    </div>
                    {/*
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => this.showModal()}
                        >
                            <CustomTranslation value={'KEY.More'} />
                        </Button>
                                */}

                    {(this.state.assignment.sender.id === this.state.user.id || this.state.assignment.targetUser.id === this.state.user.id || (this.state.constructionSiteRight && this.state.constructionSiteRight.managementRights)) &&
                        <Button
                            style={{ marginTop: 6 }}
                            block
                            variant={'dark'}
                            onClick={() => {
                                if (this.state.assignment.targetUser.id === this.state.user.id) {
                                    window.location.href = generatePath('/construction_site/:cs_id/assignment/:id/in', { cs_id: this.state.assignment.constructionSite.id, id: this.state.assignment.id });
                                } else {
                                    window.location.href = generatePath('/construction_site/:cs_id/assignment/:id/out', { cs_id: this.state.assignment.constructionSite.id, id: this.state.assignment.id });
                                }
                            }}
                        >
                            <CustomTranslation value={'KEY.To'} /> {this.state.title}
                        </Button>
                    }

                    {/* Modal */}
                    {this.state.assignmentModal}

                    <Modal show={this.state.downloadModal} onHide={() => this.setState({ downloadModal: false })} backdrop={'static'} keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <CustomTranslation value={'KEY.Download'} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <Button
                                    block
                                    variant={'dark'}
                                    onClick={() => {
                                        this.setState({ downloadModal: false });
                                        this.downloadFile();
                                    }}
                                >
                                    <CustomTranslation value={'KEY.PDF file'} />
                                </Button>

                                <Button
                                    block
                                    variant={'dark'}
                                    onClick={async () => {
                                        //set waiting
                                        this.setState({ downloadingFile: true });

                                        //default file name
                                        let now = new Date();
                                        let fileName = await TranslationAction.translate('KEY.Toolbox');
                                        fileName += ' ' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
                                        fileName += '.pdf';


                                        //request
                                        APIAction.request('/toolbox/' + this.state.assignment.toolbox.id + '/document', 'GET', true, null, true, false, 'json', true)
                                            .then(result => {
                                                fileName = fileName.replace(/['"]+/g, '');
                                                fileDownload(result, fileName, 'application/pdf');
                                            });

                                        this.setState({
                                            downloadingFile: false,
                                            downloadModal: false
                                        });
                                    }}
                                >
                                    <CustomTranslation value={'KEY.Participants list'} />
                                </Button>

                                {this.state.assignment.toolbox && this.state.assignment.toolbox.document &&
                                    <Button
                                        block
                                        variant={'dark'}
                                        onClick={async () => {
                                            //set waiting
                                            this.setState({ downloadingFile: true });

                                            //create file name
                                            let now = new Date();
                                            let fileName = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '-' + this.state.assignment.toolbox.document.originalFileName;

                                            let file = await fetch(this.state.assignment.toolbox.document.path, {
                                                method: 'GET',
                                            });
                                            file = await file.blob();

                                            this.setState({
                                                downloadingFile: false,
                                                downloadModal: false
                                            });
                                            fileDownload(file, fileName);
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Document detail'} />
                                    </Button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            );
        } else {
            return (
                <div style={{ ...this.props.style, ...{ height: '100%' } }} className={this.props.className}>
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Spinner animation={'border'} variant={'primary'} />
                    </div>
                </div>
            );
        }
    }
}

//export
export default AssignmentItem;