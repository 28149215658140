import APIAction from './APIAction';
import T from './TranslationAction';
import {toast} from 'react-hot-toast';

//define class
let CompanyAction = {

    //get companies
    getCompanies: async function(all = false) {
        //set data
        let data = {
        };

        //if all
        if (all) {
            data['all'] = true;
        }

        //fetch risks
        let companies = await APIAction.request('/companies', 'GET', true, data);

        //return
        return companies;

    },

    //get company by id
    getCompany: async function(companyId) {
        //set data
        let data = {
        };

        //fetch risks
        let company = await APIAction.request('/companies/' + companyId , 'GET', true, data);

        //return
        return company;

    },

    //edit company based on input
    editCompany: async function(id, data) {
        //call api
        let result = await APIAction.request('/companies/' + id, 'PATCH', true, data);

        //check if result is valid
        if (result && result.id) {
            //show toast
            T.init();
            let toastMessage = await T.translate('KEY.Company Info saved.');
            toast.success(toastMessage);
        }
    },

    /**
     * Get right for company
     *
     * @param id
     */
    getRights: async function(id) {

        //call api
        let result = await APIAction.request('/user_company_rights', 'GET', true, {
            companyId: id
        });

        //return
        return result;
    },

    /**
     * Update a Company right
     *
     * @param {*} originalRight
     */
    updateRights: async function(originalRight) {

        //id
        let right = JSON.parse(JSON.stringify(originalRight));
        let id = right.id;
        delete right['id'];

        //call api
        let result = await APIAction.request('/user_company_rights/' + id, 'PATCH', true, right);

        //return
        return result;
    },

    /**
     * Create a Company Right
     *
     * @param {*} right
     */
    createRights: async function(right) {

        //call api
        let result = await APIAction.request('/user_company_rights', 'POST', true, right);

        //return
        return result;
    }
};

//export
export default CompanyAction;