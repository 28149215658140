import React from "react";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import { generatePath, useParams } from "react-router-dom";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import Project from "../Home/Components/Project";
import { Breadcrumb, Button } from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import ICLItem from "./Components/ICLItem";
import Uuid from "uuid/v1";
import { toast } from "react-hot-toast";
import ICLAction from "../../Actions/ICLAction";
import ICLParent from "./Components/ICLParent";

class ICLScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {

        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //init icl
        let ICL = {};

        //create icl if needed
        if (this.state.ICL) {
            ICL = await ICLAction.getICL(this.state.ICL.id);
        } else if (this.props.params.icl_id) {
            ICL = await ICLAction.getICL(this.props.params.icl_id);
        } else {
            ICL = await ICLAction.createICL(cs.id);
        }

        //get icl items
        let items = await ICLAction.getItems(ICL.id);

        //set array
        let ICLItems = [];
        let ICLParents = [];

        //props
        let props = this.props;

        //loop
        items.forEach(element => {

            //if element is risk
            if (element.inspectionChecklistOption) {
                //get parent
                let parent = element.inspectionChecklistOption.parent;

                //check if array already exists
                if (typeof ICLItems[parent.id] === 'undefined') {
                    ICLItems[parent.id] = [];
                    ICLParents.push(parent);
                }

                ICLItems[parent.id].push(
                    <ICLItem
                        key={element.id + Uuid()}
                        title={<CustomTranslation value={element.inspectionChecklistOption.title} />}
                        value={element.status}
                        data={element}
                        className={'vertical-margin-3'}
                        onValueChange={(value) => {
                            element.status = value;
                            ICLAction.updateItem(element).then((result) => {
                                if (value === false) {
                                    window.location.href = generatePath('/construction_site/:construction_site_id/inspection_checklist/:icl_id/item/:item_id/:positive', { construction_site_id: cs.id, icl_id: ICL.id, item_id: result.id, positive: '0' });
                                }
                            });
                        }}
                        onAssignment={() => {
                            window.location.href = generatePath('/construction_site/:construction_site_id/inspection_checklist/:icl_id/item/:item_id/:positive', { construction_site_id: cs.id, icl_id: ICL.id, item_id: element.id, positive: '1' });
                        }}
                    />
                );
            }

            //if element is insert
            if (element.inspectionChecklistOptionInsert) {
                //get parent
                let parent = element.inspectionChecklistOptionInsert.inspectionChecklistOption;

                //check if array already exists
                if (typeof ICLItems[parent.id] === 'undefined') {
                    ICLItems[parent.id] = [];
                    ICLParents.push(parent);
                }

                //add to risks
                ICLItems[parent.id].push(
                    <ICLItem
                        key={element.id + Uuid()}
                        title={element.inspectionChecklistOptionInsert.title}
                        value={element.status}
                        data={element}
                        className={'vertical-margin-3'}
                        onValueChange={(value) => {
                            element.status = value;
                            ICLAction.updateItem(element).then((result) => {
                                if (value === false) {
                                    window.location.href = generatePath('/construction_site/:construction_site_id/inspection_checklist/:icl_id/item/:item_id/:positive', { construction_site_id: cs.id, icl_id: ICL.id, item_id: result.id, positive: '0' });
                                }
                            });
                        }}
                        onAssignment={() => {
                            ICLAction.getItem(element.id).then((result) => {
                                window.location.href = generatePath('/construction_site/:construction_site_id/inspection_checklist/:icl_id/item/:item_id/:positive', { construction_site_id: cs.id, icl_id: ICL.id, item_id: result.id, positive: '1' });
                            });
                        }}
                    />
                );
            }
        });

        //set array
        let ICLObjects = [];

        //loop and create parent element
        ICLParents.forEach((element, index) => {

            ICLObjects.push(
                <ICLParent
                    key={element.id}
                    title={<CustomTranslation value={element.title} />}
                    iclList={ICLItems[element.id]}
                    icl={ICL}
                    option={element}
                    className={'vertical-padding-2'}
                    style={{ paddingTop: 0 }}
                />
            );
        });

        //set state
        this.setState({
            data: {
                constructionSite: cs
            },
            ICLObjects: ICLObjects,
            ICL: ICL
        });

    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => {
                                toast.success(<CustomTranslation value={'KEY.Inspection Checklist saved.'} />);
                                setTimeout(() => {
                                    window.location.href = generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id });
                                }, 1000);
                            }}
                        >
                            <CustomTranslation value={'KEY.Finish Inspection Checklist'} />
                        </Button>
                    }
                >
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{ position: 'sticky', top: '2rem', zIndex: 9 }}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>

                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id })}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Inspection Checklist'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div className={'vertical-margin'}>
                                <h1>
                                    <CustomTranslation value={'KEY.Inspection Checklist'} />
                                </h1>
                            </div>

                            {/* List */}
                            {this.state.ICLObjects}

                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <ICLScreen {...props} params={params} />;
}