import APIAction from './APIAction';

//define class
let CountryAction = {

    //get companies
    getCountries: async function() {
        //set data
        let data = {
        };

        //fetch risks
        let countries = await APIAction.request('/countries', 'GET', true, data);

        //return
        return countries;

    },
};

//export
export default CountryAction;
