import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import Loader from "../../Components/Loader";
import { Button, Table, Modal, Image, Spinner } from "react-bootstrap";
import G from "../../../Actions/GeneralAction";
import AssignmentAction from "../../../Actions/AssignmentAction";
import { generatePath } from "react-router-dom";
import UserAction from "../../../Actions/UserAction";
import TranslationAction from "../../../Actions/TranslationAction";

//define class
class AssignmentModal extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            showModal: true,
            assignment: null
        };
    }

    //on load
    componentDidMount = () => {
        this.updateElements();
    }

    //update
    updateElements = async () => {
        //call
        let assignment = await AssignmentAction.get(this.props.data.id);

        //user
        let user = await UserAction.getCurrentUser();

        //title
        let title = await TranslationAction.translate('KEY.Assignment');
        if (assignment.message) {
            title = <CustomTranslation value={'KEY.Risk analysis message'} />;
        } else if (assignment.lastMinuteRiskAnalysisItem) {
            title = <CustomTranslation value={'KEY.LMRA'} />;
        } else if (assignment.observation && !(assignment.observation.positiveRemark)) {
            title = <CustomTranslation value={'KEY.Observation'} />;
        } else if (assignment.observation && assignment.observation.positiveRemark) {
            title = <CustomTranslation value={'KEY.Positive Remark'} />;
        } else if (assignment.taskRiskAnalysisItem) {
            title = <CustomTranslation value={'KEY.Risk analysis item'} />;
        } else if (assignment.inspectionChecklistItem && !(assignment.inspectionChecklistItem.positiveRemark)) {
            title = <CustomTranslation value={'KEY.Inspection checklist item'} />;
        } else if (assignment.inspectionChecklistItem && assignment.inspectionChecklistItem.positiveRemark) {
            title = <CustomTranslation value={'KEY.Positive inspection checklist item'} />;
        } else if (assignment.toolbox) {
            title = <CustomTranslation value={'KEY.Toolbox'} />;
        }

        //set in state
        this.setState({
            assignment: assignment,
            user: user,
            title: title
        });
    }

    //on close
    onClose = async () => {
        this.setState({
            showModal: false
        });
        this.props.onClose();
    }


    //render view
    render = () => {
        if (this.state.assignment) {
            return (
                <Modal show={this.state.showModal} onHide={() => this.onClose()} backdrop={'static'} keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Title */}
                        {!this.state.assignment.message &&
                            <h4>{this.state.assignment.message}</h4>
                        }
                        {/* Full Table */}
                        <Table striped bordered>
                            <tbody>
                                {this.state.assignment.photo &&
                                    <>
                                        <tr>
                                            <th colSpan={2}><CustomTranslation value={'KEY.Image overview'} /></th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image /*height={300}*/ style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.state.assignment.photo.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {this.state.assignment.altPhoto &&
                                    <>
                                        <tr>
                                            {this.state.assignment.toolbox ?
                                                <th colSpan={2}><CustomTranslation value={'KEY.Instruction/Toolboxmoment'} /></th> :
                                                <th colSpan={2}><CustomTranslation value={'KEY.Image detail'} /></th>
                                            }
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image height={300} style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.state.assignment.altPhoto.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                                <tr>
                                    <th><CustomTranslation value={'KEY.From'} /></th>
                                    <td>
                                        {(this.state.assignment.sender.firstName || this.state.assignment.sender.lastName)
                                            ?
                                            (this.state.assignment.sender.firstName || '') + ' ' + (this.state.assignment.sender.lastName || '')
                                            :
                                            (this.state.assignment.sender.email || '')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th><CustomTranslation value={'KEY.To'} /></th>
                                    <td>
                                        {(this.state.assignment.targetUser.firstName || this.state.assignment.targetUser.lastName)
                                            ?
                                            (this.state.assignment.targetUser.firstName || '') + ' ' + (this.state.assignment.targetUser.lastName || '')
                                            :
                                            (this.state.assignment.targetUser.email || '')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th><CustomTranslation value={'KEY.Date'} /></th>
                                    <td>
                                        {G.formatDate(this.state.assignment.createdAt)}
                                    </td>
                                </tr>
                                {!this.state.assignment.message &&
                                    <React.Fragment>
                                        <tr>
                                            <th><CustomTranslation value={'KEY.Title'} /></th>
                                            <td>{this.state.assignment.title ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <th><CustomTranslation value={'KEY.Solve by'} /></th>
                                            <td>{G.formatDate(this.state.assignment.dateRequested)}</td>
                                        </tr>
                                        {!this.state.assignment.toolbox &&
                                            <tr>
                                                {this.state.assignment.positiveRemark ?
                                                    <th><CustomTranslation value={'KEY.Positive find'} /></th> :
                                                    <th><CustomTranslation value={'KEY.Description of risk'} /></th>
                                                }
                                                <td>{G.nl2br(this.state.assignment.riskDescription)}</td>
                                            </tr>
                                        }
                                        {!this.state.assignment.positiveRemark &&
                                            <tr>
                                                {this.state.assignment.toolbox ?
                                                    <th><CustomTranslation value={'KEY.Optional extra information'} /></th> :
                                                    <th><CustomTranslation value={'KEY.Action taken'} /></th>
                                                }
                                                <td>{G.nl2br(this.state.assignment.immediateActionTaken)}</td>
                                            </tr>
                                        }
                                        {!this.state.assignment.toolbox &&
                                            <tr>
                                                <th>
                                                    {this.state.assignment.positiveRemark ?
                                                        <CustomTranslation value={'KEY.Possible Recommendation'} /> :
                                                        <CustomTranslation value={'KEY.Recommendation'} />
                                                    }
                                                </th>
                                                <td>{G.nl2br(this.state.assignment.recommendation)}</td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.assignment.message &&
                                    <React.Fragment>
                                        <tr>
                                            <th><CustomTranslation value={'KEY.Title'} /></th>
                                            <td>{this.state.assignment.message.title}</td>
                                        </tr>
                                        <tr>
                                            <th><CustomTranslation value={'KEY.Message'} /></th>
                                            <td>{this.state.assignment.message.title}</td>
                                        </tr>
                                    </React.Fragment>
                                }
                                {this.state.assignment.dateFinished &&
                                    <React.Fragment>
                                        <tr>
                                            <th><CustomTranslation value={'KEY.Finished on'} /></th>
                                            <td>
                                                {G.formatDate(this.state.assignment.dateFinished)}
                                            </td>
                                        </tr>
                                        {this.state.assignment.comment &&
                                            <tr>
                                                <th><CustomTranslation value={'KEY.Follow-up'} /></th>
                                                <td>
                                                    {G.nl2br(this.state.assignment.comment)}
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.assignment.followUpPhoto &&
                                    <>
                                        <tr>
                                            <th colSpan={2}><CustomTranslation value={'KEY.Image overview follow-up'} /></th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image /*height={300}*/ style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.state.assignment.followUpPhoto.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {this.state.assignment.followUpAltPhoto &&
                                    <>
                                        <tr>
                                            <th colSpan={2}><CustomTranslation value={'KEY.Image detail follow-up'} /></th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image /*height={300}*/ style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.state.assignment.followUpAltPhoto.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </Table>
                        {(this.state.assignment.sender.id === this.state.user.id || this.state.assignment.targetUser.id === this.state.user.id) &&
                            <Button
                                style={{ marginTop: 6 }}
                                block
                                variant={'dark'}
                                onClick={() => {
                                    if (this.state.assignment.targetUser.id === this.state.user.id) {
                                        window.location.href = generatePath('/construction_site/:cs_id/assignment/:id/in', { cs_id: this.state.assignment.constructionSiteId, id: this.state.assignment.id });
                                    } else {
                                        window.location.href = generatePath('/construction_site/:cs_id/assignment/:id/out', { cs_id: this.state.assignment.constructionSiteId, id: this.state.assignment.id });
                                    }
                                }}
                            >
                                <CustomTranslation value={'KEY.To'} /> {this.state.title}
                            </Button>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button block variant={'dark'} onClick={() => this.onClose()}>
                            <CustomTranslation value={'KEY.Close'} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return (
                <Modal show={this.state.showModal} backdrop={'static'} keyboard={false}>
                    <Modal.Body>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Spinner animation={'border'} variant={'primary'} />
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }
    }
}

//export
export default AssignmentModal;