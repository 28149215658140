import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomBorderedButton from "../../Components/CustomBorderedButton";
import CustomInput from "../../Components/CustomInput";
import CustomConfirm from "../../Components/CustomConfirm";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import FirstAidKit from "../Components/FirstAidKit";
import Loader from "../../Components/Loader";
import {toast} from "react-hot-toast";

class EditFirstAidKitTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            firstAidKitModal: false,
            modalEdit: false,
            isLoaded: false
        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update data
    updateElements = async () => {
        //refresh
        let constructionSite = await ConstructionSiteAction.getConstructionSite(this.props.constructionSite.id);

        //rebuild kits
        this.setKits(constructionSite);
    }

    //post form
    postForm = async () => {

        //form params
        let formData = {
            constructionSite: {
                id: this.props.constructionSite['@id']
            },
            location: this.state.location,
            description: this.state.description,
            id: this.state.id
        };

        //api call
        await ConstructionSiteAction.editFirstAidKit(formData);

        //close modal
        this.setState({
            firstAidKitModal: false
        });

        //fetch new data
        this.updateElements();

        //toast
        toast.success(<CustomTranslation value={'KEY.First aid kits updated.'} />);
    }

    //create objects
    setKits = async (constructionSite) => {
        //init
        let firstAidKits = [];

        //loop over first aid kits
        constructionSite.firstAidKits.forEach(element => {
            firstAidKits.push(
                <FirstAidKit
                    className={'vertical-margin-2'}
                    key={element.id}
                    data={element}
                    constructionSite={constructionSite}
                    onUpdate={() => {
                        this.setState({
                           firstAidKitModal: true,
                           location: element.location,
                           description: element.description,
                           id: element.id,
                           modalEdit: true
                        });
                    }}
                />
            );
        });

        //set state
        this.setState({
            isLoaded: true,
            firstAidKits: firstAidKits
        });
    }

    //render
    render = () => {
        if (this.state.isLoaded) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.First aid kits'}/></h2>
                    </div>

                    {/* Button */}
                    <div className={'vertical-margin-2'}>
                        <CustomBorderedButton
                            title={<CustomTranslation value={'KEY.Create First Aid Kit'}/>}
                            onClick={() => {
                                this.setState({
                                    firstAidKitModal: true,
                                    location: '',
                                    description: '',
                                    id: null,
                                    modalEdit: false
                                });
                            }}
                        />
                    </div>

                    {/* List of first aid kits  */}
                    <div>
                        {this.state.firstAidKits}
                        {
                            this.state.firstAidKits.length === 0 &&
                            <p><CustomTranslation value={'KEY.No first aid kits yet'} /></p>
                        }
                    </div>

                    {/* Modal */}
                    <CustomConfirm
                        show={this.state.firstAidKitModal}
                        variant={'success'}
                        body={
                            <div>
                                <div className={'vertical-margin-2'}>
                                    <CustomInput
                                        iconName={'las la-location-arrow'}
                                        label={<CustomTranslation value={'KEY.Location'}/>}
                                        value={this.state.location}
                                        onChangeText={(text) => this.setState({location: text})}
                                    />
                                </div>
                                <div className={'vertical-margin-2'}>
                                    <CustomInput
                                        iconName={'las la-quote-left'}
                                        label={<CustomTranslation value={'KEY.Description emergency supply'}/>}
                                        value={this.state.description}
                                        onChangeText={(text) => this.setState({description: text})}
                                    />
                                </div>
                            </div>
                        }
                        cancelLabel={<CustomTranslation value={'KEY.Cancel'}/>}
                        onCancel={() => this.setState({firstAidKitModal: false})}
                        confirmLabel={
                            this.state.modalEdit ?
                                <CustomTranslation value={'KEY.Update First aid kit'}/>
                                :
                                <CustomTranslation value={'KEY.Save First aid kit'}/>
                        }
                        onConfirm={() => this.postForm()}
                    />
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default EditFirstAidKitTab;