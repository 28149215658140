//imports
import React from 'react';
import Project from './Project';

//define class
class ProjectList extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {
            projects: []
        }
    }

    //on initial
    componentDidMount() {
        //load items
        this.loadItems();
    }

    //update
    componentDidUpdate(prevProps) {
        if ((this.props.data !== null) && (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data))) {
            this.loadItems();
        }
    }

    //load project items
    async loadItems() {

        //default props for in projects
        this.projectProps = {};
        if (!this.props.active) {
            this.projectProps['inactive'] = true;
        }

        //init
        let result = [];
        //loop
        if (this.props.data != null) {
            this.props.data.forEach(item => {
                result.push(
                    <Project
                        style={{marginBottom: 20}}
                        key={item.id}
                        data={item}
                        {...this.projectProps}
                    />
                );
            });
        }
        
        //set state
        this.setState({projects: result});
    }

    //render view
    render() {
        
        return (
            <div>
                {this.state.projects}
            </div>
        );
    }
}

//export
export default ProjectList;