import APIAction from "./APIAction";

//define class
let ObservationAction = {
    //create observation based on input
    createObservation: async function(data) {
        //call api
        let result = await APIAction.request('/observations', 'POST', true, data);

        //return
        return result;
    },

    //get observation
    getObservation: async function(id) {
        //call api
        let result = await APIAction.request('/observations/' + id, 'GET', true);

        //return
        return result;
    },
};

//export
export default ObservationAction;
