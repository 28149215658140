import React from 'react';

import G from '../../../Actions/GeneralAction';
import CustomTranslation from '../../Components/CustomTranslation';
import { generatePath } from "react-router-dom";

//define class
class Toolbox extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            isLoaded: false,
            active: true,
            data: this.props.data
        };
    }

    //render view
    render = () => {

        //set status
        this.status = '';
        if (this.props.data.assignments[0].dateFinished) {
            this.status = 'bg-success';
        } else {
            this.status = 'bg-primary';
        }

        //return
        return (
            <div style={this.props.style}
                className={this.props.className}
                onClick={() => {
                    window.location.href = generatePath('/construction_site/:construction_site_id/toolbox/:toolbox_id', { construction_site_id: this.props.cs.id, toolbox_id: this.props.data.id });
                }}
            >
                <div style={{
                    minHeight: 50,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
                    className={'bg-white bordered rounded touchable'}
                >
                    <div
                        style={{
                            minWidth: 10,
                            maxWidth: 10,
                            alignSelf: 'stretch'
                        }}
                        className={'rounded ' + this.status}
                    >

                    </div>
                    <div className={'standard-margin vertical-margin'} style={{ flexShrink: 1, flexGrow: 1 }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div
                                className={'bg-dark icon-container'}
                            >
                                <i className={'las la-briefcase text-white icon'} />
                            </div>
                            <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-2'}>
                                <h5 className={'unbold'}>
                                    <CustomTranslation value={'KEY.Toolbox'} />
                                </h5>
                                <h3 style={{ wordBreak: 'break-word' }}>
                                    {this.props.data.assignments[0].title}
                                </h3>
                                <h5 className={'unbold'}>
                                    [{G.formatDateTime(this.state.data.createdAt)}]
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default Toolbox;