import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import {Button, Modal} from "react-bootstrap";
import CustomInput from "../../Components/CustomInput";
import {toast} from "react-hot-toast";
import Uuid from "uuid/v1";

//styles
const styles = {
    bubble: {
        maxWidth: '2.45rem',
        minWidth: '2.45rem',
        maxHeight: '2.45rem',
        minHeight: '2.45rem',
        borderColor: 'rgba(98,113,127,0.3)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        borderStyle: 'none',
        fontFamily: 'Montserrat',
        width: '1rem',
        height: '1rem',
        padding: '0',
        textAlign: 'center',
        outline: 'none'
    }
}

//define class
class CodeInput extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set values
        this.values = {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: ''
        }

        //set inputs
        this.inputs = {};
    }

    //on change prop
    _onChange = async () => {
        let values = this.values;

        //result
        let result = '';
        //get keys
        Object.keys(values).forEach(function(value) {
            //put together
            result += values[value];
        });
        //return
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(result);
        }
    }

    //when key is pressed
    _keyIspressed = async (key, order) => {
        //original button
        let orgBtn = key;

        //get keys
        key = parseInt(key);

        //check key
        if (!Number.isNaN(key)) {

            //set value of key
            this.values[order] = key + '';

            //only to next item when not last
            if (order !== 'f') {
                //get next item
                let indexes = Object.keys(this.values);
                let index = indexes.indexOf(order);
                let nextOrder = indexes[index + 1];

                //focus
                this.inputs[nextOrder].focus();
            }

            //else focus out
            else {
                //no focus
                this.inputs[order].blur();
            }
        }

        //on failure reset key
        else {
            this.values[order] = '';
        }

        //trigger change
        this._onChange();

        //trigger rerender
        this.setState({
            rerender: Uuid()
        });
    }

    //when bacspace is pressed
    _onBackspace = async(key, order) => {
        if (key === 'Backspace' && (this.values[order] === '')) {

            //check if item not first
            if (order !== 'a') {
                //get previous item
                let indexes = Object.keys(this.values);
                let index = indexes.indexOf(order);
                let previousOrder = indexes[index - 1];

                //set empty
                this.values[previousOrder] = '';

                //focus
                this.inputs[previousOrder].focus();

                //trigger change
                this._onChange();
            }
        }
    }

    //render view
    render() {
        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <div>
                    {this.props.label &&
                        <div>
                            <p style={{textAlign: 'center'}} className={'label'}>{this.props.label}</p>
                        </div>
                    }
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}
                    >
                        <div className={'standard-padding-3'} style={{paddingLeft: 0}}>
                            <div
                                style={styles.bubble}
                            >
                                <input
                                    ref={(input) => { this.inputs.a = input; }}
                                    style={styles.input}
                                    type={'text'}
                                    className={'text-dark'}
                                    maxLength={1}
                                    onKeyUp={(value) => {
                                        this._onBackspace(value.key, 'a');
                                    }}
                                    onChange={(value) => {
                                        this._keyIspressed(value.target.value, 'a');
                                    }}
                                    value={this.values.a}
                                />
                            </div>
                        </div>
                        <div className={'standard-padding-3'}>
                            <div
                                style={styles.bubble}
                            >
                                <input
                                    ref={(input) => { this.inputs.b = input; }}
                                    style={styles.input}
                                    type={'text'}
                                    className={'text-dark'}
                                    maxLength={1}
                                    onKeyUp={(value) => {
                                        this._onBackspace(value.key, 'b');
                                    }}
                                    onChange={(value) => {
                                        this._keyIspressed(value.target.value, 'b');
                                    }}
                                    value={this.values.b}
                                />
                            </div>
                        </div>
                        <div className={'standard-padding-3'}>
                            <div
                                style={styles.bubble}
                            >
                                <input
                                    ref={(input) => { this.inputs.c = input; }}
                                    style={styles.input}
                                    type={'text'}
                                    className={'text-dark'}
                                    maxLength={1}
                                    onKeyUp={(value) => {
                                        this._onBackspace(value.key, 'c');
                                    }}
                                    onChange={(value) => {
                                        this._keyIspressed(value.target.value, 'c');
                                    }}
                                    value={this.values.c}
                                />
                            </div>
                        </div>
                        <div className={'standard-padding-3'}>
                            <div
                                style={styles.bubble}
                            >
                                <input
                                    ref={(input) => { this.inputs.d = input; }}
                                    style={styles.input}
                                    type={'text'}
                                    className={'text-dark'}
                                    maxLength={1}
                                    onKeyUp={(value) => {
                                        this._onBackspace(value.key, 'd');
                                    }}
                                    onChange={(value) => {
                                        this._keyIspressed(value.target.value, 'd');
                                    }}
                                    value={this.values.d}
                                />
                            </div>
                        </div>
                        <div className={'standard-padding-3'}>
                            <div
                                style={styles.bubble}
                            >
                                <input
                                    ref={(input) => { this.inputs.e = input; }}
                                    style={styles.input}
                                    type={'text'}
                                    className={'text-dark'}
                                    maxLength={1}
                                    onKeyUp={(value) => {
                                        this._onBackspace(value.key, 'e');
                                    }}
                                    onChange={(value) => {
                                        this._keyIspressed(value.target.value, 'e');
                                    }}
                                    value={this.values.e}
                                />
                            </div>
                        </div>
                        <div className={'standard-padding-3'} style={{paddingRight: 0}}>
                            <div
                                style={styles.bubble}
                            >
                                <input
                                    ref={(input) => { this.inputs.f = input; }}
                                    style={styles.input}
                                    type={'text'}
                                    className={'text-dark'}
                                    maxLength={1}
                                    onKeyUp={(value) => {
                                        this._onBackspace(value.key, 'f');
                                    }}
                                    onChange={(value) => {
                                        this._keyIspressed(value.target.value, 'f');
                                    }}
                                    value={this.values.f}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default CodeInput;