/**
 * Actions for VGPlan
 *
 * @author Robbe De Geyndt <robbe.degeyndt@aware.be>
 */

'use strict';

//imports
import APIAction from './APIAction';

//all different login actions
let VGPlanAction = {

    /**
     *
     * @param {*} vgPlanId
     */
    get: async function(vgPlanId) {
        //api call
        let result = await APIAction.request('/vg_plans/' + vgPlanId, 'GET', true);

        //return
        return result;
    },

    /**
     * Create new VG Plan
     *
     * @param {*} constructionSiteId
     * @param {*} uploadedVGPlanId
     */
    create: async function(constructionSiteId, uploadedVGPlanId = null) {
        //set data
        let data = {
            constructionSiteId: constructionSiteId
        };

        //check if uploadedVGPlan
        if (uploadedVGPlanId) {
            data['uploadedVGPlan'] = uploadedVGPlanId;
        }

        //api call
        let result = await APIAction.request('/vg_plans', 'POST', true, data);

        //return
        return result;
    },

    /**
     *
     * @param {*} vgPlanId
     * @param {*} uploadedVGPlanId
     */
    addUpload: async function(vgPlanId, uploadedVGPlanId) {
        //api call
        let result = await APIAction.request('/vg_plans/' + vgPlanId, 'PATCH', true, {
            addUploadedVGPlanId: uploadedVGPlanId
        });

        //return
        return result;
    },

    /**
     *
     * @param {*} vgPlanId
     * @param {*} uploadedVGPlanId
     */
    removeUpload: async function(vgPlanId, uploadedVGPlanId) {
        //api call
        let result = await APIAction.request('/vg_plans/' + vgPlanId, 'PATCH', true, {
            removeUploadedVGPlanId: uploadedVGPlanId
        });

        //return
        return result;
    },

    /**
     * Get list of VG Plans
     *
     * @param constructionSiteId
     */
    list: async function(constructionSiteId) {
        //api call
        let result = await APIAction.request('/vg_plans', 'GET', true, {constructionSiteId: constructionSiteId});

        //return
        return result;
    },

    /**
     * Delete
     *
     * @param vgPlanId
     */
    delete: async function(vgPlanId) {
        //call api
        let result = await APIAction.request('/vg_plans/' + vgPlanId, 'PATCH', true, {active: false})

        //return
        return result;
    }
};

//export
export default VGPlanAction;
