//imports
import Config from '../../Config.json';
import db from '../Dexie';
import Uuid from 'uuid/v1';

//class definition
export default class CacheRepository {

    //get function
    get = async (url, method, authentication, body, baseUrl, errorRedirect, contentType) => {

        //max date
        let date = new Date();
        date = date.getTime();
        date = date - (Config.CACHE_TIMEOUT * 1000);
        date = new Date(date);

        //get cacheItems
        let cacheItem = await db.cache.where({url: url + '', method: method + '', authentication: authentication + '', body: JSON.stringify(body) + '', baseUrl: baseUrl + '', errorRedirect: errorRedirect + '', contentType: contentType + ''})
            .sortBy('createdAt');

        //get first item
        cacheItem = cacheItem[0] || null;

        //generate random number between 0 & 10
        let random = Math.round(Math.random() * 10);
        //clean cache 10% of the time
        if (random === 0) await this.clean();

        //check if available
        if (cacheItem) {

            //parse boolean item
            cacheItem.authentication = cacheItem.authentication === 'true';
            cacheItem.baseUrl = cacheItem.baseUrl === 'true';
            cacheItem.errorRedirect = cacheItem.errorRedirect === 'true';
            cacheItem.lock = cacheItem.lock === 'true';

            //check time
            if (cacheItem.createdAt.getTime() > date.getTime()) {
                //lock is not allowed to be longer then 2 seconds
                if (cacheItem.lock) {
                    if (Math.abs((new Date()).getTime() - cacheItem.createdAt.getTime()) > 2000) {
                        return null;
                    }
                }

                //get result value
                let result = {
                    lock: cacheItem.lock
                };

                //if lock is false give response
                if (!cacheItem.lock) {
                    result['response'] = JSON.parse(cacheItem.response);
                }

                //return
                return result;
            }
        }

        //if unavailable
        return null;
    }

    //lock a request
    lock = async (url, method, authentication, body, baseUrl, errorRedirect, contentType) => {
        //delete cacheItems
        await db.cache.where({
            url: url + '',
            method: method + '',
            authentication: authentication + '',
            body: JSON.stringify(body) + '',
            baseUrl: baseUrl + '',
            errorRedirect: errorRedirect + '',
            contentType: contentType + ''
        }).delete();

        //insert cache item
        await db.cache.put({
            id: Uuid(),
            url: url + '',
            lock: true + '',
            method: method + '',
            authentication: authentication + '',
            body: JSON.stringify(body) + '',
            baseUrl: baseUrl + '',
            errorRedirect: errorRedirect + '',
            contentType: contentType + '',
            response: null,
            createdAt: new Date()
        });
    }

    //save function
    save = async (url, method, authentication, body, baseUrl, errorRedirect, contentType, response) => {

        //delete cacheItems
        await db.cache.where({
            url: url + '',
            method: method + '',
            authentication: authentication + '',
            body: JSON.stringify(body) + '',
            baseUrl: baseUrl + '',
            errorRedirect: errorRedirect + '',
            contentType: contentType + ''
        }).delete();

        //create cache element
        await db.cache.put({
            id: Uuid(),
            url: url + '',
            lock: false + '',
            method: method + '',
            authentication: authentication + '',
            body: JSON.stringify(body) + '',
            baseUrl: baseUrl + '',
            errorRedirect: errorRedirect + '',
            contentType: contentType + '',
            response: JSON.stringify(response),
            createdAt: new Date()
        });
    }

    //empty cache
    empty = async () => {
        //delete all records
        await db.cache.clear();
    }

    //clean cache
    clean = async () => {
        //max date
        let date = new Date();
        date = date.getTime();
        date = date - (Config.CACHE_TIMEOUT * 1000);
        date = new Date(date);

        //remove items
        await db.cache.where('createdAt').below(date).delete();
    }

    //reset cache, keep locked stuff safe
    reset = async () => {
        //reset inlocked items
        await db.cache.where('lock').equals('false').delete();
    }
}