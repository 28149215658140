import React from 'react';
import G from '../../../Actions/GeneralAction';
import CustomTranslation from "../../Components/CustomTranslation";
import CustomConfirm from "../../Components/CustomConfirm";
import RiskAnalysisAction from "../../../Actions/RiskAnalysisAction";

//define class
class RiskAnalysis extends React.Component {

    //constructor
    constructor (props) {
        super(props);

        //set state
        this.state = {
            isLoaded: false,
            active: true,
            showDelete: false
        };
    }

    //send delete call
    deleteRA = async () => {

        //make call
        let result = await RiskAnalysisAction.delete(this.props.id);

        //hide
        this.setState({
            active: false
        });

        //reload data
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

    //render view
    render = () => {

        if (!this.state.active) {
            return null;
        }

        return (
            <div style={this.props.style}
                 className={this.props.className}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        minHeight: 50,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                        className={'bg-white bordered rounded touchable'}
                         onClick={() => {
                             if (typeof this.props.onClick === 'function') this.props.onClick();
                         }}
                    >
                        <div
                            style={{
                                minWidth: 10,
                                maxWidth: 10,
                                alignSelf: 'stretch'
                            }}
                            className={'rounded ' + (this.props.isFinished ? 'bg-success' : 'bg-primary')}
                        >

                        </div>
                        <div className={'standard-margin'} style={{flexShrink: 1, flexGrow: 1}}>
                            <h5><CustomTranslation value={this.props.title} /></h5>
                        </div>
                        {this.props.date ?
                            <div style={{
                                alignSelf: 'flex-start'
                            }}
                                 className={'vertical-padding-2 standard-padding-2'}
                            >
                                <p className={'label unbold'} style={{paddingBottom: 0}}>
                                    {G.formatDate(this.props.date)}
                                </p>
                            </div>
                        : null}
                    </div>

                    {/* Delete */}
                    <div>
                        {this.props.delete &&
                        <div
                            className={'touchable standard-margin-3'}
                        >
                            <div
                                 className={'bg-dark touchable icon-btn-container'}
                                 onClick={(event) => {
                                     this.setState({showDelete: true});
                                 }}
                            >
                                <i className={'las la-trash text-white icon-btn'}/>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <CustomConfirm
                    show={this.state.showDelete}
                    title={<CustomTranslation value={'KEY.Remove'} />}
                    body={<p><CustomTranslation value={'KEY.Do you wish to delete the risk analysis:'} /> <CustomTranslation value={this.props.title} /> ?</p>}
                    variant={'danger'}
                    cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                    confirmLabel={<CustomTranslation value={'KEY.Remove'} />}
                    onCancel={() => {
                        this.setState({showDelete: false});
                    }}
                    onConfirm={() => {
                        this.deleteRA();
                    }}
                />
            </div>
        );
    }
}

//export
export default RiskAnalysis;