import APIAction from "./APIAction";
import TranslationAction from "./TranslationAction";
import fileDownload from "js-file-download";

//define class
let ReportingAction = {
    //det data based on filter values
    getData: async function(filter) {
        //get language code
        TranslationAction.init();
        let languageCode = await TranslationAction.currentLanguage();
        languageCode = languageCode.code;

        //generate url parameters
        let params = new URLSearchParams({...filter, ...{language_code: languageCode}});

        //call api
        let result = await APIAction.request('/reporting?' + params, 'GET', true);

        //return
        return result;
    },

    //download data based in filter
    downloadData: async function(filter) {
        //get language code
        TranslationAction.init();
        let languageCode = await TranslationAction.currentLanguage();
        languageCode = languageCode.code;

        //generate url parameters
        let params = new URLSearchParams({...filter, ...{language_code: languageCode}});

        //call api
        let result = await APIAction.request('/reporting/download?' + params, 'GET', true, null, true, true, 'json', true);

        //download file
        fileDownload(result, 'result.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    },
};

//export
export default ReportingAction;