import APIAction from "./APIAction";
import {toast} from 'react-hot-toast';
import T from "./TranslationAction";

//class definition
let AssignmentAction = {

    /**
     * Get assignments for LMRA
     */
    getForLastMinuteRiskAnalysis: async function(lastMinuteRiskAnalysisId) {

        //call api
        let result = await APIAction.request('/assignments', 'GET', true, {
            'last_minute_risk_analysis_id': lastMinuteRiskAnalysisId
        });

        //return
        return result;
    },

    /**
     * Get assignments for ICL
     */
    getForInspectionChecklist: async function(inspectionChecklistId) {

        //call api
        let result = await APIAction.request('/assignments', 'GET', true, {
            'inspection_checklist_id': inspectionChecklistId
        });

        //return
        return result;
    },

    /**
     * Get assignments for user (target)
     */
    getForTarget: async function(constructionSiteId) {

        //call api
        let result = await APIAction.request('/assignments', 'GET', true, {
            'user_type': 'target',
            'construction_site_id': constructionSiteId
        });

        //return
        return result;
    },

    /**
     * Get assignments made by user (sender)
     *
     * @param constructionSiteId
     */
    getForSender: async function(constructionSiteId) {

        //call api
        let result = await APIAction.request('/assignments', 'GET', true, {
            'user_type': 'sender',
            'construction_site_id': constructionSiteId
        });

        //return
        return result;
    },

    /**
     * Get assignments for all
     *
     * @param constructionSiteId
     */
    getForAll: async function(constructionSiteId) {

        //call api
        let result = await APIAction.request('/assignments', 'GET', true, {
            'user_type': 'all',
            'construction_site_id': constructionSiteId
        });

        //return
        return result;
    },

    /**
     * Get assignment concepts
     *
     * @param {*} constructionSiteId
     */
    getConcept: async function(constructionSiteId) {
        //call api
        let result = await APIAction.request('/assignments', 'GET', true, {
            'user_type': 'sender',
            'construction_site_id': constructionSiteId,
            'concept': true
        });

        //return
        return result;
    },

    /**
     * Edit assignment
     *
     * @param {*} data
     */
    edit: async function(data) {

        //set data
        let id = data.id;

        let requestData = {
            targetUser: data.targetUser.id,
            title: data.title,
            riskDescription: data.riskDescription,
            recommendation: data.recommendation,
            immediateActionTaken: data.immediateActionTaken,
            dateRequested: data.dateRequested,
            photo: data.imageApiId,
            altPhoto: data.image2ApiId,
            finished: data.finished,
            concept: data.concept || false
        };

        //if positive remark available
        if ('positiveRemark' in data) {
            requestData['positiveRemark'] = data.positiveRemark;
        }

        //call api
        let result = await APIAction.request('/assignments/' + id, 'PATCH', true, requestData);
        //check if result is valid
        if (result && result.id) {
            //show toast
            T.init();
            let toastMessage = await T.translate('KEY.Assignment created!');
            toast.success(toastMessage);
            return true;
        }
    },

    /**
     * Save an assignment
     *
     * @param {*} data
     */
    save: async function(data) {

        //set data
        let id = data.id

        let requestData = {
            finished: data.finished,
            comment: data.comment,
            forwardToUserId: data.forwardUser.id
        };
       
        requestData['followUpPhotoId'] = data.imageId;
        requestData['followUpAltPhotoId'] = data.image2Id;

        //if image change
        if (data.image3Id) {
            requestData['altPhotoId'] = data.image3Id;
        }

        //call api
        let result = await APIAction.request('/assignments/' + id, 'PATCH', true, requestData);

        //return
        return result;
    },

    /**
     * Get one assignment
     *
     * @param {*} assignmentId
     */
    get: async function(assignmentId) {

        //call api
        let result = await APIAction.request('/assignments/' + assignmentId, 'GET', true);

        //return
        return result;
    }
};

//export
export default AssignmentAction;