import React from "react";
import {Button} from "react-bootstrap";
import CustomTranslation from "../../../Components/CustomTranslation";
import {Collapse} from "react-collapse";
import LMRAAction from "../../../../Actions/LMRAAction";
import LMRAPreference from "./LMRAPreference";
import CustomInput from "../../../Components/CustomInput";
import Uuid from "uuid/v1";
import CustomConfirm from "../../../Components/CustomConfirm";

//define class
class LMRANewItem extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            insertElements: [],
            collapsed: true,
            title: '',
            removeModal: null
        };

        //set disabled
        this.disabled = {'disabled': true};
        //set input key
        this.inputKey = Uuid();
        //set button key
        this.buttonKey = Uuid();
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {
        //get lmra Template
        let lmraTemplate = this.props.template;

        //get option
        let option = this.props.option;

        //get inserts
        let inserts = await LMRAAction.getLMRAInserts(lmraTemplate, option);

        //construct option ids
        this.optionIds = [];
        lmraTemplate.lastMinuteRiskInserts.forEach((element, id) => {
            this.optionIds.push(element.id);
        });

        //create insert elements
        let insertElements = [];
        inserts.forEach((insert) => {

            //push
            insertElements.push(
                <LMRAPreference
                    className={'vertical-margin-3'}
                    key={insert.id}
                    title={insert.title}
                    template={this.props.template}
                    existing={insert.status}
                    lmraItem={insert}
                    toggleLMRA={this.toggleLMRA}
                    remove={this.removeInsert}
                />
            );
        });

        //set state
        this.setState({insertElements: insertElements});
    }

    //create a new insert
    createInsert = async () => {
        //get lmraTemplate
        let lmraTemplate = this.props.template;

        //get option
        let option = this.props.option;

        //remark data
        let insertData = {
            title: this.state.title,
            last_minute_risk_analysis_template_id: lmraTemplate.id,
            last_minute_risk_id: option.id
        };

        //call
        let insert = await LMRAAction.createLMRAInsert(insertData);

        //empty box
        this.disabled = {'disabled': true};
        this.inputKey = Uuid();
        this.setState({title: ''});

        //reload data
        await this.updateElements();
    }

    //last minute risk analysis
    toggleLMRA = async (template, lmra_id, value) => {
        let optionIds = Object.assign([], this.optionIds);

        // True: Add to array
        if(value === true) {
            optionIds.push(lmra_id);
        }
        // False: remove from array
        else{
            let index = optionIds.indexOf(lmra_id);
            if (index >= 0) {
                optionIds.splice( index, 1 );
            }
        }

        // Set Data
        let data = {
            last_minute_risk_insert_ids: optionIds,
        };

        //clear interval
        clearInterval(this.toggleLMRAInterval);
        //set interval
        this.toggleLMRAInterval = setInterval(() => {
            //call
            LMRAAction.updateTemplate(template.id, data);
            //clear when done
            clearInterval(this.toggleLMRAInterval);
        }, 1000);

        this.optionIds = optionIds;
    }

    //remove insert
    removeInsert = async (id, title) => {

        //create modal
        let removeModal =
            <CustomConfirm
                show={true}
                variant={'danger'}
                title={<CustomTranslation value={'KEY.Remove'} />}
                body={
                    <p>
                        <CustomTranslation value={'KEY.Do you wish to delete the question:'} /> "{title}" ?
                    </p>
                }
                cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                onCancel={() => this.setState({removeModal: null})}
                confirmLabel={<CustomTranslation value={'KEY.Remove'} />}
                onConfirm={async () => {
                    //update id
                    let result = await LMRAAction.updateLMRAInsert({
                        id: id,
                        active: false
                    });

                    //reload data
                    await this.updateElements();

                    //hide modal
                    this.setState({removeModal: null});
                }}
            />

        //set state
        this.setState({removeModal: removeModal});
    }

    //render view
    render = () => {
        return (
            <div style={this.props.style} className={this.props.className}>
                <div>
                    {this.state.insertElements}
                    <div className={'bordered rounded'}>
                        <Button
                            key={this.buttonKey}
                            block
                            variant={'dark'}
                            onClick={() => {
                                this.buttonKey = Uuid();
                                this.setState({collapsed: !this.state.collapsed});
                            }}
                        >
                            {this.state.collapsed ?
                                <CustomTranslation value={'KEY.Add own question'} />
                                :
                                <CustomTranslation value={'KEY.Hide'} />
                            }
                        </Button>
                        <Collapse isOpened={!this.state.collapsed}>
                            <div className={'standard-margin-2 vertical-margin-2'}>
                                <div key={this.inputKey} className={'vertical-margin-2'} style={{marginTop: 0}}>
                                    <CustomInput
                                        label={<CustomTranslation value={'KEY.Type your own question'} />}
                                        inputProps={{
                                            as: 'textarea',
                                            onKeyUp: (event) => {
                                                if (event.key === 'Enter') {
                                                    setTimeout(() => {
                                                        this.createInsert();
                                                    }, 700);
                                                }
                                            }

                                        }}
                                        onChangeText={(text) => {
                                            if (text.length > 0) {
                                                this.disabled = {};
                                            } else this.disabled = {'disabled': true};
                                            this.setState({title: text.trim()});
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        block
                                        variant={'primary'}
                                        {...this.disabled}
                                        onClick={() => this.createInsert()}
                                    >
                                        <CustomTranslation value={'KEY.Add question'} />
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                        {/* Modal */}
                        {this.state.removeModal}
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default LMRANewItem;