import React from "react";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import {generatePath, useParams} from "react-router-dom";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import Project from "../Home/Components/Project";
import {Breadcrumb, Button} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import LMRAAction from "../../Actions/LMRAAction";
import LMRAItem from "./Components/LMRAItem";
import Uuid from "uuid/v1";
import LMRAParent from "./Components/LMRAParent";
import {toast} from "react-hot-toast";

class LMRAScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {

        //get constrcutions site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //init lmra
        let LMRA = {};

        //create lmra if needed
        if (this.state.LMRA) {
            LMRA = await LMRAAction.getLMRA(this.state.LMRA.id);
        } else if (this.props.params.lmra_id) {
            LMRA = await LMRAAction.getLMRA(this.props.params.lmra_id);
        } else  {
            LMRA = await LMRAAction.createLMRA(cs.id);
        }

        //get lmra items
        let items = await LMRAAction.getItems(LMRA.id);

        //set array
        let LMRAItems = [];
        let LMRAParents = [];

        //props
        let props = this.props;

        //loop
        items.forEach(element => {

            //if element is risk
            if (element.lastMinuteRisk) {
                //get parent
                let parent = element.lastMinuteRisk.parent;

                //check if array already exists
                if (typeof LMRAItems[parent.id] === 'undefined') {
                    LMRAItems[parent.id] = [];
                    LMRAParents.push(parent);
                }

                LMRAItems[parent.id].push(
                    <LMRAItem
                        key={element.id + Uuid()}
                        title={<CustomTranslation value={element.lastMinuteRisk.title} />}
                        value={element.status}
                        className={'vertical-margin-3'}
                        onValueChange={(value) => {
                            element.status = value;
                            LMRAAction.updateItem(element).then((result) => {
                                if (value === false) {
                                    window.location.href = generatePath('/construction_site/:construction_site_id/last_minute_risk_analysis/:lmra_id/item/:item_id', {construction_site_id: cs.id, lmra_id: LMRA.id, item_id: result.id});
                                }
                            });
                        }}
                    />
                );
            }

            //if element is insert
            if (element.lastMinuteRiskInsert) {
                //get parent
                let parent = element.lastMinuteRiskInsert.lastMinuteRisk;

                //check if array already exists
                if (typeof LMRAItems[parent.id] === 'undefined') {
                    LMRAItems[parent.id] = [];
                    LMRAParents.push(parent);
                }

                //add to risks
                LMRAItems[parent.id].push(
                    <LMRAItem
                        key={element.id + Uuid()}
                        title={element.lastMinuteRiskInsert.title}
                        value={element.status}
                        className={'vertical-margin-3'}
                        onValueChange={(value) => {
                            element.status = value;
                            LMRAAction.updateItem(element).then((result) => {
                                if (value === false) {
                                    window.location.href = generatePath('/construction_site/:construction_site_id/last_minute_risk_analysis/:lmra_id/item/:item_id', {construction_site_id: cs.id, lmra_id: LMRA.id, item_id: result.id});
                                }
                            });
                        }}
                    />
                );
            }
        });

        //set array
        let LMRAObjects = [];

        //loop and create parent element
        LMRAParents.forEach((element, index) => {

            LMRAObjects.push(
                <LMRAParent
                    key={element.id}
                    title={<CustomTranslation value={element.title} />}
                    lmraList={LMRAItems[element.id]}
                    lmra={LMRA}
                    risk={element}
                    className={'vertical-padding-2'}
                    style={{paddingTop: 0}}
                />
            );
        });

        //set state
        this.setState({
            data: {
                constructionSite: cs
            },
            LMRAObjects: LMRAObjects,
            LMRA: LMRA
        });

    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => {
                                toast.success(<CustomTranslation value={'KEY.Last Minute risk analysis saved.'} />);
                                setTimeout(() => {
                                    window.location.href = generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id});
                                }, 1000);
                            }}
                        >
                            <CustomTranslation value={'KEY.Finish Last Minute Risk Analysis'} />
                        </Button>
                    }
                >
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{position: 'sticky', top: '2rem', zIndex: 9}}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>

                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Last Minute Risk Analysis'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div className={'vertical-margin'}>
                                <h1>
                                    <CustomTranslation value={'KEY.Last Minute Risk Analysis'}/>
                                </h1>
                            </div>

                            {/* List */}
                            {this.state.LMRAObjects}

                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <LMRAScreen {...props} params={params} />;
}