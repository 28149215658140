import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import UserAction from "../../../Actions/UserAction";
import LicenseAction from "../../../Actions/LicenseAction";
import T from "../../../Actions/TranslationAction";
import Loader from "../../Components/Loader";
import CustomInput from "../../Components/CustomInput";
import CustomSelect from "../../Components/CustomSelect";
import CustomCheckBox from "../../Components/CustomCheckBox";
import {Button} from "react-bootstrap";
import CompanyAction from "../../../Actions/CompanyAction";

class CompanyStructureTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //init current user
    initCurrentUser = async () => {
        if (!this.state.currentUser) {

            //get current user
            let currentUser = this.props.user;
            let userCompanyRights = await UserAction.getUserRightByCompanyId(this.props.defaultCompany.id);
            //licenses
            let licenses = await LicenseAction.getLicensesByUserAndCompany(this.props.defaultCompany.id);

            //check licenses
            if (licenses.length === 0) {
                userCompanyRights.adminRights = false;
                userCompanyRights.managementRights = false;
            }

            //set state
            this.setState({
                currentUser: currentUser,
                userCompanyRights: userCompanyRights,
                licenses: licenses
            });

            //return
            return userCompanyRights;
        }

        //return
        return this.state.userCompanyRights;
    }

    //update elements
    updateElements = async () => {

        //init current user
        this.initCurrentUser();

        //company
        let company = this.props.defaultCompany;

        //get users
        let users = await UserAction.getUserListCompany(this.props.defaultCompany.id);

        //-- make elements
        let userElements = [];
        let userElementsMultiSelect = [];

        //-- add empty option
        userElements.push({label: await T.translate('KEY.Empty'), value: '#EMPTY#'});
        //-- loop
        users.forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            userElements.push({label: label, value: element['@id']});
            userElementsMultiSelect.push({name: label, id: element['@id']});
        });

        //company spokesperson label
        let cspLabel = '';
        if (company.spokesPersonCrisisCommunication) {
            cspLabel =
                (company.spokesPersonCrisisCommunication.firstName || company.spokesPersonCrisisCommunication.lastName)
                    ?
                    (company.spokesPersonCrisisCommunication.firstName || '') + ' ' + (company.spokesPersonCrisisCommunication.lastName || '')
                    :
                    company.spokesPersonCrisisCommunication.email;
        }

        //set state
        this.setState(
            {
                company: company,
                companyDirector: company.companyDirector ? company.companyDirector : null,
                preventionAdvisor: company.preventionAdvisor ? company.preventionAdvisor : null,
                spokesPersonCrisisCommunication: {'label': cspLabel, 'value': company.spokesPersonCrisisCommunication ? company.spokesPersonCrisisCommunication['@id'] : null},
                externalPreventionService: company.externalPreventionService,
                insurer: company.insurer,
                policyStatement: company.policyStatement,
                certificates: company.certificates,
                certificatesIgnore: company.certificatesIgnore,
                users: userElements,
                usersMultiSelect: userElementsMultiSelect,
            });
    }

    updateCompanyForm = async () => {
        // Update user
        let formData = {
            'companyDirector': this.state.companyDirector ? this.state.companyDirector : null,
            'preventionAdvisor': this.state.preventionAdvisor ? this.state.preventionAdvisor : null,
            'spokesPersonCrisisCommunication': this.state.spokesPersonCrisisCommunication ? this.state.spokesPersonCrisisCommunication.value : null,
            'externalPreventionService': this.state.externalPreventionService,
            'insurer': this.state.insurer,
            'policyStatement': this.state.policyStatement,
            'certificates': this.state.certificates,
            'certificatesIgnore': this.state.certificatesIgnore,
        };

        //call api
        let response = await CompanyAction.editCompany(this.state.company.id, formData);
    }

    //render
    render = () => {
        if (this.state.company && this.state.currentUser) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Company Structure'}/></h2>
                    </div>
                    {/* Form */}
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            label={<CustomTranslation value={'KEY.Company Director'} />}
                            iconName={'las la-user'}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.companyDirector}
                            onChangeText={(value) => {this.setState({'companyDirector': value})}}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            label={
                                this.state.company.country.countryCode.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KEY.Preventiemedewerker/VG-functionaris'} /> :
                                <CustomTranslation value={'KEY.Prevention Advisor'} />
                            }
                            iconName={'las la-user'}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.preventionAdvisor}
                            onChangeText={(value) => {this.setState({'preventionAdvisor': value})}}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomSelect
                            label={<CustomTranslation value={'KEY.Spokesperson Crisis Communication'} />}
                            iconName={'las la-user'}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            options={this.state.users}
                            selected={this.state.spokesPersonCrisisCommunication}
                            onSelect={(value, label) => {
                                this.setState({'spokesPersonCrisisCommunication': {label: label, value: value}});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            label={
                                this.state.company.country.countryCode.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KEY.Arbodienst of bedrijfsarts'} /> :
                                <CustomTranslation value={'KEY.External Prevention Service'} /> 
                            }
                            iconName={'las la-quote-left'}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.externalPreventionService}
                            onChangeText={(text) => this.setState({externalPreventionService: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            label={<CustomTranslation value={'KEY.Insurer'} />}
                            iconName={'las la-quote-left'}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.insurer}
                            onChangeText={(text) => this.setState({insurer: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            label={<CustomTranslation value={'KEY.Policy Statement'} />}
                            iconName={'las la-quote-left'}
                            inputProps={{
                                as: 'textarea',
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.policyStatement}
                            onChangeText={(value) => this.setState({policyStatement: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            label={<CustomTranslation value={'KEY.Certificates'} />}
                            iconName={'las la-quote-left'}
                            inputProps={{
                                as: 'textarea',
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.certificates}
                            onChangeText={(value) => this.setState({certificates: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Applicable?'} />}
                            disabled={!this.state.userCompanyRights.adminRights}
                            value={!this.state.certificatesIgnore}
                            onValueChange={(value) => this.setState({certificatesIgnore: !value})}
                        />
                    </div>
                    {this.state.userCompanyRights.adminRights ?
                        <div className={'vertical-margin-2'}>
                            <Button
                                block
                                variant={'dark'}
                                onClick={() => this.updateCompanyForm()}
                            >
                                <CustomTranslation value={'KEY.Update Company Structure'} />
                            </Button>
                        </div>
                        :
                        null
                    }
                </div>
            );
        } else {
            return (
                <Loader />
            );
        }
    }
}

//export
export default CompanyStructureTab;