import React from "react";
import CustomInput from "./CustomInput";
import CustomConfirm from "./CustomConfirm";
import CustomTranslation from "./CustomTranslation";

class CustomMultiListInput extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set fields
        this.fields = this.props.fields;

        //set results
        this.results = [];

        //set new item
        this.newItem = {};

        //loop & find
        if (this.props.values) {
            this.props.values.forEach((item) => {

                let partResult = {};

                for (let [fieldKey, field] of Object.entries(this.fields)) {

                    let found = false;

                    //loop fields
                    for (let [itemKey, itemField] of Object.entries(item)) {
                        if (fieldKey === itemKey) {
                            partResult[fieldKey] = itemField;
                            found = true;
                        }
                    }

                    //if not found
                    if (!found) {
                        partResult[fieldKey] = null;
                    }
                }

                //set value
                this.results.push(partResult);
            });
        }

        //set state
        this.state = {
            inputs: [],
            displayFields: [],
            modalVisible: false
        }
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //if text is changed run this
    handleInputChange = (text, index) => {

        //add/update to input list
        this.newItem[index] = text;
    }

    //add field
    handleAddClick = async () => {
        //create state object
        let state = {modalVisible: true};

        //add new item if needed
        if (Object.keys(this.newItem).length < 1) {
            let newItem = {};
            for (let [key, value] of Object.entries(this.fields)) {
                newItem[key] = '';
            }

            //add
            this.newItem = newItem;
        }
        //update all items
        await this.updateElements();
        //set state
        this.setState(state);
    }

    //create new field
    handleCreateClick = async () => {
        //add to results
        this.results.push(this.newItem);

        //reset new item
        this.newItem = {};

        //update all items
        await this.updateElements();

        //set state
        this.setState({
            modalVisible: false
        });

        //return
        if (this.props.onChangeItems) {
            this.props.onChangeItems(this.results);
        }
    }

    // add field
    handleRemoveClick = async (index) => {
        //remove
        this.results.splice(index, 1);

        //update list
        await this.updateElements();

        //return
        if (this.props.onChangeItems) {
            this.props.onChangeItems(this.results);
        }
    }

    //update elements
    updateElements = async () => {
        //create inputs
        let inputs = [];
        for (let [key, field] of Object.entries(this.fields)) {
            inputs.push(
                <CustomInput
                    key={key}
                    label={field}
                    iconName={'las la-quote-left'}
                    className={'vertical-padding-3'}
                    onChangeText={(value) => {
                        this.handleInputChange(value, key);
                    }}
                    value={this.newItem[key]}
                />
            );
        }

        //show fields
        let displayFields = [];
        this.results.forEach((result, i) => {

            //loop fields
            let fields = [];
            for (let [fieldKey, field] of Object.entries(this.fields)) {
                for (let [resKey, resItem] of Object.entries(result)) {
                    if (fieldKey === resKey) {
                        fields.push(
                            <div key={resKey}>
                                <p className={'label'} style={{paddingBottom: 0}}>{ field }</p>
                                <p className={'vertical-margin-3'} style={{marginTop: 0}}>{ resItem }</p>
                            </div>
                        );
                    }
                }
            }

            //push items
            displayFields.push(
                <div
                    key={i}
                    className={'vertical-margin-3'}
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
                >
                    <div className={'bordered rounded standard-padding-2 vertical-padding-2'}
                         style={{flexGrow: 1, flexShrink: 1}}
                    >
                        { fields }
                    </div>
                    <div
                        className={'standard-margin-2'}
                        style={{marginRight: 0}}
                    >
                        <span
                            className={'icon-container bg-danger touchable'}
                            onClick={(event) => {
                                this.handleRemoveClick(i);
                            }}
                        >
                            <i className={'las la-minus icon text-white'} />
                        </span>
                    </div>
                </div>
            );
        });

        //set in state
        this.setState({
            inputs: inputs,
            displayFields: displayFields
        });
    }

    //render view
    render = () => {
        return (
            <div className={this.props.className} style={this.props.style}>
                <div>
                    {/* Label */}
                    <div style={{
                        display: 'flex',
                        flex: 'row'
                    }}>
                        {this.props.iconName &&
                            <div
                                className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                            >
                                <i className={this.props.iconName + ' icon'} />
                            </div>
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexGrow: 1,
                            flexShrink: 1
                        }}>
                            <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')} style={{paddingBottom: 0}}>{this.props.label}</span>
                        </div>
                        <div>
                            <span
                                className={'icon-container bg-success touchable'}
                                onClick={(event) => {
                                    this.handleAddClick();
                                }}
                            >
                                <i className={'las la-plus icon text-white'} />
                            </span>
                        </div>
                    </div>
                    {/* Content */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            className={'bordered-left standard-margin-2'}
                            style={{marginLeft: 0}}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                        >
                            {this.state.displayFields}
                        </div>
                    </div>
                    {/* Modal */}
                    <CustomConfirm
                        show={this.state.modalVisible}
                        title={this.props.label}
                        variant={'success'}
                        body={
                            <div>
                                {this.state.inputs}
                            </div>
                        }
                        cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                        onCancel={() => {
                            this.setState({modalVisible: false});
                        }}
                        confirmLabel={<CustomTranslation value={'KEY.Save'} />}
                        onConfirm={() => {
                            this.handleCreateClick();
                        }}
                    />
                </div>
            </div>
        );
    }
}

//export
export default CustomMultiListInput;