import React from "react";
import GeneralAction from "../../../Actions/GeneralAction";
import { generatePath } from "react-router-dom";
import CustomTranslation from "../../Components/CustomTranslation";

//define class
class Assignment extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {

        }

        //set status
        this.status = {};
        if (this.props.data.dateFinished) {
            this.status = ' bg-success';
        } else {
            this.status = ' bg-primary';
        }
    }

    //on mount
    componentDidMount = () => {
        this.setState({
            navigateFn: this.navigateAssignment
        });
    }

    //navigate to LMRA Item
    navigateAssignment = async () => {

        //is in or out?
        let type = '';
        if (!(this.props.type === 'out')) {
            type = 'in';
        } else {
            type = 'out';
        }

        //navigate
        if ('edit' in this.props) {
            window.location.href = generatePath('/construction_site/:construction_site_id/edit_assignment/:assignment_id', { construction_site_id: this.props.constructionSite.id, assignment_id: this.props.data.id });
        } else {
            window.location.href = generatePath('/construction_site/:construction_site_id/assignment/:assignment_id/:direction', { construction_site_id: this.props.constructionSite.id, assignment_id: this.props.data.id, direction: type })
        }
    }

    //render view
    render() {

        let type = '';

        if (this.props.data.message && !(this.props.data.accessibilityOverview)) {
            type = <CustomTranslation value={'KEY.RA message:'} />;
        } else if (this.props.data.message && this.props.data.accessibilityOverview) {
            type = <CustomTranslation value={'KEY.Accessibility message:'} />;
        } else if (this.props.data.lastMinuteRiskAnalysisItem) {
            type = <CustomTranslation value={'KEY.LMRA:'} />;
        } else if (this.props.data.observation && !(this.props.data.observation.positiveRemark)) {
            type = <CustomTranslation value={'KEY.O:'} />;
        } else if (this.props.data.observation && this.props.data.observation.positiveRemark) {
            type = <CustomTranslation value={'KEY.PR:'} />;
        } else if (this.props.data.taskRiskAnalysisItem) {
            type = <CustomTranslation value={'KEY.RA:'} />;
        } else if (this.props.data.inspectionChecklistItem && !(this.props.data.inspectionChecklistItem.positiveRemark)) {
            type = <CustomTranslation value={'KEY.ICL:'} />;
        } else if (this.props.data.inspectionChecklistItem && this.props.data.inspectionChecklistItem.positiveRemark) {
            type = <CustomTranslation value={'KEY.PICL:'} />;
        } else if (this.props.data.toolbox) {
            type = <CustomTranslation value={'KEY.TB:'} />;
        }
        
        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <div
                    className={'touchable rounded bordered bg-white'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                    onClick={this.state.navigateFn}
                >
                    <div
                        className={'rounded' + this.status}
                        style={{
                            minWidth: 10,
                            maxWidth: 10
                        }}
                    />
                    <div
                        className={'standard-padding vertical-padding-2'}
                        style={{
                            flexGrow: 1,
                            flexShrink: 1
                        }}
                    >
                        {(!(this.props.type === 'out')) &&
                            <h3>
                                {(this.props.data.sender.firstName || this.props.data.sender.lastName)
                                    ?
                                    (this.props.data.sender.firstName || '') + ' ' + (this.props.data.sender.lastName || '')
                                    :
                                    (this.props.data.sender.email || '')
                                }
                            </h3>
                        }
                        {(this.props.type === 'out') &&
                            <h3>
                                {(this.props.data.targetUser.firstName || this.props.data.targetUser.lastName)
                                    ?
                                    (this.props.data.targetUser.firstName || '') + ' ' + (this.props.data.targetUser.lastName || '')
                                    :
                                    (this.props.data.targetUser.email || '')
                                }
                            </h3>
                        }
                        <h5
                            className={'unbold'}
                        >
                            {type} {this.props.data.title}
                        </h5>
                    </div>
                    <div
                        className={'standard-padding vertical-padding-2'}
                        style={{ paddingLeft: 0 }}
                    >
                        <p className={'label unbold'}>
                            {this.props.data.createdAt != null ? GeneralAction.formatDate(this.props.data.createdAt) : ''}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default Assignment;