import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomInput from "../../Components/CustomInput";
import {Button} from "react-bootstrap";
import {toast} from "react-hot-toast";
import UserAction from "../../../Actions/UserAction";
import Uuid from "uuid/v1";

class PasswordPreferencesTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            uniqueId: Uuid()
        };
    }

    updatePasswordForm = async () => {
        // If no plain password show toast
        if(!this.state.newPassword) {
            toast.error(<CustomTranslation value={'KEY.Please fill in the password.'} />);
            return false;
        }

        // Update user
        let formData = {
            'plainPassword': this.state.newPassword,
        };

        //call to api
        let response = await UserAction.requestChangePassword(formData);

        //reset
        this.setState({
            newPassword: null,
            uniqueId: Uuid()
        });
    }

    //render
    render = () => {
        return (
            <div style={this.props.style} className={this.props.className} key={this.props.key}>
                {/* Title */}
                <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                    <h2 className={'text-left'}><CustomTranslation value={'KEY.Change password'} /></h2>
                </div>
                {/* Form */}
                <div className={'vertical-margin-2'}>
                    <CustomInput
                        key={this.state.uniqueId}
                        label={<CustomTranslation value={'KEY.New password'} />}
                        iconName={'las la-lock'}
                        onChangeText={(text) => {this.setState({newPassword: text})}}
                        inputProps={{
                            type: 'password'
                        }}
                    />
                </div>
                <div className={'vertical-margin-2'}>
                    <Button
                        block
                        variant={'dark'}
                        onClick={() => this.updatePasswordForm()}
                    >
                        <CustomTranslation value={'KEY.Update Password'} />
                    </Button>
                </div>
            </div>
        );
    }
}

//export
export default PasswordPreferencesTab;