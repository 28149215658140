import logo from '../../Images/logo-short.png';
import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import CustomTranslation from "./CustomTranslation";
import UserAction from "../../Actions/UserAction";
import TranslationAction from "../../Actions/TranslationAction";
import {generatePath} from "react-router-dom";
import Config from "../../Config.json";
import LicenseAction from "../../Actions/LicenseAction";

class CustomHeader extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            user: null
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //change language
    onChangeLanguage = async (lang) => {
        //set language
        await TranslationAction.setLanguage(lang);
        //refresh
        window.location.reload();
    }

    //log out
    onLogOut = async () => {
        //do logout action
        await UserAction.logoutSubmit();
        //go to login screen
        window.location.href = generatePath('/login');
    }

    //update elements
    updateElements = async () => {
        //get current user
        let user = await UserAction.getCurrentUser();

        //get current language
        let currentLanguage = await TranslationAction.currentLanguage();
        //get all languages
        let languages = await TranslationAction.languages();
        //create language options
        let languageOptions = [];
        for (let language of languages) {
            let languageProps = {};
            if (language.code === currentLanguage.code) languageProps.disabled = true;
            else {
                languageProps.onClick = (event) => {
                    event.preventDefault();
                    this.onChangeLanguage(language.code);
                };
            }
            languageOptions.push(
                <NavDropdown.Item {...languageProps} key={language.code}>
                    {language.code === currentLanguage.code &&
                        <i className={'las la-check'} />
                    }
                    {language.name}
                </NavDropdown.Item>
            );
        }

        //get licenses
        let reporting = false;
        let licenses = await LicenseAction.getLicenses(true);
        for (let [index, license] of Object.entries(licenses)) {
            if (license.company) {
                let right = await UserAction.getUserRightByCompanyId(license.company.id);
                if (right.adminRights || right.managementRights) {
                    reporting = true;
                    break;
                }
            }
        }

        //set user in state
        this.setState({
            user: user,
            currentLanguage: currentLanguage,
            languageOptions: languageOptions,
            reporting: reporting
        });
    }

    render = () => {

        let userName = null;
        if (this.state.user) {
            //name to display
            userName = this.state.user.email;
            if (this.state.user.firstName && this.state.user.lastName) {
                userName = this.state.user.firstName + ' ' + this.state.user.lastName;
            }
        }

        //return
        return (
            <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', zIndex: 10}} className={'shadow'}>
                <div style={{maxWidth: this.props.extraWide ? 1500 : 1000, width: '100%'}}>
                    <Navbar bg="white" expand="lg">
                        <Navbar.Brand href="/">
                            <img alt="CSC logo" src={logo} height="30"/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            {this.state.currentLanguage &&
                                <Nav className="mr-auto">
                                    <NavDropdown title={this.state.currentLanguage.name} id={'language-dropdown'}>
                                        {this.state.languageOptions}
                                    </NavDropdown>
                                </Nav>
                            }
                            {userName &&
                                <Nav>
                                    <NavDropdown id={'user-dropdown'}
                                                 alignRight
                                                 title={
                                                     <span style={{display: 'inline-block'}}>

                                                            {this.state.user.picture ?
                                                                <span
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        backgroundImage: 'url("' + this.state.user.picture.resizedPath + '")',
                                                                        height: '2rem',
                                                                        width: '2em',
                                                                        minWidth: '2rem',
                                                                        minHeight: '2rem',
                                                                        backgroundSize: 'cover',
                                                                        backgroundRepeat: 'no-repeat',
                                                                        backgroundPosition: 'center',
                                                                        borderRadius: '50%',
                                                                        position: 'absolute',
                                                                        top: '0.20rem',
                                                                        left: '-0.2rem'
                                                                    }}
                                                                />
                                                                :
                                                                <span className={'text-primary'} style={{display: 'inline-block', position: 'absolute', top: '0.20rem', left: '0rem'}}>
                                                                    <i style={{fontSize: '2rem'}} className={'las la-user-circle'} />
                                                                </span>
                                                            }
                                                            <span style={{display: 'inline-block', marginLeft: '2rem'}}>{userName}</span>
                                                     </span>
                                                }
                                            >
                                        {this.state.reporting ?
                                            <React.Fragment>
                                                <NavDropdown.Item
                                                    href={generatePath('/reporting/actions')}
                                                >
                                                    <i className={'las la-flag text-primary'}/>&nbsp;<CustomTranslation
                                                    value={'KEY.Reporting - Actions'}/>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item
                                                    href={generatePath('/reporting')}
                                                >
                                                    <i className={'las la-flag text-primary'}/>&nbsp;<CustomTranslation
                                                    value={'KEY.Reporting - Tools'}/>
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider/>
                                            </React.Fragment> : null
                                        }
                                        <NavDropdown.Item
                                            href={generatePath('/preferences')}
                                        >
                                            <i className={'las la-cog text-primary'}/>&nbsp;<CustomTranslation value={'KEY.Preferences'}/>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={(event) => {
                                            event.preventDefault();
                                            window.open('https://www.cscapp.eu/tarieven/', '_blank');
                                        }}>
                                            <i className={'las la-sync text-primary'}/>&nbsp;<CustomTranslation value={'KEY.Subscriptions'}/>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item onClick={(event) => {
                                            event.preventDefault();
                                            window.open('https://www.cscapp.eu/privacy-policy/', '_blank');
                                        }}>
                                            <i className={'las la-shield-alt text-primary'}/>&nbsp;<CustomTranslation value={'KEY.Privacy policy'}/>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={(event) => {
                                            event.preventDefault();
                                            window.open('https://www.cscapp.eu/privacypolicystores', '_blank');
                                        }}>
                                            <i className={'las la-file-contract text-primary'}/>&nbsp;<CustomTranslation value={'KEY.Terms of use'}/>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.onLogOut();
                                            }}
                                        >
                                            <i className={'las la-sign-out-alt text-primary'}/>&nbsp;<CustomTranslation value={'KEY.Logout'}/>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            }
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        );
    }
}

//export
export default CustomHeader;