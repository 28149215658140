//imports
import APIAction from './APIAction';
import T from './TranslationAction';
import {toast} from 'react-hot-toast';

//define class
let LicenseAction = {

    //get licenses
    getLicenses: async function(filterCurrentUser = false) {
        //set data
        let data = {
            filter_current_user: filterCurrentUser,
        };

        //fetch licenses
        let licenses = await APIAction.request('/licenses', 'GET', true, data);

        //return
        return licenses;

    },

    //get licenses by company
    getLicensesByCompany: async function(companyId) {
        //set data
        let data = {
            company_id: companyId,
        };

        //fetch licenses
        let licenses = await APIAction.request('/licenses', 'GET', true, data);

        //return
        return licenses;
    },

    //get license for company and user
    getLicensesByUserAndCompany: async function(companyId) {
        //set data
        let data = {
            company_id : companyId,
            filter_current_user: true
        }

        //fetch licenses
        let licenses = await APIAction.request('/licenses', 'GET', true, data);

        //return
        return licenses;
    },

    //get license by id
    getLicense: async function(id) {
        //set data
        let data = {
        };

        //fetch licenses
        let license = await APIAction.request('/licenses/' + id , 'GET', true, data);

        //return
        return license;

    },

    //edit company based on input
    editLicense: async function(id, data) {
        //call api
        let result = await APIAction.request('/licenses/' + id, 'PATCH', true, data);

        //check if result is valid
        if (result && result.id) {
            //navigate to correct screen
            T.init();
            let toastMessage = await T.translate('KEY.License Info saved.');
            toast.success(toastMessage);
        }
    },
};

//export
export default LicenseAction;