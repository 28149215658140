import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import Uuid from "uuid/v1";
import Loader from "../../Components/Loader";
import ICLAction from "../../../Actions/ICLAction";
import ICLPreference from "./Components/ICLPreference";
import ICLNewItem from "./Components/ICLNewItem";

class ICLPreferencesTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //inspection checklist
    toggleICL = async (template, icl_id, value) => {
        let optionIds = Object.assign([], this.optionIds);

        // True: Add to array
        if(value === true){
            optionIds.push(icl_id);
        }
        // False: remove from array
        else{
            let index = optionIds.indexOf(icl_id);
            if (index >= 0) {
                optionIds.splice( index, 1 );
            }
        }

        // Set Data
        let data = {
            inspection_checklist_option_ids: optionIds,
        };

        //clear interval
        clearInterval(this.toggleICLInterval);
        //set interval
        this.toggleICLInterval = setInterval(() => {
            //call
            ICLAction.updateTemplate(template.id, data);
            //clear when done
            clearInterval(this.toggleICLInterval);
        }, 1000);

        this.optionIds = optionIds;
    }

    //update elements
    updateElements = async () => {

        //get templates for company (currently only one /company)
        let options = await ICLAction.getICLOptions();

        let templates = await ICLAction.getTemplates(this.props.defaultCompany.id);
        let template = null;
        templates.forEach(element => {
            if(!template){
                template = element;
            }
        });

        //get options from template
        if(template){
            //set array
            let inspectionChecklistOptions = template.inspectionChecklistOptions;
            let ICLPreferenceItems = [];
            let ICLParents = [];

            //construct optionIds
            this.optionIds = [];
            template.inspectionChecklistOptions.forEach((element, id) => {
                this.optionIds.push(element.id);
            });


            //loop
            options.forEach(element => {
                // Only use lowest level (no children)
                if(element.children.length === 0){
                    let parent = element.parent;

                    //check if array already exists
                    if (typeof ICLPreferenceItems[parent.id] === 'undefined') {
                        ICLPreferenceItems[parent.id] = [];
                        ICLParents.push(parent);
                    }

                    let existing = null;
                    inspectionChecklistOptions.forEach(inspectionChecklistOption => {
                        if(inspectionChecklistOption.id === element.id){
                            existing = inspectionChecklistOption;
                        }
                    });

                    ICLPreferenceItems[parent.id].push(
                        <ICLPreference
                            className={'vertical-margin-3'}
                            key={element.id + Uuid()}
                            title={<CustomTranslation value={element.title} />}
                            template={template}
                            iclItem={element}
                            existing={existing}
                            toggleICL={this.toggleICL}
                        />
                    );
                }
            });

            //set array
            let ICLObjects = [];

            //loop and create parent element
            ICLParents.forEach((element, index) => {

                ICLObjects.push(
                    <div key={element.id} className={'bordered rounded standard-padding-2 vertical-padding-2 vertical-margin-2'}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <p className={'label unbold'} style={{paddingBottom: 0, flexGrow: 1, flexShrink: 1, wordBreak: 'break-word'}}>
                                <CustomTranslation value={element.title} />
                            </p>
                            <div className={'standard-padding-2'} style={{paddingRight: 0}}>
                                <i className={'las la-thumbs-up text-success'} style={{fontSize: '1.4rem'}} />
                            </div>
                        </div>

                        {/* LIST */}
                        {ICLPreferenceItems[element.id]}

                        {/* ADD NEW ITEM */}
                        <ICLNewItem
                            template={template}
                            option={element}
                        />
                    </div>
                );
            });

            //set state
            this.setState({
                ICLObjects: ICLObjects
            });
        }
    }

    //render
    render = () => {
        if (this.state.ICLObjects) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation
                            value={'KEY.Inspection Checklist Template'}/></h2>
                    </div>
                    {/* Content */}
                    <div className={'vertical-margin-2'}>
                        {this.state.ICLObjects}
                    </div>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default ICLPreferencesTab;