//imports
import React from "react";
import Risk from "./Risk";
import RiskAction from "../../../Actions/RiskAction";
import {Collapse} from "react-collapse";
import RiskAnalysisAction from "../../../Actions/RiskAnalysisAction";
import Uuid from "uuid/v1";
import {generatePath} from "react-router-dom";
import { Button } from "react-bootstrap";
import CustomTranslation from "../../Components/CustomTranslation";

//define class
class RiskList extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //default open lvls
        this.openLvls = [3, 6];

        //colors
        this.colors = [
            'rgba(254,235,197,1)',
            'rgba(255,247,221,1)',
            'rgba(242,242,242,1)',
            'rgba(255,221,221,1)',
            'rgba(255,255,255,1)'
        ];

        //wait for status update
        this.statusWait = false;

        //wait for collapse update
        this.collapseWait = false;

        //wait for matrix update
        this.updateWait = false;

        //wait interval
        this.waitInterval = null;

        //last state in json form
        this.riskMatrixObject = [];
        this.riskMatrix = [];

        //set input start
        this.inputStart = [];

        //set scroll position
        this.scrollPosition = 0;

        //state
        this.state = {
            riskMatrixObject: [],
            riskMatrix: []
        }
    }

    //on mount
    componentDidMount = () => {
        this.updateElements(this.props);
    }

    //update function
    updateElements = async (props) => {

        //get stored matrix from storage
        let riskMatrix = sessionStorage.getItem('risk-matrix-' + props.riskAnalysis.id);

        //get risk matrix
        if (riskMatrix === null) {
            riskMatrix = await this.createRiskMatrix(props.riskAnalysis);
        } else {
            riskMatrix = JSON.parse(riskMatrix);
            sessionStorage.removeItem('risk-matrix-' + props.riskAnalysis.id);
        }

        //create matrix object
        let riskMatrixObject = await this.createMatrixObject(riskMatrix);

        //set state
        this.riskMatrixObject = riskMatrixObject;
        this.riskMatrix = riskMatrix;
        this.setState({
            riskMatrixObject: riskMatrixObject,
            riskMatrix: riskMatrix
        }, () => {
            //scroll in to view if needed
            let tag = window.location.hash;
            if (tag) {
                tag = tag.substr(1);
                let risk = document.getElementById(tag);
                risk.scrollIntoView({block: 'center'});
                risk.classList.add('pulsate-bck');
            }
        });
    }

    //update matrix state
    updateMatrixDelay = async () => {
        //clear interval
        clearInterval(this.waitInterval);

        //get scroll position
        let scrollFrame = document.getElementById('scroll-frame');
        this.scrollPosition = scrollFrame.scrollTop;

        //if no input is being processed anymore, render
        if (Object.keys(this.inputStart).length < 1) {
            this.setState({riskMatrix: this.riskMatrix, riskMatrixObject: this.riskMatrixObject}, () => {
                //set scroll position
                let scrollFrame = document.getElementById('scroll-frame');
                scrollFrame.scrollTop = this.scrollPosition;
            });
        }
    }

    //create risk matrix
    createRiskMatrix = async(riskAnalysis, parentId = null) => {
        //init
        let riskMatrix = [];

        //get
        let risks = await RiskAction.getRiskList(riskAnalysis, parentId);

        //loop
        let riskListParallel = [];
        let riskListParallelIds = [];
        for (let risk of risks) {
            if (
                (risk.taskStatus !== 'ignored' && risk.lvl < 7) &&
                (this.openLvls.includes(risk.lvl))
            ) {
                riskListParallel.push(this.createRiskMatrix(riskAnalysis, risk.id));
                riskListParallelIds.push(risk.id);
            }

            //check if lvl is open
            if (this.openLvls.includes(risk.lvl)) {
                risk['collapsed'] = false;
            } else {
                risk['collapsed'] = true;
            }

            //set uuid
            risk['uuid'] = Uuid();

            //push in matrix
            riskMatrix.push(risk);
        }

        //get result and loop
        let results = await Promise.all(riskListParallel);
        riskMatrix.forEach((risk, riskIndex) => {
            riskListParallelIds.forEach((riskId, resultIndex) => {
                if (risk.id === riskId) {
                    riskMatrix[riskIndex]['subRisks'] = results[resultIndex];
                }
            });
        });

        //return
        return riskMatrix;
    }

    //update risk matrix
    updateRiskMatrix = async (riskAnalysis, risk, recursive = false, subrisks = false) => {

        //check if process is running
        if (this.updateWait) {
            //wait for status to become free
            let promise = new Promise((resolve, reject) => setTimeout(() => {
                resolve(this.updateRiskMatrix(riskAnalysis, risk, recursive, subrisks));
            }, 250));
            
            let result = await promise;
            await result;
        } else {

            //mark process as running
            this.updateWait = true;

            //get flat risk array
            let flatArray = await this.flattenMatrix(this.riskMatrix);

            //find
            let foundRisk = null;
            for (let flatRisk of flatArray) {
                if (flatRisk.risk.id === risk.id) {
                    foundRisk = flatRisk;
                }
            }

            //only do calls if subrisks are still unknown
            if (!('subRisks' in risk) && risk.lvl < 7 && subrisks) {

                //create new part of matrix
                let newMatrixSegment = await this.createRiskMatrix(riskAnalysis, foundRisk.risk.id);

                //append to matrix
                newMatrixSegment = await this.flattenMatrix(newMatrixSegment, foundRisk.trail.concat([foundRisk.risk.id]));
                flatArray = flatArray.concat(newMatrixSegment);
            }

            //only do if recursive update is required
            if (recursive) {

                //init arrays
                let recursiveUpdates = [];
                let recursiveUpdatesObjects = [];

                //add main risk
                recursiveUpdates.push(RiskAction.getRisk(risk.id, riskAnalysis));
                recursiveUpdatesObjects.push(foundRisk);

                //add trail risks
                for (let riskId of foundRisk.trail) {
                    for (let flatRisk of flatArray) {
                        if (flatRisk.risk.id === riskId) {
                            recursiveUpdates.push(RiskAction.getRisk(riskId, riskAnalysis));
                            recursiveUpdatesObjects.push(flatRisk);
                        }
                    }
                }

                //wait for calls
                let recursiveResult = await Promise.all(recursiveUpdates);

                //loop results
                for (let i = 0; i < recursiveResult.length; i++) {
                    let newRisk = recursiveResult[i];
                    newRisk['subRisks'] = recursiveUpdatesObjects[i].risk.subRisks;
                    newRisk['collapsed'] = recursiveUpdatesObjects[i].risk.collapsed;
                    newRisk['uuid'] = Uuid();
                    recursiveUpdatesObjects[i].risk = newRisk;
                }
            }

            //inflate matrix
            let riskMatrix = await this.inflateMatrix(flatArray);

            //create object
            let riskMatrixObject = await this.createMatrixObject(riskMatrix);

            //set state
            this.riskMatrix = riskMatrix;
            this.riskMatrixObject = riskMatrixObject;

            //mark process as free
            this.updateWait = false;
        }
    }

    //make matrix flat for searching
    flattenMatrix = async (riskMatrix, trail = []) => {
        let plainRisks = [];

        //loop
        for (let matrixRisk of riskMatrix) {
            plainRisks.push({
                risk: matrixRisk,
                trail: trail
            });

            //if needed go deeper
            if ('subRisks' in matrixRisk) {
                let result = await this.flattenMatrix(matrixRisk.subRisks, trail.concat([matrixRisk.id]));
                plainRisks = plainRisks.concat(result);
            }
        }

        //return
        return plainRisks;
    }

    //make matrix multidimentional for showing
    inflateMatrix = async (flatMatrix, trail = []) => {
        //init
        let riskMatrix = [];

        for (let flatItem of flatMatrix) {
            //check if trail is equal
            if (JSON.stringify(flatItem.trail) === JSON.stringify(trail)) {
                //check if subs already set
                if ('subRisks' in flatItem.risk) delete flatItem.risk.subRisks;
                //get subitems
                let subItems = await this.inflateMatrix(flatMatrix, trail.concat([flatItem.risk.id]));
                //if subitems exist add
                if (subItems.length > 0) {
                    flatItem.risk['subRisks'] = subItems;
                }
                //set item
                riskMatrix.push(flatItem.risk);
            }
        }

        //return
        return riskMatrix;
    }

    //collapse risk
    collapseRisk = async (risk, collapsed) => {

        //check if process is running
        if (this.collapseWait) {
            //wait for status to become free
            let promise = new Promise((resolve, reject) => setTimeout(() => {
                resolve(this.collapseRisk(risk, collapsed));
            }, 250));
            
            let result = await promise;
            await result;
        } else {
            //mark process as running
            this.collapseWait = true;

            //get flat risk array
            let flatArray = await this.flattenMatrix(this.riskMatrix);

            //find
            let foundRisk = null;
            for (let flatRisk of flatArray) {
                if (flatRisk.risk.id === risk.id) {
                    foundRisk = flatRisk;
                }
            }

            //set uncollapsed
            foundRisk.risk.collapsed = collapsed;

            //create risk matrix
            let riskMatrix = await this.inflateMatrix(flatArray);

            //create matrix object
            let riskMatrixObject = await this.createMatrixObject(riskMatrix);

            //set state
            this.riskMatrix = riskMatrix;
            this.riskMatrixObject = riskMatrixObject;

            //mark process as free
            this.collapseWait = false;
        }

    }

    //spinning risk
    spinningRisk = async (risk, spinner) => {
        //get flat risk array
        let flatArray = await this.flattenMatrix(this.riskMatrix);

        //find
        let foundRisk = null;
        for (let flatRisk of flatArray) {
            if (flatRisk.risk.id === risk.id) {
                foundRisk = flatRisk;
            }
        }

        //set spinner
        if (spinner) {
            foundRisk.risk.spinner = true;
        } else {
            delete foundRisk.risk.spinner;
        }

        //create risk matrix
        let riskMatrix = await this.inflateMatrix(flatArray);

        //create matrix object
        let riskMatrixObject = await this.createMatrixObject(riskMatrix);

        //set state
        this.riskMatrix = riskMatrix;
        this.riskMatrixObject = riskMatrixObject;
    }

    //change status on higher level
    statusChange = async (risk, value) => {

        //set spinner
        await this.spinningRisk(risk, true);

        //check if process is running
        if (this.statusWait) {
            //wait for status to become free
            let promise = new Promise((resolve, reject) => setTimeout(() => {
                resolve(this.statusChange(risk, value));
            }, 500));
            
            let result = await promise;
            await result;

        } else {

            //mark process as running
            this.statusWait = true;

            //send call
            await RiskAnalysisAction.recursiveRiskAnalysisItem(this.props.riskAnalysis.id, risk.id, !value);

            //update matrix
            await this.updateRiskMatrix(this.props.riskAnalysis, risk, true, value);
            
            //collapse if needed
            await this.collapseRisk(risk, !value);

            //unset spinner
            await this.spinningRisk(risk, false);
        
            //mark process as free
            this.statusWait = false;
        }
    }

    //create matrix object
    createMatrixObject = async (riskMatrix) => {
        //init
        let riskItems = [];

        //loop matrix
        for (let risk of riskMatrix) {
            //set icon style
            let iconStyle = 'normal';
            if (risk.lvl > 6) iconStyle = 'edit';
            else if (risk.taskStatus === 'ignored') iconStyle = 'none';

            //push risk itself in object
            riskItems.push(
                <div className={'vertical-padding-3'}>
                    <Risk
                        id={risk.id}
                        key={risk.uuid}
                        spinner={risk.spinner}
                        style={{
                            backgroundColor: this.colors[risk.lvl - 3]
                        }}
                        risk={risk}
                        iconStyle={iconStyle}
                        onCollapse={async (value) => {
                            //timestamp
                            let timeStamp = Date.now();
                            this.inputStart[timeStamp] = true;

                            if (value === false && !('subRisks' in risk)) {
                                await this.spinningRisk(risk,true);
                                await this.updateRiskMatrix(this.props.riskAnalysis, risk, false, true);
                                await this.spinningRisk(risk,false);
                            }
                            await this.collapseRisk(risk, value);

                            //set ready
                            delete this.inputStart[timeStamp];

                            //refresh
                            await this.updateMatrixDelay();
                        }}
                        onCheckBoxClick={async (value) => {
                            //timestamp
                            let timeStamp = Date.now();
                            this.inputStart[timeStamp] = true;

                            await this.statusChange(risk, value);

                            //set ready
                            delete this.inputStart[timeStamp];

                            //refresh
                            await this.updateMatrixDelay();
                        }}
                        onClick={async () => {
                            //timestamp
                            let timeStamp = Date.now();
                            this.inputStart[timeStamp] = true;

                            if (risk.lvl > 6) {
                                if (risk.taskStatus === 'ignored') {
                                    await this.statusChange(risk, true);
                                }
                                //store current matrix
                                sessionStorage.setItem('risk-matrix-' + this.props.riskAnalysis.id, JSON.stringify(this.riskMatrix));
                                //go to next page
                                window.location.href = generatePath('/risk_analysis/:risk_analysis_id/risk/:risk_id', {'risk_analysis_id': this.props.riskAnalysis.id, 'risk_id': risk.id});
                            } else if (risk.collapsed && !('subRisks' in risk) && risk.taskStatus !== 'ignored') {
                                await this.spinningRisk(risk,true);
                                await this.updateRiskMatrix(this.props.riskAnalysis, risk, false, true);
                                await this.spinningRisk(risk,false);
                                await this.collapseRisk(risk, !risk.collapsed);
                            } else if (risk.taskStatus === 'ignored') {
                                await this.statusChange(risk, true);
                            } else {
                                await this.collapseRisk(risk, !risk.collapsed);
                            }

                            //set ready
                            delete this.inputStart[timeStamp];

                            //refresh
                            await this.updateMatrixDelay();
                        }}
                    />
                </div>
            );

            //add subrisk if exist
            if ('subRisks' in risk) {
                //get subrisk objects
                let subRisks = await this.createMatrixObject(risk.subRisks);
                riskItems.push(
                    <Collapse isOpened={!risk.collapsed} key={Uuid()}>
                        <div className={'standard-padding bordered-left'} style={{paddingRight: 0}}>
                            {subRisks}
                        </div>
                    </Collapse>
                );
            }
        }

        //return
        return (
            <div>
                {riskItems}
            </div>
        );
    }

    //fold on level
    foldOnLevel = async (level) => {

        //get flat risk array
        let flatArray = await this.flattenMatrix(this.riskMatrix);

        //loop
        for (let flatRisk of flatArray) {
            if (flatRisk.risk.lvl >= level) {
                flatRisk.risk.collapsed = true;
            }
        }

        //inflate matrix
        let riskMatrix = await this.inflateMatrix(flatArray);

        //create object
        let riskMatrixObject = await this.createMatrixObject(riskMatrix);

        //set state
        this.riskMatrix = riskMatrix;
        this.riskMatrixObject = riskMatrixObject;

        //refresh
        await this.updateMatrixDelay();
    }

    //render view
    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end'}}>
                    <div>
                        {/* Indicators */}
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{width: 15, height: 15}} className={'bg-success rounded'}/>
                            <p style={{marginBottom: 0}} className={'standard-margin-2'}><CustomTranslation
                                value={'KEY.Finished'}/></p>
                            <div style={{width: 15, height: 15}} className={'bg-primary rounded'}/>
                            <p style={{marginBottom: 0}} className={'standard-margin-2'}><CustomTranslation
                                value={'KEY.In progress'}/></p>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => this.foldOnLevel(3)} variant={'light'} style={{backgroundColor: this.colors[0], marginRight: 0}} className={'standard-margin-3'}><i className="las la-arrows-alt-v"></i></Button>
                        <Button onClick={() => this.foldOnLevel(4)} variant={'light'} style={{backgroundColor: this.colors[1], marginRight: 0}} className={'standard-margin-3'}><i className="las la-arrows-alt-v"></i></Button>
                        <Button onClick={() => this.foldOnLevel(5)} variant={'light'} style={{backgroundColor: this.colors[2], marginRight: 0}} className={'standard-margin-3'}><i className="las la-arrows-alt-v"></i></Button>
                        <Button onClick={() => this.foldOnLevel(6)} variant={'light'} style={{backgroundColor: this.colors[3], marginRight: 0}} className={'standard-margin-3'}><i className="las la-arrows-alt-v"></i></Button>
                    </div>
                </div>
                {this.state.riskMatrixObject}
            </div>
        );
    }
}

//export
export default RiskList;
