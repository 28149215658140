import React from "react";
import {Form} from "react-bootstrap";
import T from "../../../Actions/TranslationAction";
import Uuid from "uuid/v1";

//set factors
const factors = {
    E: [1, 3, 7, 15, 40, 100],
    B: [0.5, 1, 2, 3, 6, 10],
    W: [0.2, 0.5, 1, 3, 6, 10]
};

//text values
const textValues = {
    E: [
        'KEY.Meaningful, first aid may be required',
        'KEY.Important, incapacity for work',
        'KEY.Significant, serious injury',
        'KEY.Very serious, 1 dead',
        'KEY.Disaster, several deaths',
        'KEY.Catastrophic, many dead'
    ],
    B: [
        'KEY.Very rarely',
        'KEY.Several times a year',
        'KEY.Monthly',
        'KEY.Weekly or occasionally',
        'KEY.Daily during working hours',
        'KEY.Constantly'
    ],
    W: [
        'KEY.Practically impossible',
        'KEY.Considerable, but very unlikely',
        'KEY.Only possible in the long term',
        'KEY.Unusual but possible',
        'KEY.Quite possible',
        'KEY.Can be expected, almost certainly'
    ]
};

//define class
class RiskLetter extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {

        };
    }

    //on mount
    componentDidMount() {
        this.translateTextValues();
    }

    //translate text values
    translateTextValues = async () => {
        //init new values
        let newTextValues = {};

        //loop over values
        for (let letter in textValues) {
            newTextValues[letter] = [];
            for (const value of textValues[letter]) {
                let translated = await T.translate(value);
                newTextValues[letter].push(translated);
            }
        }

        //set text values
        this.setState({
            textValues: newTextValues
        });
    }

    //update F&K
    updateFK = async (value) => {
        //if exists
        if (typeof this.props.onPress ===  'function') {
            this.props.onPress(factors[this.props.letter][value - 1]);
        }
    }

    /**
     * Create pill list
     *
     * @param {*} number
     */
    createPill(number) {

        //init result
        let result = [];

        //loop
        for (let i = 0; i < 6; i++) {

            //styles
            let customClasses = 'rounded touchable ';
            let customStyles = {height: '0.625rem', width: '100%', flex: 1};

            //check number
            if (i < number) {
                //if number is 1-2
                if (number < 3) {
                    customClasses += 'bg-primary ';
                }
                //if number is 3-4
                else if (number < 5) {
                    customClasses += 'bg-warning ';
                }
                //if number is 5-6
                else {
                    customClasses += 'bg-danger ';
                }
            } else {
                //gray
                customClasses += 'bg-light ';
            }

            //add result
            result.push(
                <div
                    key={i}
                    style={{flex: 1, marginRight: 5}}
                    onClick={() => this.updateFK(i + 1)}
                >
                    <div className={customClasses} style={customStyles} />
                </div>
            );
        }

        //return
        return result;
    }

    //F&K position
    positionFK = (value) => {
        let range = factors[this.props.letter];

        //loop
        let proximate = 999999999999999;
        let proximateIndex = 0;
        range.forEach((range, index) => {
            let prox = Math.abs(range - value);
            if (prox < proximate) {
                proximate = prox;
                proximateIndex = index;
            }
        });

        //return result
        return proximateIndex + 1;
    }



    //render view
    render = () => {
        if (this.state.textValues) {
            //set index
            let index = this.positionFK(this.props.value);

            //set options
            let options = [];
            factors[this.props.letter].forEach((factor, index) => {
                //create label string
                let label = null;
                if (this.state.textValues[this.props.letter] && this.state.textValues) {
                    label = this.state.textValues[this.props.letter][index];
                }

                //set in options
                options.push(
                    <option
                        key={Uuid()}
                        value={factor}
                    >
                        {factor} {label}
                    </option>
                );
            });

            //render
            return (
                <div className={this.props.className} style={this.props.style}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div className={'touchable'} style={{position: 'relative'}}>
                            <div
                                className={'bg-light rounded'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    height: '3rem',
                                    width: '3rem',
                                    alignItems: 'center',
                                }}
                            >
                                <p style={{margin: 0, fontSize: '1.3rem', fontWeight: '700'}}>{this.props.letter}</p>
                                <i style={{fontSize: '1rem'}} className={'las la-chevron-down'}/>
                            </div>
                            <Form.Control as={'select'}
                                          key={Uuid()}
                                          className={'touchable'}
                                          style={{
                                              position: 'absolute',
                                              top: 0,
                                              bottom: 0,
                                              left: 0,
                                              right: 0,
                                              opacity: 0,
                                              height: '100%',
                                              width: '100%'
                                          }}
                                          defaultValue={this.props.value}
                                          onChange={(event) => {
                                              if (typeof this.props.onPress === 'function') {
                                                  this.props.onPress(event.target.value);
                                              }
                                          }}
                            >
                                {options}
                            </Form.Control>
                        </div>
                        <div className={'standard-padding-2'} style={{flexGrow: 1, flexShrink: 1, paddingRight: 0}}>
                            <p className={'label vertical-padding-3'} style={{paddingTop: 0}}>{this.props.label}</p>
                            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                                {this.createPill(index)}
                            </div>
                            {textValues[this.props.letter] &&
                            <p className={'label vertical-padding-3 unbold'}>
                                {this.props.value} {this.state.textValues[this.props.letter][index - 1]}
                            </p>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

//export
export default RiskLetter;