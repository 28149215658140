import React from 'react';
import CustomContainer from "../Components/CustomContainer";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import Loader from "../Components/Loader";
import Project from "../Home/Components/Project";
import { Breadcrumb, Button, Spinner } from "react-bootstrap";
import { generatePath, useParams } from "react-router-dom";
import CustomTranslation from "../Components/CustomTranslation";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import CustomImageInput from "../Components/CustomImageInput";
import CustomInput from "../Components/CustomInput";
import CustomDate from "../Components/CustomDate";
import CustomCheckBox from "../Components/CustomCheckBox";
import CustomSelect from "../Components/CustomSelect";
import UserAction from "../../Actions/UserAction";
import { toast } from "react-hot-toast";
import ToolboxAction from "../../Actions/ToolboxAction";
import CustomFileInput from '../Components/CustomFileInput';
import CustomListInput from '../Components/CustomListInput';

class ToolboxScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            processing: false,
            dateRequested: new Date(),
            finished: false,
            concept: false,
            participants: null
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    async createObservation() {
        // Set processing
        this.setState({ processing: true });

        // Validate data
        if (!this.state.user) {
            toast.error(<CustomTranslation value={'KEY.Please select a target User.'} />);

            return false;
        }

        // Update user
        let formData = {
            'target_user': this.state.user.value,
            'constructionSite': this.state.constructionSite,
            'document': this.state.documentApiId,
            'assignment': {
                'title': this.state.title,
                'immediateActionTaken': this.state.actionsTaken,
                'dateRequested': this.state.dateRequested,
                'photoId': this.state.imageId,
                'altPhotoId': this.state.image2Id,
                'finished': this.state.finished,
                'concept': this.state.concept,
            }
        };

        // set participants
        let participants = [];
        for (let participant of this.state.participants) {
            participants.push({
                name: participant,
                signature: null
            });
        }
        formData['participants'] = participants;

        //return response
        let response = await ToolboxAction.createToolbox(formData);

        //set processing
        setTimeout(() => {
            this.setState({ processing: false });
        }, 1000);

        //check response
        if (response && response.id) {
            //show toast
            toast.success(<CustomTranslation value={'KEY.Toolbox created!'} />);

            //navigate after delay
            setTimeout(() => {
                window.location.href = generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id });
            }, 1000);
        }
    }

    //handle photo upload
    handleUpload = async (file, name) => {
        let newState = {};
        newState[`${name}Id`] = file.id;
        newState[`${name}ApiId`] = file['@id'];
        this.setState(newState);
    }

    updateElements = async () => {
        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //get users for construction site
        let users = await UserAction.getUserListConstructionSite(cs.id);

        //define user options
        let userOptions = [];

        //loop
        users.forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            userOptions.push({
                label: label,
                value: element.email
            });
        });

        let targetUser = userOptions[0];
        let loggedInUser = await UserAction.getCurrentUser();
        if (loggedInUser !== null) {
            userOptions.forEach(element => {
                if (element.value.includes(loggedInUser.email)) {
                    targetUser = element;
                }
            });
        }

        //set state
        this.setState({
            data: {
                constructionSite: cs
            },
            constructionSite: {
                id: cs['@id']
            },
            userOptions: userOptions,
            user: targetUser
        });
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            disabled={
                                this.state.processing ||
                                (
                                    !(this.state.concept) &&
                                    !(this.state.user.value && this.state.title && this.state.dateRequested && this.state.participants && this.state.participants.length > 0 &&
                                        (this.state.documentId /*|| this.state.image2Id*/)
                                    )
                                )
                            }
                            onClick={() => {
                                this.createObservation();
                            }}
                        >
                            <CustomTranslation value={'KEY.Create Toolbox'} />
                            {this.state.processing &&
                                <Spinner size={'sm'} animation={'border'} variant={'white'} style={{ margin: 2 }} />
                            }
                        </Button>
                    }
                >
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{ position: 'sticky', top: '2rem', zIndex: 9 }}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>

                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id })}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Instruction - Toolbox'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div className={'vertical-margin'}>
                                <h1>
                                    <CustomTranslation value={'KEY.Instruction - Toolbox'} />
                                </h1>
                            </div>

                            {/* Form */}
                            <div>
                                {/** <div className={'vertical-padding-3'}>
                                    <CustomImageInput
                                        iconName={'las la-image'}
                                        label={<CustomTranslation value={'KEY.Image overview'} />}
                                        onUpload={(val) => this.handleUpload(val, 'image')}
                                        imageId={this.state.imageId ? this.state.imageId : null}
                                    />
                </div>**/}
                                <div className={'vertical-padding-3'}>
                                    <CustomFileInput
                                        iconName={'las la-file-alt'}
                                        label={<CustomTranslation value={'KEY.Document detail'} />}
                                        labelAppend={this.state.concept ? '' : '*'}
                                        mimeType={() =>
                                            [
                                                "application/pdf",
                                                "application/msword",
                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                "application/vnd.oasis.opendocument.text",
                                                "application/vnd.apple.pages",
                                                "application/x-iwork-pages-sffpages",
                                                "application/vnd.ms-powerpoint",
                                                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                                "application/vnd.oasis.opendocument.presentation",
                                                "application/vnd.apple.keynote",
                                                "application/x-iwork-keynote-sffkey",
                                                "application/vnd.ms-excel",
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                "application/vnd.oasis.opendocument.spreadsheet",
                                                "application/vnd.apple.numbers",
                                                "application/x-iwork-numbers-sffnumbers"
                                            ].join(',')
                                        }
                                        btnLabel={<CustomTranslation value={'KEY.Upload document'} />}
                                        onUpload={(val) => this.handleUpload(val, 'document')}
                                        fileId={this.state.documentId ? this.state.documentId : null}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomInput
                                        iconName={'las la-quote-left'}
                                        label={<CustomTranslation value={'KEY.Title'} />}
                                        labelAppend={this.state.concept ? '' : '*'}
                                        value={this.state.title}
                                        onChangeText={(text) => {
                                            this.setState({ title: text });
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomInput
                                        iconName={'las la-quote-left'}
                                        label={<CustomTranslation value={'KEY.Optional extra information'} />}
                                        inputProps={{
                                            as: 'textarea'
                                        }}
                                        value={this.state.actionsTaken}
                                        onChangeText={(text) => {
                                            this.setState({ actionsTaken: text });
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomDate
                                        iconName={'las la-calendar'}
                                        label={<CustomTranslation value={'KEY.Toolbox ready by'} />}
                                        labelAppend={this.state.concept ? '' : '*'}
                                        value={this.state.dateRequested}
                                        onChange={(value) => {
                                            this.setState({ dateRequested: value });
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomCheckBox
                                        label={<CustomTranslation value={'KEY.Finished'} />}
                                        value={this.state.finished}
                                        onValueChange={(value) => {
                                            this.setState({ finished: value });
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomSelect
                                        iconName={'las la-user'}
                                        label={<CustomTranslation value={'KEY.Assign to'} />}
                                        labelAppend={this.state.concept ? '' : '*'}
                                        options={this.state.userOptions}
                                        selected={this.state.user}
                                        onSelect={(value, label) => {
                                            this.setState({
                                                user: {
                                                    label: label,
                                                    value: value,
                                                }
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomCheckBox
                                        label={<CustomTranslation value={'KEY.Save as concept'} />}
                                        value={this.state.concept}
                                        onValueChange={(value) => {
                                            this.setState({ concept: value });
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomListInput
                                        label={<CustomTranslation value={'KEY.Participants'} />}
                                        labelAppend={this.state.concept ? '' : '*'}
                                        unique={true}
                                        iconName={'las la-users'}
                                        onChangeText={(value) => {
                                            let output = value;
                                            if (typeof value === 'object') {
                                                output = output.filter(n => {
                                                    return n !== '';
                                                });
                                            }
                                            this.setState({ participants: output });
                                        }}
                                        value={this.state.participants}
                                    />
                                </div>
                                <div className={'vertical-padding-3'}>
                                    <CustomImageInput
                                        iconName={'las la-image'}
                                        label={<CustomTranslation value={'KEY.Instruction/Toolboxmoment'} />}
                                        labelAppend={this.state.concept ? '' : ''}
                                        onUpload={(val) => this.handleUpload(val, 'image2')}
                                        imageId={this.state.image2Id ? this.state.image2Id : null}
                                    />
                                </div>
                            </div>
                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <ToolboxScreen {...props} params={params} />;
}