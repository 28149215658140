import React from "react";
import CustomTranslation from "../../../Components/CustomTranslation";
import CustomConfirm from "../../../Components/CustomConfirm";
import LicenseAction from "../../../../Actions/LicenseAction";
import CompanyAction from "../../../../Actions/CompanyAction";
import T from "../../../../Actions/TranslationAction";
import {Spinner} from "react-bootstrap";
import CustomSelect from "../../../Components/CustomSelect";
import GeneralAction from "../../../../Actions/GeneralAction";

class CompanyLicenseEditModal extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {

        }
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {
        //get license
        let license = await LicenseAction.getLicense(this.props.license.id);

        //get rights
        let rights = await CompanyAction.getRights(this.props.company.id);

        let stdUsers = [];
        let reqUsers = [];
        //get users form rights
        rights.forEach((right, index) => {
            if (!right.license) {
                if (!right.licenseRequested) {
                    stdUsers.push(right.user);
                } else {
                    reqUsers.push(right.user);
                }
            }
        });

        //-- make elements
        let userElements = [];

        //-- loop users that requested
        for (let [index, element] of Object.entries(reqUsers)) {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            let groupName = await T.translate('KEY.License needed');
            userElements.push({
                label: label,
                value: element['@id'],
                group: groupName
            });
        }

        //-- add current user option
        if (license.user) {
            let currentUserLabel = null;
            if ((!license.user.firstName) || (!license.user.lastName)) {
                currentUserLabel = license.user.email;
            } else {
                currentUserLabel = license.user.firstName + ' ' + license.user.lastName;
            }
            userElements.push({label: currentUserLabel, value: license.user['@id'], group: await T.translate('KEY.Current user')});
        }

        //-- add empty option
        userElements.push({label: await T.translate('KEY.Empty'), value: '#EMPTY#', group: await T.translate('KEY.Other users')});

        //-- loop other users
        for (let [index, element] of Object.entries(stdUsers)) {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            let groupName = await T.translate('KEY.Other users');
            userElements.push({
                label: label,
                value: element['@id'],
                group: groupName
            });
        }

        //set user label
        let userLabel = await T.translate('KEY.Empty');
        if (license.user) {
            userLabel = ((license.user.firstName) && (license.user.lastName)) ? ((license.user.firstName || '') + ' ' + (license.user.lastName || '')) : license.user.email;
        }

        //get state
        this.setState({
            license: license,
            user: {
                label: userLabel,
                value: license.user ? license.user['@id'] : '#EMPTY#',
            },
            users: userElements,
        });
    }

    //update license
    updateLicenseForm = async () => {
        //update user
        let formData = {
            user: this.state.user ? this.state.user.value : null,
        };
        //check if empty
        if (formData.user === '#EMPTY#') formData.user = null;

        //call
        let response = await LicenseAction.editLicense(this.state.license.id, formData);
    }

    //render view
    render = () => {
        //render
        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <CustomConfirm
                    show={true}
                    title={<CustomTranslation value={'KEY.Licences Details'}/>}
                    body={
                        Object.keys(this.state).length > 0 ?
                            <div>
                                {/* Info */}
                                <div
                                    className={'rounded bg-light standard-padding vertical-padding'}
                                >
                                    <h3>{this.state.license.code ? this.state.license.code : <CustomTranslation value={'KEY.No code'} />}</h3>
                                    <h5 className={'unbold'}>{this.state.license.startDate ? GeneralAction.formatDate(this.state.license.startDate) : <CustomTranslation value={'KEY.Unknown'} />} - {this.state.license.startDate ? GeneralAction.formatDate(this.state.license.endDate) : <CustomTranslation value={'KEY.Unknown'} />}</h5>
                                </div>
                                {/* Select */}
                                <div className={'vertical-margin-2'}>
                                    <CustomSelect
                                        iconName={'las la-user'}
                                        label={<CustomTranslation value={'KEY.User'} />}
                                        options={this.state.users}
                                        selected={this.state.user}
                                        onSelect={(value, label) => {
                                            this.setState({
                                                user: {
                                                    label: label,
                                                    value: value
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}
                            >
                                <Spinner animation={'border'} variant={'primary'} />
                            </div>
                    }
                    variant={'success'}
                    cancelLabel={<CustomTranslation value={'KEY.Cancel'}/>}
                    confirmLabel={<CustomTranslation value={'KEY.Update License'}/>}
                    onCancel={this.props.onCancel}
                    onConfirm={() => {
                        this.updateLicenseForm().then(() => {
                            if (typeof this.props.onConfirm === 'function') {
                                this.props.onConfirm();
                            }
                        });
                    }}
                />
            </div>
        );
    }
}

//export
export default CompanyLicenseEditModal;