import React from 'react';
import { Form } from 'react-bootstrap';
import Uuid from 'uuid/v1';

class CustomSelect extends React.Component {

    //constructor
    constructor(props) {
        super(props);
    }

    //create options
    createOptions = () => {

        //check
        let propOptions = [];
        if ('options' in this.props) {
            propOptions = this.props.options;
        }

        let options = {};
        //loop over data
        propOptions.forEach(element => {

            //create option
            let option = (
                <option
                    key={Uuid()}
                    value={element.value}
                >
                    {element.label}
                </option>
            );

            //check if group exists, if not set default group
            if (!element.group) {
                element.group = '#DEFAULT#';
            }

            //add to existing group or create new group
            if (element.group in options) {
                options[element.group].push(option);
            } else {
                options[element.group] = [option];
            }
        });

        //create options in group
        let optionsElements = [];
        for (let [group, elements] of Object.entries(options)) {
            if (group !== '#DEFAULT#') {
                optionsElements.push(
                    <optgroup label={group}>
                        {elements}
                    </optgroup>
                );
            }
        }

        //create options not in group
        for (let [group, elements] of Object.entries(options)) {
            if (group === '#DEFAULT#') {
                optionsElements = [...optionsElements, ...elements];
            }
        }

        //return
        return optionsElements;
    }

    //get selected
    getSelected() {

        //check if provided
        if (this.props.selected) {
            return this.props.selected.value;
        }

        //get first in array
        return this.props.options[0].value;
    }

    render = () => {

        //create options
        let options = this.createOptions();

        //get selected value
        let selected = this.getSelected();

        //set defaults
        let inputProps = {

        };

        //combine
        if (typeof this.props.inputProps === 'object') {
            inputProps = { ...inputProps, ...this.props.inputProps };
        }

        //return view
        return (
            <div className={this.props.className} style={this.props.style}>
                <Form.Group controlId={'form_' + Uuid()} style={{ margin: 0 }}>
                    <Form.Label>
                        <div style={{
                            display: 'flex',
                            flex: 'row'
                        }}>
                            {this.props.iconName &&
                                <div
                                    className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                                >
                                    <i className={this.props.iconName + ' icon'} />
                                </div>
                            }
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')} style={{ paddingBottom: 0 }}>{this.props.label}{this.props.labelAppend}</span>
                            </div>
                        </div>
                    </Form.Label>
                    <Form.Control
                        {...inputProps}
                        as={'select'}
                        onChange={(event) => {
                            if (typeof this.props.onSelect === 'function') {
                                this.props.onSelect(event.target.value, event.target.selectedOptions[0].innerText);
                            }
                        }}
                        value={selected}
                    >
                        {options}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    }
}

//export
export default CustomSelect;