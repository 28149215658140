import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import {Button, Table, Modal, ProgressBar} from "react-bootstrap";
import G from "../../../Actions/GeneralAction";
import Config from "../../../Config.json";
import fileDownload from "js-file-download";

//define class
class RAItem extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            showModal: false,
            downloadingFull: false,
            downloadingLimited: false
        };
    }

    //render view
    render = () => {
        return (
            <div style={this.props.style} className={this.props.className}>
                <h4><CustomTranslation value={'KEY.Risk analysis'} /></h4>
                {/* Table */}
                <Table striped bordered>
                    <tbody>
                    <tr>
                        <th><CustomTranslation value={'KEY.Company'} /></th>
                        <td>{this.props.data.constructionSite.company.name}</td>
                    </tr>
                    <tr>
                        <th><CustomTranslation value={'KEY.Construction Site'} /></th>
                        <td>{this.props.data.constructionSite.name}</td>
                    </tr>
                    <tr>
                        <th><CustomTranslation value={'KEY.Created by'} /></th>
                        <td>{(this.props.data.startedBy.firstName && this.props.data.startedBy.lastName) ?
                            this.props.data.startedBy.firstName + ' ' + this.props.data.startedBy.lastName :
                            this.props.data.startedBy.email
                        }</td>
                    </tr>
                    <tr>
                        <th><CustomTranslation value={'KEY.Created at'} /></th>
                        <td>{G.formatDateTime(this.props.data.createdAt)}</td>
                    </tr>
                    </tbody>
                </Table>
                {/* Button */}
                <Button
                    block
                    variant={'dark'}
                    onClick={() => this.setState({showModal: true})}
                >
                    <CustomTranslation value={'KEY.More'} />
                </Button>

                {/* Modal */}
                <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})} backdrop={'static'} keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <CustomTranslation value={'KEY.Risk analysis'} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Full Table */}
                        <Table striped bordered>
                            <tbody>
                            <tr>
                                <th><CustomTranslation value={'KEY.Title'} /></th>
                                <td><CustomTranslation value={this.props.data.title} /></td>
                            </tr>
                            <tr>
                                <th><CustomTranslation value={'KEY.Finished'} /></th>
                                <td style={{fontSize: '1.5rem'}}>
                                    {this.props.data.isFinished ?
                                        <i className={'las la-check text-success font-weight-bold'}/> :
                                        <i className={'las la-times text-danger font-weight-bold'}/>
                                    }
                                </td>
                            </tr>
                            {this.props.data.isFinished ?
                                <React.Fragment>
                                    <tr>
                                        <th><CustomTranslation value={'KEY.Full risk analysis'} /></th>
                                        <td>
                                            <Button
                                                block
                                                disabled={this.state.downloadingFull}
                                                variant={'dark'}
                                                onClick={() => {
                                                    //set downloading animation
                                                    this.setState({downloadingFull: true});
                                                    //default file name
                                                    let fileName = 'document.pdf';
                                                    fetch(Config.BASE_URL + '/en/general/document/tra/full/' + this.props.data.id + '/1')
                                                        .then(res => {
                                                            fileName = res.headers.get('content-disposition');
                                                            let parts = fileName.split(';');
                                                            fileName = parts[1].split('=')[1];
                                                            return res.blob();
                                                        })
                                                        .then(res => {
                                                            //stop animation
                                                            this.setState({downloadingFull: false});
                                                            //download prompt
                                                            fileName = fileName.replace(/['"]+/g, '');
                                                            fileDownload(res, fileName, 'application/pdf');
                                                        });
                                                }}
                                            >
                                                <i className={'las la-file-pdf'} />
                                                <CustomTranslation value={'KEY.Download'} />
                                                {this.state.downloadingFull ?
                                                    <ProgressBar striped animated variant={'primary'} now={100}/> : null
                                                }
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><CustomTranslation value={'KEY.Limited risk analysis'} /></th>
                                        <td>
                                            <Button
                                                block
                                                disabled={this.state.downloadingLimited}
                                                variant={'dark'}
                                                onClick={() => {
                                                    //set downloading animation
                                                    this.setState({downloadingLimited: true});
                                                    //default file name
                                                    let fileName = 'document.pdf';
                                                    fetch(Config.BASE_URL + '/en/general/document/tra/limited/' + this.props.data.id + '/1')
                                                        .then(res => {
                                                            fileName = res.headers.get('content-disposition');
                                                            let parts = fileName.split(';');
                                                            fileName = parts[1].split('=')[1];
                                                            return res.blob();
                                                        })
                                                        .then(res => {
                                                            //stop animation
                                                            this.setState({downloadingLimited: false});
                                                            //download prompt
                                                            fileName = fileName.replace(/['"]+/g, '');
                                                            fileDownload(res, fileName, 'application/pdf');
                                                        });
                                                }}
                                            >
                                                <i className={'las la-file-pdf'} />
                                                <CustomTranslation value={'KEY.Download'} />
                                                {this.state.downloadingLimited ?
                                                    <ProgressBar striped animated variant={'primary'} now={100}/> : null
                                                }
                                            </Button>
                                        </td>
                                    </tr>
                                </React.Fragment> : null
                            }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button block variant={'dark'} onClick={() => this.setState({showModal: false})}>
                            <CustomTranslation value={'KEY.Close'} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

//export
export default RAItem;
