import React from "react";
import {Spinner} from "react-bootstrap";
import ObservationAction from "../../../Actions/ObservationAction";
import UserAction from "../../../Actions/UserAction";
import ObservationItem from "./ObservationItem";
import LMRAAction from "../../../Actions/LMRAAction";
import LMRAItem from "./LMRAItem";
import ICLAction from "../../../Actions/ICLAction";
import ICLItem from "./ICLItem";
import RiskAnalysisAction from "../../../Actions/RiskAnalysisAction";
import RAItem from "./RAItem";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import CompanyAction from "../../../Actions/CompanyAction";
import ToolboxAction from "../../../Actions/ToolboxAction";
import ToolboxItem from "./ToolboxItem";

//define class
class ReportingItem extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            isLoaded: false
        };
    }

    //on mount
    componentDidMount = () => {
        //set slug in state
        this.setState({slug: this.props.slug},
            () => this.updateElements());
    }

    //on update
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.slug !== prevProps.slug) {
            this.setState({slug: this.props.slug},
                () => this.updateElements());
        }
    }

    //update
    updateElements = async () => {
        //set loader
        this.setState({isLoaded: false});

        //split slug
        let slug = this.state.slug.split(':');

        //get current user
        let user = await UserAction.getCurrentUser();

        //check slug
        switch(slug[0]) {
            case 'observation':
                let observationData = await ObservationAction.getObservation(slug[1]);
                let observation = <ObservationItem data={observationData} user={user} />;
                this.setState({item: observation, isLoaded: true});
                break;
            case 'last_minute_risk_analysis':
                let lmraData = await LMRAAction.getLMRA(slug[1]);
                let lmraRiskItems = await LMRAAction.getItems(slug[1]);
                lmraData.constructionSite = await ConstructionSiteAction.getConstructionSite(lmraData.constructionSiteId);
                lmraData.constructionSite.company = await CompanyAction.getCompany(lmraData.constructionSite.companyId);
                let lmra = <LMRAItem data={lmraData} items={lmraRiskItems} />;
                this.setState({item: lmra, isLoaded: true});
                break;
            case 'inspection_checklist':
                let iclData = await ICLAction.getICL(slug[1]);
                let iclItems = await ICLAction.getItems(slug[1]);
                iclData.constructionSite = await ConstructionSiteAction.getConstructionSite(iclData.constructionSiteId);
                iclData.constructionSite.company = await CompanyAction.getCompany(iclData.constructionSite.companyId);
                let icl = <ICLItem data={iclData} items={iclItems} />;
                this.setState({item: icl, isLoaded: true});
                break;
            case 'task_risk_analysis':
                let raData = await RiskAnalysisAction.get(slug[1]);
                let ra = <RAItem data={raData} />;
                this.setState({item: ra, isLoaded: true});
                break;
            case 'toolbox':
                let toolboxData = await ToolboxAction.getToolbox(slug[1]);
                let toolbox = <ToolboxItem data={toolboxData} user={user} />;
                this.setState({item: toolbox, isLoaded: true});
                break;
        }
    }

    //render view
    render = () => {
        if (this.state.isLoaded) {
            return (
                <div style={this.props.style} className={this.props.className}>
                    <div>
                        {this.state.item}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{...this.props.style, ...{height: '100%'}}} className={this.props.className}>
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Spinner animation={'border'} variant={'primary'} />
                    </div>
                </div>
            );
        }
    }
}

//export
export default ReportingItem;
