import APIAction from './APIAction';

//define class
let ICLAction = {

    //get ICL's by construction site
    getICLListByConstructionSite: async function(constructionSiteId) {

        //get ICL
        let icl = await APIAction.request('/inspection_checklists', 'GET', true, {constructionSiteId: constructionSiteId});

        //return
        return icl;

    },

    //get ICL with id
    getICL: async function(iclId) {

        //get icl
        let icl = await APIAction.request('/inspection_checklists/' + iclId, 'GET', true);

        //return
        return icl;

    },

    //create ICL
    createICL: async function(constructionSiteId) {

        //get icl
        let icl = await APIAction.request('/inspection_checklists', 'POST', true, {constructionSiteId: constructionSiteId});

        //return
        return icl;

    },

    //get items
    getItems: async function(iclId) {

        //get items
        let items = await APIAction.request('/inspection_checklist_items', 'GET', true, {inspection_checklist_id: iclId});

        //return
        return items;
    },

    //get single item
    getItem: async function(itemId) {

        //get item
        let item = await APIAction.request('/inspection_checklist_items/' + itemId, 'GET', true);

        //return
        return item;
    },

    //get templates
    getTemplates: async function(companyId) {

        //get templates
        let templates = await APIAction.request('/inspection_checklist_templates', 'GET', true, {company_id: companyId});

        //return
        return templates;
    },

    //update template with id
    updateTemplate: async function(templateId, data) {

        //call api
        let result = await APIAction.request('/inspection_checklist_templates/' + templateId, 'PATCH', true, data);

        //return
        return result;
    },

    //update an item of icl
    updateItem: async function(iclItem) {

        //hard copy
        let data = JSON.parse(JSON.stringify(iclItem));

        //delete id field
        delete data.id;
        delete data.inspectionChecklistOption;
        delete data.inspectionChecklist;

        //do call
        let result = await APIAction.request('/inspection_checklist_items/' + iclItem.id, 'PATCH', true, data);

        //return
        return result;
    },

    //create assignment
    createAssignment: async function(input) {

        //set data
        let data = {
            source: "",
            recommendation: input.recommendation,
            followUp: "",
            dateRequested: input.date,
            targetUser: input.user,
            inspectionChecklistId: input.inspectionChecklistId
        };

        //do call
        let result = await APIAction.request('/assignments', 'POST', true, data);

        //return
        return result;
    },

    //get ICL Options by country code
    getICLOptions: async function(country_code = 'be') {

        //get icl options
        let iclOptions = await APIAction.request('/inspection_checklist_options', 'GET', true, {country_code: country_code});

        //return
        return iclOptions;

    },

    //get icl inserts by item
    getICLInserts: async function(iclTemplate, iclOption) {

        //get inserts
        let iclInserts = await APIAction.request('/inspection_checklist_option_inserts', 'GET', true, {inspection_checklist_template_id: iclTemplate.id, inspection_checklist_option_id: iclOption.id});

        //return
        return iclInserts;

    },

    //create icl insert
    createICLInsert: async function(data) {

        //create insert
        let iclInsert = await APIAction.request('/inspection_checklist_option_inserts', 'POST', true, data);

        //return
        return iclInsert;

    },

    //update icl insert
    updateICLInsert: async function(data) {

        //set data
        let reqData = {
            active: data.active
        };

        //update
        let updateInsert = await APIAction.request('/inspection_checklist_option_inserts/' + data.id, 'PATCH', true, reqData);

        //return
        return updateInsert;
    }
};

//export
export default ICLAction;