import React from 'react';
import {Spinner} from "react-bootstrap";

class Loader extends React.Component {

    //constructor
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 'calc(50vh - 5rem)'}}>
                <Spinner animation={'border'} variant={'primary'} />
            </div>
        );
    }
}

//export
export default Loader;