import TokenRepository from '../Database/Repositories/TokenRepository';

//class definition
let AuthenticationAction = {

    //function for generic request
    getAuthenticationToken: async function() {

        //get repository
        let tokenRepository = new TokenRepository();

        //get token
        let token = await tokenRepository.get();

        //check if token
        if (token === null) {
            window.location = '/login';
        }

        //return
        return token;
    }
};

//export
export default AuthenticationAction;