//imports
import Config from '../../Config.json';
import db from '../Dexie';

//class definition
export default class LanguageRepository {

    //save function
    save = async (language, code) => {

        //delete previous record
        await db.language.where('code').equals(code).delete();
        
        //insert new
        await db.language.put(language);
    }

    //get function
    get = async (code) => {

        //get languages
        let languages = await db.language.where('code').equals(code).sortBy('createdAt');
        languages = languages.reverse();

        //set language
        let language = null;

        //check if language exists
        if (languages.length > 0) {
            language = languages[0];
        }

        //return
        return language;
    }

    //check if update is required
    updateRequired = async () => {

        //get languages
        let languages = await db.language.orderBy('createdAt').toArray();
        
        //check length
        if (languages.length < 1) {
            return true;
        }
        
        //check last date
        let last = languages[0];
        let lastDate = last.createdAt;
        lastDate = (lastDate.getTime()) / 1000;
        
        //get date
        let date = (new Date()).getTime() / 1000;
        date = date - (Config.TRANSLATION_TIMEOUT * 86400);

        //compare
        if (date > lastDate) {
            return true;
        }

        //else, no update is required
        return false;
    }

    //get all languages
    getAll = async () => {

        //get languages
        let languages = await db.language.orderBy('name').toArray();

        //check length
        if (languages.length < 1) {
            return null;
        }

        //return
        return languages;
    }

    //get current selected language
    getSelected = async () => {
      
        //set language
        let language = null;

        //get languages
        let languages = await db.language.where('selected').equals(1).toArray();

        //check if exists
        if (languages.length < 1) {

            //try to get default
            languages = await db.language.where('code').equals(Config.LOCALE).toArray();

            //check if exists
            if (languages.length < 1) {
                return null;
            }

            //set default language
            language = languages[0];

            //save default language
            await db.language.update(language.code, {selected: 1});
            
            return language;
        }

        //set language
        language = languages[0];

        //return
        return language;
    }

    //set selected
    setSelected = async (language) => {

        //get languages
        let languages = await db.language.toArray();
        //loop
        let updates = [];
        languages.forEach((langItem) => {
            //make all languages not default
            updates.push(db.language.update(langItem.code, {selected: 0}));
        });
        await Promise.all(updates);

        //set language as default
        await db.language.update(language.code, {selected: 1});
    }

    //import function
    import = async (data) => {

        //get current language
        let current = await this.getSelected();
        let currentCode = null;

        //check if exists
        if (current == null) {
            currentCode = Config.LOCALE;
        } else {
            currentCode = current.code;
        }

        //delete all previous
        await db.language.clear();

        //loop import
        let inserts = [];
        data.forEach((languageRecord) => {
            //crate new language record
            let language = {
                name: languageRecord.name,
                code: languageRecord.code,
                selected: 0,
                createdAt: new Date()
            };

            //check if selected
            if (languageRecord.code == currentCode) {
                language.selected = 1;
            }

            //save
            inserts.push(language);
        });
        await db.language.bulkPut(inserts);
    }
}