import firebase from './Firebase';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

let messaging = null;

let notificationIsSupported = !!(window.Notification /* W3C Specification */ || navigator.mozNotification /* Firefox for Android and Firefox OS */) 

if (notificationIsSupported) {
    try {
        //init messaging
        messaging = getMessaging(firebase);

        //if service workers are available
        if ('serviceWorker' in navigator) {
            getToken(messaging, {vapidKey: 'BHM7dAe6YHixoa5TkZCTjCy4PdSrnKydFKbVQMlTJmZpCroGnhUdNgnF7zf6t2si7iDoJ5dbOmUZPPW1Lo4msxY'}).then((currentToken) => {
                //save notification token
                localStorage.setItem('firebaseToken', currentToken);
            });
            
        }
    } catch (e) {
        console.log(e);
    }
}

export {messaging, onMessage};