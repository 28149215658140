import React from "react";
import CustomCheckBox from "./CustomCheckBox";

class CustomCheckBoxList extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set values if available
        this.values = [];
        if (this.props.values) {
            //hard copy
            this.values = JSON.parse(JSON.stringify(this.props.values));
        }
    }

    render = () => {
        //set checkbox
        let checkBoxes = [];
        this.props.items.forEach(element => {

            //get value
            let value = false;
            if (this.values.includes(element.value)) {
                value = true;
            }

            //add to array
            checkBoxes.push(
                <div
                    key={element.value}
                    className={'vertical-padding-3 standard-padding-3'}
                >
                    <CustomCheckBox
                        label={element.label}
                        value={value}
                        onValueChange={(value) => {
                            //check if element is in array
                            let index = this.values.indexOf(element.value);

                            //if true add
                            if (value) {
                                if (index === -1) {
                                    this.values.push(element.value);
                                }
                            }
                            //if false, remove
                            else {
                                let index = this.values.indexOf(element.value);
                                if (index > -1) {
                                    this.values.splice(index, 1);
                                }
                            }

                            //execute function
                            if (typeof this.props.onValueChange === "function") {
                                this.props.onValueChange(this.values);
                            }
                        }}
                    />
                </div>
            );
        });

        //render
        return (
            <div
                className={this.props.className}
                style={this.props.style}
            >
                {/* Label */}
                <div
                    style={{
                        display: 'flex',
                        flex: 'row',
                        paddingTop: 0
                    }}
                    className={'vertical-padding-3'}
                >
                    {this.props.iconName &&
                        <div
                            className={'bg-light'}
                            style={{
                                width: '1.8rem',
                                height: '1.8rem',
                                borderRadius: '50%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <i
                                className={this.props.iconName}
                                style={{
                                    fontWeight: '900',
                                    fontSize: '1rem'
                                }}
                            />
                        </div>
                    }
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')} style={{paddingBottom: 0}}>{this.props.label}</span>
                    </div>
                </div>

                {/* These options */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        marginLeft: '-3px',
                        marginRight: '-3px'
                    }}
                >
                    {checkBoxes}
                </div>
            </div>
        );
    }
}

//export
export default CustomCheckBoxList;