import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import { Button, Table, Modal, Image } from "react-bootstrap";
import G from "../../../Actions/GeneralAction";
import TranslationAction from "../../../Actions/TranslationAction";
import APIAction from "../../../Actions/APIAction";
import LanguageRepository from "../../../Database/Repositories/LanguageRepository";
import fileDownload from "js-file-download";
import { generatePath } from "react-router-dom";

//define class
class ObservationItem extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            showModal: false,
            downloadingFile: false
        };
    }

    //render view
    render = () => {
        return (
            <div style={this.props.style} className={this.props.className}>
                {this.props.data.positiveRemark ?
                    <h4><CustomTranslation value={'KEY.Positive Remark'} /></h4> :
                    <h4><CustomTranslation value={'KEY.Observation'} /></h4>
                }
                {/* Table */}
                <Table striped bordered>
                    <tbody>
                        <tr>
                            <th><CustomTranslation value={'KEY.Company'} /></th>
                            <td>{this.props.data.constructionSite.company.name}</td>
                        </tr>
                        <tr>
                            <th><CustomTranslation value={'KEY.Construction Site'} /></th>
                            <td>{this.props.data.constructionSite.name}</td>
                        </tr>
                        <tr>
                            <th><CustomTranslation value={'KEY.Created by'} /></th>
                            <td>{(this.props.data.createdBy.firstName && this.props.data.createdBy.lastName) ?
                                this.props.data.createdBy.firstName + ' ' + this.props.data.createdBy.lastName :
                                this.props.data.createdBy.email
                            }</td>
                        </tr>
                        <tr>
                            <th><CustomTranslation value={'KEY.Created at'} /></th>
                            <td>{G.formatDateTime(this.props.data.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                {/* Buttons */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flexBasis: 1, flexGrow: 1, marginLeft: 0 }} className={'standard-margin-3'}>
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => this.setState({ showModal: true })}
                        >
                            <CustomTranslation value={'KEY.More'} />
                        </Button>
                    </div>
                    <div style={{ flexBasis: 1, flexGrow: 1, marginRight: 0 }} className={'standard-margin-3'}>
                        <Button
                            block
                            variant={'dark'}
                            disabled={this.state.downloadingFile}
                            onClick={() => {
                                this.setState({ downloadingFile: true }, async () => {
                                    //default file name
                                    let now = new Date();
                                    let fileName = await TranslationAction.translate('KEY.Observation');
                                    if (this.props.data.positiveRemark) {
                                        fileName = await TranslationAction.translate('KEY.Positive Remark');
                                    }
                                    fileName += ' ' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
                                    fileName += '.pdf';

                                    //get language
                                    let languageRepository = new LanguageRepository();
                                    let language = await languageRepository.getSelected();

                                    //request
                                    APIAction.request('/assignments/' + this.props.data.assignments[0].id + '/pdf', 'GET', true, { language: language.code }, true, false, 'json', true)
                                        .then(result => {
                                            fileName = fileName.replace(/['"]+/g, '');
                                            fileDownload(result, fileName, 'application/pdf');
                                            this.setState({ downloadingFile: false });
                                        });

                                });
                            }}
                        >
                            <CustomTranslation value={'KEY.Download'} />
                        </Button>
                    </div>
                </div>

                {(this.props.data.assignments[0].sender.id === this.props.user.id || this.props.data.assignments[0].targetUser.id === this.props.user.id) &&
                    <Button
                        style={{ marginTop: 6 }}
                        block
                        variant={'dark'}
                        onClick={() => {
                            if (this.props.data.assignments[0].targetUser.id === this.props.user.id) {
                                window.location.href = generatePath('/construction_site/:cs_id/assignment/:id/in', { cs_id: this.props.data.constructionSite.id, id: this.props.data.assignments[0].id });
                            } else {
                                window.location.href = generatePath('/construction_site/:cs_id/assignment/:id/out', { cs_id: this.props.data.constructionSite.id, id: this.props.data.assignments[0].id });
                            }
                        }}
                    >
                        <CustomTranslation value={'KEY.To'} /> {this.props.data.positiveRemark ?
                            <CustomTranslation value={'KEY.Positive Remark'} /> :
                            <CustomTranslation value={'KEY.Observation'} />
                        }
                    </Button>
                }

                {/* Modal */}
                <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} backdrop={'static'} keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.data.positiveRemark ?
                                <CustomTranslation value={'KEY.Positive Remark'} /> :
                                <CustomTranslation value={'KEY.Observation'} />
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Full Table */}
                        <Table striped bordered>
                            <tbody>
                                {this.props.data.assignments[0].photo &&
                                    <>
                                        <tr>
                                            <th colSpan={2}><CustomTranslation value={'KEY.Image overview'} /></th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image /*height={300}*/ style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.props.data.assignments[0].photo.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {this.props.data.assignments[0].altPhoto &&
                                    <>
                                        <tr>
                                            <th colSpan={2}><CustomTranslation value={'KEY.Image detail'} /></th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image height={300} style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.props.data.assignments[0].altPhoto.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                                <tr>
                                    <th><CustomTranslation value={'KEY.From'} /></th>
                                    <td>
                                        {(this.props.data.assignments[0].sender.firstName || this.props.data.assignments[0].sender.lastName)
                                            ?
                                            (this.props.data.assignments[0].sender.firstName || '') + ' ' + (this.props.data.assignments[0].sender.lastName || '')
                                            :
                                            (this.props.data.assignments[0].sender.email || '')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th><CustomTranslation value={'KEY.To'} /></th>
                                    <td>
                                        {(this.props.data.assignments[0].targetUser.firstName || this.props.data.assignments[0].targetUser.lastName)
                                            ?
                                            (this.props.data.assignments[0].targetUser.firstName || '') + ' ' + (this.props.data.assignments[0].targetUser.lastName || '')
                                            :
                                            (this.props.data.assignments[0].targetUser.email || '')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th><CustomTranslation value={'KEY.Date'} /></th>
                                    <td>
                                        {G.formatDate(this.props.data.assignments[0].createdAt)}
                                    </td>
                                </tr>
                                <tr>
                                    <th><CustomTranslation value={'KEY.Title'} /></th>
                                    <td>{this.props.data.assignments[0].title ?? ''}</td>
                                </tr>
                                <tr>
                                    <th><CustomTranslation value={'KEY.Solve by'} /></th>
                                    <td>{G.formatDate(this.props.data.assignments[0].dateRequested)}</td>
                                </tr>
                                <tr>
                                    {this.props.data.positiveRemark ?
                                        <th><CustomTranslation value={'KEY.Positive find'} /></th> :
                                        <th><CustomTranslation value={'KEY.Description of risk'} /></th>
                                    }
                                    <td>{G.nl2br(this.props.data.assignments[0].riskDescription)}</td>
                                </tr>
                                {!this.props.data.positiveRemark &&
                                    <tr>
                                        <th><CustomTranslation value={'KEY.Action taken'} /></th>
                                        <td>{G.nl2br(this.props.data.assignments[0].immediateActionTaken)}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>
                                        {this.props.data.positiveRemark ?
                                            <CustomTranslation value={'KEY.Possible Recommendation'} /> :
                                            <CustomTranslation value={'KEY.Recommendation'} />
                                        }
                                    </th>
                                    <td>{G.nl2br(this.props.data.assignments[0].recommendation)}</td>
                                </tr>
                                {this.props.data.assignments[0].dateFinished &&
                                    <React.Fragment>
                                        <tr>
                                            <th><CustomTranslation value={'KEY.Finished on'} /></th>
                                            <td>
                                                {G.formatDate(this.props.data.assignments[0].dateFinished)}
                                            </td>
                                        </tr>
                                        {this.props.data.assignments[0].comment &&
                                            <tr>
                                                <th><CustomTranslation value={'KEY.Follow-up'} /></th>
                                                <td>
                                                    {G.nl2br(this.props.data.assignments[0].comment)}
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                }
                                {this.props.data.assignments[0].followUpPhoto &&
                                    <>
                                        <tr>
                                            <th colSpan={2}><CustomTranslation value={'KEY.Image overview follow-up'} /></th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image /*height={300}*/ style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.props.data.assignments[0].followUpPhoto.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {this.props.data.assignments[0].followUpAltPhoto &&
                                    <>
                                        <tr>
                                            <th colSpan={2}><CustomTranslation value={'KEY.Image detail follow-up'} /></th>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className={'d-flex flex-row justify-content-center'}>
                                                    <Image /*height={300}*/ style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.props.data.assignments[0].followUpAltPhoto.resizedPath} rounded />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button block variant={'dark'} onClick={() => this.setState({ showModal: false })}>
                            <CustomTranslation value={'KEY.Close'} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

//export
export default ObservationItem;