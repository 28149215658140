import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomInput from "../../Components/CustomInput";
import T from "../../../Actions/TranslationAction";
import UserAction from "../../../Actions/UserAction";
import Loader from "../../Components/Loader";
import {Button} from "react-bootstrap";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import CustomImageInput from "../../Components/CustomImageInput";
import CustomListInput from "../../Components/CustomListInput";
import CustomDate from "../../Components/CustomDate";
import CustomCheckBox from "../../Components/CustomCheckBox";
import CustomMultiSelect from "../../Components/CustomMultiSelect";

class EditConstructionSiteProjectInfoTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            constructionSiteId: this.props.constructionSite.id
        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update data
    updateElements = async () => {
        //make sure translations are ready
        await T.init();

        //get users
        let users = await UserAction.getUserListConstructionSite(this.props.constructionSite.id);

        //-- make elements
        let userElements = [];
        let userElementsMultiSelect = [];

        //-- add empty option
        userElements.push({label: await T.translate('KEY.Empty'), value: null});
        //-- loop
        users.forEach(element => {
            let name1 = (element.firstName || element.lastName) ? (element.firstName || '') + ' ' + (element.lastName || '') : element.email;
            userElements.push({label: name1, value: element['@id']});
        });
        users.forEach(element => {
            let name2 = (element.firstName || element.lastName) ? (element.firstName || '') + ' ' + (element.lastName || '') : element.email;
            userElementsMultiSelect.push({label: name2, value: element['@id']});
        });

        // retrieve ids for ManyToMany
        let firstAidProviders = [];
        this.props.constructionSite.firstAidProviders.forEach(firstAidProvider => {
            firstAidProviders.push(firstAidProvider['@id']);
        });

        //get state
        this.setState(
            {
                constructionSite: this.props.constructionSite,
                phoneEmergency: this.props.constructionSite.phoneEmergency,
                projectFases: this.props.constructionSite.projectFases,
                designCoordinator: this.props.constructionSite.designCoordinator,
                creationCoordinator: this.props.constructionSite.creationCoordinator,
                contactPersonWorkPermit: this.props.constructionSite.contactPersonWorkPermit,
                projectInfo: this.props.constructionSite.projectInfo,
                users: userElements,
                usersMultiSelect: userElementsMultiSelect,
                imageId: this.props.constructionSite.picture ? this.props.constructionSite.picture.id : null, // Used to display
                imageApiId: this.props.constructionSite.picture ? this.props.constructionSite.picture['@id'] : null, // Used to save data
                startDate: this.props.constructionSite.startDate ? new Date(this.props.constructionSite.startDate) : new Date(),
                endDate: this.props.constructionSite.endDate ? new Date(this.props.constructionSite.endDate) : new Date(),
                workPermit: this.props.constructionSite.workPermit ? this.props.constructionSite.workPermit : false,
                totalEmployees: this.props.constructionSite.totalEmployees ? this.props.constructionSite.totalEmployees.toString() : '1',
                firstAidProviders: firstAidProviders,
            });
    }

    //set data for form
    updateForm = async () => {
        // Update user
        let formData = {
            phoneEmergency: (this.state.phoneEmergency || null),
            projectFases: Array.isArray(this.state.projectFases) ? this.state.projectFases : [],
            designCoordinator: this.state.designCoordinator ? this.state.designCoordinator : null,
            creationCoordinator: this.state.creationCoordinator ? this.state.creationCoordinator : null,
            contactPersonWorkPermit: this.state.contactPersonWorkPermit ? this.state.contactPersonWorkPermit : null,
            projectInfo: this.state.projectInfo,
            picture: this.state.imageApiId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            workPermit: this.state.workPermit,
            totalEmployees: Number(this.state.totalEmployees.replace(/[^0-9]/g, '')),
            firstAidProviders: this.state.firstAidProviders,
        };

        let response = await ConstructionSiteAction.editConstructionSite(this.state.constructionSiteId, formData);
    }

    //handle upload event
    handleUpload = async (file) => {
        this.setState(
            {
                'imageId': file.id,
                'imageApiId': file['@id']
            }
        );
    }

    //render
    render = () => {
        if (this.state.users) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Project Info'}/></h2>
                    </div>
                    {/* Form */}
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-phone'}
                            label={<CustomTranslation value={'KEY.Emergency number'}/>}
                            inputProps={{
                                type: 'tel'
                            }}
                            value={this.state.phoneEmergency}
                            onChangeText={(text) => {
                                this.setState({phoneEmergency: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomImageInput
                            iconName={'las la-image'}
                            label={<CustomTranslation value={'KEY.Upload project image'} />}
                            imageId={this.state.imageId ? this.state.imageId : null}
                            onUpload={this.handleUpload}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Project Description'} />}
                            inputProps={{
                                as: 'textarea'
                            }}
                            value={this.state.projectInfo}
                            onChangeText={(text) => this.setState({projectInfo: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomListInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Project Fases'} />}
                            value={this.state.projectFases}
                            onChangeText={(value) => this.setState({projectFases: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomDate
                            iconName={'las la-calendar'}
                            label={<CustomTranslation value={'KEY.Start'} />}
                            noMinDate
                            value={this.state.startDate}
                            onChange={(value) => this.setState({startDate: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomDate
                            iconName={'las la-calendar'}
                            label={<CustomTranslation value={'KEY.End'} />}
                            noMinDate
                            value={this.state.endDate}
                            onChange={(value) => this.setState({endDate: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Work Permit'} />}
                            value={this.state.workPermit}
                            onValueChange={(value) => this.setState({workPermit: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-user'}
                            label={<CustomTranslation value={'KEY.Contact Person Work Permit'} />}
                            value={this.state.contactPersonWorkPermit}
                            onChangeText={(value) => this.setState({contactPersonWorkPermit: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-user'}
                            label={<CustomTranslation value={'KEY.Design Coordinator'} />}
                            value={this.state.designCoordinator}
                            onChangeText={(value) => this.setState({designCoordinator: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-user'}
                            label={
                                this.state.constructionSite.country.countryCode.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KEY.Veiligheidscoördinator Uitvoering (indien toepasselijk)'} /> :
                                <CustomTranslation value={'KEY.Creation Coordinator'} />
                            }
                            value={this.state.creationCoordinator}
                            onChangeText={(value) => this.setState({creationCoordinator: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomMultiSelect
                            iconName={'las la-user'}
                            label={<CustomTranslation value={'KEY.FirstAid Providers'} />}
                            items={this.state.usersMultiSelect}
                            selectedItems={this.state.firstAidProviders}
                            onSelectedItemsChange={(value) => {
                                let result = [];
                                for (let item of value) {
                                    result.push(item);
                                }
                                this.setState({'firstAidProviders': result});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Total Employees'} />}
                            inputProps={{
                                type: 'number'
                            }}
                            value={this.state.totalEmployees}
                            onChangeText={(value) => this.setState({totalEmployees: value})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => {
                                this.updateForm()
                            }}
                        >
                            <CustomTranslation value={'KEY.Update Project Info'}/>
                        </Button>
                    </div>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default EditConstructionSiteProjectInfoTab;