import Dexie from 'dexie';
import Config from '../Config.json';

const db = new Dexie('csc_db');

//set models
db.version(Config.DATABASE_VERSION).stores({
    token: '&token, createdAt',
    language: '&code, name, selected, createdAt',
    translation: '&[key+languageCode], key, translation, createdAt, languageCode',
    cache: '&id, lock, url, method, authentication, body, baseUrl, errorRedirect, contentType, response, createdAt, [url+method+authentication+body+baseUrl+errorRedirect+contentType]'
});

export default db;