import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomInput from "../../Components/CustomInput";
import T from "../../../Actions/TranslationAction";
import Loader from "../../Components/Loader";
import {Button} from "react-bootstrap";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import CustomListInput from "../../Components/CustomListInput";
import CustomCheckBox from "../../Components/CustomCheckBox";

class EditConstructionSiteAccessibilityTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            constructionSiteId: this.props.constructionSite.id
        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update data
    updateElements = async () => {
        //make sure translations are ready
        await T.init();

        //get state
        this.setState(
            {
                isLoaded: true,
                constructionSite: this.props.constructionSite,
                access: this.props.constructionSite.access,
                accommodation: this.props.constructionSite.accommodation,
                assemblyArea: this.props.constructionSite.assemblyArea,
                parkingFacilities: this.props.constructionSite.parkingFacilities,
                registrationProcedure: this.props.constructionSite.registrationProcedure,
                maximumSpeed: this.props.constructionSite.maximumSpeed ? this.props.constructionSite.maximumSpeed.toString() : '50',
                smokingFacility: this.props.constructionSite.smokingFacility,
                specialConditionsSetUpMachinesDescription: this.props.constructionSite.specialConditionsSetUpMachinesDescription,
                specialConditionsSetUpMachines: this.props.constructionSite.specialConditionsSetUpMachines ? this.props.constructionSite.specialConditionsSetUpMachines : false,
                storagePlaceDescription: this.props.constructionSite.storagePlaceDescription,
                storagePlace: this.props.constructionSite.storagePlace ? this.props.constructionSite.storagePlace : false,
                safetyIntroductionDescription: this.props.constructionSite.safetyIntroductionDescription,
                safetyIntroduction: this.props.constructionSite.safetyIntroduction ? this.props.constructionSite.safetyIntroduction : false,
                instructions: this.props.constructionSite.instructions,
                vgPlanSafetyCoordinatorDescription: this.props.constructionSite.vgPlanSafetyCoordinatorDescription,
                vgPlanSafetyCoordinator: this.props.constructionSite.vgPlanSafetyCoordinator ? this.props.constructionSite.vgPlanSafetyCoordinator : false,
                extraInstructions: this.props.constructionSite.extraInstructions,
                accessibilitySaved: this.props.constructionSite.accessibilitySaved
            });
    }

    //set data for form
    updateForm = async () => {
        // Update user
        let formData = {
            access: Array.isArray(this.state.access) ? this.state.access : [],
            accommodation: Array.isArray(this.state.accommodation) ? this.state.accommodation : [],
            assemblyArea: this.state.assemblyArea,
            parkingFacilities: Array.isArray(this.state.parkingFacilities) ? this.state.parkingFacilities : [],
            registrationProcedure: Array.isArray(this.state.registrationProcedure) ? this.state.registrationProcedure : [],
            maximumSpeed: Number(this.state.maximumSpeed.replace(/[^0-9]/g, '')),
            smokingFacility: this.state.smokingFacility,
            specialConditionsSetUpMachinesDescription: this.state.specialConditionsSetUpMachinesDescription,
            specialConditionsSetUpMachines: this.state.specialConditionsSetUpMachines,
            storagePlaceDescription: this.state.storagePlaceDescription,
            storagePlace: this.state.storagePlace,
            safetyIntroductionDescription: this.state.safetyIntroductionDescription,
            safetyIntroduction: this.state.safetyIntroduction,
            instructions: Array.isArray(this.state.instructions) ? this.state.instructions : [],
            vgPlanSafetyCoordinatorDescription: this.state.vgPlanSafetyCoordinatorDescription,
            vgPlanSafetyCoordinator: this.state.vgPlanSafetyCoordinator,
            extraInstructions: this.state.extraInstructions,
            accessibilitySaved: this.state.accessibilitySaved
        };

        let response = await ConstructionSiteAction.editConstructionSite(this.state.constructionSiteId, formData);
    }

    //handle upload event
    handleUpload = async (file) => {
        this.setState(
            {
                'imageId': file.id,
                'imageApiId': file['@id']
            }
        );
    }

    //render
    render = () => {
        if (this.state.isLoaded) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}>
                            {
                                this.state.constructionSite.country.countryCode.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KEY.Projectlocatie voorschriften'}/> :
                                <CustomTranslation value={'KEY.Accessibility'}/>
                            }
                            
                        </h2>
                    </div>
                    {/* Form */}
                    <div className={'vertical-margin-2'}>
                        <CustomListInput
                            iconName={'las la-quote-left'}
                            label={
                                this.state.constructionSite.country.countryCode.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KEY.Afscherming en toegang tot de bouwplaats'}/> :
                                <CustomTranslation value={'KEY.Access'}/>
                            }
                            value={this.state.access}
                            onChangeText={(text) => {
                                this.setState({access: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomListInput
                            iconName={'las la-quote-left'}
                            label={
                                this.state.constructionSite.country.countryCode.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KEY.Bouwplaatsaccommodatie'}/> :
                                <CustomTranslation value={'KEY.Accommodation'}/>
                            }
                            value={this.state.accommodation}
                            onChangeText={(text) => {
                                this.setState({accommodation: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Assembly Area'}/>}
                            inputProps={{
                                as: 'textarea'
                            }}
                            value={this.state.assemblyArea}
                            onChangeText={(text) => {
                                this.setState({assemblyArea: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomListInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Parking Facilities'}/>}
                            value={this.state.parkingFacilities}
                            onChangeText={(text) => {
                                this.setState({parkingFacilities: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomListInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Registration Procedure'}/>}
                            value={this.state.registrationProcedure}
                            onChangeText={(text) => {
                                this.setState({registrationProcedure: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Maximum Speed'}/>}
                            inputProps={{
                                type: 'number',
                                min: 0,
                                step: 5
                            }}
                            value={this.state.maximumSpeed}
                            onChangeText={(text) => {
                                this.setState({maximumSpeed: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Smoking Facility'}/>}
                            inputProps={{
                                as: 'textarea'
                            }}
                            value={this.state.smokingFacility}
                            onChangeText={(text) => {
                                this.setState({smokingFacility: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Special Conditions Setup Machines'}/>}
                            inputProps={{
                                as: 'textarea'
                            }}
                            value={this.state.specialConditionsSetUpMachinesDescription}
                            onChangeText={(text) => {
                                this.setState({specialConditionsSetUpMachinesDescription: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Applicable?'} />}
                            value={this.state.specialConditionsSetUpMachines}
                            onValueChange={(value) => {
                                this.setState({specialConditionsSetUpMachines: value});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Storage Place'}/>}
                            inputProps={{
                                as: 'textarea'
                            }}
                            value={this.state.storagePlaceDescription}
                            onChangeText={(text) => {
                                this.setState({storagePlaceDescription: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Applicable?'} />}
                            value={this.state.storagePlace}
                            onValueChange={(value) => {
                                this.setState({storagePlace: value});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Safety Introduction'}/>}
                            inputProps={{
                                as: 'textarea'
                            }}
                            value={this.state.safetyIntroductionDescription}
                            onChangeText={(text) => {
                                this.setState({safetyIntroductionDescription: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Applicable?'} />}
                            value={this.state.storagePlace}
                            onValueChange={(value) => {
                                this.setState({safetyIntroduction: value});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomListInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Instructions'}/>}
                            value={this.state.instructions}
                            onChangeText={(text) => {
                                this.setState({instructions: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.VG Plan Safety Coordinator'}/>}
                            inputProps={{
                                as: 'textarea'
                            }}
                            value={this.state.vgPlanSafetyCoordinatorDescription}
                            onChangeText={(text) => {
                                this.setState({vgPlanSafetyCoordinatorDescription: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Applicable?'} />}
                            value={this.state.vgPlanSafetyCoordinator}
                            onValueChange={(value) => {
                                this.setState({vgPlanSafetyCoordinator: value});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomListInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Extra instructions'}/>}
                            value={this.state.extraInstructions}
                            onChangeText={(text) => {
                                this.setState({extraInstructions: text});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Share accessibility overview with viewer'} />}
                            value={this.state.accessibilitySaved}
                            onValueChange={(value) => {
                                this.setState({accessibilitySaved: value});
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => {
                                this.updateForm()
                            }}
                        >
                            {
                                this.state.constructionSite.country.countryCode.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KEY.Voorschriften opslaan'} /> :
                                <CustomTranslation value={'KEY.Update Accessibility'} />
                            }
                        </Button>
                    </div>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default EditConstructionSiteAccessibilityTab;