import React from 'react';
import {Form, Button, InputGroup} from 'react-bootstrap';
import CustomTranslation from '../Components/CustomTranslation';
import UserAction from '../../Actions/UserAction';
import CacheRepository from "../../Database/Repositories/CacheRepository";
import CustomContainer from "../Components/CustomContainer";
import TranslationAction from "../../Actions/TranslationAction";
import Loader from "../Components/Loader";
import helmetImage from "../../Images/construction-hat.png";
import logoImage from "../../Images/logo-full.png";
import PasswordModal from "./Components/PasswordModal";
import RegisterModal from "./Components/RegisterModal";
import {useParams} from "react-router-dom";

class LoginScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showPasswordModal: false,
            showRegisterModal: false
        };
    }

    //on mount
    componentDidMount = () => {
        //empty cache repo
        let cacheRepository = new CacheRepository();
        cacheRepository.empty();

        //update elements
        this.updateElements();
    }

    //update elements
    updateElements = async () => {
        //translate
        let translations = {};
        translations['Email'] = await TranslationAction.translate('KEY.Email');
        translations['Password'] = await TranslationAction.translate('KEY.Password');

        //set state
        this.setState({
            translations: translations
        });
    }

    render = () => {
        if (this.state.translations) {
            return (
                <CustomContainer noHeader
                                 footer={
                                     <Button variant="primary" type="button" block
                                             onClick={() => {
                                                 UserAction.loginSubmit({
                                                     email: this.state.email,
                                                     password: this.state.password
                                                 });
                                             }}
                                     >
                                         <CustomTranslation value={'KEY.Login in CSC'}/>
                                     </Button>
                                 }
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                className={'vertical-margin'}
                                style={{
                                    height: '10vh',
                                    maxWidth: '500px',
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    backgroundImage: 'url('+logoImage+')',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain'
                                }}
                            >
                            </div>
                        </div>
                        <div
                            className={'vertical-margin'}
                            style={{
                                height: '25vh',
                                flexGrow: 1,
                                flexShrink: 1,
                                backgroundImage: 'url('+helmetImage+')',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'contain'
                            }}
                        >
                        </div>
                        <div
                            className={'vertical-padding'}
                        >
                            <Form>
                                <Form.Group controlId="formEmail">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className={'bg-light borderless'}>
                                                <i className={'las la-user text-primary'} style={{fontSize: '1.4rem'}}/>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            ref={(input) => {this.emailInput = input;}}
                                            className={'bg-light login-control borderless'}
                                            type={'email'}
                                            placeholder={this.state.translations.Email}
                                            onChange={(event) => {
                                                this.setState({email: event.target.value})
                                            }}
                                            onKeyUp={(event) => {
                                                if (event.key === 'Enter') {
                                                    this.passwordInput.focus();
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className={'bg-light borderless'}>
                                                <i className={'las la-lock text-primary'} style={{fontSize: '1.4rem'}}/>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            ref={(input) => {this.passwordInput = input;}}
                                            className={'bg-light login-control borderless'}
                                            type={'password'}
                                            placeholder={this.state.translations.Password}
                                            onChange={(event) => {
                                                this.setState({password: event.target.value})
                                            }}
                                            onKeyUp={(event) => {
                                                if (event.key === 'Enter') {
                                                    UserAction.loginSubmit({
                                                        email: this.state.email,
                                                        password: this.state.password
                                                    });
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <div>
                                    <Button
                                        className={'text-dark'}
                                        style={{textDecoration: 'underline'}}
                                        variant={'link'}
                                        block
                                        onClick={() => {
                                            this.setState({showPasswordModal: true});
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Forgot your password?'} />
                                    </Button>
                                    <Button
                                        className={'text-dark'}
                                        style={{textDecoration: 'underline'}}
                                        variant={'link'}
                                        block
                                        onClick={() => {
                                            this.setState({showRegisterModal: true});
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Register'} />
                                    </Button>
                                </div>
                            </Form>
                        </div>
                        {/* Modal for password reset */}
                        <PasswordModal
                            show={this.state.showPasswordModal}
                            onHide={() => {
                                this.setState({showPasswordModal: false});
                            }}
                        />
                        {/* Modal for registering new user */}
                        <RegisterModal
                            show={this.state.showRegisterModal}
                            onHide={() => {
                                this.setState({showRegisterModal: false});
                            }}
                        />
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer noHeader>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <LoginScreen {...props} params={params} />;
}