//define class
let GeneralAction = {

    //format date from ISO string
    formatDate: function(ISOString) {

        //create date object
        let date =  new Date(ISOString);

        //define format
        let dateFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});

        //apply
        let [{value: month}, , {value: day}, , {value: year}] = dateFormat.formatToParts(date);

        //return string
        return `${day}/${month}/${year}`;
    },

    //format date & time from ISO string
    formatDateTime: function(ISOString) {

        //create date object
        let date =  new Date(ISOString);

        //define format
        let dateFormat = new Intl.DateTimeFormat('nl-BE', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});

        //apply
        let [{value: day}, , {value: month}, , {value: year}, , {value: hour}, , {value: minute}] = dateFormat.formatToParts(date);

        //return string
        return `${day}/${month}/${year} ${hour}:${minute}`;
    },

    //calculate color form gradient between 2 colors and ratio
    colorGradient: function(color1, color2, ratio) {

        //create hex
        let hex = function(x) {
            x = x.toString(16);
            return (x.length == 1) ? '0' + x : x;
        };

        //remove #
        color1 = color1.replace('#', '');
        color2 = color2.replace('#', '');

        //calculate RGB values
        let r = Math.ceil(parseInt(color1.substring(0,2), 16) * ratio + parseInt(color2.substring(0,2), 16) * (1-ratio));
        let g = Math.ceil(parseInt(color1.substring(2,4), 16) * ratio + parseInt(color2.substring(2,4), 16) * (1-ratio));
        let b = Math.ceil(parseInt(color1.substring(4,6), 16) * ratio + parseInt(color2.substring(4,6), 16) * (1-ratio));
        
        //return value
        return '#' + hex(r) + hex(g) + hex(b);
    },

    //new line to break for html
    nl2br: function(str) {
        if (null === str) str = '';
        let prepare = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '%%%BREAK%%%' + '$2');
        let exploded = prepare.split('%%%BREAK%%%');

        let result = [];

        for (let item of exploded) {
          result.push(item.replace('\r', '').replace('\n', ''));
          result.push(<br />);
        }

        result.pop();

        return result;
    }
};

//export
export default GeneralAction;