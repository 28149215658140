//imports
import LanguageRepository from '../Database/Repositories/LanguageRepository';
import TranslationRepository from '../Database/Repositories/TranslationRepository';
import Config from '../Config.json';

//define class
let TranslationAction = {

    //init
    init: function() {
        //language respository
        this.languageRepository = new LanguageRepository();
        this.translationRepository = new TranslationRepository();
    },

    //get languages
    languages: async function() {
        return this.languageRepository.getAll();
    },

    //get current
    currentLanguage: async function() {
        return this.languageRepository.getSelected();
    },

    //translate
    translate: async function(key) {
        //get active language
        let language = await this.languageRepository.getSelected();

        //if null return key
        if (language == null) {
            return key.replace('KEY.', '');
        }

        //search for translation
        let translation = await this.translationRepository.get(key, language);

        //if null return key
        if (translation == null) {
            return key.replace('KEY.', '');
        }

        //return
        return translation.translation;
    },

    //set current language
    setLanguage: async function(code) {
        //get language
        let language = await this.languageRepository.get(code);
        //set the language as selected
        await this.languageRepository.setSelected(language);
    },

    //update
    update: async function() {

        //translation update
        let transUpdate = await this.translationRepository.updateRequired();
        //language update
        let langUpdate = await this.languageRepository.updateRequired();
        
        //check if needed
        if (transUpdate || langUpdate) {

            //request translation
            let translationsImport = undefined;
            try {
                translationsImport = await fetch(Config.TRANSLATION_URL)
                    .then(
                        response => response.json()
                    ).catch(error => {
                        return undefined;
                    });
            } catch (e) {
                translationsImport = undefined;
            }

            //check if ok
            if (typeof translationsImport !== 'undefined') {
                //import languages
                await this.languageRepository.import(translationsImport.languages);

                //import translation
                await this.translationRepository.import(translationsImport.translations);

                //return
                return true;
            }
        }

        //return unneeded
        return false;
    },

    //translate based on array from request
    translateArray: async function(input) {

        //check if is object
        if ((input === null) || (typeof input !== 'object')) {
            return '';
        }

        //change keys to lower case
        let temp = {};
        //loop
        Object.keys(input).forEach(function(key) {
            temp[key.toLowerCase()] = input[key];
        });
        //set
        input = temp;

        //get current language
        let language = await this.languageRepository.getSelected();

        //check if language is supported
        if (
            (language !== null) &&
            (language.code in input)
        ) {
            return input[language.code];
        }

        //use config value as fallback
        if (Config.LOCALE in input) {
            return input[Config.LOCALE];
        }

        //use first key as last resort
        return input[Object.keys(input)[0]];
    }

};

//export
export default TranslationAction;