import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import RiskAnalysisAction from "../../Actions/RiskAnalysisAction";
import {Breadcrumb, Button, Modal, Spinner} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import RiskList from "./Components/RiskList";

class RiskListScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            waitModal: false
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {
        //get risk analysis
        let ra = await RiskAnalysisAction.get(this.props.params.id);

        //set state
        this.setState({
            data: {
                riskAnalysis: ra
            }
        });
    }

    //finish RA
    finish = async () => {
        //finish
        this.setState({waitModal: true});
        let result = await RiskAnalysisAction.finishRiskAnalysis(this.state.data.riskAnalysis);

        //navigate
        sessionStorage.removeItem('risk-matrix-' + this.state.data.riskAnalysis.id);
        this.setState({waitModal: false});
        window.location.href = generatePath('/construction_site/:id', {id: this.state.data.riskAnalysis.constructionSite.id});
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => {this.finish();}}
                        >
                            <CustomTranslation value={'KEY.Finish Risk Analysis'} />
                        </Button>
                    }
                >
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/construction_site/:id', {id: this.state.data.riskAnalysis.constructionSite.id})}
                            >
                                {this.state.data.riskAnalysis.constructionSite.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/risk_analysis/:id', {id: this.state.data.riskAnalysis.id})}
                            >
                                <CustomTranslation value={this.state.data.riskAnalysis.title} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.Risk analysis'}/>
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div style={{marginBottom: '-1rem'}}>
                            <h1>
                                <CustomTranslation value={'KEY.Risk analysis'}/>
                            </h1>
                        </div>
                        {/* Subtitle */}
                        <div>
                            <h4>
                                <CustomTranslation value={this.state.data.riskAnalysis.title} />
                            </h4>
                        </div>
                        {/* Info text */}
                        <p>
                            <CustomTranslation value={'KEY.Check the relevant topics and evaluate'} />
                        </p>
                        {/* Risk list */}
                        <RiskList
                            className={'fade-in'}
                            riskAnalysis={this.state.data.riskAnalysis}
                        />

                        {/* Modal */}
                        <Modal
                            show={this.state.waitModal}
                            onHide={null}
                            backdrop={'static'}
                            keyboard={false}
                        >
                            <Modal.Body>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <div>
                                        <Spinner animation={'border'} variant={'primary'} />
                                    </div>
                                    <div>
                                        <CustomTranslation value={'KEY.Finishing risk analysis please wait...'} />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <RiskListScreen {...props} params={params}/>;
}
