import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomInput from "../../Components/CustomInput";
import CustomSelect from "../../Components/CustomSelect";
import T from "../../../Actions/TranslationAction";
import UserAction from "../../../Actions/UserAction";
import Loader from "../../Components/Loader";
import CustomMultiListInput from "../../Components/CustomMultiListInput";
import { Button } from "react-bootstrap";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import CountryAction from "../../../Actions/CountryAction";
import Uuid from "uuid/v1";

class EditConstructionSiteTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            constructionSiteId: this.props.constructionSite.id
        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update data
    updateElements = async () => {

        //set state
        this.setState({
            name: this.props.constructionSite.name,
            addressCompany: this.props.constructionSite.addressCompany,
            street: this.props.constructionSite.street,
            number: this.props.constructionSite.number,
            zip: this.props.constructionSite.zip,
            city: this.props.constructionSite.city,
            country: { label: await T.translateArray(this.props.constructionSite.country.name), value: this.props.constructionSite.country['@id'] },
            countryId: this.props.constructionSite.country['@id'],
            countryUuid: Uuid(),
            contactPersons: this.props.constructionSite.contactPersons
        });

        //array for requests
        let requests = [];

        //push needed requests
        //get countries
        requests.push(CountryAction.getCountries());
        //get users
        requests.push(UserAction.getUserListConstructionSite(this.props.constructionSite.id));

        //wait for result
        let results = await Promise.all(requests);

        //set results
        let countries = [];
        let currentCountry = null;
        for (let [key, element] of Object.entries(results[0])) {
            countries.push({ label: await T.translateArray(element.name), value: element['@id'], code: element.countryCode });

            if (this.props.constructionSite.country && this.props.constructionSite.country.id === element.id) {
                currentCountry = { 'label': await T.translateArray(element.name), 'value': element['@id'], 'code': element.countryCode };
            }
        }

        let users = [];
        //add empty option
        users.push({ label: await T.translate('KEY.Empty'), value: 'EMPTY' });
        results[1].forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            users.push({ label: label, value: element.email });
        });

        //set correct user
        if (this.props.constructionSite && (this.props.constructionSite.workManager || this.props.constructionSite.projectManager)) {
            if (this.props.constructionSite.workManager) {
                let wManager = this.props.constructionSite.workManager;
                let wName = (wManager.firstName || wManager.lastName) ? (wManager.firstName || '') + ' ' + (wManager.lastName || '') : wManager.email;
                this.setState({
                    workManager: { label: wName, value: this.props.constructionSite.workManager.email }
                });
            } else {
                this.setState({
                    workManager: users[0]
                });
            }

            if (this.props.constructionSite.projectManager) {
                let pManager = this.props.constructionSite.projectManager;
                let pName = (pManager.firstName || pManager.lastName) ? (pManager.firstName || '') + ' ' + (pManager.lastName || '') : pManager.email;
                this.setState({
                    projectManager: { label: pName, value: this.props.constructionSite.projectManager.email }
                });
            } else {
                this.setState({
                    projectManager: users[0]
                });
            }
        } else {

            //get user
            let currentUser = await UserAction.getCurrentUser();

            //set user
            let user = users[0];

            //search
            /*users.forEach(element => {
                if (element.value === currentUser.email) {
                    user = element;
                }
            });*/

            //state
            this.setState({
                workManager: user,
                projectManager: user,
            });
        }

        //set state
        this.setState({
            countries: countries,
            users: users,
            currentCountry: currentCountry
        });
    }

    //change country
    countryChange = async (label, value = null) => {

        //get country
        let currentCountry = this.state.countries.find(obj => {
            return obj.value === value;
        });

        //set new sate
        this.setState({
            'currentCountry': currentCountry,
            'countryId': value,
            'countryUuid': Uuid()
        });
    }

    //check if everything is filled in
    isReady = () => {
        if (this.state.name && this.state.street && this.state.number && this.state.zip && this.state.city) {
            return true;
        }
        return false;
    }

    //render
    render = () => {
        if (this.state.countries && this.state.users) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{ marginTop: 0 }}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Edit construction site'} /></h2>
                    </div>
                    {/* Form */}
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Construction site name'} />}
                            value={this.state.name}
                            onChangeText={(text) => {
                                this.setState({ name: text });
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-industry'}
                            label={<CustomTranslation value={'KEY.Company name'} />}
                            value={this.state.addressCompany}
                            onChangeText={(text) => {
                                this.setState({ addressCompany: text });
                            }}
                        />
                    </div>
                    <div
                        className={'vertical-margin-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '-3px',
                            marginRight: '-3px'
                        }}
                    >
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                            iconName={'las la-home'}
                            label={<CustomTranslation value={'KEY.Street'} />}
                            value={this.state.street}
                            onChangeText={(text) => this.setState({ street: text })}
                        />
                        <CustomInput
                            className={'standard-margin-3'}
                            iconName={'las la-home'}
                            label={<CustomTranslation value={'KEY.Number'} />}
                            value={this.state.number}
                            onChangeText={(text) => this.setState({ number: text })}
                        />
                    </div>
                    <div
                        className={'vertical-margin-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '-3px',
                            marginRight: '-3px'
                        }}
                    >
                        <CustomInput
                            className={'standard-margin-3'}
                            iconName={'las la-city'}
                            label={<CustomTranslation value={'KEY.Zip'} />}
                            value={this.state.zip}
                            onChangeText={(text) => this.setState({ zip: text })}
                        />
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                            iconName={'las la-city'}
                            label={<CustomTranslation value={'KEY.City'} />}
                            value={this.state.city}
                            onChangeText={(text) => this.setState({ city: text })}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomSelect
                            iconName={'las la-globe'}
                            label={<CustomTranslation value={'KEY.Country'} />}
                            options={this.state.countries}
                            selected={{
                                label: this.state.currentCountry ? this.state.currentCountry.label : '',
                                value: this.state.currentCountry ? this.state.currentCountry.value : null
                            }}
                            onSelect={(value, label) => {
                                this.countryChange(label, value);
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomSelect
                            key={this.state.countryUuid + '_1'}
                            iconName={'las la-user'}
                            label={
                                this.state.currentCountry.code.toUpperCase() === 'NL' ?
                                    <CustomTranslation value={'KEY.Projectmanager'} /> :
                                    <CustomTranslation value={'KEY.Project manager'} />
                            }
                            options={this.state.users}
                            selected={this.state.projectManager.value === null ? { label: this.state.projectManager.label, value: 'EMPTY' } : this.state.projectManager}
                            onSelect={(value, label) => {
                                if (value === 'EMPTY') value = null;
                                this.setState({ projectManager: { label: label, value: value } });
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomSelect
                            key={this.state.countryUuid + '_2'}
                            iconName={'las la-user'}
                            label={
                                this.state.currentCountry.code.toUpperCase() === 'NL' ?
                                    <CustomTranslation value={'KEY.Hoofduitvoerder'} /> :
                                    <CustomTranslation value={'KEY.Work manager'} />
                            }
                            options={this.state.users}
                            selected={this.state.workManager.value === null ? { label: this.state.workManager.label, value: 'EMPTY' } : this.state.workManager}
                            onSelect={(value, label) => {
                                if (value === 'EMPTY') value = null;
                                this.setState({ workManager: { label: label, value: value } });
                            }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomMultiListInput
                            iconName={'las la-user'}
                            label={<CustomTranslation value={'KEY.Company Contact Persons'} />}
                            fields={{
                                name: <CustomTranslation value={'KEY.Name'} />,
                                email: <CustomTranslation value={'KEY.Email'} />,
                                phone: <CustomTranslation value={'KEY.Phone'} />,
                                function: <CustomTranslation value={'KEY.Function'} />
                            }}
                            values={this.state.contactPersons}
                            onChangeItems={(value) => { this.setState({ contactPersons: value }) }}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <Button
                            block
                            variant={'dark'}
                            disabled={!this.isReady()}
                            onClick={() => {
                                ConstructionSiteAction.edit(this.state);
                            }}
                        >
                            <CustomTranslation value={'KEY.Update construction site'} />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default EditConstructionSiteTab;