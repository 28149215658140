//imports
import React from 'react';
import CustomTranslation from "../../../Components/CustomTranslation";
import {Spinner} from "react-bootstrap";

//define class
class Risk extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {

        }
    }

    //render view
    render() {
        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <div
                    className={'bordered rounded touchable'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                    onClick={(event) => {
                        if (typeof this.props.onClick === 'function' && this.props.spinner !== true) {
                            this.props.onClick();
                        }
                    }}
                >
                    {/* title */}
                    <div
                        className={'standard-padding-2 vertical-padding-2'}
                        style={{flexGrow: 1, flexShrink: 1}}
                    >
                        <h5 style={{wordWrap: 'anywhere'}}><CustomTranslation value={this.props.risk.title}/></h5>
                    </div>
                    {/* spinner */}
                    {('spinner' in this.props) && (this.props.spinner === true) &&
                    <div
                        className={'standard-margin-2'}
                        style={{alignSelf: 'center'}}
                    >
                        <Spinner style={{height: '1rem', width: '1rem'}} animation={'border'} variant={'primary'}/>
                    </div>
                    }
                    {/* icon */}
                    {this.props.iconStyle !== 'none' &&
                    <div
                        style={{alignSelf: 'center'}}
                        className={'standard-margin-2 touchable'}
                        onClick={(event) => {
                            //trigger event
                            if (typeof this.props.onCollapse === 'function') {
                                if (this.props.iconStyle === 'normal') {
                                    event.stopPropagation();
                                    if (('spinner' in this.props) && (this.props.spinner === true)) {

                                    } else {
                                        this.props.onCollapse(!this.props.risk.collapsed);
                                    }
                                }
                            }
                        }}
                    >
                        <div
                            className={'bg-light'}
                            style={{
                                height: '1.5rem',
                                width: '1.5rem',
                                borderRadius: '50%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: '900'
                            }}
                        >
                            {this.props.iconStyle === 'edit' ?
                                <i
                                    className={'las la-pen'}
                                />
                                :
                                <i
                                    className={this.props.risk.collapsed ? 'las la-plus' : 'las la-minus'}
                                />
                            }
                        </div>
                    </div>
                    }
                    {/* checkbox */}
                    <div
                        style={{alignSelf: 'center'}}
                        className={'standard-margin-2'}
                    >

                        <span
                            className={'checkmark-light bordered rounded touchable ' + (this.props.included ? 'bg-light' : '')}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (typeof this.props.onCheckBoxClick === 'function' && this.props.spinner !== true) {
                                    this.props.onCheckBoxClick(!this.props.included);
                                }
                            }}
                        >
                            {this.props.included &&
                            <i
                                className={'las la-check'}
                                style={{
                                    fontWeight: '900'
                                }}
                            />
                            }
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default Risk;