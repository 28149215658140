import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import LMRAAction from "../../../Actions/LMRAAction";
import Uuid from "uuid/v1";
import Loader from "../../Components/Loader";
import LMRAPreference from "./Components/LMRAPreference";
import LMRANewItem from "./Components/LMRANewItem";

class LMRAPreferencesTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //last minute risk analysis
    toggleLMRA = async (template, lmra_id, value) => {
        let lastMinuteRiskIds = Object.assign([], this.lastMinuteRiskIds);

        // True: Add to array
        if(value === true){
            lastMinuteRiskIds.push(lmra_id);
        }
        // False: remove from array
        else{
            let index = lastMinuteRiskIds.indexOf(lmra_id);
            if (index >= 0) {
                lastMinuteRiskIds.splice( index, 1 );
            }
        }

        // Set Data
        let data = {
            last_minute_risk_ids: lastMinuteRiskIds,
        };

        //clear interval
        clearInterval(this.toggleLMRAInterval);
        //set interval
        this.toggleLMRAInterval = setInterval(() => {
            //call
            LMRAAction.updateTemplate(template.id, data);
            //clear when done
            clearInterval(this.toggleLMRAInterval);
        }, 1000);

        this.lastMinuteRiskIds = lastMinuteRiskIds;
    }

    //update elements
    updateElements = async () => {

        //get templates for company (currently only one /company)
        let risks = await LMRAAction.getLMRARisks();

        let templates = await LMRAAction.getTemplates(this.props.defaultCompany.id);
        let template = null;
        templates.forEach(element => {
            if(!template){
                template = element;
            }
        });

        //get risks from template
        if(template){
            //set array
            let lastMinuteRisks = template.lastMinuteRisks;
            let LMRAPreferenceItems = [];
            let LMRAParents = [];

            //construct lastMinuteRiskIds
            this.lastMinuteRiskIds = [];
            template.lastMinuteRisks.forEach((element, id) => {
                this.lastMinuteRiskIds.push(element.id);
            });


            //loop
            risks.forEach(element => {
                // Only use lowest level (no children)
                if(element.children.length === 0){
                    let parent = element.parent;

                    //check if array already exists
                    if (typeof LMRAPreferenceItems[parent.id] === 'undefined') {
                        LMRAPreferenceItems[parent.id] = [];
                        LMRAParents.push(parent);
                    }

                    let existing = null;
                    lastMinuteRisks.forEach(lastMinuteRisk => {
                        if(lastMinuteRisk.id === element.id){
                            existing = lastMinuteRisk;
                        }
                    });

                   LMRAPreferenceItems[parent.id].push(
                        <LMRAPreference
                            className={'vertical-margin-3'}
                            key={element.id + Uuid()}
                            title={<CustomTranslation value={element.title} />}
                            template={template}
                            lmraItem={element}
                            existing={existing}
                            toggleLMRA={this.toggleLMRA}
                        />
                    );
                }
            });

            //set array
            let LMRAObjects = [];

            //loop and create parent element
            LMRAParents.forEach((element, index) => {

                LMRAObjects.push(
                    <div key={element.id} className={'bordered rounded standard-padding-2 vertical-padding-2 vertical-margin-2'}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <p className={'label unbold'} style={{paddingBottom: 0, flexGrow: 1, flexShrink: 1, wordBreak: 'break-word'}}>
                                <CustomTranslation value={element.title} />
                            </p>
                            <div className={'standard-padding-2'} style={{paddingRight: 0}}>
                                <i className={'las la-thumbs-up text-success'} style={{fontSize: '1.4rem'}} />
                            </div>
                        </div>

                        {/* LIST */}
                        {LMRAPreferenceItems[element.id]}

                        {/* ADD NEW ITEM */}
                        <LMRANewItem
                            template={template}
                            option={element}
                        />
                    </div>
                );
            });

            //set state
            this.setState({
                LMRAObjects: LMRAObjects
            });
        }

    }

    //render
    render = () => {
        if (this.state.LMRAObjects) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation
                            value={'KEY.Last Minute Risk Analysis Template'}/></h2>
                    </div>
                    {/* Content */}
                    <div className={'vertical-margin-2'}>
                        {this.state.LMRAObjects}
                    </div>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default LMRAPreferencesTab;