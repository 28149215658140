import React from 'react';
import TranslationAction from '../../Actions/TranslationAction';

class CustomTranslation extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init
        TranslationAction.init();

        //state
        this.state = {
            translation: null
        }
    }

    componentDidMount = async () => {
        let translated = '';
        if (typeof this.props.value === 'string') {
            translated = await TranslationAction.translate(this.props.value);
        } else {
            translated = await TranslationAction.translateArray(this.props.value);
        }

        this.setState({translation: translated});
    }

    render = () => {
        return (
            this.state.translation
        );
    }
}

//export
export default CustomTranslation;