import React from "react";
import { generatePath, useParams } from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import Loader from "../Components/Loader";
import APIAction from "../../Actions/APIAction";
import TranslationAction from "../../Actions/TranslationAction";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import Project from "../Home/Components/Project";
import { Breadcrumb, Button, Modal, Image } from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import Uuid from "uuid/v1";
import FileButton from "../ConstructionSite/Components/FileButton";
import fileDownload from "js-file-download";
import { ReactSketchCanvas } from "react-sketch-canvas";
import ToolboxAction from "../../Actions/ToolboxAction";
import { toast } from "react-hot-toast";
import CustomConfirm from "../Components/CustomConfirm";
import UserAction from "../../Actions/UserAction";
import T from "../../Actions/TranslationAction";

class ToolboxDetailScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //canvas ref
        this.canvasRef = React.createRef();

        //set state
        this.state = {
            signModal: false,
            currentSignee: null,
            sigBtnDisabled: false,
            showDelete: false
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {
        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //get toolbox
        let toolbox = await APIAction.request('/toolboxes/' + this.props.params.toolbox_id, 'GET', true);

        //get user
        let user = await UserAction.getCurrentUser();

        //set state
        this.setState({
            data: {
                constructionSite: cs,
                toolbox: toolbox,
                user: user
            },
        });
    }

    //download toolbox list
    downloadToolbox = async () => {

        //default file name
        let now = new Date();
        let fileName = await TranslationAction.translate('KEY.Toolbox');
        fileName += ' ' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        fileName += '.pdf';


        //request
        APIAction.request('/toolbox/' + this.state.data.toolbox.id + '/document', 'GET', true, null, true, false, 'json', true)
            .then(result => {
                fileName = fileName.replace(/['"]+/g, '');
                fileDownload(result, fileName, 'application/pdf');
            });
    }

    //convert
    dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //upload signature to server
    uploadSignature = async (file) => {

        //create form data
        let formData = new FormData();
        formData.append('file', file, file.name);

        //send data
        let response = await APIAction.request('/files', 'POST', true, formData, true, true, 'formdata');

        await ToolboxAction.updateToolbox({
            id: this.state.data.toolbox.id,
            participants: this.state.data.toolbox.participants.map(participant => {
                if (participant.name === this.state.currentSignee.name) {
                    participant.signature = response.id;
                }
                return participant;
            })
        });

        //show toast
        toast.success(<CustomTranslation value={'KEY.Toolbox updated!'} />);

        //update state
        this.setState({
            signModal: false,
            currentSignee: null,
            sigBtnDisabled: false
        });
    }

    //send delete call
    deleteToolbox = async () => {

        //make call
        let result = await ToolboxAction.deleteToolbox(this.state.data.toolbox.id);

        //navigate
        window.location.href = generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id });
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer>
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{ position: 'sticky', top: '2rem', zIndex: 9 }}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>

                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id })}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Instruction - Toolbox'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            <div className={'vertical-margin'}>
                                {/* Title */}
                                <div style={{ marginBottom: '-1rem' }}>
                                    <h1>
                                        <CustomTranslation value={'KEY.Instruction - Toolbox'} />
                                    </h1>
                                </div>

                                {/* Subtitle */}
                                <div>
                                    <h4>
                                        {this.state.data.toolbox.assignments[0].title}
                                    </h4>
                                </div>
                            </div>

                            {/* Toolbox */}
                            <div className={'vertical-margin-2'}>
                                <div
                                    className={'rounded bg-light vertical-padding-2 standard-padding-2'}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <i style={{ fontSize: '1.2rem' }} className={'las la-file-alt icon'} />
                                        <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-2'}>
                                            <h4 style={{ marginBottom: 0 }} >
                                                <CustomTranslation value={'KEY.Documents'} />
                                            </h4>
                                        </div>
                                    </div>
                                    {/* Doc */}
                                    <div className={'vertical-margin-2'} style={{ marginBottom: 0 }}>
                                        <FileButton
                                            className={'bg-white'}
                                            topTitle={<CustomTranslation value={'KEY.Download'} />}
                                            title={<CustomTranslation value={'KEY.Participants list'} />}
                                            onClick={() => {
                                                this.downloadToolbox();
                                            }}
                                        />
                                    </div>
                                    {this.state.data.toolbox.document &&
                                        <div className={'vertical-margin-2'} style={{ marginBottom: 0 }}>
                                            <FileButton
                                                className={'bg-white'}
                                                topTitle={<CustomTranslation value={'KEY.Download'} />}
                                                title={<CustomTranslation value={'KEY.Document detail'} />}
                                                iconName={'las la-file'}
                                                onClick={async () => {
                                                    if (!this.state.toolboxDocWaiting) {
                                                        //set waiting
                                                        this.setState({ toolboxDocWaiting: true });

                                                        //get language
                                                        let language = await T.currentLanguage();

                                                        //create file name
                                                        let now = new Date();
                                                        let fileName = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '-' + this.state.data.toolbox.document.originalFileName;

                                                        //url
                                                        let url = this.state.data.toolbox.document.path;

                                                        //fetch
                                                        fetch(url)
                                                            .then(res => {
                                                                return res.blob();
                                                            })
                                                            .then(res => {
                                                                fileDownload(res, fileName, 'application/octet-stream');
                                                            });


                                                        //set waiting
                                                        this.setState({ toolboxDocWaiting: false });
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    {this.state.data.toolbox.assignments[0].altPhoto &&
                                        <div className={'vertical-margin-2'} style={{ marginBottom: 0 }}>
                                            <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.Instruction/Toolboxmoment'} /></p>
                                            <Image height={300} style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.state.data.toolbox.assignments[0].altPhoto.resizedPath} rounded />
                                        </div>
                                    }
                                </div>
                            </div>

                            {this.state.data.user.id === this.state.data.toolbox.assignments[0].targetUser.id &&
                                <>
                                    {/* Participants */}
                                    <div className={'vertical-margin-2'}>
                                        <div
                                            className={'rounded bg-light vertical-padding-2 standard-padding-2'}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <i style={{ fontSize: '1.2rem' }} className={'las la-users icon'} />
                                                <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-2'}>
                                                    <h4 style={{ marginBottom: 0 }} >
                                                        <CustomTranslation value={'KEY.Participants'} />
                                                    </h4>
                                                </div>
                                            </div>
                                            {/* Participants */}
                                            <div className={'vertical-margin-2'} style={{ marginBottom: 0 }}>
                                                {this.state.data.toolbox.participants.sort((a, b) => a.name > b.name).map((participant, index) => {
                                                    let color = 'primary';
                                                    if (participant.signature) {
                                                        color = 'success';
                                                    }

                                                    return (
                                                        <div className={'rounded bordered bg-white vertical-margin-3'}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <div className={'standard-padding vertical-padding-2'} style={{ flex: 1, flexGrow: 1, flexShrink: 1 }}>
                                                                    <p style={{ margin: 0 }}>{participant.name}</p>
                                                                </div>
                                                                <div className={'standard-padding-2 vertical-padding-2'} style={{ flex: 1, flexGrow: 1, flexShrink: 1 }}>
                                                                    <Button
                                                                        block
                                                                        variant={color}
                                                                        disabled={participant.signature}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                signModal: true,
                                                                                currentSignee: participant
                                                                            });
                                                                        }}
                                                                    >
                                                                        <i className={'las la-pen-alt'} />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Back to assignment */}
                                    {this.state.data.user.id === this.state.data.toolbox.assignments[0].targetUser.id &&
                                        <div
                                            className={'vertical-margin-2'}
                                        >
                                            <Button
                                                variant={'dark'}
                                                block
                                                onClick={() => {
                                                    window.location.href = generatePath('/construction_site/:construction_site_id/assignment/:assignment_id/:direction', {
                                                        construction_site_id: this.state.data.constructionSite.id,
                                                        assignment_id: this.state.data.toolbox.assignments[0].id,
                                                        direction: 'in'
                                                    });
                                                }}
                                            >
                                                <CustomTranslation value={'KEY.To assignment'} />
                                            </Button>
                                        </div>
                                    }

                                    {/* Archive */}
                                    <div
                                        className={'vertical-margin-2'}
                                    >
                                        <Button
                                            variant={'danger'}
                                            block
                                            onClick={() => {
                                                this.setState({ showDelete: true });
                                            }}
                                        >
                                            <CustomTranslation value={'KEY.Remove'} />
                                        </Button>
                                    </div>
                                </>
                            }

                            {/* Autograph modal */}
                            <Modal
                                show={this.state.signModal}
                            >
                                <Modal.Body>
                                    <div style={{ height: 500, width: '100%' }} className={'bg-light'}>
                                        <ReactSketchCanvas
                                            ref={this.canvasRef}
                                            style={{ border: 'none' }}
                                            canvasColor={'transparent'}
                                            strokeColor={'black'}
                                            strokeWidth={5}
                                        />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }} className={'vertical-margin-2'}>
                                        <div style={{ flex: 1, paddingRight: 5 }}>
                                            <Button
                                                block
                                                onClick={() => {
                                                    this.canvasRef.current?.resetCanvas();
                                                }}
                                            >
                                                <CustomTranslation value={'KEY.Clear'} />
                                            </Button>
                                        </div>
                                        <div style={{ flex: 1, paddingLeft: 5 }}>
                                            <Button
                                                block
                                                disabled={this.state.sigBtnDisabled}
                                                onClick={async () => {
                                                    this.setState({ sigBtnDisabled: true });
                                                    let fileName = Uuid() + '_autograph.png';
                                                    let fileData = await this.canvasRef.current?.exportImage('png');
                                                    let file = this.dataURLtoFile(fileData, fileName);
                                                    this.uploadSignature(file);
                                                }}
                                            >
                                                <CustomTranslation value={'KEY.Save'} />
                                            </Button>
                                        </div>
                                    </div>
                                    <Button
                                        block
                                        variant={'dark'}
                                        onClick={() => {
                                            this.setState({
                                                signModal: false,
                                                currentSignee: null
                                            });
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Close'} />
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Delete modal */}
                            <CustomConfirm
                                show={this.state.showDelete}
                                variant={'danger'}
                                title={<CustomTranslation value={'KEY.Remove'} />}
                                body={
                                    <p style={{ wordBreak: 'break-word' }}>
                                        <CustomTranslation value={'KEY.Do you want to delete the toolbox with name:'} /><br />
                                        "{this.state.data.toolbox.assignments[0].title}"
                                    </p>
                                }
                                cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                                onCancel={() => {
                                    this.setState({ showDelete: false });
                                }}
                                confirmLabel={<CustomTranslation value={'KEY.Remove'} />}
                                onConfirm={() => {
                                    this.deleteToolbox();
                                }}
                            />
                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <ToolboxDetailScreen {...props} params={params} />;
}