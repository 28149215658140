import React from "react";
import {Form} from "react-bootstrap";
import Select from "react-select";
import Uuid from "uuid/v1";
import CustomTranslation from "./CustomTranslation";

//custom styles
const customStyles = {
    control: (base, state) => ({
        ...base,
        '&:hover': {borderColor: state.isFocused ? '#fedb97' : 'rgba(98,113,127,0.3)'},
        borderColor: state.isFocused ? '#fedb97' : 'rgba(98,113,127,0.3)',
        fontSize: '0.85rem',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(253,176,25, 0.25)' : 'none'
    }),
    option: (base, state) => ({
        ...base,
        '&:hover': {backgroundColor: '#fdb019', color: '#fff'},
        backgroundColor: state.isFocused ? '#fedb97': '#fff',
        fontSize: '0.85rem',
        padding: '0.375rem 0.75rem'
    }),
    multiValue: (base, state) => ({
        ...base,
        backgroundColor: '#f4f4f4'
    }),
    multiValueRemove: (base, state) => ({
        ...base,
        '&:hover': {
            backgroundColor: '#d9534f',
            color: '#fff'
        }
    })
}

class CustomMultiSelect extends React.Component {

    //constructor
    constructor(props) {
        super(props);
    }

    render = () => {

        //set formId
        let formId = 'form_' + Uuid();

        //set values
        let values = [];
        if (
            (typeof this.props.selectedItems === 'object') &&
            (typeof this.props.items === 'object')
        ) {
            for (let selected of this.props.selectedItems) {
                for (let item of this.props.items) {
                    if (selected === item.value) {
                        values.push(item);
                    }
                }
            }
        }

        return (
            <div className={this.props.className} style={this.props.style}>
                <Form.Group controlId={formId} style={{margin: 0}}>
                    {this.props.noLabel ? null :
                        <Form.Label>
                            <div style={{
                                display: 'flex',
                                flex: 'row'
                            }}>
                                {this.props.iconName &&
                                <div
                                    className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                                >
                                    <i className={this.props.iconName + ' icon'}/>
                                </div>
                                }
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                   <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')}
                                         style={{paddingBottom: 0}}>{this.props.label}</span>
                                </div>
                            </div>
                        </Form.Label>
                    }
                    <Select
                        name={formId}
                        styles={customStyles}
                        isMulti
                        options={this.props.items}
                        defaultValue={values}
                        placeholder={<CustomTranslation value={'KEY.Select'} />}
                        noOptionsMessage={() => {return <CustomTranslation value={'KEY.No options'} />;}}
                        onChange={(value) => {
                            let result = [];
                            for (let item of value) {
                                result.push(item.value);
                            }
                            if (typeof this.props.onSelectedItemsChange === 'function') {
                                this.props.onSelectedItemsChange(result);
                            }
                        }}
                    />
                </Form.Group>
            </div>
        );
    }
}

//export
export default CustomMultiSelect;