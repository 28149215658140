import React from 'react';
import CustomTranslation from "./CustomTranslation";
import { Image } from "react-bootstrap";
import bm1 from '../../Images/bm-1.png';
import bm4 from '../../Images/bm-4.png';
import bm2 from '../../Images/bm-2.png';
import bm3 from '../../Images/bm-3.png';
import bm5 from '../../Images/bm-5.png';
import bm6 from '../../Images/bm-6.png';

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { generatePath } from "react-router-dom";
import LicenseAction from "../../Actions/LicenseAction";
import Uuid from "uuid/v1";

class ConstructionSiteWrapper extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            uuid: Uuid()
        };
    }

    //on mount
    componentDidMount() {
        this.updateElements();
    }

    //update the elements
    updateElements = async () => {

        //get licenses
        let licenses = await LicenseAction.getLicensesByUserAndCompany(this.props.constructionSite.company.id);

        //set in state
        this.setState({
            licenses: licenses,
            uuid: Uuid()
        });
    }

    render = () => {
        return (
            <div>
                {this.props.children}
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <div key={this.state.uuid} style={{ zIndex: 11 }}>
                        <div style={{ position: 'fixed', bottom: '2rem' }}>
                            <div style={{ position: 'relative', marginLeft: -66 }}>
                                <Fab
                                    style={{ right: 0, position: 'relative' }}
                                    icon={<i className={'las la-ellipsis-h'} />}
                                    alwaysShowTitle={true}
                                    mainButtonStyles={{ backgroundColor: '#fdb019' }}
                                >
                                    {(this.state.licenses && this.state.licenses.length > 0) &&
                                        <Action
                                            style={{ backgroundColor: '#fff' }}
                                            text={<CustomTranslation value={'KEY.Instruction - Toolbox'} />}
                                            onClick={() => {
                                                window.location.href = generatePath('/construction_site/:construction_site_id/toolbox', { construction_site_id: this.props.constructionSite.id });
                                            }}
                                        >
                                            <Image src={bm6} height={35} />
                                        </Action>
                                    }
                                    {(this.state.licenses && this.state.licenses.length > 0) &&
                                        <Action
                                            style={{ backgroundColor: '#fff' }}
                                            text={<CustomTranslation value={'KEY.Inspection Checklist'} />}
                                            onClick={() => {
                                                window.location.href = generatePath('/construction_site/:construction_site_id/inspection_checklist', { construction_site_id: this.props.constructionSite.id });
                                            }}
                                        >
                                            <Image src={bm5} height={35} />
                                        </Action>
                                    }
                                    <Action
                                        style={{ backgroundColor: '#fff' }}
                                        text={<CustomTranslation value={'KEY.Last Minute Risk Analysis'} />}
                                        onClick={() => {
                                            window.location.href = generatePath('/construction_site/:construction_site_id/last_minute_risk_analysis', { construction_site_id: this.props.constructionSite.id });
                                        }}
                                    >
                                        <Image src={bm3} height={35} />
                                    </Action>
                                    <Action
                                        style={{ backgroundColor: '#fff' }}
                                        text={<CustomTranslation value={'KEY.Positive Remark'} />}
                                        onClick={() => {
                                            window.location.href = generatePath('/construction_site/:construction_site_id/positive_remark', { construction_site_id: this.props.constructionSite.id });
                                        }}
                                    >
                                        <Image src={bm2} height={35} />
                                    </Action>
                                    <Action
                                        style={{ backgroundColor: '#fff' }}
                                        text={<CustomTranslation value={'KEY.Observation'} />}
                                        onClick={() => {
                                            window.location.href = generatePath('/construction_site/:construction_site_id/observation', { construction_site_id: this.props.constructionSite.id });
                                        }}
                                    >
                                        <Image src={bm4} height={35} />
                                    </Action>
                                    <Action
                                        style={{ backgroundColor: '#fff' }}
                                        text={<CustomTranslation value={'KEY.SOS'} />}
                                        onClick={() => {
                                            window.location.href = generatePath('/construction_site/:construction_site_id/sos', { construction_site_id: this.props.constructionSite.id });
                                        }}
                                    >
                                        <Image src={bm1} height={35} />
                                    </Action>
                                </Fab>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default ConstructionSiteWrapper;