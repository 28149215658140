import "./Css/App.scss";
import "./Css/Animations.scss";
import "./Css/Reporting.scss";

import React, { useRef, useState } from "react";
import TokenRepository from "./Database/Repositories/TokenRepository";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { messaging, onMessage } from "./Firebase/FirebaseMessaging";
import { Button, Modal } from "react-bootstrap";
import CustomTranslation from "./Views/Components/CustomTranslation";
import TranslationAction from "./Actions/TranslationAction";
import { Toaster } from "react-hot-toast";
import AnalyticsLog from "./Firebase/AnalyticsLog";

import LoginScreen from "./Views/User/LoginScreen";
import HomeScreen from "./Views/Home/HomeScreen";
import ConstructionSiteScreen from "./Views/ConstructionSite/ConstructionSiteScreen";
import CreateRiskAnalysisScreen from "./Views/RiskAnalysis/CreateRiskAnalysisScreen";
import RiskAnalysisScreen from "./Views/RiskAnalysis/RiskAnalysisScreen";
import EmailScreen from "./Views/Email/EmailScreen";
import RiskListScreen from "./Views/RiskAnalysis/RiskListScreen";
import RiskScreen from "./Views/RiskAnalysis/RiskScreen";
import AssignmentScreen from "./Views/Assignment/AssignmentScreen";
import VGPlanScreen from "./Views/VGPlan/VGPlanScreen";
import SOSScreen from "./Views/SOS/SOSScreen";
import ObservationScreen from "./Views/Observation/ObservationScreen";
import ToolboxScreen from "./Views/Toolbox/ToolboxScreen";
import ToolboxDetailScreen from "./Views/Toolbox/ToolboxDetailScreen";
import PositiveRemarkScreen from "./Views/PositiveRemark/PositiveRemarkScreen";
import LMRAScreen from "./Views/LMRA/LMRAScreen";
import LMRAItemScreen from "./Views/LMRA/LMRAItemScreen";
import ICLScreen from "./Views/ICL/ICLScreen";
import ICLItemScreen from "./Views/ICL/ICLItemScreen";
import CreateConstructionSiteScreen from "./Views/ConstructionSite/CreateConstructionSiteScreen";
import AssignmentItemScreen from "./Views/Assignment/AssignmentItemScreen";
import EditAssignmentItemScreen from "./Views/Assignment/EditAssignmentItemScreen";
import PreferencesScreen from "./Views/Preferences/PreferencesScreen";
import EditConstructionSiteScreen from "./Views/ConstructionSite/Edit/EditConstructionSiteScreen";
import OverviewScreen from "./Views/Reporting/OverviewScreen";
import ActionsScreen from "./Views/Reporting/ActionsScreen";
import EmailAccessibilityScreen from "./Views/Email/EmailAccessibilityScreen";
import { generatePath } from "react-router-dom";

function App() {

    //variables
    const [ready, setReady] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [messageAction, setMessageAction] = useState(null);
    const notificationListener = useRef(null);

    //preflight function
    let preFlightChecks = async () => {
        //import translations if needed
        TranslationAction.init();
        await TranslationAction.update();

        //set ready
        setReady(true);
    }

    //check if not ready
    if (!ready) preFlightChecks();

    //show notification
    let showNotification = (payload) => {
        if (payload.notification &&
            (payload.notification.title || payload.notification.body)
        ) {

            //check if action
            let actionSet = false;
            if (payload.data?.action) {
                payload.data.action = JSON.parse(payload.data.action);

                if (payload.data?.action?.constructionSiteId && payload.data?.action?.assignmentId) {
                    setMessageAction(generatePath('/construction_site/:construction_site_id/assignment/:assignment_id/:direction', {
                        construction_site_id: payload.data.action.constructionSiteId,
                        assignment_id: payload.data.action.assignmentId,
                        direction: 'in'
                    }));
                    actionSet = true;
                }
            }

            //if no action, don't show
            if (actionSet === false) setMessageAction(null);

            setMessageTitle(payload.notification.title);
            setMessageBody(payload.notification.body);
            setShowMessage(true);
        }
    };

    //listen for messages if possible
    if (messaging) {
        if (notificationListener.current === null) {
            //create channels
            const notificationChannel = new BroadcastChannel('notification');
            const loadedChannel = new BroadcastChannel('loaded');

            //show page as loaded
            loadedChannel.postMessage('loaded');

            notificationListener.current = notificationChannel.onmessage = (payload) => {
                showNotification(payload.data);
            };
        }

        //if message is received show on screen
        onMessage(messaging, (payload) => {
            showNotification(payload);
        });
    }

    //if ready render
    if (ready) {
        return (
            <div className="App">
                {/* Show toasts */}
                <Toaster
                    toastOptions={{
                        position: 'top-center',
                        style: {
                            fontFamily: 'Montserrat'
                        }
                    }}
                />
                {/* Route to correct page */}
                <Router>
                    <Switch>
                        {/* User */}
                        <Route exact path={'/login'}>
                            <AnalyticsLog screen={'Login'} />
                            <LoginScreen />
                        </Route>
                        {/* Home */}
                        <PrivateRoute exact path={'/'}>
                            <AnalyticsLog screen={'Home'} />
                            <HomeScreen />
                        </PrivateRoute>
                        {/* Construction site */}
                        <PrivateRoute exact path={'/create_construction_site'}>
                            <AnalyticsLog screen={'CreateConstructionSite'} />
                            <CreateConstructionSiteScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:id'}>
                            <AnalyticsLog screen={'ConstructionSite'} />
                            <ConstructionSiteScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/create_risk_analysis'}>
                            <AnalyticsLog screen={'CreateRiskAnalysis'} />
                            <CreateRiskAnalysisScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:id/edit'}>
                            <AnalyticsLog screen={'MenuEditConstructionSite'} />
                            <EditConstructionSiteScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:id/email'}>
                            <AnalyticsLog screen={'EmailAccessibility'} />
                            <EmailAccessibilityScreen />
                        </PrivateRoute>
                        {/* SOS */}
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/sos'}>
                            <AnalyticsLog screen={'SOS'} />
                            <SOSScreen />
                        </PrivateRoute>
                        {/* Observation */}
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/observation'}>
                            <AnalyticsLog screen={'Observation'} />
                            <ObservationScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/positive_remark'}>
                            <AnalyticsLog screen={'PositiveRemark'} />
                            <PositiveRemarkScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/toolbox'}>
                            <AnalyticsLog screen={'Toolbox'} />
                            <ToolboxScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/toolbox/:toolbox_id/:return_assignment_id?'}>
                            <AnalyticsLog screen={'ToolboxDetail'} />
                            <ToolboxDetailScreen />
                        </PrivateRoute>
                        {/* LMRA */}
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/last_minute_risk_analysis/:lmra_id?'}>
                            <AnalyticsLog screen={'LMRA'} />
                            <LMRAScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/last_minute_risk_analysis/:lmra_id/item/:item_id'}>
                            <AnalyticsLog screen={'LMRAItem'} />
                            <LMRAItemScreen />
                        </PrivateRoute>
                        {/* ICL */}
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/inspection_checklist/:icl_id?'}>
                            <AnalyticsLog screen={'ICL'} />
                            <ICLScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/inspection_checklist/:icl_id/item/:item_id/:positive'}>
                            <AnalyticsLog screen={'ICLItem'} />
                            <ICLItemScreen />
                        </PrivateRoute>
                        {/* Risk Analysis */}
                        <PrivateRoute exact path={'/risk_analysis/:id'}>
                            <AnalyticsLog screen={'RiskAnalysis'} />
                            <RiskAnalysisScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/risk_analysis/:id/email/:limited'}>
                            <AnalyticsLog screen={'Email'} />
                            <EmailScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/risk_analysis/:id/edit'}>
                            <AnalyticsLog screen={'RiskList'} />
                            <RiskListScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/risk_analysis/:risk_analysis_id/risk/:risk_id'}>
                            <AnalyticsLog screen={'Risk'} />
                            <RiskScreen />
                        </PrivateRoute>
                        {/* VGPlan */}
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/vg_plan/:vg_plan_id'}>
                            <AnalyticsLog screen={'VGPlan'} />
                            <VGPlanScreen />
                        </PrivateRoute>
                        {/* Assignments */}
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/assignments'}>
                            <AnalyticsLog screen={'Assignment'} />
                            <AssignmentScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/assignment/:assignment_id/:direction'}>
                            <AnalyticsLog screen={'AssignmentItem'} />
                            <AssignmentItemScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/construction_site/:construction_site_id/edit_assignment/:assignment_id'}>
                            <AnalyticsLog screen={'EditAssignmentItem'} />
                            <EditAssignmentItemScreen />
                        </PrivateRoute>
                        {/* Preferences */}
                        <PrivateRoute exact path={'/preferences'}>
                            <AnalyticsLog screen={'Preferences'} />
                            <PreferencesScreen />
                        </PrivateRoute>
                        {/* Reporting */}
                        <PrivateRoute exact path={'/reporting'}>
                            <AnalyticsLog screen={'Reporting - Tools'} />
                            <OverviewScreen />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/reporting/actions'}>
                            <AnalyticsLog screen={'Reporting - Actions'} />
                            <ActionsScreen />
                        </PrivateRoute>
                    </Switch>
                </Router>
                {/* Show notification modal */}
                <Modal
                    show={showMessage}
                    onHide={null}
                    backdrop={'static'}
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>{messageTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {messageBody}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div
                            style={{
                                flexGrow: 1,
                                flexShrink: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                margin: '-3px'
                            }}
                        >
                            {messageAction &&
                                <div
                                    className={'standard-padding-3 vertical-padding-3'}
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <Button
                                        block
                                        variant={'primary'}
                                        onClick={() => {
                                            window.location.href = messageAction;
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Open'} />
                                    </Button>
                                </div>
                            }
                            <div
                                className={'standard-padding-3 vertical-padding-3'}
                                style={{
                                    flex: 1
                                }}
                            >
                                <Button
                                    block
                                    variant={'dark'}
                                    onClick={() => {
                                        setShowMessage(false);
                                    }}
                                >
                                    <CustomTranslation value={'KEY.Close'} />
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    } else {
        return null;
    }
}

function PrivateRoute({ children, ...rest }) {
    const [token, setToken] = useState(null);
    const [done, setDone] = useState(false);
    const [start, setStart] = useState(true);

    let tokenRepository = new TokenRepository();

    let checkAuth = async () => {
        setStart(false);
        let tokenResponse = await tokenRepository.get();
        setToken(tokenResponse);
        setDone(true);
    };

    if (start) checkAuth();

    return (
        <Route
            {...rest}
        >
            {done ?
                token ?
                    children
                    :
                    <Redirect to={{ pathname: '/login' }} />
                :
                null
            }
        </Route>
    );
}

//export
export default App;
