import React from "react";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import {generatePath, useParams} from "react-router-dom";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import Project from "../Home/Components/Project";
import {Breadcrumb, Button, Spinner} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import LMRAAction from "../../Actions/LMRAAction";
import G from "../../Actions/GeneralAction";
import T from "../../Actions/TranslationAction";
import LMRAItem from "./Components/LMRAItem";
import CustomImageInput from "../Components/CustomImageInput";
import CustomInput from "../Components/CustomInput";
import UserAction from "../../Actions/UserAction";
import CustomDate from "../Components/CustomDate";
import CustomCheckBox from "../Components/CustomCheckBox";
import CustomSelect from "../Components/CustomSelect";
import AssignmentAction from "../../Actions/AssignmentAction";

class LMRAItemScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            processing: false,
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {

        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //get last minute risk analysis
        let lmra = await LMRAAction.getLMRA(this.props.params.lmra_id);

        //get last minute risk analysis item
        let lmraItem = await LMRAAction.getItem(this.props.params.item_id);

        //get users for construction site
        let users = await UserAction.getUserListConstructionSite(cs.id);

        //define user options
        let userOptions = [];

        //loop
        users.forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            userOptions.push({
                label: label,
                value: element['@id']
            });
        });

        //data for assignment
        let currentAssignment = JSON.parse(JSON.stringify(lmraItem.assignments[0]));

        if (currentAssignment.targetUser === null) {
            if (lmraItem.lastMinuteRisk) {
                currentAssignment.title = await T.translateArray(lmraItem.lastMinuteRisk.title);
                currentAssignment.recommendation = await T.translateArray(lmraItem.lastMinuteRisk.recommendations);
            } else {
                currentAssignment.title = lmraItem.lastMinuteRiskInsert.title;
                currentAssignment.recommendation = '';
            }

            let targetUser = userOptions[0].value;
            let loggedInUser = await UserAction.getCurrentUser();
            if (loggedInUser !== null) {
                userOptions.forEach(element => {
                    if (element.value.includes(loggedInUser.id)) {
                        targetUser = element.value;
                    }
                });
            }

            currentAssignment.targetUser = {
                id: targetUser
            };
            currentAssignment.dateRequested = new Date();
        } else {
            currentAssignment.dateRequested = new Date(currentAssignment.dateRequested);
            currentAssignment.targetUser = {
                id: currentAssignment.targetUser['@id']
            };
        }

        //check if photo is available
        if (currentAssignment.photo) {
            currentAssignment.imageId = currentAssignment.photo.id;
            currentAssignment.imageApiId = currentAssignment.photo['@id'];
        }

        if (currentAssignment.altPhoto) {
            currentAssignment.image2Id = currentAssignment.altPhoto.id;
            currentAssignment.image2ApiId = currentAssignment.altPhoto['@id'];
        }

        //data for user
        let user = userOptions[0];
        if (currentAssignment.targetUser !== null) {
            //try to find in options
            userOptions.forEach((value) => {
                if (value.value === currentAssignment.targetUser.id) {
                    user = value;
                }
            });
        }

        //set state
        this.setState({
            data: {
                constructionSite: cs,
                lastMinuteRiskAnalysis: lmra,
                lastMinuteRiskAnalysisItem: lmraItem
            },
            userOptions: userOptions,
            user: user,
            currentAssignment: currentAssignment
        });
    }

    //data from image upload
    handleUpload = async (file, name) => {
        let newState = {};
        newState[`${name}Id`] = file.id;
        newState[`${name}ApiId`] = file['@id'];
        this.setStateAssignment(newState);
    }

    //set state for current assignment
    setStateAssignment = (object) => {
        let assignment = this.state.currentAssignment;
        assignment = {...assignment, ...object};
        this.setState({'currentAssignment': assignment});
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            disabled={this.state.processing}
                            variant={'dark'}
                            onClick={() => {
                                this.setState({processing: true}, () => {
                                    AssignmentAction.edit(this.state.currentAssignment).then((result) => {
                                        if (result) {
                                            setTimeout(() => {
                                                window.location.href = generatePath('/construction_site/:construction_site_id/last_minute_risk_analysis/:lmra_id', {construction_site_id: this.state.data.constructionSite.id, lmra_id: this.state.data.lastMinuteRiskAnalysis.id});
                                            }, 1000);
                                        }
                                     }).then(() => {
                                        setTimeout(() => {
                                            this.setState({processing: false});
                                        }, 1000);
                                     });
                                });  
                            }}
                        >
                            <CustomTranslation value={'KEY.Add LMRA'} />
                            {this.state.processing &&
                                <Spinner size={'sm'} animation={'border'} variant={'white'} style={{margin: 2}} />
                            }
                        </Button>
                    }
                >
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{position: 'sticky', top: '2rem', zIndex: 9}}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>

                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:construction_site_id/last_minute_risk_analysis/:lmra_id', {construction_site_id: this.state.data.constructionSite.id, lmra_id: this.state.data.lastMinuteRiskAnalysis.id})}
                                >
                                    <CustomTranslation value={'KEY.Last Minute Risk Analysis'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Item'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div className={'vertical-margin'}>
                                <h1>
                                    <CustomTranslation value={'KEY.Last Minute Risk Analysis'}/>
                                </h1>
                            </div>

                            {/* User */}
                            <div className={'vertical-margin-2'}>
                                <div
                                    className={'bordered rounded vertical-padding-2 standard-padding-2'}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p className={'label unbold'}>
                                            <CustomTranslation value={'KEY.By'} />
                                        </p>
                                        <p className={'label unbold'}>
                                            {G.formatDate(this.state.data.lastMinuteRiskAnalysis.createdAt)}
                                        </p>
                                    </div>
                                    <h3>
                                        {
                                            (this.state.data.lastMinuteRiskAnalysis.createdBy.firstName || this.state.data.lastMinuteRiskAnalysis.createdBy.lastName)
                                                ?
                                                (this.state.data.lastMinuteRiskAnalysis.createdBy.firstName || '') + ' ' + (this.state.data.lastMinuteRiskAnalysis.createdBy.lastName || '')
                                                :
                                                this.state.data.lastMinuteRiskAnalysis.createdBy.email
                                        }
                                    </h3>
                                </div>
                            </div>

                            {/* Lmra item */}
                            <div className={'vertical-margin-2'}>
                                <div
                                    className={'bordered rounded vertical-padding-2 standard-padding-2'}
                                >
                                    {/* Header */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <p className={'label unbold'} style={{paddingBottom: 0, flexGrow: 1, flexShrink: 1, wordBreak: 'break-word'}}>
                                            {
                                                this.state.data.lastMinuteRiskAnalysisItem.lastMinuteRisk ?
                                                    <CustomTranslation value={this.state.data.lastMinuteRiskAnalysisItem.lastMinuteRisk.parent.title} />
                                                    :
                                                    <CustomTranslation value={this.state.data.lastMinuteRiskAnalysisItem.lastMinuteRiskInsert.lastMinuteRisk.title} />
                                            }
                                        </p>
                                        <div className={'standard-padding-2'}>
                                            <i className={'las la-thumbs-up text-success'} style={{fontSize: '1.4rem'}} />
                                        </div>
                                        <div>
                                            <i className={'las la-thumbs-down text-primary'} style={{fontSize: '1.4rem'}} />
                                        </div>
                                    </div>
                                    {/* Item */}
                                    <LMRAItem
                                        className={'vertical-margin-3'}
                                        title={
                                            this.state.data.lastMinuteRiskAnalysisItem.lastMinuteRisk ?
                                                <CustomTranslation value={this.state.data.lastMinuteRiskAnalysisItem.lastMinuteRisk.title} />
                                                :
                                                this.state.data.lastMinuteRiskAnalysisItem.lastMinuteRiskInsert.title
                                        }
                                        value={this.state.data.lastMinuteRiskAnalysisItem.status}
                                        onValueChange={(value) => {
                                            let element = this.state.data.lastMinuteRiskAnalysisItem;
                                            element.status = value;
                                            LMRAAction.updateItem(element).then((result) => {
                                                //if true
                                               if (value === true || value === null) {
                                                   window.location.href = generatePath('/construction_site/:construction_site_id/last_minute_risk_analysis/:lmra_id', {construction_site_id: this.state.data.constructionSite.id, lmra_id: this.state.data.lastMinuteRiskAnalysis.id});
                                               }
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Assignment form */}
                            <div className={'vertical-margin-2'}>
                                <h4><CustomTranslation value={'KEY.LMRA assignment'} /></h4>
                                <div
                                    className={'bordered rounded vertical-padding-2 standard-padding-2'}
                                >
                                    <div>
                                        <CustomImageInput
                                            label={<CustomTranslation value={'KEY.Image overview'} />}
                                            onUpload={(val) => this.handleUpload(val, 'image')}
                                            imageId={this.state.currentAssignment.imageId ? this.state.currentAssignment.imageId : null}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomImageInput
                                            label={<CustomTranslation value={'KEY.Image detail'} />}
                                            onUpload={(val) => this.handleUpload(val, 'image2')}
                                            imageId={this.state.currentAssignment.image2Id ? this.state.currentAssignment.image2Id : null}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Title'} />}
                                            value={this.state.currentAssignment.title}
                                            onChangeText={(value) => {
                                                this.setStateAssignment({title: value});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Description'} />}
                                            value={this.state.currentAssignment.riskDescription}
                                            onChangeText={(value) => {
                                                this.setStateAssignment({riskDescription: value});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Actions taken (by me)'} />}
                                            value={this.state.currentAssignment.immediateActionTaken}
                                            inputProps={{
                                                as: 'textarea'
                                            }}
                                            onChangeText={(value) => {
                                                this.setStateAssignment({immediateActionTaken: value});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomInput
                                            label={<CustomTranslation value={'KEY.Recommendation'} />}
                                            value={this.state.currentAssignment.recommendation}
                                            inputProps={{
                                                as: 'textarea'
                                            }}
                                            onChangeText={(value) => {
                                                this.setStateAssignment({recommendation: value});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomDate
                                            label={<CustomTranslation value={'KEY.Solve by'} />}
                                            value={this.state.currentAssignment.dateRequested}
                                            onChange={(value) => {
                                                this.setStateAssignment({dateRequested: value});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomCheckBox
                                            label={<CustomTranslation value={'KEY.Finished'} />}
                                            value={this.state.currentAssignment.finished}
                                            onValueChange={(value) => {
                                                this.setStateAssignment({finished: value});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomSelect
                                            label={<CustomTranslation value={'KEY.Assign to'} />}
                                            options={this.state.userOptions}
                                            selected={this.state.user}
                                            onSelect={(value, label) => {
                                                this.setStateAssignment({targetUser: {id: value}});
                                                this.setState({user: {value: value, label: label}});
                                            }}
                                        />
                                    </div>
                                    <div className={'vertical-margin-3'}>
                                        <CustomCheckBox
                                            label={<CustomTranslation value={'KEY.Save as concept'} />}
                                            value={this.state.currentAssignment.concept}
                                            onValueChange={(value) => {
                                                this.setStateAssignment({concept: value});
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <LMRAItemScreen {...props} params={params} />;
}