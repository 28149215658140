import React from 'react';
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import {Breadcrumb} from "react-bootstrap";
import {generatePath, useParams} from "react-router-dom";
import CustomTranslation from "../Components/CustomTranslation";
import Project from "../Home/Components/Project";
import Uuid from "uuid/v1";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import {Collapse} from "react-collapse";
import AssignmentAction from "../../Actions/AssignmentAction";
import Assignment from "./Components/Assignment";
import UserAction from "../../Actions/UserAction";

class AssignmentScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            assignments: [],
            toMeCollapsed: true,
            byMeCollapsed: true,
            conceptCollapsed: true,
            allCollapsed: true,
            toMeUuid: Uuid(),
            byMeUuid: Uuid(),
            allUuid: Uuid()
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {
        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //requests parallel
        let requests = [];
        //get assignments for user
        requests.push(AssignmentAction.getForTarget(cs.id));
        //get assignments of user
        requests.push(AssignmentAction.getForSender(cs.id));
        //get assignment concepts
        requests.push(AssignmentAction.getConcept(cs.id));

        //check if user has rights
        let constructionSiteRight = await UserAction.getUserRightByConstructionSiteId(this.props.params.construction_site_id);
        let companyRight = await UserAction.getUserRightByCompanyId(cs.company.id);
        if (!constructionSiteRight && companyRight?.adminRights) {
            constructionSiteRight = {
                managementRights: true
            };
        }

        //if user is manager, more rights apply
        if (constructionSiteRight.managementRights) {
            //extra request
            requests.push(AssignmentAction.getForAll(cs.id));
        }

        //wait for results
        let results = await Promise.all(requests);

        /* ASSIGNMENTS */
        let assignmentsIn = results[0] || [];
        let assignmentsOut = results[1] || [];
        let assignmentConcepts = results[2] || [];
        let assignmentAll = results[3] || [];

        //create assignment elements
        let assignmentInElements = [];
        let assignmentOutElements = [];
        let assignmentConceptElements = [];
        let assignmentAllElements = [];

        let assignmentsInUnfinished = 0;
        let assignmentsOutUnfinished = 0;

        //loop and add
        assignmentsIn.forEach((element) => {
            assignmentInElements.push(
                <Assignment
                    className={'vertical-margin-3'}
                    key={Uuid()}
                    data={element}
                    constructionSite={cs}
                />
            );

            if(!element.dateFinished){
                assignmentsInUnfinished++;
            }
        });

        assignmentsOut.forEach((element) => {
            assignmentOutElements.push(
                <Assignment
                    className={'vertical-margin-3'}
                    key={Uuid()}
                    data={element}
                    constructionSite={cs}
                    type={'out'}
                />
            );

            if(!element.dateFinished){
                assignmentsOutUnfinished++;
            }
        });

        assignmentConcepts.forEach((element) => {
            assignmentConceptElements.push(
                <Assignment
                    className={'vertical-margin-3'}
                    key={Uuid()}
                    data={element}
                    constructionSite={cs}
                    type={'out'} //display recipient
                    edit={true}
                />
            );
        });
        
        assignmentAll.forEach((element) => {
            assignmentAllElements.push(
                <Assignment
                    className={'vertical-margin-3'}
                    key={Uuid()}
                    data={element}
                    constructionSite={cs}
                    type={'out'} //display recipient
                />
            );
        });

        //set state
        this.setState({
            data: {
                constructionSite: cs,
            },
            assignmentsIn: assignmentInElements,
            assignmentsOut: assignmentOutElements,
            assignmentsInUnfinished: assignmentsInUnfinished,
            assignmentsOutUnfinished: assignmentsOutUnfinished,
            assignmentsConcept: assignmentConceptElements,
            assignmentsAll: assignmentAllElements,
            toMeUuid: Uuid(),
            byMeUuid: Uuid(),
            conceptUuid: Uuid(),
            allUuid: Uuid()
        });
    }

    render = () => {
        if (this.state && this.state.data) {
            return (
                <CustomContainer>
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{position: 'sticky', top: '2rem', zIndex: 9}}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>
                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Assignment actions'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div>
                                <h1>
                                    <CustomTranslation value={'KEY.Assignment actions'}/>
                                </h1>
                            </div>

                            {/* Concepts */}
                            {this.state.assignmentsConcept.length > 0 ?
                                <div
                                    className={'rounded bg-light vertical-margin-2'}
                                >
                                    <div
                                        className={'touchable vertical-padding-2 standard-padding-2'}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => {
                                            this.setState({conceptCollapsed: !this.state.conceptCollapsed});
                                        }}
                                    >
                                        <i style={{fontSize: '1.25rem'}} className={'las la-pen-square icon'}/>
                                        <div style={{flexGrow: 1, flexShrink: 1}} className={'standard-padding-2'}>
                                            <h4 style={{marginBottom: 0}}>
                                                <CustomTranslation value={'KEY.Concept actions'}/> <span className={'text-primary unbold'}>[{this.state.assignmentsConcept.length}]</span>
                                            </h4>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <i style={{fontWeight: '900', fontSize: '1.25rem'}}
                                               className={this.state.conceptCollapsed ? 'las la-chevron-down' : 'las la-chevron-up'}/>
                                        </div>
                                    </div>
                                    <Collapse
                                        isOpened={!this.state.conceptCollapsed}
                                    >
                                        <div className={'vertical-padding-2 standard-padding-2'} style={{paddingTop: 0}}>
                                            {/* Indicators */}
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0}} className={'vertical-margin-2'}>
                                                <div style={{width: 15, height: 15}} className={'bg-success rounded'}/>
                                                <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                    value={'KEY.Finished'}/></p>
                                                <div style={{width: 15, height: 15}} className={'bg-primary rounded'}/>
                                                <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                    value={'KEY.Open'}/></p>
                                            </div>
                                            <div>
                                                {/* Assignment list */}
                                                {this.state.assignmentsConcept.length > 0 ?
                                                    <div>
                                                        {this.state.assignmentsConcept}
                                                    </div>
                                                    :
                                                    <h2><CustomTranslation value={'KEY.No assignments'}/></h2>
                                                }
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                :
                                null
                            }

                            {/* Inbox */}
                            <div
                                className={'rounded bg-light vertical-margin-2'}
                            >
                                <div
                                    className={'touchable vertical-padding-2 standard-padding-2'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                    onClick={() => {
                                        this.setState({toMeCollapsed: !this.state.toMeCollapsed});
                                    }}
                                >
                                    <i style={{fontSize: '1.25rem'}} className={'las la-envelope-square icon'}/>
                                    <div style={{flexGrow: 1, flexShrink: 1}} className={'standard-padding-2'}>
                                        <h4 style={{marginBottom: 0}}>
                                            <CustomTranslation value={'KEY.Actions assigned to me'}/> <span className={'text-primary unbold'}>[{this.state.assignmentsInUnfinished}/{this.state.assignmentsIn.length}]</span>
                                        </h4>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <i style={{fontWeight: '900', fontSize: '1.25rem'}}
                                           className={this.state.toMeCollapsed ? 'las la-chevron-down' : 'las la-chevron-up'}/>
                                    </div>
                                </div>
                                <Collapse
                                    isOpened={!this.state.toMeCollapsed}
                                >
                                    <div className={'vertical-padding-2 standard-padding-2'} style={{paddingTop: 0}}>
                                        {/* Indicators */}
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0}} className={'vertical-margin-2'}>
                                            <div style={{width: 15, height: 15}} className={'bg-success rounded'}/>
                                            <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                value={'KEY.Finished'}/></p>
                                            <div style={{width: 15, height: 15}} className={'bg-primary rounded'}/>
                                            <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                value={'KEY.Open'}/></p>
                                        </div>
                                        <div>
                                            {/* Assignment list */}
                                            {this.state.assignmentsIn.length > 0 ?
                                                <div>
                                                    {this.state.assignmentsIn}
                                                </div>
                                                :
                                                <h2><CustomTranslation value={'KEY.No assignments'}/></h2>
                                            }
                                        </div>
                                    </div>
                                </Collapse>
                            </div>

                            {/* Outbox */}
                            <div
                                className={'rounded bg-light vertical-margin-2'}
                            >
                                <div
                                    className={'touchable vertical-padding-2 standard-padding-2'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                    onClick={() => {
                                        this.setState({byMeCollapsed: !this.state.byMeCollapsed});
                                    }}
                                >
                                    <i style={{fontSize: '1.25rem'}} className={'las la-share-square icon'}/>
                                    <div style={{flexGrow: 1, flexShrink: 1}} className={'standard-padding-2'}>
                                        <h4 style={{marginBottom: 0}}>
                                            <CustomTranslation value={'KEY.Actions assigned by me'}/> <span className={'text-primary unbold'}>[{this.state.assignmentsOutUnfinished}/{this.state.assignmentsOut.length}]</span>
                                        </h4>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <i style={{fontWeight: '900', fontSize: '1.25rem'}}
                                           className={this.state.byMeCollapsed ? 'las la-chevron-down' : 'las la-chevron-up'}/>
                                    </div>
                                </div>
                                <Collapse
                                    isOpened={!this.state.byMeCollapsed}
                                >
                                    <div className={'vertical-padding-2 standard-padding-2'} style={{paddingTop: 0}}>
                                        {/* Indicators */}
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0}} className={'vertical-margin-2'}>
                                            <div style={{width: 15, height: 15}} className={'bg-success rounded'}/>
                                            <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                value={'KEY.Finished'}/></p>
                                            <div style={{width: 15, height: 15}} className={'bg-primary rounded'}/>
                                            <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                value={'KEY.Open'}/></p>
                                        </div>
                                        <div>
                                            {/* Assignment list */}
                                            {this.state.assignmentsOut.length > 0 ?
                                                <div>
                                                    {this.state.assignmentsOut}
                                                </div>
                                                :
                                                <h2><CustomTranslation value={'KEY.No assignments'}/></h2>
                                            }
                                        </div>
                                    </div>
                                </Collapse>
                            </div>

                            {/* All */}
                            {this.state.assignmentsAll.length > 0 ?
                                <div
                                    className={'rounded bg-light vertical-margin-2'}
                                >
                                    <div
                                        className={'touchable vertical-padding-2 standard-padding-2'}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => {
                                            this.setState({allCollapsed: !this.state.allCollapsed});
                                        }}
                                    >
                                        <i style={{fontSize: '1.25rem'}} className={'las la-globe icon'}/>
                                        <div style={{flexGrow: 1, flexShrink: 1}} className={'standard-padding-2'}>
                                            <h4 style={{marginBottom: 0}} >
                                                <CustomTranslation value={'KEY.All actions'}/>
                                            </h4>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <i style={{fontWeight: '900', fontSize: '1.25rem'}}
                                               className={this.state.allCollapsed ? 'las la-chevron-down' : 'las la-chevron-up'}/>
                                        </div>
                                    </div>
                                    <Collapse
                                        isOpened={!this.state.allCollapsed}
                                    >
                                        <div className={'vertical-padding-2 standard-padding-2'} style={{paddingTop: 0}}>
                                            {/* Indicators */}
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0}} className={'vertical-margin-2'}>
                                                <div style={{width: 15, height: 15}} className={'bg-success rounded'}/>
                                                <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                    value={'KEY.Finished'}/></p>
                                                <div style={{width: 15, height: 15}} className={'bg-primary rounded'}/>
                                                <p style={{marginBottom: 0, paddingBottom: 0}} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                    value={'KEY.Open'}/></p>
                                            </div>
                                            <div>
                                                {/* Assignment list */}
                                                {this.state.assignmentsAll.length > 0 ?
                                                    <div>
                                                        {this.state.assignmentsAll}
                                                    </div>
                                                    :
                                                    <h2><CustomTranslation value={'KEY.No assignments'}/></h2>
                                                }
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                :
                                null
                            }

                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <AssignmentScreen {...props} params={params} />;
}
