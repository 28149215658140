import React from 'react';

import G from '../../../Actions/GeneralAction';
import CustomTranslation from '../../Components/CustomTranslation';
import fileDownload from 'js-file-download';
import {generatePath} from "react-router-dom";

//define class
class VGPlan extends React.Component {

    //constructor
    constructor (props) {
        super(props);

        //set state
        this.state = {
            isLoaded: false,
            active: true,
            data: this.props.data
        };
    }

    //render view
    render = () => {

        //set status
        this.status = '';
        if (
            (this.props.ext === 'pdf') ||
            (this.state.data.uploadedVGPlans && (this.state.data.uploadedVGPlans.length > 0))
        ) {
            this.status = 'bg-success';
        } else {
            this.status = 'bg-primary';
        }

        //return
        return (
            <div style={this.props.style}
                 className={this.props.className}
                 onClick={() => {
                     if (this.props.ext === 'pdf') {
                        fetch(this.state.data.path)
                            .then(res => res.blob())
                            .then(res => {
                                fileDownload(res, this.state.data.originalFileName);
                            });
                     } else {
                         //go to next page
                         window.location.href = generatePath('/construction_site/:construction_site_id/vg_plan/:vg_plan_id', {construction_site_id: this.props.cs.id, vg_plan_id: this.props.data.id});
                     }
                 }}
            >
                <div style={{
                    minHeight: 50,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
                     className={'bg-white bordered rounded touchable'}
                >
                    <div
                        style={{
                            minWidth: 10,
                            maxWidth: 10,
                            alignSelf: 'stretch'
                        }}
                        className={'rounded ' + this.status}
                    >

                    </div>
                    <div className={'standard-margin vertical-margin'} style={{flexShrink: 1, flexGrow: 1}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div
                                className={'bg-dark icon-container'}
                            >
                                <i
                                    className={
                                        (this.props.ext === 'pdf') ?
                                            'las la-file-pdf text-white icon'
                                            :
                                            (this.state.data.uploadedVGPlans.length > 0) ?
                                                'las la-file-pdf text-white icon'
                                                :
                                                'las la-file-word text-white icon'
                                    }
                                />
                            </div>
                            <div style={{flexGrow: 1, flexShrink: 1}} className={'standard-padding-2'}>
                                <h5 className={'unbold'}>
                                    <CustomTranslation
                                        value={
                                            (this.props.ext === 'pdf') ?
                                                'KEY.Download'
                                                :
                                                'KEY.VG Plan'
                                        }
                                    />
                                </h5>
                                {(this.props.ext === 'pdf') ?
                                    <h3 style={{wordBreak: 'break-word'}}>
                                        { this.props.data.originalFileName }
                                    </h3>
                                    :
                                    <h3>
                                        { (this.state.data.createdBy.firstName  || this.state.data.createdBy.lastName ) ?
                                            (this.state.data.createdBy.firstName || '') + ' ' + (this.state.data.createdBy.lastName || '')
                                            :
                                            this.state.data.createdBy.email
                                        }
                                    </h3>
                                }

                                <h5 className={'unbold'}>
                                    [{G.formatDateTime(this.state.data.createdAt)}]
                                </h5>
                            </div>
                            {this.props.delete ?
                                <div className={'bg-dark touchable icon-btn-container'}>
                                    <i className={'las la-pen text-white icon-btn'}/>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default VGPlan;