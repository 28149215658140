import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import Loader from "../../Components/Loader";
import UserAction from "../../../Actions/UserAction";
import CompanyAction from "../../../Actions/CompanyAction";
import CustomBorderedButton from "../../Components/CustomBorderedButton";
import CustomConfirm from "../../Components/CustomConfirm";
import CustomInput from "../../Components/CustomInput";
import CustomCheckBox from "../../Components/CustomCheckBox";
import {toast} from "react-hot-toast";
import UserRight from "./Components/UserRight";
import LicenseAction from "../../../Actions/LicenseAction";

class CompanyUserRightTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set collapsed users
        this.userCollapsed = {};

        //set state
        this.state = {

        };
    }

    //on initial
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //init current user
    initCurrentUser = async () => {
        if (!this.state.currentUser) {

            //get current user
            let currentUser = this.props.user;
            let userCompanyRights = await UserAction.getUserRightByCompanyId(currentUser.defaultCompany.id);
            //licenses
            let licenses = await LicenseAction.getLicensesByUserAndCompany(currentUser.defaultCompany.id);

            //check licenses
            if (licenses.length === 0) {
                userCompanyRights.adminRights = false;
                userCompanyRights.managementRights = false;
            }

            //set state
            this.setState({
                currentUser: currentUser,
                userCompanyRights: userCompanyRights,
                licenses: licenses
            });

            //return
            return userCompanyRights;
        }

        //return
        return this.state.userCompanyRights;
    }

    //update elements
    updateElements = async () => {

        //get user company rights
        let userCompanyRights = await this.initCurrentUser();

        //array for requests
        let requests = [];

        //push needed requests
        //get users
        requests.push(UserAction.getUserListCompany(this.props.defaultCompany.id, true));
        requests.push(CompanyAction.getRights(this.props.defaultCompany['@id']));

        //wait for result
        let results = await Promise.all(requests);

        //set users
        let users = [];
        results[0].forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            users.push({label: label, value: element.email});
        });

        //populate rights array
        let rights = this.populateRights(results[1], userCompanyRights);

        //set users without rights
        let newUsers = [];

        //loop & compare
        users.forEach(user => {
            let found = false;
            results[1].forEach(right => {
                if (user.value === right.user.email) found = true;
            });
            if (!found) newUsers.push(user);
        });

        //set state
        this.setState({
            requestRights: results[1],
            userCompanyRights: userCompanyRights,
            company: this.props.defaultCompany,
            users: users,
            rights: rights,
            newUsers: newUsers
        });
    }

    //populate rights
    populateRights = (input, userCompanyRights) => {
        //init array
        let rights = [];

        //loop inputs
        input.forEach((element, index) => {
            //set as collapsed by default
            if (!this.userCollapsed.hasOwnProperty(element.id)) {
                this.userCollapsed[element.id] = {
                    'index': index,
                    'collapsed': true
                };
            }

            //add each right
            rights.push(
                <UserRight
                    className={'vertical-padding-3'}
                    key={element.id}
                    data={element}
                    userCompanyRights={userCompanyRights}
                    collapsed={this.userCollapsed[element.id].collapsed}
                    onCollapse={(value) => {
                        this.onCollapse(element.id, value);
                    }}
                    onValueChange={(value) => {
                        this.removeRight(value);
                    }}
                />
            );
        });

        //return
        return rights;
    }

    //create new company right
    createRight = async (email) => {
        //make call
        await CompanyAction.createRights({
            jobTitle: '',
            adminRights: false,
            managementRights: false,
            user: email,
            company: {
                id: this.props.defaultCompany['@id']
            },
            isEmployee: this.state.newUserIsEmployee
        });

        //reload
        await this.updateElements();
    }

    //remove/update company right
    removeRight = async (right) => {
        // Remove
        await CompanyAction.updateRights(right);

        //reload
        await this.updateElements();
    }

    //add users
    newUser = async () => {
        if(this.state.newUserEmail){
            // Hide modal
            this.setState({
                showUserModal: false,
            });

            // Create Right for New User
            await this.createRight(this.state.newUserEmail);

            // Clear email from state
            this.setState({
                newUserEmail: null,
            });

            // Show Success
            toast.success(<CustomTranslation value={'KEY.User added to company!'} />);
        }
        else{
            toast.error(<CustomTranslation value={'KEY.Email not found!'} />);
        }
    }

    //if collapse is triggerd, only one can be open at any time
    onCollapse = (key, value) => {
        //open correct one close others
        if (!value) {
            for (let [index, item] of Object.entries(this.userCollapsed)) {
                if (index === key) {
                    this.userCollapsed[index].collapsed = false;
                } else {
                    this.userCollapsed[index].collapsed = true;
                }
            }
            //close
        } else {
            this.userCollapsed[key].collapsed = true;
        }

        //repopulate rights array
        let rights = this.populateRights(this.state.requestRights, this.state.userCompanyRights);

        //put in state
        this.setState({rights: rights});
    }

    //render
    render = () => {

        //if loaded
        if ('rights' in this.state) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Company Users'}/></h2>
                    </div>
                    {/* Add new user */}
                    <div className={'vertical-margin-2'}>
                        <CustomBorderedButton
                            title={<CustomTranslation value={'KEY.Add User'} />}
                            onClick={() => {
                                this.setState({showUserModal: true});
                            }}
                        />
                    </div>
                    {/* List */}
                    <div>
                        {this.state.rights}
                    </div>
                    {/* Modal */}
                    <CustomConfirm
                        show={this.state.showUserModal}
                        variant={'success'}
                        body={
                            <div>
                                <div className={'vertical-margin-2'}>
                                    <CustomInput
                                        iconName={'las la-user'}
                                        label={<CustomTranslation value={'KEY.Email'} />}
                                        inputProps={{
                                            type: 'email'
                                        }}
                                        onChangeText={(email) => this.setState({newUserEmail: email.trim().toLowerCase()})}
                                    />
                                </div>
                                <div className={'vertical-margin-2'}>
                                    <CustomCheckBox
                                        label={<CustomTranslation value={'KEY.Own employee'} />}
                                        onValueChange={(value) => {
                                            this.setState({newUserIsEmployee: value});
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                        onCancel={() => this.setState({showUserModal: false})}
                        confirmLabel={<CustomTranslation value={'KEY.Add User'} /> }
                        onConfirm={() => this.newUser()}
                    />
                </div>
            );
        } else {
            return (
                <Loader />
            );
        }
    }
}

//export
export default CompanyUserRightTab;