import React from "react";
import {Button} from "react-bootstrap";

//define class
class LMRAPreference extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            template: this.props.template,
            lmraItem: this.props.lmraItem,
            existing: this.props.existing,
            value: this.props.existing ? true : false,
            last: Date.now()
        };
    }

    toggleCheckbox = async (template, lmra_id, value) => {
        await this.setState({'value': value }, () => {
            this.props.toggleLMRA(template, lmra_id, value);
        });
    }

    //if delete is pressed
    remove = async () => {
        if (typeof this.props.remove === 'function') {
            this.props.remove(this.state.lmraItem.id, this.props.title);
        }
    }

    //render view
    render = () => {

        return (
            <div style={this.props.style} className={this.props.className}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    { this.state.lmraItem['@type'] ===  'LastMinuteRiskInsert' ?
                        <div className={'standard-padding-2'} style={{paddingLeft: 0, justifyContent: 'center'}}>
                            <Button
                                size={'sm'}
                                variant={'danger'}
                                style={{height: '1.5rem', width: '1.5rem', paddingTop: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
                                    onClick={() => {
                                        this.remove();
                                    }}
                            >
                                <span>
                                     <i style={{fontSize: '1rem'}} className={'las la-trash text-white'} />
                                </span>
                            </Button>
                        </div>
                        :
                        null
                    }
                    <div
                        style={{
                            flexGrow: 1,
                            flexShrink: 1,
                            maxWidth: '31rem'
                        }}
                    >
                        <h3 style={{wordBreak: 'break-word'}}>{this.props.title}</h3>
                    </div>
                    <div
                        className={'standard-padding-2'}
                        style={{paddingRight: 0}}
                    >
                        <span
                            className={'checkmark-success bordered rounded touchable ' + (this.state.value ? 'bg-success' : '')}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={() => {
                                this.toggleCheckbox(this.state.template, this.state.lmraItem.id, !this.state.value);
                            }}
                        >
                            {this.state.value &&
                            <i
                                className={'las la-check text-white'}
                                style={{
                                    fontWeight: '900'
                                }}
                            />
                            }
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default LMRAPreference;