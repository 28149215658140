import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import {Button, Modal} from "react-bootstrap";
import CustomInput from "../../Components/CustomInput";
import {toast} from "react-hot-toast";
import CodeInput from "./CodeInput";
import UserAction from "../../../Actions/UserAction";

//define class
class PasswordModal extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {
            email: '',
            password: '',
            code: '',
            firstName: '',
            lastName: '',
            showStep1: true,
            showStep2: false,
            onInvite: false,
        }
    }

    //check if everything is filled in
    isReadyStep1 = () => {
        if (this.state.email && (this.state.email.trim() !== "")) {
            return true;
        }
        return false;
    }

    isReadyStep2 = () => {
        if (this.state.onInvite) {
            if (this.state.code && this.state.password && this.state.firstName && this.state.lastName) {
                return true;
            }
        } else {
            if (this.state.code && this.state.password) {
                return true;
            }
        }

        return false;
    }

    //skip first step if code already send
    skipFirstStep = () => {
        if(this.state.email){
            this.setState({
                showStep1: false,
                showStep2: true,
                onInvite: true
            })
        }
        else{
            //show toast
            toast.error(<CustomTranslation value={'KEY.Please fill in the email your received the code for!'} />);
        }
    }

    //first step
    firstStep = async () => {

        //send to logic
        let test = await UserAction.requestNewPasswordCode(this.state);

        //if OK, show next step
        if (test) {
            this.setState({
                showStep1: false,
                showStep2: true
            });
        }
    }

    //render view
    render() {
        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title><CustomTranslation value={'KEY.Forgot password'}/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showStep1 &&
                            <div>
                                <div className={'vertical-padding-2'}>
                                    <CustomInput
                                        iconName={'las la-at'}
                                        label={<CustomTranslation value={'KEY.Email'}/>}
                                        inputProps={{
                                            type: 'email'
                                        }}
                                        onChangeText={(text) => {
                                            this.setState({email: text.trim().toLowerCase()});
                                        }}
                                    />
                                </div>
                                <div className={'vertical-padding-2'}>
                                    <Button
                                        block
                                        dark
                                        variant={'link'}
                                        className={'text-dark'}
                                        style={{textDecoration: 'underline'}}
                                        onClick={() => {
                                            this.skipFirstStep();
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Already have a code?'}/>
                                    </Button>
                                </div>
                            </div>
                        }
                        {this.state.showStep2 &&
                            <div>
                                <div className={'vertical-padding-2'}>
                                    <CodeInput
                                        label={<CustomTranslation value={'KEY.Confirmation code'} />}
                                        onChange={(value) => {
                                            this.setState({code: value});
                                        }}
                                    />
                                </div>
                                <div>
                                    {this.state.onInvite &&
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}
                                            className={'vertical-padding-2'}
                                        >
                                            <CustomInput
                                                iconName={'las la-quote-left'}
                                                label={<CustomTranslation value={'KEY.First name'}/>}
                                                className={'standard-margin-3'}
                                                style={{
                                                    flex: 1,
                                                    marginLeft: 0
                                                }}
                                                onChangeText={(text) => {
                                                    this.setState({firstName: text});
                                                }}
                                            />
                                            <CustomInput
                                                iconName={'las la-quote-left'}
                                                label={<CustomTranslation value={'KEY.Last name'}/>}
                                                className={'standard-margin-3'}
                                                style={{
                                                    flex: 1,
                                                    marginRight: 0
                                                }}
                                                onChangeText={(text) => {
                                                    this.setState({lastName: text});
                                                }}
                                            />
                                        </div>
                                    }
                                    <div className={'vertical-padding-2'}>
                                        <CustomInput
                                            iconName={'las la-lock'}
                                            label={this.state.onInvite ? <CustomTranslation value={'KEY.Password'} /> : <CustomTranslation value={'KEY.New password'} />}
                                            inputProps={{
                                                type: 'password'
                                            }}
                                            onChangeText={(text) => {
                                                this.setState({password: text});
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.showStep1 &&
                            <Button
                                block
                                variant={'dark'}
                                onClick={() => this.firstStep()}
                                disabled={!this.isReadyStep1()}
                            >
                                <CustomTranslation value={'KEY.Send confirmation code'}/>
                            </Button>
                        }
                        {this.state.showStep2 &&
                            <Button
                                block
                                variant={'dark'}
                                onClick={() => UserAction.requestNewPassword(this.state)}
                                disabled={!this.isReadyStep2()}
                            >
                                <CustomTranslation value={'KEY.Request new password'}/>
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

//export
export default PasswordModal;