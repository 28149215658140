import React from "react";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import {generatePath, useParams} from "react-router-dom";
import RiskAnalysisAction from "../../Actions/RiskAnalysisAction";
import TranslationAction from "../../Actions/TranslationAction";
import UserAction from "../../Actions/UserAction";
import {Breadcrumb, Button} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import CustomInput from "../Components/CustomInput";
import CustomCheckBoxList from "../Components/CustomCheckBoxList";

class EmailScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set limited value
        let limited = false;
        if (this.props.params.limited && (this.props.params.limited === 1)) {
            limited = true;
        }

        //set state
        this.state = {
            email: [],
            ra: [],
            limited: limited
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {
        //get risk analysis
        let ra = await RiskAnalysisAction.get(this.props.params.id);

        /** RISK ANALYSES **/

        //get all risk analyses
        let ras = await RiskAnalysisAction.list(ra.constructionSite, true);

        //create options
        let raOptions = [];

        //loop
        ras.forEach(element => {
            raOptions.push({
                label: (<CustomTranslation value={element.title} />),
                value: element.id
            });
        });

        /** USERS **/

        //get users
        let users = await UserAction.getUserListConstructionSite(ra.constructionSite.id);

        //create options
        let userOptions = [];

        //loop users
        users.forEach(element => {
            if (element.firstName && element.lastName) {
                userOptions.push({
                    label: (element.firstName ? element.firstName : '') + (element.lastName ? ' ' + element.lastName : ''),
                    value: element.email
                });
            } else {
                userOptions.push({
                    label: (element.email ? element.email : ''),
                    value: element.email
                });
            }
        });

        /** TITLE **/

        //translate title
        let title = null;
        if (!this.state.title) {
            title = await TranslationAction.translate('KEY.Risk analysis');
            title += ': ' + ra.constructionSite.name;
        } else {
            title = this.state.title;
        }

        //set state
        this.setState({
            data: {
                riskAnalysis: ra,
            },
            raOptions: raOptions,
            userOptions: userOptions,
            title: title,
            ra: [ra.id],
            constructionSiteId: ra.constructionSite.id
        });
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            disabled={
                                !(this.state.message && this.state.title && (this.state.email.length > 0) && (this.state.ra.length > 0))
                            }
                            onClick={async () => {
                                //send request
                                await RiskAnalysisAction.sendRiskAnalysis(this.state);
                                //navigate
                                setTimeout(() => {
                                    window.location.href = generatePath('/risk_analysis/:id', {id: this.state.data.riskAnalysis.id});
                                }, 1000);
                            }}
                        >
                            <CustomTranslation value={'KEY.Send Risk Analysis'} />
                        </Button>
                    }
                >
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/construction_site/:id', {id: this.state.data.riskAnalysis.constructionSite.id})}
                            >
                                {this.state.data.riskAnalysis.constructionSite.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/risk_analysis/:id', {id: this.state.data.riskAnalysis.id})}
                            >
                                <CustomTranslation value={this.state.data.riskAnalysis.title} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.Notification Risk Analysis'}/>
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div>
                            <h1>
                                <CustomTranslation value={'KEY.Notification Risk Analysis'}/>
                            </h1>
                        </div>

                        {/* Form */}
                        <div>
                            <div className={'vertical-padding-3'}>
                                <CustomInput
                                    label={<CustomTranslation value={'KEY.Title'} />}
                                    iconName={'las la-quote-left'}
                                    value={this.state.title}
                                    onChangeText={(value) => {this.state({title: value})}}
                                />
                            </div>
                            <div className={'vertical-padding-3'}>
                                <CustomCheckBoxList
                                    label={<CustomTranslation value={'KEY.Recipients'} />}
                                    iconName={'las la-user'}
                                    items={this.state.userOptions}
                                    onValueChange={(values) => {this.setState({email: values})}}
                                />
                            </div>
                            <div className={'vertical-padding-3'}>
                                <CustomCheckBoxList
                                    label={<CustomTranslation value={'KEY.Risk analysis'} />}
                                    iconName={'las la-file'}
                                    items={this.state.raOptions}
                                    values={this.state.ra}
                                    onValueChange={(values) => {this.setState({ra: values})}}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    label={<CustomTranslation value={'KEY.Message'} />}
                                    iconName={'las la-comment'}
                                    inputProps={{
                                        as: 'textarea',
                                        rows: 10
                                    }}
                                    onChangeText={(value) => {this.setState({message: value})}}
                                />
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <EmailScreen {...props} params={params} />;
}