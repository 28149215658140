//imports
import React from 'react';
import CustomTranslation from "../../Components/CustomTranslation";

//define class
class RiskAnalysisFileButton extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {

        }
    }

    //render view
    render() {

        return (
            <div className={this.props.className} style={this.props.style}>
                <div
                    className={'bordered rounded standard-padding vertical-padding touchable'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                    onClick={() => this.props.onClick()}
                >
                    <div>
                        <div
                            className={'bg-dark'}
                            style={{
                                height: '1.5rem',
                                width: '1.5rem',
                                borderRadius: '50%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <i
                                className={'las la-file-pdf text-white'}
                                style={{
                                    fontSize: '1rem'
                                }}
                            >
                            </i>
                        </div>
                    </div>
                    <div
                        className={'standard-margin-2'}
                        style={{
                            marginRight: 0,
                            flexGrow: 1,
                            flexShrink: 1
                        }}
                    >
                        <h5 className={'unbold'}><CustomTranslation value={'KEY.Download'} /></h5>
                        <h3>{this.props.title}</h3>
                    </div>
                    {(typeof this.props.onMailPress === 'function') &&
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                className={'bg-dark touchable icon-btn-container'}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (typeof this.props.onMailPress === 'function') {
                                        this.props.onMailPress();
                                    }
                                }}
                            >
                                <i className={'las la-share-square text-white icon-btn'}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

//export
export default RiskAnalysisFileButton;