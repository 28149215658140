import React from 'react';
import ConstructionSiteAction from '../../Actions/ConstructionSiteAction';
import RiskAnalysisAction from '../../Actions/RiskAnalysisAction';
import APIAction from '../../Actions/APIAction';
import VGPlanAction from '../../Actions/VGPlanAction';
import CustomTranslation from '../Components/CustomTranslation';
import CustomBorderedButton from '../Components/CustomBorderedButton';
import { generatePath, useParams } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import UserAction from '../../Actions/UserAction';
import LicenseAction from '../../Actions/LicenseAction';
import RiskAnalysis from './Components/RiskAnalysis';
import Uuid from 'uuid/v1';
import Project from '../Home/Components/Project';
import VGPlan from './Components/VGPlan';
import { Breadcrumb, Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import CustomContainer from "../Components/CustomContainer";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import Loader from "../Components/Loader";
import CustomInfoButton from "../Components/CustomInfoButton";
import fileDownload from "js-file-download";
import LanguageRepository from '../../Database/Repositories/LanguageRepository';
import TranslationAction from '../../Actions/TranslationAction';
import FileButton from './Components/FileButton';
import CustomConfirm from '../Components/CustomConfirm';
import ToolboxAction from '../../Actions/ToolboxAction';
import Toolbox from './Components/Toolbox';

class ConstructionSiteScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            project: null,
            riskAnalysisCollapsed: true,
            vgsCollapsed: true,
            toolboxCollapsed: true,
            infoModalRA: false,
            infoModalVG: false,
            waitModal: false,
            accessibilityModal: false,
            raCollapseUuid: Uuid(),
            vgCollapseUuid: Uuid(),
            toolboxCollapseUuid: Uuid()
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {
        //get project
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.id, true);

        //requests parallel
        let requests = [];
        //get risk analyses
        requests.push(RiskAnalysisAction.list(cs));
        //get vg plans
        requests.push(VGPlanAction.list(cs.id));
        //get toolbox
        requests.push(ToolboxAction.list(cs.id));

        //wait for results
        let results = await Promise.all(requests);

        /** RIGHTS **/

        //rights
        let userConstructionSiteRights = await UserAction.getUserRightByConstructionSiteId(cs.id);
        let userCompanyRights = await UserAction.getUserRightByCompanyId(cs.company ? cs.company.id : null);
        if (!userConstructionSiteRights && userCompanyRights.adminRights) {
            userConstructionSiteRights = { managementRights: true };
        }

        //licenses
        let licenses = await LicenseAction.getLicensesByUserAndCompany(cs.company ? cs.company.id : null);

        /** RISK ANALYSES **/
        //get risk analyses
        let tra = results[0] || [];

        //add risk analysis items
        let traItems = [];
        let traItemsFinished = [];
        tra.forEach((element, id) => {
            if (element.isFinished === false) {
                traItems.push(
                    <RiskAnalysis
                        key={Uuid()}
                        id={element.id}
                        title={element.title}
                        date={element.updatedAt}
                        isFinished={element.isFinished}
                        className={'vertical-margin-3 standard-margin-3'}
                        style={{ flexBasis: 250, flexGrow: 1, flexShrink: 1 }}
                        onClick={() => {
                            window.location.href = generatePath('/risk_analysis/:id', { id: element.id });
                        }}
                        delete={userConstructionSiteRights.managementRights ? true : false}
                        onDelete={this.onDelete}
                    />
                );
            } else {
                traItemsFinished.push(
                    <RiskAnalysis
                        key={Uuid()}
                        id={element.id}
                        title={element.title}
                        date={element.updatedAt}
                        isFinished={element.isFinished}
                        className={'vertical-margin-3 standard-margin-3'}
                        style={{ flexBasis: 250, flexGrow: 1, flexShrink: 1 }}
                        onClick={() => {
                            window.location.href = generatePath('/risk_analysis/:id', { id: element.id });
                        }}
                        delete={userConstructionSiteRights.managementRights ? true : false}
                        onDelete={this.onDelete}
                    />
                );
            }
        });

        /** VG PLANS **/
        let vgPlans = results[1] || [];

        //init
        let vgItems = [];

        //check if admin or not
        if (userConstructionSiteRights.managementRights) {
            //loop
            vgPlans.forEach((plan, id) => {

                //create item
                let planItem = (
                    <VGPlan
                        key={id}
                        data={plan}
                        cs={cs}
                        delete={userConstructionSiteRights.managementRights ? true : false}
                        ext={'docx'}
                        className={'vertical-margin-3 standard-margin-3'}
                        style={{ flexBasis: 250, flexGrow: 1, flexShrink: 1 }}
                    />
                );
                vgItems.push(planItem);
            });
        } else {
            //loop
            vgPlans.forEach((plan, planId) => {
                let uploadedPlans = plan.uploadedVGPlans;
                //loop
                uploadedPlans.forEach((uplan, uplanId) => {
                    //create item
                    let uplanItem = (
                        <VGPlan
                            key={'u' + uplanId}
                            data={uplan}
                            cs={cs}
                            delete={false}
                            ext={'pdf'}
                            className={'vertical-margin-3 standard-margin-3'}
                            style={{ flexBasis: 250, flexGrow: 1, flexShrink: 1 }}
                        />
                    );

                    vgItems.push(uplanItem);
                });
            });
        }

        /** TOOLBOXES **/
        let toolboxes = results[2] || [];
        let toolboxItems = [];
        //loop
        toolboxes.forEach((toolbox, id) => {

            //create item
            let toolboxItem = (
                <Toolbox
                    key={'t' + id}
                    data={toolbox}
                    cs={cs}
                    className={'vertical-margin-3 standard-margin-3'}
                    style={{ flexBasis: 250, flexGrow: 1, flexShrink: 1 }}
                />
            );

            toolboxItems.push(toolboxItem);
        });

        //set state
        this.setState({
            data: {
                cs: cs,
                userConstructionSiteRights: userConstructionSiteRights,
                userCompanyRights: userCompanyRights,
                licenses: licenses,
                traCombined: traItems.concat(traItemsFinished),
                traNrUnfinished: traItems.length,
                vgPlans: vgItems,
                toolboxes: toolboxItems
            },
            raCollapseUuid: Uuid(),
            vgCollapseUuid: Uuid(),
            toolboxCollapseUuid: Uuid()
        });
    }

    //trigger new VGPlan
    newVGPlan = async () => {

        //show wait modal
        this.setState({
            waitModal: true
        });

        //create new
        let result = await VGPlanAction.create(this.state.data.cs.id);

        //reload
        await this.updateElements();

        //open VG Plan
        this.setState({
            vgsCollapsed: false,
            waitModal: false
        });

        //show toast
        toast.success(<CustomTranslation value={'KEY.V&G plan created!'} />);
    }

    //on TRA delete
    onDelete = async () => {
        await this.updateElements();
    }

    //download accessibility file
    downloadAccessibility = async () => {
        //default file name
        let now = new Date();
        let fileName = await TranslationAction.translate('KEY.Accessibility Overview');
        fileName += ' ' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        fileName += '.pdf';

        //get language
        let languageRepository = new LanguageRepository();
        let language = await languageRepository.getSelected();

        //request
        APIAction.request('/construction_sites/' + this.state.data.cs.id + '/pdf', 'GET', true, { language: language.code }, true, false, 'json', true)
            .then(result => {
                fileName = fileName.replace(/['"]+/g, '');
                fileDownload(result, fileName, 'application/pdf');
            });
    }

    //send accessibility
    sendAccessibility = () => {
        window.location.href = generatePath('/construction_site/:id/email', { id: this.state.data.cs.id });
    }

    render = () => {
        if (this.state && this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            onClick={() => { window.location.href = generatePath('/construction_site/:construction_site_id/assignments', { 'construction_site_id': this.state.data.cs.id }); }}
                        >
                            <CustomTranslation value={'KEY.Action list'} />&nbsp;
                            <span className={'text-primary'}>
                                [{this.state.data.cs.activeAssignmentCount}/{this.state.data.cs.totalAssignmentCount}]
                            </span>
                        </Button>
                    }
                >
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.cs}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{ position: 'sticky', top: '2rem', zIndex: 9 }}>
                                <Project
                                    float
                                    data={this.state.data.cs}
                                />
                            </div>

                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {this.state.data.cs.name}
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div
                                className={'vertical-margin'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div
                                    style={{ marginBottom: '-1rem' }}
                                >
                                    <h1><CustomTranslation value={'KEY.Project'} /></h1>
                                </div>
                                {!this.state.data.cs.hideEditButton && (this.state.data.licenses && this.state.data.licenses.length > 0) && ((this.state.data.userConstructionSiteRights && this.state.data.userConstructionSiteRights.managementRights)) &&
                                    <div>
                                        <div
                                            className={'icon-btn-container bg-dark touchable'}
                                            onClick={() => {
                                                window.location.href = generatePath('/construction_site/:id/edit', { id: this.state.data.cs.id });
                                            }}
                                        >
                                            <i className={'las la-pen icon-btn text-white'} />
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* New risk analysis */}
                            {(this.state.data.licenses && this.state.data.licenses.length > 0) && ((this.state.data && this.state.data.userConstructionSiteRights) && (this.state.data.userConstructionSiteRights.managementRights)) &&
                                <div className={'vertical-margin-2'}>
                                    <CustomBorderedButton
                                        title={<CustomTranslation value={'KEY.Create new risk analysis'} />}
                                        onClick={() => {
                                            window.location.href = generatePath('/construction_site/:construction_site_id/create_risk_analysis', {
                                                construction_site_id: this.state.data.cs.id
                                            });
                                        }}
                                    />
                                </div>
                            }

                            {/* Overview risk analyses */}
                            {(this.state.data.traCombined.length > 0) ?
                                <div
                                    className={'rounded bg-light vertical-margin-2'}
                                >
                                    <div
                                        onClick={() => {
                                            this.setState({ riskAnalysisCollapsed: !this.state.riskAnalysisCollapsed })
                                        }}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                        className={'touchable vertical-padding-2 standard-padding-2'}
                                    >
                                        <i style={{ fontSize: '1.2rem' }} className={'las la-exclamation-triangle icon'} />
                                        <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-2'}>
                                            <h4 style={{ marginBottom: 0 }} >
                                                <CustomTranslation value={'KEY.Overview risk analysis'} /> <span
                                                    className={'text-primary unbold'}>[{this.state.data.traNrUnfinished}/{this.state.data.traCombined.length}]</span>
                                            </h4>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <i style={{ fontWeight: '900', fontSize: '1.25rem' }}
                                                className={this.state.riskAnalysisCollapsed ? 'las la-chevron-down' : 'las la-chevron-up'} />
                                        </div>
                                    </div>
                                    <Collapse isOpened={!this.state.riskAnalysisCollapsed} key={this.state.raCollapseUuid}>
                                        <div className={'vertical-padding-2 standard-padding-2'} style={{ paddingTop: 0 }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }} className={'vertical-padding-2'}>
                                                {/* Indicators */}
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0 }} className={'vertical-margin-2'}>
                                                    <div style={{ width: 15, height: 15 }} className={'bg-success rounded'} />
                                                    <p style={{ marginBottom: 0, paddingBottom: 0 }} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                        value={'KEY.Finished'} /></p>
                                                    <div style={{ width: 15, height: 15 }} className={'bg-primary rounded'} />
                                                    <p style={{ marginBottom: 0, paddingBottom: 0 }} className={'standard-margin-2 label unbold'}><CustomTranslation
                                                        value={'KEY.In progress'} /></p>
                                                </div>
                                                <div>
                                                    <CustomInfoButton
                                                        onClick={() => this.setState({ infoModalRA: true })}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                flexDirection: '',
                                                marginLeft: -3,
                                                marginRight: -3
                                            }}>
                                                {this.state.data.traCombined}
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                :
                                null
                            }

                            {/* New VG Plan */}
                            {(this.state.data.licenses && this.state.data.licenses.length > 0) && ((this.state.data && this.state.data.userConstructionSiteRights) && (this.state.data.userConstructionSiteRights.managementRights)) &&
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                    className={'vertical-margin-2'}>
                                    <CustomBorderedButton
                                        style={{ flexGrow: 1, flexShrink: 1 }}
                                        title={<CustomTranslation
                                            value={'KEY.Create new safety, health and environment plan'} />}
                                        onClick={() => {
                                            this.newVGPlan();
                                        }}
                                    />
                                    <div className={'standard-margin'} style={{ marginRight: 0 }}>
                                        <CustomInfoButton
                                            onClick={() => this.setState({ infoModalVG: true })}
                                        />
                                    </div>
                                </div>
                            }

                            {/* Overview vg plans */}
                            {(this.state.data.vgPlans.length > 0) ?
                                <div
                                    className={'rounded bg-light vertical-margin-2'}
                                >
                                    <div
                                        onClick={() => {
                                            this.setState({ vgsCollapsed: !this.state.vgsCollapsed })
                                        }}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                        className={'touchable vertical-padding-2 standard-padding-2'}
                                    >
                                        <i style={{ fontSize: '1.2rem' }} className={'las la-file-invoice icon'} />
                                        <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-2'}>
                                            <h4 style={{ marginBottom: 0 }} >
                                                <CustomTranslation value={'KEY.Overview safety, health and environment plans'} />
                                            </h4>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <i style={{ fontWeight: '900', fontSize: '1.25rem' }}
                                                className={this.state.vgsCollapsed ? 'las la-chevron-down' : 'las la-chevron-up'} />
                                        </div>
                                    </div>
                                    <Collapse isOpened={!this.state.vgsCollapsed} key={this.state.vgCollapseUuid}>
                                        <div className={'vertical-padding-2 standard-padding-2'} style={{ paddingTop: 0 }}>
                                            <div style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                flexDirection: '',
                                                marginLeft: -3,
                                                marginRight: -3
                                            }}
                                            >
                                                {this.state.data.vgPlans}
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                :
                                null
                            }

                            {/* Download PDF */}
                            {
                                (
                                    this.state.data.cs.accessibilitySaved ||
                                    ((this.state.data.licenses && this.state.data.licenses.length > 0) && (this.state.data.userConstructionSiteRights && this.state.data.userConstructionSiteRights.managementRights))
                                ) &&
                                <div className={'vertical-margin-2'}>
                                    <FileButton
                                        topTitle={<CustomTranslation value={'KEY.Download Overview'} />}
                                        title={
                                            this.state.data.cs.country.countryCode.toUpperCase() === 'NL' ?
                                                <CustomTranslation value={'KEY.Project Accessibility'} /> :
                                                <CustomTranslation value={'KEY.Accessibility'} />
                                        }
                                        onClick={async () => {
                                            if (this.state.data.cs.accessibilitySaved) {
                                                await this.downloadAccessibility();
                                            } else {
                                                this.setState({ accessibilityModal: true });
                                            }
                                        }}
                                        onMailPress={() => {
                                            if (this.state.data.cs.accessibilitySaved) {
                                                this.sendAccessibility();
                                            } else {
                                                this.setState({ accessibilityModal: 'send' });
                                            }
                                        }}
                                    />
                                </div>
                            }

                            {/* Overview toolboxes */}
                            {(this.state.data.toolboxes.length > 0) ?
                                <div
                                    className={'rounded bg-light vertical-margin-2'}
                                >
                                    <div
                                        onClick={() => {
                                            this.setState({ toolboxCollapsed: !this.state.toolboxCollapsed })
                                        }}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                        className={'touchable vertical-padding-2 standard-padding-2'}
                                    >
                                        <i style={{ fontSize: '1.2rem' }} className={'las la-briefcase icon'} />
                                        <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-2'}>
                                            <h4 style={{ marginBottom: 0 }} >
                                                <CustomTranslation value={'KEY.Overview'} /> <CustomTranslation value={'KEY.Instruction - Toolbox'} />
                                            </h4>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <i style={{ fontWeight: '900', fontSize: '1.25rem' }}
                                                className={this.state.toolboxCollapsed ? 'las la-chevron-down' : 'las la-chevron-up'} />
                                        </div>
                                    </div>
                                    <Collapse isOpened={!this.state.toolboxCollapsed} key={this.state.toolboxCollapseUuid}>
                                        <div className={'vertical-padding-2 standard-padding-2'} style={{ paddingTop: 0 }}>
                                            <div style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                flexDirection: '',
                                                marginLeft: -3,
                                                marginRight: -3
                                            }}
                                            >
                                                {this.state.data.toolboxes}
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                :
                                null
                            }

                            {/* MODALS */}
                            {/* Info modal RA */}
                            <Modal
                                show={this.state.infoModalRA}
                                onHide={null}
                                backdrop={'static'}
                                keyboard={false}
                            >
                                <Modal.Header>
                                    <Modal.Title><CustomTranslation value={'KEY.Info'} /></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        <CustomTranslation value={'KEY.Tap on the desired completed risk analysis or risk analysis under construction to further edit it fit or download it in a risk analysis report.'} />
                                    </p>
                                    <p>
                                        <CustomTranslation value={'KEY.Finished risk analyzes can be downloaded via a pdf file as a full risk analysis or as a shortened instruction version.'} />
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        block
                                        variant={'dark'}
                                        onClick={() => {
                                            this.setState({
                                                infoModalRA: false
                                            });
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Close'} />
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Info modal VG */}
                            <Modal
                                show={this.state.infoModalVG}
                                onHide={null}
                                backdrop={'static'}
                                keyboard={false}
                            >
                                <Modal.Header>
                                    <Modal.Title><CustomTranslation value={'KEY.Info'} /></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        <CustomTranslation value={'KEY.Fill in or adjust all project data and company data before downloading a new version of the project H&S plan.'} />
                                    </p>
                                    <p>
                                        <CustomTranslation value={'KEY.All entered data is automatically included in the H&S plan that can be opened and further edited in Word.'} />
                                    </p>
                                    <p>
                                        <CustomTranslation value={'KEY.After you have completed the H&S plan in Word, you can download it again in PDF. This pdf version is visible to the "viewers" of your project.'} />
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        block
                                        variant={'dark'}
                                        onClick={() => {
                                            this.setState({
                                                infoModalVG: false
                                            });
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Close'} />
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Wait Modal */}
                            <Modal
                                show={this.state.waitModal}
                                onHide={null}
                                backdrop={'static'}
                                keyboard={false}
                            >
                                <Modal.Body>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div>
                                            <Spinner animation={'border'} variant={'primary'} />
                                        </div>
                                        <div>
                                            <CustomTranslation value={'KEY.Creating VG plan, please wait...'} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            {/* Accessibility modal */}
                            <CustomConfirm
                                show={this.state.accessibilityModal}
                                title={
                                    this.state.data.cs.country.countryCode.toUpperCase() === 'NL' ?
                                        <CustomTranslation value="KEY.Zijn de project locatievoorschriften actueel ingevuld?" /> :
                                        <CustomTranslation value="KEY.Project Accessibility has not been filled out yet." />
                                }
                                variant={'warning'}
                                body={
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div>
                                                <p style={{ margin: 0 }}>
                                                    <CustomTranslation value="KEY.No, first complete this under the project" />
                                                </p>
                                            </div>
                                            {!this.state.data.cs.hideEditButton && (this.state.data.licenses && this.state.data.licenses.length > 0) && ((this.state.data.userConstructionSiteRights && this.state.data.userConstructionSiteRights.managementRights)) &&
                                                <div style={{ marginLeft: 10 }}>
                                                    <div
                                                        className={'icon-btn-container bg-dark touchable'}
                                                        onClick={() => {
                                                            window.location.href = generatePath('/construction_site/:id/edit', { id: this.state.data.cs.id });
                                                        }}
                                                    >
                                                        <i className={'las la-pen icon-btn text-white'} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <p style={{ margin: 0 }}>
                                                <CustomTranslation value="KEY.Yes, continue" />
                                            </p>
                                        </div>
                                    </div>
                                }
                                cancelLabel={<CustomTranslation value={'KEY.Go back'} />}
                                onCancel={() => {
                                    this.setState({ accessibilityModal: false });
                                }}
                                confirmLabel={<CustomTranslation value={'KEY.Continue anyway'} />}
                                onConfirm={() => {
                                    if (this.state.accessibilityModal === 'send') {
                                        this.sendAccessibility();
                                    } else {
                                        this.downloadAccessibility();
                                    }
                                    this.setState({ accessibilityModal: false });
                                }}
                            />
                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <ConstructionSiteScreen {...props} params={params} />;
}