import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import {Breadcrumb, Button,} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import {Chart} from "react-google-charts";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import CustomCheckBox from "../Components/CustomCheckBox";
import CompanyAction from "../../Actions/CompanyAction";
import TranslationAction from "../../Actions/TranslationAction";
import ReportingItem from "./Components/ReportingItem";
import Uuid from "uuid/v1";
import CustomDate from "../Components/CustomDate";
import ReportingAction from "../../Actions/ReportingAction";
import UserAction from "../../Actions/UserAction";

class OverviewScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //default dates
        let from = new Date();
        from.setHours(0, 0, 0, 0);
        from.setFullYear(from.getFullYear() - 1);
        let until = new Date();
        until.setHours(23, 29, 59, 999);

        //set state
        this.state = {
            isLoaded: false,
            filter: {
                from: from.valueOf(),
                until: until.valueOf(),
            },
            companies: [],
            companiesAvailable: [],
            constructionSites: [],
            constructionSitesAvailable: [],
            users: [],
            finishedOptions: [],
            selectedFinishedOption: {label: null, value: null},
            table: null,
            singleCompany: false,
            resultCount: 0,
            current: true
        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update elements
    updateElements = async (current = null) => {

        if (current === null) {
            current = this.state.current;
        }

        //requests in one go
        let requests = [];
        requests.push(CompanyAction.getCompanies(true));
        requests.push(ConstructionSiteAction.getConstructionSites(current, true, false));
        requests.push(UserAction.getUserListAll());
        let results = await Promise.all(requests);

        //get companies
        let companyItems = [];
        results[0].forEach((element) => {
            companyItems.push({
                label: element.name,
                value: element.id
            });
        });

        //get construction sites
        let constructionSiteItems = [];
        results[1].forEach((element) => {
            constructionSiteItems.push({
                label: element.name,
                value: element.id,
                companyId: element.companyId
            });
        });

        //get users
        let userItems = [];
        results[2].forEach((element) => {
           userItems.push({
               label: (element.firstName && element.lastName) ? (element.firstName + ' ' + element.lastName) : element.email,
               value: element.id
           });
        });

        //get finsihed options
        let finishedOptions = [
          {
            label: await TranslationAction.translate('KEY.All'),
            value: 'null',
          },
          {
            label: await TranslationAction.translate('KEY.Finished'),
            value: true,
          },
          {
            label: await TranslationAction.translate('KEY.Unfinished'),
            value: false,
          }
        ];

        //set in state
        this.setState({
            companies: companyItems,
            constructionSites: constructionSiteItems,
            users: userItems,
            singleCompany: companyItems.length < 2,
            finishedOptions: finishedOptions,
            selectedFinishedOption: finishedOptions[0],
            current: current
        }, () => {
            this.filterConstructionSites();
            this.updateTable();
        });
    }

    //update table according to new filter
    updateTable = async () => {
        //get table data from reporting
        let result = await ReportingAction.getData(this.state.filter);

        //create table
        let table = await this.generateTable(result.table);
        
        //count rows
        let resultCount = result.table.rows.length;

        //set state
        this.setState({
            isLoaded: true,
            tableData: result.table,
            chartData: result.chart,
            table: table,
            resultCount: resultCount
        });
    }

    //update filter for data
    updateFilter = async (filterVal) => {
        let newFilter = {...this.state.filter, ...filterVal};
        this.setState({filter: newFilter}, () => {
            //when filter is changed, update table
            this.filterConstructionSites();
            this.updateTable();
        });
    }

    //filter construction sites
    filterConstructionSites = async () => {

        // filter out construction sites that don't belong to one of the selected companies
        let availableConstructionSites = [];
        if (this.state.filter.companies && this.state.filter.companies.length > 0) {
            this.state.constructionSites.forEach(cs => {
                if (this.state.filter.companies.includes(cs.companyId)) {
                    availableConstructionSites.push(cs);
                }
            });
        } else {
            availableConstructionSites = this.state.constructionSites;
        }

        // filter out companies that are not part of one of the selected construction sites
        let availableCompanies = [];
        if (this.state.filter.construction_sites && this.state.filter.construction_sites.length > 0) {
            this.state.companies.forEach(co => {
                //get values in construction site array that have ids that match with values in the construction sites filter
                let constructionSitesWithCompanyId = this.state.constructionSites.filter(a => this.state.filter.construction_sites.includes(a.value));

                //if the company id is found within the construction site array, include it
                if (constructionSitesWithCompanyId.map(a => a.companyId).includes(co.value)) {
                   availableCompanies.push(co);
                }
            });
        } else {
            availableCompanies = this.state.companies;
        }

        //set in state
        this.setState({
            constructionSitesAvailable: availableConstructionSites,
            companiesAvailable: availableCompanies
        });
    }

    //show selected item
    selectItem = async (slug) => {
        let selectedItem =
            <ReportingItem
                key={Uuid()}
                slug={slug}
            />;

        //set in state
        this.setState({selectedItem: selectedItem});
    }

    //download
    download = async () => {
        //call api
        await ReportingAction.downloadData(this.state.filter);
    }

    //generate table
    generateTable = async (tableData) => {
        //columns to be shown
        let columns = [1, 2, 3, 4, 5, 6];
        let sortColumn = 5;
        if (this.state.singleCompany) {
            columns = [2, 3, 4, 5, 6];
            sortColumn = 4;
        }

        //table object
        let table =
            <Chart
                chartType={'Table'}
                width={'100%'}
                options={{
                    page: 'enable',
                    pageSize: 20,
                    sortColumn: sortColumn,
                    sortAscending: false,
                    cssClassNames: {
                        hoverTableRow: 'touchable'
                    }
                }}
                chartWrapperParams={{ view: { columns: columns } }}
                chartEvents={[
                    {
                        eventName: 'select',
                        callback: ({chartWrapper}) => {
                            let chart = chartWrapper.getChart();
                            let selection = chart.getSelection();

                            //only if one value is selected
                            if (selection.length === 1) {
                                //get selected item and data table
                                let [selectedItem] = selection;
                                let dataTable = chartWrapper.getDataTable();
                                let { row, column } = selectedItem;

                                //show selected item
                                this.selectItem(dataTable.getValue(row, 0));
                            }
                        }
                    }
                ]}
                data={tableData}
                rootProps={{'data-chart': 'table'}}
            />;

        //return
        return table;
    }

    //render
    render = () => {
        if (this.state.isLoaded) {
            return (
                <CustomContainer extraWide>
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.Reporting - Tools'} />
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div>
                            <h1>
                                <CustomTranslation value={'KEY.Reporting - Tools'}/>
                            </h1>
                        </div>

                        {/* Content Info */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginLeft: '-3px',
                                marginRight: '-3px'
                            }}
                        >
                            <div
                                className={'standard-padding-3'}
                                style={{
                                    flex: 2
                                }}
                            >
                                {this.state.chartData &&
                                    <div
                                        className={'bordered rounded standard-padding vertical-padding'}
                                        style={{
                                            height: '15rem'
                                        }}
                                    >
                                        {/* column chart */}
                                        <Chart
                                            chartType={'ColumnChart'}
                                            width={'100%'}
                                            height={'100%'}
                                            options={{
                                                isStacked: true,
                                                legend: {
                                                    position: 'bottom'
                                                },
                                                hAxis: {
                                                    format: 'MM/yyyy'
                                                },
                                                chartArea: {
                                                    top: 10,
                                                    width: '90%',
                                                    bottom: 40
                                                }
                                            }}
                                            data={this.state.chartData}
                                            rootProps={{'data-chart': 'column'}}
                                        />
                                    </div>
                                }
                            </div>
                            {/* display info */}
                            <div
                                className={'standard-padding-3'}
                                style={{
                                    flex: 1
                                }}
                            >
                                <div
                                    className={'rounded bg-light standard-padding vertical-padding'}
                                    style={{height: '100%'}}
                                >
                                    {this.state.selectedItem ?
                                        this.state.selectedItem :
                                        <div
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <h4><CustomTranslation value={'KEY.Select item for more information.'} /></h4>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        {/* Content Table */}
                        <div
                            className={'vertical-margin-2'}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginLeft: '-3px',
                                marginRight: '-3px'
                            }}
                        >
                            {/* Filter */}
                            <div
                                className={'standard-padding-3'}
                                style={{
                                    width: 300
                                }}
                            >
                                <div
                                    className={'bordered rounded standard-padding vertical-padding'}
                                >
                                    <h4><CustomTranslation value={'KEY.Filter'} /></h4>
                                    {!this.state.singleCompany ?
                                        <div className={'vertical-margin-2'}>
                                            <CustomMultiSelect
                                                iconName={'la la-building'}
                                                label={<CustomTranslation value={'KEY.Select company'}/>}
                                                items={this.state.companiesAvailable}
                                                onSelectedItemsChange={(value) => {
                                                    this.updateFilter({companies: value});
                                                }}
                                                value={this.state.filter.companies}
                                            />
                                        </div> : null
                                    }
                                    <div className={'vertical-margin-2'}>
                                        <CustomMultiSelect
                                            iconName={'la la-industry'}
                                            label={<CustomTranslation value={'KEY.Select construction site'} />}
                                            items={this.state.constructionSitesAvailable}
                                            onSelectedItemsChange={(value) => {
                                                this.updateFilter({construction_sites:  value});
                                            }}
                                            value={this.state.filter.construction_sites}
                                        />
                                        <div style={{ marginTop: '0.5em' }}>
                                            <CustomCheckBox
                                                label={<CustomTranslation value={'KEY.Only active'} />}
                                                value={this.state.current === null ? true : this.state}
                                                onValueChange={(value) => {
                                                    this.updateElements(value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'vertical-margin-2'}>
                                        <CustomMultiSelect
                                            iconName={'la la-filter'}
                                            label={<CustomTranslation value={'KEY.Select type'} />}
                                            items={[
                                                {
                                                    label: <CustomTranslation value={'KEY.Observation'} />,
                                                    value: 'observation'
                                                },
                                                {
                                                    label: <CustomTranslation value={'KEY.Positive Remark'} />,
                                                    value: 'positive_remark'
                                                },
                                                {
                                                    label: <CustomTranslation value={'KEY.Last Minute Risk Analysis'} />,
                                                    value: 'last_minute_risk_analysis'
                                                },
                                                {
                                                    label: <CustomTranslation value={'KEY.Inspection Checklist'} />,
                                                    value: 'inspection_checklist'
                                                },
                                                {
                                                    label: <CustomTranslation value={'KEY.Risk analysis'} />,
                                                    value: 'task_risk_analysis'
                                                },
                                                {
                                                    label: <CustomTranslation value={'KEY.Toolbox'} />,
                                                    value: 'toolbox'
                                                }
                                            ]}
                                            onSelectedItemsChange={(value) => {
                                                this.updateFilter({types:  value});
                                            }}
                                            value={this.state.filter.types}
                                        />
                                    </div>
                                    <div className={'vertical-margin-2'}>
                                        <CustomMultiSelect
                                            iconName={'la la-user'}
                                            label={<CustomTranslation value={'KEY.Select user'} />}
                                            items={this.state.users}
                                            onSelectedItemsChange={(value) => {
                                                this.updateFilter({users:  value});
                                            }}
                                            value={this.state.filter.users}
                                        />
                                    </div>
                                    {/*<div className={'vertical-margin-2'}>
                                        <CustomSelect
                                            iconName={'la la-check-circle'}
                                            label={<CustomTranslation value={'KEY.Finished'} />}
                                            options={this.state.finishedOptions}
                                            onSelect={(value, label) => {
                                                this.updateFilter({finished: value});
                                                this.setState({selectedFinishedOption: {label: label, value: value}});
                                            }}
                                            selected={this.state.selectedFinishedOption}
                                        />
                                        </div>*/}
                                    <div className={'vertical-margin-2'}>
                                        <CustomDate
                                            iconName={'la la-calendar'}
                                            label={<CustomTranslation value={'KEY.From'} />}
                                            value={new Date(this.state.filter.from)}
                                            onChange={(value) => {
                                                value.setHours(0, 0, 0, 0);
                                                this.updateFilter({from: value.valueOf()})
                                            }}
                                            noMinDate
                                        />
                                    </div>
                                    <div className={'vertical-margin-2'}>
                                        <CustomDate
                                            iconName={'la la-calendar'}
                                            label={<CustomTranslation value={'KEY.Until'} />}
                                            value={new Date(this.state.filter.until)}
                                            onChange={(value) => {
                                                value.setHours(23, 29, 59, 999);
                                                this.updateFilter({until: value.valueOf()})
                                            }}
                                            noMinDate
                                        />
                                    </div>
                                    <div className={'vertical-margin-2'}>
                                        <Button block variant={'dark'}
                                                onClick={() => this.download()}
                                        >
                                            <i className={'las la-file-excel'} />
                                            <CustomTranslation value={'KEY.Download'} />
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            {/* Table */}
                            <div
                                className={'standard-padding-3'}
                                style={{
                                    flexGrow: 1,
                                    flexShrink: 1
                                }}
                            >
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                    <small><CustomTranslation value={'KEY.Results:'} />&nbsp;{ this.state.resultCount }</small>
                                </div>
                                {this.state.table}
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer extraWide>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <OverviewScreen {...props} params={params} />;
}
