import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import Loader from "../Components/Loader";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import Project from "../Home/Components/Project";
import {Breadcrumb} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import Uuid from "uuid/v1";

class SOSScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            firstAidKits: []
        };
    }

    //on mount
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update elements
    updateElements = async () => {
        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //set first aid kits
        let firstAidKitElements = [];
        cs.firstAidKits.forEach((element) => {
            firstAidKitElements.push(
                <div
                    key={Uuid()}
                    className={'bg-light rounded standard-padding vertical-padding vertical-margin-2'}
                    style={{display: 'flex', flexDirection: 'row'}}
                >
                    <div>
                        <div className={'icon-container bg-primary'}>
                            <i className={'las la-first-aid icon text-white'} />
                        </div>
                    </div>
                    <div className={'standard-padding-2'} style={{paddingRight: 0, flexGrow: 1, flexShrink: 1}}>
                        <h3>{element.location}</h3>
                        <h5 className={'unbold'}>{element.description}</h5>
                    </div>
                </div>
            );
        });

        //set in state
        this.setState({
            data: {
                constructionSite: cs
            },
            firstAidKits: firstAidKitElements
        });
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer>
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{position: 'sticky', top: '2rem', zIndex: 9}}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>

                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.SOS'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Title */}
                            <div className={'vertical-margin'}>
                                <h1>
                                    <CustomTranslation value={'KEY.SOS'}/>
                                </h1>
                            </div>

                            {/* Emergency number */}
                            {this.state.data.constructionSite.phoneEmergency &&
                                <div className={'vertical-margin-2'}>
                                    <div
                                        className={'bg-light rounded standard-padding vertical-padding'}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <div className={'icon-container bg-primary'}>
                                            <i className={'las la-phone icon text-white'} />
                                        </div>
                                        <div
                                            className={'standard-padding-2'}
                                            style={{
                                                flexGrow: 1,
                                                flexShrink: 1
                                            }}
                                        >
                                            <h3><CustomTranslation value={'KEY.Emergency number project'} /></h3>
                                            <h5 className={'unbold'}>{this.state.data.constructionSite.phoneEmergency}</h5>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div
                                                className={'icon-btn-container bg-dark touchable'}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    window.open('tel:' + this.state.data.constructionSite.phoneEmergency, '_self');
                                                }}
                                            >
                                                <i className={'las la-phone icon-btn text-white'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* First aid */}
                            {(this.state.firstAidKits.length > 0) ?
                                this.state.firstAidKits
                                :
                                <div
                                    key={Uuid()}
                                    className={'bg-light rounded standard-padding vertical-padding vertical-margin-2'}
                                    style={{display: 'flex', flexDirection: 'row'}}
                                >
                                    <div>
                                        <div className={'icon-container bg-primary'}>
                                            <i className={'las la-first-aid icon text-white'} />
                                        </div>
                                    </div>
                                    <div className={'standard-padding-2'} style={{paddingRight: 0, flexGrow: 1, flexShrink: 1}}>
                                        <h3><CustomTranslation value={'KEY.No First aid kits for this project.'} /></h3>
                                        <h5 className={'unbold'}><CustomTranslation value={'KEY.Contact your supervisor.'} /></h5>
                                    </div>
                                </div>
                            }

                            {/* Hospital */}
                            {(this.state.data.constructionSite.hospital) ?
                                <div
                                    key={Uuid()}
                                    className={'bg-light rounded standard-padding vertical-padding vertical-margin-2'}
                                    style={{display: 'flex', flexDirection: 'row'}}
                                >
                                    <div>
                                        <div className={'icon-container bg-primary'}>
                                            <i className={'las la-hospital icon text-white'} />
                                        </div>
                                    </div>
                                    <div className={'standard-padding-2'} style={{flexGrow: 1, flexShrink: 1}}>
                                        <h3>{this.state.data.constructionSite.hospital.name}</h3>
                                        <h5 className={'unbold'}>{this.state.data.constructionSite.hospital.street} {this.state.data.constructionSite.hospital.number}, {this.state.data.constructionSite.hospital.zip} {this.state.data.constructionSite.hospital.city}</h5>
                                    </div>
                                    <div>
                                        {(!!this.state.data.constructionSite.hospital.phone && (this.state.data.constructionSite.hospital.phone !== "")) &&
                                            <div className={'icon-btn-container dark-bg touchable'}
                                                 onClick={(event) => {
                                                     window.open('tel:' + this.state.data.constructionSite.hospital.phone, '_self');
                                                 }}
                                            >
                                                <i className={'las la-phone text-white'} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div
                                    key={Uuid()}
                                    className={'bg-light rounded standard-padding vertical-padding vertical-margin-2'}
                                    style={{display: 'flex', flexDirection: 'row'}}
                                >
                                    <div>
                                        <div className={'icon-container bg-primary'}>
                                            <i className={'las la-hospital icon text-white'} />
                                        </div>
                                    </div>
                                    <div className={'standard-padding-2'} style={{paddingRight: 0, flexGrow: 1, flexShrink: 1}}>
                                        <h3><CustomTranslation value={'KEY.No hospital for this project.'} /></h3>
                                        <h5 className={'unbold'}><CustomTranslation value={'KEY.Contact your supervisor.'} /></h5>
                                    </div>
                                </div>
                            }

                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <SOSScreen {...props} params={params} />;
}