import React from "react";
import {generatePath, useParams} from "react-router-dom";
import CustomContainer from "../Components/CustomContainer";
import Loader from "../Components/Loader";
import VGPlanAction from "../../Actions/VGPlanAction";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import Project from "../Home/Components/Project";
import {Breadcrumb, Button} from "react-bootstrap";
import CustomTranslation from "../Components/CustomTranslation";
import VGPlanDocument from "./Components/VGPlanDocument";
import CustomFileInput from "../Components/CustomFileInput";
import {toast} from "react-hot-toast";
import Uuid from "uuid/v1";
import CustomConfirm from "../Components/CustomConfirm";
import G from "../../Actions/GeneralAction";

class VGPlanScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            showDelete: false
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {
        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);
        //get vg plan
        let vgPlan = await VGPlanAction.get(this.props.params.vg_plan_id);

        //set uploaded VG Plans
        let uploadedVGs = [];
        vgPlan.uploadedVGPlans.forEach((element, index) => {
            uploadedVGs.push(
                <div className={'vertical-padding-3'}>
                    <VGPlanDocument
                        key={Uuid()}
                        className={'bg-white'}
                        data={element}
                        vgPlan={vgPlan}
                        uploaded={true}
                        reload={this.updateElements}
                    />
                </div>
            );
        });

        //set state
        this.setState({
            data: {
                constructionSite: cs,
                vgPlan: vgPlan
            },
            uploadedVGs: uploadedVGs
        });
    }

    //create VG Plan
    createVGPlan = async (uploadedFile) => {

        //request
        let result = await VGPlanAction.addUpload(this.state.data.vgPlan.id, uploadedFile.id);

        //Show toast
        toast.success(<CustomTranslation value={'KEY.V&G plan uploaded!'} />);

        //update
        await this.updateElements();
    }

    //send delete call
    deletePlan = async () => {

        //make call
        let result = await VGPlanAction.delete(this.state.data.vgPlan.id);

        //navigate
        window.location.href = generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id});
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer>
                    <div>
                        {/* Project header */}
                        <div style={{position: 'sticky', top: '2rem', zIndex: 9}}>
                            <Project
                                float
                                data={this.state.data.constructionSite}
                            />
                        </div>

                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                            >
                                {this.state.data.constructionSite.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.VG Plan'} />
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div className={'vertical-margin'}>
                            <h1>
                                <CustomTranslation value={'KEY.VG Plan'}/>
                            </h1>
                        </div>

                        {/* Generated VG Plan */}
                        <div className={'vertical-margin-2'}>
                            <div
                                className={'rounded bg-light vertical-padding-2 standard-padding-2'}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <i style={{fontSize: '1.2rem'}} className={'las la-file-word icon'}/>
                                    <div style={{flexGrow: 1, flexShrink: 1}} className={'standard-padding-2'}>
                                        <h4 style={{marginBottom: 0}} >
                                            <CustomTranslation value={'KEY.Generated VG Plan'}/>
                                        </h4>
                                    </div>
                                </div>
                                {/* Plan */}
                                <div>
                                    <VGPlanDocument
                                        className={'bg-white vertical-margin-3'}
                                        data={this.state.data.vgPlan.generatedVGPlan}
                                        vgPlan={this.state.data.vgPlan}
                                        uploaded={false}
                                        reload={this.updateElements}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Uploaded VG Plan */}
                        <div className={'vertical-margin-2'}>
                            <div
                                className={'rounded bg-light vertical-padding-2 standard-padding-2'}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <i style={{fontSize: '1.2rem'}} className={'las la-file-pdf icon'}/>
                                    <div style={{flexGrow: 1, flexShrink: 1}} className={'standard-padding-2'}>
                                        <h4 style={{marginBottom: 0}} >
                                            <CustomTranslation value={'KEY.Uploaded VG Plans'}/>
                                        </h4>
                                    </div>
                                </div>
                                {/* Plan */}
                                <div>
                                    {this.state.uploadedVGs}
                                    {/* Upload button */}
                                    <CustomFileInput
                                        className={'vertical-margin-3'}
                                        style={{marginBottom: 0}}
                                        btnLabel={
                                            <CustomTranslation value={'KEY.Upload'} />
                                        }
                                        mimeType={'application/pdf'}
                                        onUpload={(file) => {
                                            this.createVGPlan(file);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Archive */}
                        <div
                            className={'vertical-margin-2'}
                        >
                            <Button
                                variant={'danger'}
                                block
                                onClick={() => {
                                    this.setState({showDelete: true});
                                }}
                            >
                                <CustomTranslation value={'KEY.Remove'} />
                            </Button>
                        </div>

                        {/* Delete modal */}
                        <CustomConfirm
                            show={this.state.showDelete}
                            variant={'danger'}
                            title={<CustomTranslation value={'KEY.Remove'} />}
                            body={
                                <p style={{wordBreak: 'break-word'}}>
                                    <CustomTranslation value={'KEY.Do you wish to delete the generated draft H&S plan with date:'} /><br />
                                    "{G.formatDateTime(this.state.data.vgPlan.createdAt)}"<br />
                                    <CustomTranslation value={'KEY.and the uploaded PDF version?'} />
                                </p>
                            }
                            cancelLabel={<CustomTranslation value={'KEY.Cancel'}/>}
                            onCancel={() => {
                                this.setState({showDelete: false});
                            }}
                            confirmLabel={<CustomTranslation value={'KEY.Remove'}/>}
                            onConfirm={() => {
                                this.deletePlan();
                            }}
                        />
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <VGPlanScreen {...props} params={params} />;
}