import React from 'react';
import firebase from './Firebase';
import { getAnalytics, logEvent } from 'firebase/analytics';

class AnalyticsLog extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init firebase analytics
        this.firebaseAnalytics = getAnalytics(firebase);
    }

    componentDidMount = async () => {
        //log
        logEvent(this.firebaseAnalytics, 'screen_view', {'firebase_screen': this.props.screen});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //log
        logEvent(this.firebaseAnalytics, 'screen_view', {'firebase_screen': this.props.screen});
    }

    render = () => {
        return null;
    }
}

//export
export default AnalyticsLog;