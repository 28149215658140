import React from "react";
import {Form} from "react-bootstrap";
import Uuid from "uuid/v1";

class CustomCheckBox extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            value: this.props.value || false
        }
    }

    render = () => {

        //set variant
        let variant = 'primary';
        if (this.props.variant) {
            variant = this.props.variant;
        }

        //if disabled
        let disabled = false;
        if (
            ('disabled' in this.props) &&
            this.props.disabled
        ) {
            variant = 'dark';
            disabled = true;
        }

        return (
            <div
                className={this.props.className}
                style={this.props.style}
            >
                <div className={'bordered rounded touchable'}>
                    <Form.Group controlId={'form_' + Uuid()} style={{margin: 0}}>
                        <Form.Check inline type={'checkbox'} className={'checkbox-container'} style={{width: '100%', margin: 0}}>
                            <Form.Check.Label style={{width: '100%', display: 'flex', flexDirection: 'row'}} className={'standard-padding-2 vertical-padding-2'}>
                                <p style={{flexGrow: 1, flexShrink: 1}}>{this.props.label}</p>
                                <Form.Check.Input type={'checkbox'} className={'bg-' + variant}
                                                  disabled={disabled}
                                                  onChange={(event) => {
                                                      if (!disabled) {
                                                          if (typeof this.props.onValueChange === 'function') {
                                                              this.props.onValueChange(event.target.checked);
                                                          }
                                                          this.setState({value: event.target.checked});
                                                      }
                                                  }}
                                                  checked={this.state.value}
                                />
                                <span className={'checkmark-' + variant + ' bordered rounded'} />
                            </Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

//export
export default CustomCheckBox;