//imports
import APIAction from './APIAction';
import ConstructionSiteAction from './ConstructionSiteAction';
import CompanyAction from './CompanyAction';
import TokenRepository from '../Database/Repositories/TokenRepository';
import T from './TranslationAction';
import {toast} from 'react-hot-toast';
import CustomTranslation from "../Views/Components/CustomTranslation";
import {ClientJS} from 'clientjs';

//all different login actions
const UserAction = {

    /**
     * when login form is submitted
     *
     * @param {*} input
     */
    loginSubmit: async function(input) {

        //get email
        let email = input.email;
        //get password
        let password = input.password;

        //set translation message
        T.init();
        let toastMessage = await T.translate('KEY.Wrong credentials.');

        //do checks
        if (typeof email === 'string' && typeof password === 'string') {

            if (password !== '' && email.trim() !== '') {

                //construct body
                let body = {
                    email: email,
                    password: password,
                };

                try {
                    if (
                        (typeof localStorage.getItem('firebaseToken') === 'string')
                    ) {
                        let client = new ClientJS();
                        body['reference'] = client.getBrowser() + ' ' + client.getFingerprint();
                        body['firebaseToken'] = localStorage.getItem('firebaseToken');
                        body['os'] = 'web';
                    }
                } catch (err) {
                    console.log(err);
                }

                //get result
                let fetchResult = await APIAction.request('/login_check', 'POST', false, body);

                //check if token is included
                if (typeof fetchResult.token === 'string') {

                    //create repository
                    let tokenRepository = new TokenRepository();

                    //save
                    await tokenRepository.save(fetchResult.token);

                    //reset navigation
                    window.location = '/';

                } else {
                    //if not included show error message
                    toast.error(toastMessage);
                }

            } else {
                //show toast with message
                toast.error(toastMessage);
            }
        } else {
            //show toast with message
            toast.error(toastMessage);
        }

    },

    /**
     * Performs logout action
     */
    logoutSubmit: async function() {

        //create repository
        let tokenRepository = new TokenRepository();

        //empty collections
        await tokenRepository.empty();
    },

    /**
     * Request new verification code for changing password (Anonymous)
     *
     * @param {*} input
     */
    requestNewPasswordCode: async function(input) {

        //set result
        let result = false;

        //get email
        let email = input.email;

        //send request
        let verificationToken = await APIAction.request('/anonymous/change_password_request', 'PATCH', false, { email: email }, true, false);

        //check if valid response
        if (typeof verificationToken.email === 'string') {
            result = true;
        }

        //set toast if needed
        if (!result) {
            toast.error(<CustomTranslation value={'KEY.User not found.'} />);
        }

        //return
        return result;
    },

    /**
     * Request new password (Anonymous)
     *
     * @param {*} input
     */
    requestNewPassword: async function(input) {

        //set data
        let data = {
            email: input.email,
            plainPassword: input.password
        };

        //get code
        let code = input.code;

        //if first name
        if (input.firstName && input.lastName) {
            data['firstName'] = input.firstName;
            data['lastName'] = input.lastName;
        }

        //request new password
        let result = await APIAction.request('/anonymous/change_password/' + code, 'PATCH', false, data, true, false);

        //check result
        if ((typeof result === 'undefined') || (typeof result === 'object' && result['@type'] === 'hydra:Error')) {
            //show toast of failure
            toast.error(<CustomTranslation value={'KEY.Wrong credentials.'} />);
        } else {
            //if OK, log in
            await this.loginSubmit(input);
        }
    },

    /**
     * Change password (Authenticated)
     *
     * @param {*} formData
     */
    requestChangePassword: async function(formData) {

        //change password
        let result = await APIAction.request('/change_password', 'PATCH', true, formData);

        //check result
        if ((typeof result === 'undefined') || (result.length > 0)) {
            //show toast of failure
            toast.error(<CustomTranslation value={'KEY.Connection failed.'} />);
        } else {
            //navigate to correct screen
            toast.success(<CustomTranslation value={'KEY.Password changed!'} />);
        }
    },

    /**
     * When the registration form gets submitted
     *
     * @param {*} input
     */
    registerSubmit: async function(input) {

        //get vars
        let firstName = input.firstName;
        let lastName = input.lastName;
        let email = input.email;
        let phone = input.phone;
        let password = input.password;

        //request to register user
        let result = await APIAction.request('/anonymous/register', 'POST', false,
            {
                email: email,
                plainPassword: password,
                firstName: firstName,
                lastName: lastName,
                mobile: phone
            }, true, false);

        //check result
        if ((typeof result === 'undefined') || (typeof result === 'object' && result['@type'] === 'hydra:Error')) {
            //show toast of failure
            toast.error(<CustomTranslation value={'KEY.This email cannot be registered. Please contact helpdesk@riskminimizer.eu'} />);
        } else {
            //if OK, log in
            await this.loginSubmit(input);
        }
    },

    /**
     * Get current user
     */
    getCurrentUser: async function() {

        //call
        let apiUser = await APIAction.request('/current/user', 'GET', true);

        //return api user
        return apiUser;
    },

    /**
     * Edit user based on input
     */
    editUser: async function(id, data) {

        //call api
        let result = await APIAction.request('/users/' + id, 'PATCH', true, data);

        //check if result is valid
        if (result && result.id) {
            //navigate to correct screen
            toast.success(<CustomTranslation value={'KEY.Personal Info saved.'} />);
        }
    },

    getUserRightByCompanyId: async function(companyId){

        //init
        let right = null;
        let user = await this.getCurrentUser();
        let company = await CompanyAction.getCompany(companyId); // Acts as extra validation

        //retrieve rights
        if(company){
            let userCompanyRights = user.userCompanyRights;

            userCompanyRights.forEach((userCompanyRight) => {
                if(userCompanyRight.company['@id'] == company['@id']){
                    right = userCompanyRight;
                }
            });
        }

        //response
        return right;
    },

    getUserRightByConstructionSiteId: async function(constructionSiteId) {

        //init
        let right = null;
        let user = await this.getCurrentUser(); // Force yes, since we dont save the rights in Realm
        let constructionSite = await ConstructionSiteAction.getConstructionSite(constructionSiteId, false); // Acts as extra validation

        //retrieve rights
        if(constructionSite){
            let userConstructionSiteRights = user.userConstructionSiteRights;

            userConstructionSiteRights.forEach((userConstructionSiteRight) => {
                if(userConstructionSiteRight.constructionSite == constructionSite['@id']){
                    right = userConstructionSiteRight;
                }
            });
        }

        //response
        return right;
    },

    getUserListConstructionSite: async function(constructionSiteId, excludeCurrent = false) {

        //call
        let result = await APIAction.request('/users', 'GET', true, {
            construction_site_id: constructionSiteId,
            exclude_current_user: excludeCurrent
        });

        //return
        return result;
    },

    getUserListCompany: async function(companyId, excludeCurrent = false) {

        //call
        let result = await APIAction.request('/users', 'GET', true, {
            company_id: companyId,
            exclude_current_user: excludeCurrent
        });

        //return
        return result;
    },

    getUserListAll: async function(excludeCurrent = false) {

        //call
        let result = await APIAction.request('/users', 'GET', true, {
           all: true,
           exclude_current_user: excludeCurrent
        });

        //return
        return result;
    }
};

//export
export default UserAction;