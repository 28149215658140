import React from "react";
import CustomInput from "./CustomInput";
import Uuid from "uuid/v1";

class CustomListInput extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set interval
        this.waitInterval = null;

        //set input list values
        this.inputList = (this.props.value && Array.isArray(this.props.value)) ? this.props.value : [''];
        this.inputRef = [];
        if (this.inputList.length < 1) {
            this.inputList = [''];
        }

        //set state
        this.state = {
            inputRedraw: Uuid(),
            rerender: Uuid(),
            invalid: []
        };
    }

    //check if valid
    isValid = () => {
        //if unique
        let newInvalid = [];
        if (this.props.unique === true) {
            for (let i = 0; i < this.inputList.length; i++) {
                for (let j = 0; j < this.inputList.length; j++) {
                    if (i !== j && this.inputList[i] === this.inputList[j]) {
                        newInvalid.push(i);
                    }
                }
            }
        }
        //set state
        this.setState({ invalid: newInvalid });
    }

    //if text is changed run this
    handleInputChange = (text, index) => {
        //clear interval
        clearInterval(this.waitInterval);

        //add/update to input list
        this.inputList[index] = text;

        //set timeout
        this.waitInterval = setInterval(() => {

            //update values
            this.props.onChangeText(this.inputList);

            //check if valid
            this.isValid();

            clearInterval(this.waitInterval);
        }, 500);
    }

    //add field
    handleAddClick = async () => {
        //new field
        this.inputList.push('');

        //update values
        this.props.onChangeText(this.inputList);

        //set state
        this.setState({
            rerender: Uuid()
        });
    }

    // add field
    handleRemoveClick = async (index) => {
        //remove
        this.inputList.splice(index, 1);
        this.inputRef.splice(index, 1);

        //update values
        this.props.onChangeText(this.inputList);

        //check if valid
        this.isValid();

        //set state
        this.setState({
            rerender: Uuid(),
            inputRedraw: Uuid()
        });
    }

    // handle enter
    handleEnter = (event) => {
        if (event.key === 'Enter') {

            let target = event.target;
            let index = this.inputRef.indexOf(target);

            //if last input, add field
            if (index === this.inputRef.length - 1) {
                this.handleAddClick();
            }

            //focus next input
            if (index < this.inputRef.length - 1) {
                this.inputRef[index + 1].focus();
            }
        }
    }

    //render view
    render = () => {
        return (
            <div className={this.props.className} style={this.props.style}>
                <div>
                    {/* Label */}
                    <div style={{
                        display: 'flex',
                        flex: 'row'
                    }}>
                        {this.props.iconName &&
                            <div
                                className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                            >
                                <i className={this.props.iconName + ' icon'} />
                            </div>
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexGrow: 1,
                            flexShrink: 1
                        }}>
                            <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')}
                                style={{ paddingBottom: 0 }}>{this.props.label}{this.props.labelAppend}</span>
                        </div>
                        <div>
                            <span
                                className={'icon-container bg-success touchable'}
                                onClick={(event) => {
                                    this.handleAddClick();
                                }}
                            >
                                <i className={'las la-plus icon text-white'} />
                            </span>
                        </div>
                    </div>
                    {/* Content */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            className={'bordered-left standard-margin-2'}
                            style={{ marginLeft: 0 }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                        >
                            {this.inputList.map((x, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={'vertical-margin-3'}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <div
                                                style={{
                                                    flexGrow: 1,
                                                    flexShrink: 1
                                                }}
                                            >
                                                <CustomInput
                                                    inputProps={{
                                                        key: this.state.inputRedraw + '_' + i,
                                                        ref: (inputRef) => {
                                                            if (null !== inputRef) {
                                                                inputRef.removeEventListener('keydown', this.handleEnter);
                                                                inputRef.addEventListener('keydown', this.handleEnter);
                                                                this.inputRef[i] = inputRef
                                                            }
                                                        },
                                                        isInvalid: this.state.invalid.includes(i)
                                                    }}
                                                    noLabel
                                                    value={x}
                                                    onChangeText={(value) => {
                                                        this.handleInputChange(value, i)
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <span
                                                    className={'icon-container bg-danger touchable standard-margin-2'}
                                                    style={{ marginRight: 0 }}
                                                    onClick={(event) => {
                                                        this.handleRemoveClick(i);
                                                    }}
                                                >
                                                    <i className={'las la-minus icon text-white'} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default CustomListInput;