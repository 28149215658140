import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomInfoButton from "../../Components/CustomInfoButton";
import {Button, Modal} from "react-bootstrap";
import RiskList from "./Components/RiskList";
import RiskAnalysisAction from "../../../Actions/RiskAnalysisAction";
import Loader from "../../Components/Loader";

class RiskAnalysisPreferencesTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            infoModalRa: false
        };
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        //get templates
        let templates = await RiskAnalysisAction.getTemplates(this.props.defaultCompany.id);

        //check if template exists, if not create
        let template = null;
        if (templates.length > 0) {
            template = templates[0];
        } else {
            template = await RiskAnalysisAction.createTemplate(this.props.defaultCompany.id);
        }

        //set template
        this.setState({
            template: template
        });
    }

    //render
    render = () => {
        if (this.state.template) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start'
                            }}
                        >
                            <h2 className={'text-left'}><CustomTranslation value={'KEY.Risk Analysis Template'}/></h2>
                            <CustomInfoButton
                                onClick={() => this.setState({infoModalRA: true})}
                            />
                        </div>
                    </div>
                    {/* Content */}
                    <div className={'vertical-margin-2'}>
                        <RiskList
                            template={this.state.template}
                        />
                    </div>
                    {/* MODALS */}
                    {/* Info modal */}
                    <Modal
                        show={this.state.infoModalRA}
                        onHide={null}
                        backdrop={'static'}
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title><CustomTranslation value={'KEY.Info'}/></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <CustomTranslation
                                    value={'KEY.Via this matrix you can uncheck topics that are not relevant at different topic level for your company.'}/>
                            </p>
                            <p>
                                <CustomTranslation
                                    value={'KEY.These topics are then not shown as options when building your risk analyzes.'}/>
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                block
                                variant={'dark'}
                                onClick={() => {
                                    this.setState({
                                        infoModalRA: false
                                    });
                                }}
                            >
                                <CustomTranslation value={'KEY.Close'}/>
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        } else {
            return <Loader />;
        }
    }
}

//export
export default RiskAnalysisPreferencesTab;