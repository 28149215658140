import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomImageInput from "../../Components/CustomImageInput";
import CustomInput from "../../Components/CustomInput";
import {Button} from "react-bootstrap";
import CountryAction from "../../../Actions/CountryAction";
import T from "../../../Actions/TranslationAction";
import Loader from "../../Components/Loader";
import CustomSelect from "../../Components/CustomSelect";
import CustomCheckBox from "../../Components/CustomCheckBox";
import UserAction from "../../../Actions/UserAction";
import LicenseAction from "../../../Actions/LicenseAction";
import CompanyAction from "../../../Actions/CompanyAction";
import Uuid from "uuid/v1";

class CompanyPreferencesTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            countryUuid: Uuid()
        };
    }

    //on initial
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        //get countries
        let countries = await CountryAction.getCountries(this.props.defaultCompany.id);
        let currentCountry = null;
        let countryItems = [];
        for (let [key, element] of Object.entries(countries)) {
            countryItems.push({'label': await T.translateArray(element.name), 'value': element['@id'], 'code': element.countryCode });

            if(this.props.defaultCompany.country && this.props.defaultCompany.country.id === element.id){
                currentCountry = {'label': await T.translateArray(element.name), 'value': element['@id'], 'code': element.countryCode};
            }
        }

        //get rights
        let userCompanyRights = await UserAction.getUserRightByCompanyId(this.props.defaultCompany.id);

        //get licenses
        let licenses = await LicenseAction.getLicensesByUserAndCompany(this.props.defaultCompany.id);

        //check licenses
        if (licenses.length === 0) {
            userCompanyRights.adminRights = false;
            userCompanyRights.managementRights = false;
        }

        //set state
        this.setState({
            userCompanyRights: userCompanyRights,
            licenses: licenses,
            name: this.props.defaultCompany.name,
            city: this.props.defaultCompany.city,
            street: this.props.defaultCompany.street,
            number: this.props.defaultCompany.number,
            zip: this.props.defaultCompany.zip,
            countryId: this.props.defaultCompany.country ? this.props.defaultCompany.country['@id'] : null,
            countryItems: countryItems,
            currentCountry: currentCountry,
            imageId: this.props.defaultCompany.logo ? this.props.defaultCompany.logo.id : null,
            imageApiId: this.props.defaultCompany.logo ? this.props.defaultCompany.logo['@id'] : null,
            mobile: this.props.defaultCompany.mobile,
            vat: this.props.defaultCompany.vat,
            nsso: this.props.defaultCompany.nsso,
            adminShowAllAssignments: this.props.defaultCompany.adminShowAllAssignments
        });
    }

    //send update to api
    updateCompanyForm = async () => {
        // Update user
        let formData = {
            name: this.state.name,
            city: this.state.city,
            street: this.state.street,
            number: this.state.number,
            zip: this.state.zip,
            logo: this.state.imageApiId,
            country: this.state.countryId,
            mobile: this.state.mobile,
            vat: this.state.vat,
            nsso: this.state.nsso,
            adminShowAllAssignments: this.state.adminShowAllAssignments
        };

        let response = await CompanyAction.editCompany(this.props.defaultCompany.id, formData);
    }

    //handle image upload
    handleUpload = async (file) => {
        this.setState(
            {
                imageId: file.id,
                imageApiId: file['@id']
            }
        );
    }

    //change country
    countryChange = async (label, value = null) => {

        //get country
        let currentCountry = this.state.countryItems.find(obj => {
            return obj.value === value;
        });

        //set new sate
        this.setState({
            'currentCountry': currentCountry,
            'countryId': value,
            'countryUuid': Uuid()
        });
    }

    //render
    render = () => {

        //if loaded
        if (Object.keys(this.state).length > 1) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title 1 */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Company Info'}/></h2>
                    </div>
                    {/* Form 1 */}
                    <div className={'vertical-margin-2'}>
                        <CustomImageInput
                            iconName={'las la-image'}
                            label={<CustomTranslation value={'KEY.Upload company logo'}/>}
                            disabled={!this.state.userCompanyRights.adminRights}
                            onUpload={this.handleUpload}
                            imageId={this.state.imageId ? this.state.imageId : null}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-quote-left'}
                            label={<CustomTranslation value={'KEY.Name'}/>}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.name}
                            onChangeText={(text) => this.setState({name: text})}
                        />
                    </div>
                    <div
                        className={'vertical-margin-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '-3px',
                            marginRight: '-3px'
                        }}
                    >
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                            iconName={'las la-home'}
                            label={<CustomTranslation value={'KEY.Street'}/>}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.street}
                            onChangeText={(text) => this.setState({street: text})}
                        />
                        <CustomInput
                            className={'standard-margin-3'}
                            iconName={'las la-home'}
                            label={<CustomTranslation value={'KEY.Number'}/>}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.number}
                            onChangeText={(text) => this.setState({number: text})}
                        />
                    </div>
                    <div
                        className={'vertical-margin-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '-3px',
                            marginRight: '-3px'
                        }}
                    >
                        <CustomInput
                            className={'standard-margin-3'}
                            iconName={'las la-city'}
                            label={<CustomTranslation value={'KEY.Zip'}/>}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.zip}
                            onChangeText={(text) => this.setState({zip: text})}
                        />
                        <CustomInput
                            className={'standard-margin-3'}
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                            iconName={'las la-city'}
                            label={<CustomTranslation value={'KEY.City'}/>}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.city}
                            onChangeText={(text) => this.setState({city: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomSelect
                            iconName={'las la-globe'}
                            label={<CustomTranslation value={'KEY.Country'} />}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            options={this.state.countryItems}
                            selected={{'label': this.state.currentCountry ? this.state.currentCountry.label : '', 'value': this.state.currentCountry ? this.state.currentCountry.value : null}}
                            onSelect={(value, label) => {
                                this.countryChange(label, value);
                            }}
                        />
                    </div>
                    {/* Title 2 */}
                    <div className={'vertical-margin-2'}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Contact Info'}/></h2>
                    </div>
                    {/* Form 2 */}
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-mobile'}
                            label={<CustomTranslation value={'KEY.Mobile'} />}
                            inputProps={{
                                type: 'tel',
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.mobile}
                            onChangeText={(text) => this.setState({mobile: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            iconName={'las la-receipt'}
                            label={<CustomTranslation value={'KEY.VAT'} />}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.vat}
                            onChangeText={(text) => this.setState({vat: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomInput
                            key={this.state.countryUuid}
                            iconName={'las la-passport'}
                            label={
                                this.state.currentCountry.code.toUpperCase() === 'NL' ?
                                <CustomTranslation value={'KvK'} /> :
                                <CustomTranslation value={'KEY.RSZ'} />
                            }
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.nsso}
                            onChangeText={(text) => this.setState({nsso: text})}
                        />
                    </div>
                    <div className={'vertical-margin-2'}>
                        <CustomCheckBox
                            label={<CustomTranslation value={'KEY.Show all assignments to admin'} />}
                            inputProps={{
                                disabled: !this.state.userCompanyRights.adminRights
                            }}
                            value={this.state.adminShowAllAssignments}
                            onValueChange={(value) => this.setState({adminShowAllAssignments: value})}
                        />
                    </div>
                    {/* Button */}
                    {this.state.userCompanyRights.adminRights ?
                        <div className={'vertical-margin-2'}>
                            <Button
                                block
                                variant={'dark'}
                                onClick={() => {
                                    this.updateCompanyForm();
                                }}
                            >
                                <CustomTranslation value={'KEY.Save'}/>
                            </Button>
                        </div>
                        :
                        null
                    }
                </div>
            );
        } else {
            return (
                <Loader />
            );
        }
    }
}

//export
export default CompanyPreferencesTab;