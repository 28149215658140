import React from 'react';
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import CustomConfirm from "../../Components/CustomConfirm";
import CustomTranslation from "../../Components/CustomTranslation";
import {toast} from "react-hot-toast";

//define class
class FirstAidKit extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            isActive: true,
            deleteModal: null,
        };
    }

    //show confirmation dialog
    deleteConfirmation = () => {
        //set in state
        this.setState({
            deleteModal:
                <CustomConfirm
                    show={true}
                    title={<CustomTranslation value={'KEY.Delete'} />}
                    body={<p><CustomTranslation value={'KEY.Do you wish to delete the first aid kit at:'} /> { this.props.data.location } ?</p>}
                    variant={'danger'}
                    cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                    confirmLabel={<CustomTranslation value={'KEY.Delete'} />}
                    onCancel={() => {
                        this.setState({deleteModal: null});
                    }}
                    onConfirm={() => {
                        this.deleteKit();
                    }}
                />
        });
    }

    //send delete call
    deleteKit = async () => {
        //make call
        let result = await ConstructionSiteAction.deleteFirstAidKit(this.props.data);

        //hide
        this.setState({
            isActive: false
        });

        //toast
        toast.success(<CustomTranslation value={'KEY.First aid kit deleted.'} />);
    }

    //render view
    render() {

        if (this.state.isActive) {
            return (
                <div className={this.props.className} style={this.props.style}>
                    <div
                        className={'bg-light rounded standard-padding vertical-padding'}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <div
                                className={'icon-container bg-primary'}
                            >
                                <i className={'la la-first-aid icon text-white'}/>
                            </div>
                            <div
                                className={'standard-padding-2'}
                                style={{flexGrow: 1, flexShrink: 1}}
                            >
                                <h5>{this.props.data.location}</h5>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div
                                    className={'standard-padding-2'}
                                    style={{
                                        paddingLeft: 0
                                    }}
                                >
                                    <div
                                        className={'icon-btn-container bg-dark touchable'}
                                        onClick={(event) => {
                                            if (typeof this.props.onUpdate === 'function') {
                                                this.props.onUpdate();
                                            }
                                        }}
                                    >
                                        <i className={'la la-edit icon-btn text-white'} />
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className={'icon-btn-container bg-danger touchable'}
                                        onClick={(event) => {
                                            this.deleteConfirmation();
                                        }}
                                    >
                                        <i className={'la la-trash icon-btn text-white'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p
                                className={'vertical-margin-3'}
                                style={{
                                    marginBottom: 0
                                }}
                            >
                                {this.props.data.description}
                            </p>
                        </div>
                    </div>
                    {/* Delete modal */}
                    {this.state.deleteModal}
                </div>
            );
        } else {
            return null;
        }
    }
}

//export
export default FirstAidKit;