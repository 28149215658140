import React from "react";

//define class
class LMRAItem extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //init state
        this.state = {
            value: ('value' in this.props ? this.props.value : null),
            last: Date.now()
        };
    }

    //on value change
    onValueChange = async (value) => {
        if (typeof this.props.onValueChange === 'function') {
            this.props.onValueChange(value);
        }
    };

    //render view
    render = () => {

        //set values for checkboxes
        let yesValue = false;
        let noValue = false;
        if (this.state.value === true) {
            yesValue = true;
            noValue = false;
        } else if (this.state.value === false) {
            yesValue = false;
            noValue = true;
        }

        return (
            <div style={this.props.style} className={this.props.className}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            flexGrow: 1,
                            flexShrink: 1
                        }}
                    >
                        <h3 style={{wordBreak: 'break-word'}}>{this.props.title}</h3>
                    </div>
                    <div
                        className={'standard-padding-2'}
                    >
                        <span
                            className={'checkmark-success bordered rounded touchable ' + (yesValue ? 'bg-success' : '')}
                            style={{
                               display: 'flex',
                               flexDirection: 'row',
                               justifyContent: 'center',
                               alignItems: 'center'
                            }}
                            onClick={() => {
                                if (true === this.state.value) {
                                    this.setState({
                                        value: null,
                                        last: new Date()
                                    });
                                    this.onValueChange(null);
                                } else {
                                    this.setState({
                                        value: true,
                                        last: new Date()
                                    });
                                    this.onValueChange(true);
                                }
                                
                            }}
                        >
                            {yesValue &&
                                <i
                                    className={'las la-check text-white'}
                                    style={{
                                        fontWeight: '900'
                                    }}
                                />
                            }
                        </span>
                    </div>
                    <div>
                        <span
                            className={'checkmark-primary bordered rounded touchable ' + (noValue ? 'bg-primary' : '')}
                            style={{
                               display: 'flex',
                               flexDirection: 'row',
                               justifyContent: 'center',
                               alignItems: 'center'
                            }}
                            onClick={() => {
                                if (false === this.state.value) {
                                    this.setState({
                                        value: null,
                                        last: new Date()
                                    });
                                    this.onValueChange(null);
                                } else {
                                    this.setState({
                                        value: false,
                                        last: new Date()
                                    });
                                    this.onValueChange(false);
                                }
                            }}
                        >
                            {noValue &&
                                <i
                                    className={'las la-check text-white'}
                                    style={{
                                        fontWeight: '900'
                                    }}
                                />
                            }
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default LMRAItem;