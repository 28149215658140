import React from 'react';
import {Form} from 'react-bootstrap';
import Uuid from 'uuid/v1';

class CustomInput extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set interval
        this.waitInterval = null;
    }

    //if text is changed run this
    textUpdate = async (text) => {
        //if function is supplied
        if (typeof this.props.onChangeText === 'function') {
            //clear interval
            clearInterval(this.waitInterval);

            //set timeout
            this.waitInterval = setInterval(() => {
                this.props.onChangeText(text);
                clearInterval(this.waitInterval);
            }, 500);
        }
    }

    render = () => {

        //set defaults
        let inputProps = {
            type: 'text'
        };

        //combine
        if (typeof this.props.inputProps === 'object') {
            inputProps = {...inputProps, ...this.props.inputProps};
        }

        return (
           <div className={this.props.className} style={this.props.style}>
               <Form.Group controlId={'form_' + Uuid()} style={{margin: 0}}>
                   {this.props.noLabel ? null :
                       <Form.Label>
                           <div style={{
                               display: 'flex',
                               flex: 'row'
                           }}>
                               {this.props.iconName &&
                               <div
                                   className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                               >
                                   <i className={this.props.iconName + ' icon'}/>
                               </div>
                               }
                               <div style={{
                                   display: 'flex',
                                   flexDirection: 'row',
                                   alignItems: 'center'
                               }}>
                                   <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')}
                                         style={{paddingBottom: 0}}>{this.props.label}{this.props.labelAppend}</span>
                               </div>
                           </div>
                       </Form.Label>
                   }
                   <Form.Control
                       {...inputProps}
                       onChange={(event) => {
                           this.textUpdate(event.target.value);
                       }}
                       defaultValue={this.props.value}
                   />
               </Form.Group>
           </div>
        );
    }
}

//export
export default CustomInput;