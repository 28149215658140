import APIAction from "./APIAction";

//define class
let ToolboxAction = {
    //create toolbox based on input
    createToolbox: async function (data) {
        //call api
        let result = await APIAction.request('/toolboxes', 'POST', true, data);

        //return
        return result;
    },

    //update toolbox
    updateToolbox: async function (data) {
        //call api
        let result = await APIAction.request('/toolboxes/' + data.id, 'PATCH', true, data);

        //return
        return result;
    },

    //list all toolboxes
    list: async function (constructionSiteId) {
        //call api
        let result = await APIAction.request('/toolboxes', 'GET', true, {
            'construction_site_id': constructionSiteId
        });

        //return
        return result;
    },

    //get toolbox
    getToolbox: async function (id) {
        //call api
        let result = await APIAction.request('/toolboxes/' + id, 'GET', true);

        //return
        return result;
    },

    //delete toolbox
    deleteToolbox: async function (toolboxId) {
        //call api
        let result = await APIAction.request('/toolboxes/' + toolboxId, 'PATCH', true, { active: false })

        //return
        return result;
    }
};

//export
export default ToolboxAction;
