import APIAction from './APIAction';

//define class
let LMRAAction = {

    //get LMRA's by construction site
    getLMRAListByConstructionSite: async function(constructionSiteId) {

        //get lmra
        let lmra = await APIAction.request('/last_minute_risk_analyses', 'GET', true, {constructionSiteId: constructionSiteId});

        //return
        return lmra;

    },

    //get LMRA with id
    getLMRA: async function(lmraId) {

        //get lmra
        let lmra = await APIAction.request('/last_minute_risk_analyses/' + lmraId, 'GET', true);

        //return
        return lmra;

    },

    //Create LMRA
    createLMRA: async function(constructionSiteId) {

        //get lmra
        let lmra = await APIAction.request('/last_minute_risk_analyses', 'POST', true, {constructionSiteId: constructionSiteId});

        //return
        return lmra;

    },

    //get items
    getItems: async function(lmraId) {

        //get items
        let items = await APIAction.request('/last_minute_risk_analysis_items', 'GET', true, {last_minute_risk_analysis_id: lmraId});

        //return
        return items;
    },

    //get single item
    getItem: async function(itemId) {

        //get item
        let item = await APIAction.request('/last_minute_risk_analysis_items/' + itemId, 'GET', true);

        //return
        return item;
    },

    //get templates
    getTemplates: async function(companyId) {

        //get templates
        let templates = await APIAction.request('/last_minute_risk_analysis_templates', 'GET', true, {company_id: companyId});

        //return
        return templates;
    },

    //update template with id
    updateTemplate: async function(templateId, data) {

        //call api
        let result = await APIAction.request('/last_minute_risk_analysis_templates/' + templateId, 'PATCH', true, data);

        //return
        return result;
    },

    //update an item of lmra
    updateItem: async function(lmraItem) {

        //hard copy
        let data = JSON.parse(JSON.stringify(lmraItem));

        //delete id field
        delete data.id;
        delete data.lastMinuteRisk;
        delete data.lastMinuteRiskAnalysis;

        //do call
        let result = await APIAction.request('/last_minute_risk_analysis_items/' + lmraItem.id, 'PATCH', true, data);

        //return
        return result;
    },

    //create assignment
    createAssignment: async function(input) {

        //set data
        let data = {
            source: "",
            recommendation: input.recommendation,
            followUp: "",
            dateRequested: input.date,
            targetUser: input.user,
            lastMinuteRiskAnalysisId: input.lastMinuteRiskAnalysisId
        };

        //do call
        let result = await APIAction.request('/assignments', 'POST', true, data);

        //return
        return result;
    },

    //get LMRA Risks by country code
    getLMRARisks: async function(country_code = 'be') {

        //get lmra risks
        let lmraRisks = await APIAction.request('/last_minute_risks', 'GET', true, {country_code: country_code});

        //return
        return lmraRisks;

    },

    getLMRARisksByLMRA: async function(lmraId) {

        //get lmra risks
        let lmraRisks = await APIAction.request('/last_minute_risk_analysis/risks/' + lmraId, 'GET', true);

        //return
        return lmraRisks;
    },

    //get LMRA inserts by item
    getLMRAInserts: async function(lmraTemplate, lmraRisk) {

        //get inserts
        let lmraInserts = await APIAction.request('/last_minute_risk_inserts', 'GET', true, {last_minute_risk_analysis_template_id: lmraTemplate.id, last_minute_risk_id: lmraRisk.id});

        //return
        return lmraInserts;

    },

    //create lmra insert
    createLMRAInsert: async function(data) {

        //create insert
        let lmraInsert = await APIAction.request('/last_minute_risk_inserts', 'POST', true, data);

        //return
        return lmraInsert;
    },

    //update lmra insert
    updateLMRAInsert: async function(data) {

        //set data
        let reqData = {
            active: data.active
        };

        //update
        let updateInsert = await APIAction.request('/last_minute_risk_inserts/' + data.id, 'PATCH', true, reqData);

        //return
        return updateInsert;
    }
};

//export
export default LMRAAction;
