import React from 'react';

class CustomInfoButton extends React.Component {

    //constructor
    constructor(props) {
        super(props);
    }

    render = () => {

        //return render
        return (
            <div
                style={this.props.style}
                className={this.props.className}
                key={this.props.key}
            >
                <div
                    style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '0.75rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    className={'dark-bg touchable'}
                    onClick={(event) => {
                        if (typeof this.props.onClick === 'function') {
                            this.props.onClick(event);
                        }
                    }}
                >
                    <i
                        className={'las la-info'}
                        style={{
                            fontSize: '1.125rem',
                            color: '#fff',
                            fontWeight: '900'
                        }}
                    />
                </div>
            </div>
        );
    }
}

//export
export default CustomInfoButton;