/**
 * Actions for Risk
 *
 * @author Robbe De Geyndt <robbe.degeyndt@aware.be>
 */

'use strict';

//imports
import APIAction from './APIAction';

//define class
let RiskAction = {

    //get risks
    getRisks: async function(country_code = 'be') {

        //fetch risks
        let risks = await APIAction.request('/risks', 'GET', true, {country_code: country_code});

        return risks;
    },

    //get list of risks or get go to risks
    getRiskList: async function(riskAnalysis, parentId = null, countryCode = null) {

        //set data
        let data = {};

        //add parent
        if (parentId !== null) {
            data.parent_id = parentId;
        }

        //add risk analysis
        if (riskAnalysis) {
            data.country_code = riskAnalysis.constructionSite.country.countryCode;
            data.task_risk_analysis_id = riskAnalysis.id;
        } else {
            data.country_code = countryCode;
        }

        //fetch risks
        let risks = await APIAction.request('/risks', 'GET', true, data);

        //check if risks are list
        if (risks.length > 0) {
            return risks;
        }

        //if not list, get risk
        let risk = await APIAction.request('/risks/' + parentId, 'GET', true, data);

        //return
        return risk;
    },

    //get risk by id
    getRisk: async function(riskId, riskAnalysis, countryCode = null) {


        //set data
        let data = {};

        if (riskAnalysis) {
            data['task_risk_analysis_id'] = riskAnalysis.id;
            data['country_code'] = riskAnalysis.constructionSite.country.countryCode;
        } else {
            data['country_code'] = countryCode;
        }

        //call
        let risk = await APIAction.request('/risks/' + riskId, 'GET', true, data);

        //return
        return risk;
    }

};

//export
export default RiskAction;
