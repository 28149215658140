import React from "react";

//define class
class LMRAParent extends React.Component {

    //render view
    render = () => {

        return (
            <div style={this.props.style} className={this.props.className}>
                <div
                    className={'bordered rounded standard-padding-2 vertical-padding-2'}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <p className={'label unbold'} style={{paddingBottom: 0, flexGrow: 1, flexShrink: 1, wordBreak: 'break-word'}}>
                            {this.props.title}
                        </p>
                        <div className={'standard-padding-2'}>
                            <i className={'las la-thumbs-up text-success'} style={{fontSize: '1.4rem'}} />
                        </div>
                        <div>
                            <i className={'las la-thumbs-down text-primary'} style={{fontSize: '1.4rem'}} />
                        </div>
                    </div>
                    {/* List */}
                    {this.props.lmraList}
                </div>

            </div>
        );
    }
}

//export
export default LMRAParent;