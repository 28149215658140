import React from "react";

class RiskCheckbox extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            value: this.props.value || false
        }

        //waitinterval
        this.waitInterval = null;
    }

    //on click
    onClick = async () => {
        this.setState({value: !this.state.value},
            () => {
                if (typeof this.props.onClick === 'function') {
                    clearInterval(this.waitInterval);
                    this.waitInterval = setInterval(() => {
                        this.props.onClick(this.state.value);
                        clearInterval(this.waitInterval);
                    }, 1000);
                }
            });
    }

    render = () => {
        return (
            <div
                className={this.props.className}
                style={this.props.style}
            >

                <span
                    className={'checkmark-light bordered rounded touchable ' + (this.state.value ? 'bg-light' : '')}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        if (!this.props.disabled) {
                            this.onClick();
                        }
                    }}
                >
                    {this.state.value &&
                        <i
                            className={'las la-check'}
                            style={{
                                fontWeight: '900'
                            }}
                        />
                    }
                </span>
            </div>
        );
    }
}

//export
export default RiskCheckbox;