//imports
import React from 'react';
import CustomTranslation from "../../Components/CustomTranslation";
import G from "../../../Actions/GeneralAction";

//define class
class RiskAnalysisResult extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {

        }
    }

    //render view
    render() {

        return (
            <div className={this.props.className} style={this.props.style}>
                <div
                    className={'rounded bg-light touchable standard-padding vertical-padding-2 standard-margin-3 vertical-margin-3'}
                    onClick={this.props.onPress}
                >
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flexGrow: 1, flexShrink: 1}}>
                            <p className={'label'} style={{paddingBottom: 0}}><CustomTranslation value={this.props.title} /></p>
                            <p className={'label unbold'} style={{paddingBottom: 0}}>{this.props.constructionSiteName}</p>
                        </div>
                        <p className={'label unbold standard-margin-2'} style={{paddingBottom: 0}}>{G.formatDate(this.props.date)}</p>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default RiskAnalysisResult;