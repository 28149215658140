//imports
import db from '../Dexie';
import Config from '../../Config.json';

//class definition
export default class TranslationRepository {

    //save function
    save = async (translation) => {
        
        //delete previous record
        await db.translation.where({key: translation.key, languageCode: translation.languageCode}).delete();

        //insert new
        await db.translation.put(translation);
    }

    //get function
    get = async (key, language) => {

        //get translations
        let translations = await db.translation.where({key: key, languageCode: language.code}).sortBy('createdAt');
        translations = translations.reverse();

        //set translation
        let translation = null;

        //check if token exists
        if (translations.length > 0) {
            translation = translations[0];
        }

        //return
        return translation;
    }

    //check if update is required
    updateRequired = async () => {
       
        //get translations
        let translations = await db.translation.orderBy('createdAt').toArray();
        
        //check length
        if (translations.length < 1) {
            return true;
        }

        //check last date
        let last = translations[0];
        let lastDate = last.createdAt;
        lastDate = (lastDate.getTime()) / 1000;

        //get date
        let date = (new Date()).getTime() / 1000;
        date = date - (Config.TRANSLATION_TIMEOUT * 86400);

        //compare
        if (date > lastDate) {
            return true;
        }

        //else, no update is required
        return false;
    }

    //import function
    import = async (data) => {
        //delete all previous
        await db.translation.clear();

        //get languages
        let languagesQuery = await db.language.toArray();
        let languages = {};
        languagesQuery.forEach(language => {
            languages[language.code] = language;
        });

        //loop import
        let inserts = [];
        data.forEach(translationRecord => {

            //get the key
            let key = translationRecord.key;

            //loop over records for key
            Object.keys(translationRecord).forEach((index) => {

                //key already obtained
                if (index !== 'key') {

                    //get language
                    let language = languages[index];

                    //only continue if language exists
                    if (typeof language !== 'undefined') {

                        //create new translation
                        let translation = {
                            key: key,
                            languageCode: language.code,
                            translation: translationRecord[index],
                            createdAt: new Date()
                        };

                        //save
                        inserts.push(translation);
                    }
                }
            });
        });
        await db.translation.bulkPut(inserts);
    }
}