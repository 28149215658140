import React from "react";
import Risk from "./Risk";
import RiskAction from "../../../../Actions/RiskAction"
import {Collapse} from "react-collapse";
import RiskAnalysisAction from "../../../../Actions/RiskAnalysisAction";
import Uuid from "uuid/v1";

//define class
class RiskList extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //default open lvls
        this.openLvls = [3, 6];

        //colors
        this.colors = [
            'rgba(253,176,25,0.25)',
            'rgba(98,177,246,0.25)',
            'rgba(107,232,152,0.25)',
            'rgba(217,83,79,0.25)',
            'rgba(255,255,255,0.25)'
        ];

        //state
        this.state = {
            riskMatrixObject: [],
            riskMatrix: []
        }

        //template
        this.template = this.props.template;
    }

    //on mount
    componentDidMount = () => {
        this.updateElements();
    }

    //update function
    updateElements = async () => {

        //get risk matrix
        let riskMatrix = await this.createRiskMatrix();

        //create matrix object
        let riskMatrixObject = await this.createMatrixObject(riskMatrix);

        //set state
        this.setState({
            riskMatrixObject: riskMatrixObject,
            riskMatrix: riskMatrix
        });
    }

    //create risk matrix
    createRiskMatrix = async(parentId = null) => {
        //init
        let riskMatrix = [];

        //get
        let risks = await RiskAction.getRiskList(null, parentId, this.template.company.country.countryCode);

        //loop
        let riskListParallel = [];
        let riskListParallelIds = [];
        for (let risk of risks) {
            let included = await this.isRiskIncluded(risk);
            if (
                (risk.lvl < 7 && included) &&
                (this.openLvls.includes(risk.lvl))
            ) {
                riskListParallel.push(this.createRiskMatrix(risk.id));
                riskListParallelIds.push(risk.id);
            }

            //check if lvl is open
            if (this.openLvls.includes(risk.lvl)) {
                risk['collapsed'] = false;
            } else {
                risk['collapsed'] = true;
            }

            //set uuid
            risk['uuid'] = Uuid();

            //push in matrix
            riskMatrix.push(risk);
        }

        //get result and loop
        let results = await Promise.all(riskListParallel);
        riskMatrix.forEach((risk, riskIndex) => {
            riskListParallelIds.forEach((riskId, resultIndex) => {
                if (risk.id === riskId) {
                    riskMatrix[riskIndex]['subRisks'] = results[resultIndex];
                }
            });
        });

        //return
        return riskMatrix;
    }

    //update risk matrix
    updateRiskMatrix = async (risk, recursive = false, subrisks = false) => {

        //get flat risk array
        let flatArray = await this.flattenMatrix(this.state.riskMatrix);

        //find
        let foundRisk = null;
        for (let flatRisk of flatArray) {
            if (flatRisk.risk.id === risk.id) {
                foundRisk = flatRisk;
            }
        }

        //only do calls if subrisks are still unknown
        if (!('subRisks' in risk) && risk.lvl < 7 && subrisks) {

            //create new part of matrix
            let newMatrixSegment = await this.createRiskMatrix(foundRisk.risk.id);

            //append to matrix
            newMatrixSegment = await this.flattenMatrix(newMatrixSegment, foundRisk.trail.concat([foundRisk.risk.id]));
            flatArray = flatArray.concat(newMatrixSegment);
        }

        //only do if recursive update is required
        if (recursive) {

            //init arrays
            let recursiveUpdates = [];
            let recursiveUpdatesObjects = [];

            //add main risk
            recursiveUpdates.push(RiskAction.getRisk(risk.id, null, this.template.company.country.countryCode));
            recursiveUpdatesObjects.push(foundRisk);

            //add trail risks
            for (let riskId of foundRisk.trail) {
                for (let flatRisk of flatArray) {
                    if (flatRisk.risk.id === riskId) {
                        recursiveUpdates.push(RiskAction.getRisk(riskId, null, this.template.company.country.countryCode));
                        recursiveUpdatesObjects.push(flatRisk);
                    }
                }
            }

            //wait for calls
            let recursiveResult = await Promise.all(recursiveUpdates);

            //loop results
            for (let i = 0; i < recursiveResult.length; i++) {
                let newRisk = recursiveResult[i];
                newRisk['subRisks'] = recursiveUpdatesObjects[i].risk.subRisks;
                newRisk['collapsed'] = recursiveUpdatesObjects[i].risk.collapsed;
                newRisk['uuid'] = Uuid();
                recursiveUpdatesObjects[i].risk = newRisk;
            }
        }

        //inflate matrix
        let riskMatrix = await this.inflateMatrix(flatArray);

        //create object
        let riskMatrixObject = await this.createMatrixObject(riskMatrix);

        //set state
        this.setState({
            riskMatrix: riskMatrix,
            riskMatrixObject: riskMatrixObject
        });
    }

    //make matrix flat for searching
    flattenMatrix = async (riskMatrix, trail = []) => {
        let plainRisks = [];

        //loop
        for (let matrixRisk of riskMatrix) {
            plainRisks.push({
                risk: matrixRisk,
                trail: trail
            });

            //if needed go deeper
            if ('subRisks' in matrixRisk) {
                let result = await this.flattenMatrix(matrixRisk.subRisks, trail.concat([matrixRisk.id]));
                plainRisks = plainRisks.concat(result);
            }
        }

        //return
        return plainRisks;
    }

    //make matrix multidimentional for showing
    inflateMatrix = async (flatMatrix, trail = []) => {
        //init
        let riskMatrix = [];

        for (let flatItem of flatMatrix) {
            //check if trail is equal
            if (JSON.stringify(flatItem.trail) === JSON.stringify(trail)) {
                //check if subs already set
                if ('subRisks' in flatItem.risk) delete flatItem.risk.subRisks;
                //get subitems
                let subItems = await this.inflateMatrix(flatMatrix, trail.concat([flatItem.risk.id]));
                //if subitems exist add
                if (subItems.length > 0) {
                    flatItem.risk['subRisks'] = subItems;
                }
                //set item
                riskMatrix.push(flatItem.risk);
            }
        }

        //return
        return riskMatrix;
    }

    //collapse risk
    collapseRisk = async (risk, collapsed) => {
        //get flat risk array
        let flatArray = await this.flattenMatrix(this.state.riskMatrix);

        //find
        let foundRisk = null;
        for (let flatRisk of flatArray) {
            if (flatRisk.risk.id === risk.id) {
                foundRisk = flatRisk;
            }
        }

        //set uncollapsed
        foundRisk.risk.collapsed = collapsed;

        //create risk matrix
        let riskMatrix = await this.inflateMatrix(flatArray);

        //create matrix object
        let riskMatrixObject = await this.createMatrixObject(riskMatrix);

        //set state
        this.setState({
            riskMatrix: riskMatrix,
            riskMatrixObject: riskMatrixObject
        });
    }

    //spinning risk
    spinningRisk = async (risk, spinner) => {
        //get flat risk array
        let flatArray = await this.flattenMatrix(this.state.riskMatrix);

        //find
        let foundRisk = null;
        for (let flatRisk of flatArray) {
            if (flatRisk.risk.id === risk.id) {
                foundRisk = flatRisk;
            }
        }

        //set spinner
        if (spinner) {
            foundRisk.risk.spinner = true;
        } else {
            delete foundRisk.risk.spinner;
        }

        //create risk matrix
        let riskMatrix = await this.inflateMatrix(flatArray);

        //create matrix object
        let riskMatrixObject = await this.createMatrixObject(riskMatrix);

        //set state
        this.setState({
            riskMatrix: riskMatrix,
            riskMatrixObject: riskMatrixObject
        });
    }

    //check if risk is included
    isRiskIncluded = async (risk) => {
        let included = true;

        //loop and search
        this.template.risks.forEach((element) => {
            if (element.id === risk.id) {
                included = false;
            }
        });

        //return
        return included;
    }

    //change status on higher level
    statusChange = async (risk, value) => {
        //set spinner
        await this.spinningRisk(risk, true);

        //send call
        this.template = await RiskAnalysisAction.updateTemplate(value, this.template.id, risk.id);

        //update matrix
        await this.updateRiskMatrix(risk, true, !value);

        //unset spinner
        await this.spinningRisk(risk, false);

        //open if selected
        await this.collapseRisk(risk, value);
    }

    //create matrix object
    createMatrixObject = async (riskMatrix) => {
        //init
        let riskItems = [];

        //loop matrix
        for (let risk of riskMatrix) {
            //set icon style
            let iconStyle = 'normal';
            if (risk.lvl > 6) iconStyle = 'edit';

            //check if included in template
            let included = await this.isRiskIncluded(risk);

            //push risk itself in object
            riskItems.push(
                <Risk
                    id={risk.id}
                    key={risk.uuid}
                    spinner={risk.spinner}
                    className={'vertical-margin-3'}
                    style={{
                        backgroundColor: this.colors[risk.lvl - 3]
                    }}
                    risk={risk}
                    included={included}
                    iconStyle={iconStyle}
                    onCollapse={async (value) => {
                        if (value === false && !('subRisks' in risk)) {
                            await this.spinningRisk(risk,true);
                            await this.updateRiskMatrix(risk, false, true);
                            await this.spinningRisk(risk,false);
                        }
                        this.collapseRisk(risk, value);
                    }}
                    onCheckBoxClick={(value) => {
                        this.statusChange(risk, !value);
                    }}
                    onClick={async () => {
                        if (risk.lvl > 6) {
                            await this.statusChange(risk, included);
                        } else if (risk.collapsed && !('subRisks' in risk) && included) {
                            await this.spinningRisk(risk, true);
                            await this.updateRiskMatrix(risk, false, true);
                            await this.spinningRisk(risk, false);
                            this.collapseRisk(risk, !risk.collapsed);
                        } else if (!included) {
                            await this.statusChange(risk, false);
                        } else {
                            this.collapseRisk(risk, !risk.collapsed);
                        }
                    }}
                />
            );

            //add subrisk if exist
            if ('subRisks' in risk) {
                //get subrisk objects
                let subRisks = await this.createMatrixObject(risk.subRisks);
                riskItems.push(
                    <Collapse isOpened={!risk.collapsed} key={Uuid()}>
                        <div className={'standard-padding bordered-left'} style={{paddingRight: 0}}>
                            {subRisks}
                        </div>
                    </Collapse>
                );
            }
        }

        //return
        return (
            <div>
                {riskItems}
            </div>
        );
    }

    //render view
    render() {

        return (
            <div className={this.props.className} style={this.props.style}>
                {this.state.riskMatrixObject}
            </div>
        );
    }
}

//export
export default RiskList;