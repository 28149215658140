import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import Loader from "../../Components/Loader";
import UserAction from "../../../Actions/UserAction";
import CustomBorderedButton from "../../Components/CustomBorderedButton";
import CustomConfirm from "../../Components/CustomConfirm";
import CustomInput from "../../Components/CustomInput";
import CustomCheckBox from "../../Components/CustomCheckBox";
import {toast} from "react-hot-toast";
import UserRight from "../Components/UserRight";
import ConstructionSiteAction from "../../../Actions/ConstructionSiteAction";
import CustomSelect from "../../Components/CustomSelect";
import T from "../../../Actions/TranslationAction";

class EditRightTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set collapsed users
        this.userCollapsed = {};

        //set state
        this.state = {

        };
    }

    //on initial
    componentDidMount = () => {
        //update elements
        this.updateElements();
    }

    //update elements
    updateElements = async () => {

        //init translation
        T.init();

        //array for requests
        let requests = [];

        //push needed requests
        //get users
        requests.push(UserAction.getUserListCompany(this.props.constructionSite.company.id, true));
        requests.push(ConstructionSiteAction.getRights(this.props.constructionSite['@id']));

        //wait for result
        let results = await Promise.all(requests);

        //set users
        let users = [];
        results[0].forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            users.push({label: label, value: element.email});
        });

        //populate rights array
        let rights = this.populateRights(results[1]);

        //set users without rights
        let newUsers = [];

        //loop & compare
        users.forEach(user => {
            let found = false;
            results[1].forEach(right => {
                if (user.value === right.user.email) found = true;
            });
            if (!found) newUsers.push(user);
        });

        //add invite new user option
        let emptyLabel = await T.translate('KEY.Pick / add user');
        newUsers.unshift({
            label: emptyLabel,
            value: 'NULL'
        });

        //set state
        this.setState({
            requestRights: results[1],
            users: users,
            rights: rights,
            newUsers: newUsers
        });
    }

    //populate rights
    populateRights = (input) => {
        //init array
        let rights = [];

        //loop inputs
        input.forEach((element, index) => {
            //set as collapsed by default
            if (!this.userCollapsed.hasOwnProperty(element.id)) {
                this.userCollapsed[element.id] = {
                    'index': index,
                    'collapsed': true
                };
            }

            //add each right
            rights.push(
                <UserRight
                    className={'vertical-padding-3'}
                    key={element.id}
                    data={element}
                    collapsed={this.userCollapsed[element.id].collapsed}
                    onCollapse={(value) => {
                        this.onCollapse(element.id, value);
                    }}
                    onValueChange={(value) => {
                        this.removeRight(value);
                    }}
                />
            );
        });

        //return
        return rights;
    }

    //create new company right
    createRight = async (email) => {
        if (email !== 'NULL') {
            //make call
            await ConstructionSiteAction.createRights({
                jobTitle: '',
                managementRights: false,
                user: email,
                constructionSite: {
                    id: this.props.constructionSite['@id']
                }
            });

            //reload
            await this.updateElements();
        }
    }

    //remove/update company right
    removeRight = async (right) => {
        // Remove
        await ConstructionSiteAction.updateRights(right);

        //reload
        await this.updateElements();
    }

    //add users
    newUser = async () => {
        if(this.state.newUserEmail){
            // Hide modal
            this.setState({
                showUserModal: false,
            });

            // Create Right for New User
            await this.createRight(this.state.newUserEmail);

            // Clear email from state
            this.setState({
                newUserEmail: null,
            });

            // Show Success
            toast.success(<CustomTranslation value={'KEY.User added to construction site!'} />);
        }
        else{
            toast.error(<CustomTranslation value={'KEY.Email not found!'} />);
        }
    }

    //if collapse is triggerd, only one can be open at any time
    onCollapse = (key, value) => {
        //open correct one close others
        if (!value) {
            for (let [index, item] of Object.entries(this.userCollapsed)) {
                if (index === key) {
                    this.userCollapsed[index].collapsed = false;
                } else {
                    this.userCollapsed[index].collapsed = true;
                }
            }
            //close
        } else {
            this.userCollapsed[key].collapsed = true;
        }

        //repopulate rights array
        let rights = this.populateRights(this.state.requestRights);

        //put in state
        this.setState({rights: rights});
    }

    //render
    render = () => {

        //if loaded
        if ('rights' in this.state) {
            return (
                <div style={this.props.style} className={this.props.className} key={this.props.key}>
                    {/* Title */}
                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                        <h2 className={'text-left'}><CustomTranslation value={'KEY.Users construction site'}/></h2>
                    </div>
                    {/* Select user */}
                    <div className={'vertical-margin-2'}>
                        <CustomSelect
                            iconName={'la la-user'}
                            label={<i className={'la la-plus'} />}
                            selected={this.state.newUsers[0]}
                            options={this.state.newUsers}
                            onSelect={(value, label) => this.createRight(value)}
                        />
                    </div>
                    {/* Add new user */}
                    <div className={'vertical-margin-2'}>
                        <CustomBorderedButton
                            title={<CustomTranslation value={'KEY.Invite new user'} />}
                            onClick={() => {
                                this.setState({showUserModal: true});
                            }}
                        />
                    </div>
                    {/* List */}
                    <div>
                        {this.state.rights}
                    </div>
                    {/* Modal */}
                    <CustomConfirm
                        show={this.state.showUserModal}
                        variant={'success'}
                        body={
                            <div>
                                <div className={'vertical-margin-2'}>
                                    <CustomInput
                                        iconName={'las la-user'}
                                        label={<CustomTranslation value={'KEY.Email'} />}
                                        inputProps={{
                                            type: 'email'
                                        }}
                                        onChangeText={(email) => this.setState({newUserEmail: email.trim().toLowerCase()})}
                                    />
                                </div>
                            </div>
                        }
                        cancelLabel={<CustomTranslation value={'KEY.Cancel'} />}
                        onCancel={() => this.setState({showUserModal: false})}
                        confirmLabel={<CustomTranslation value={'KEY.Add User'} /> }
                        onConfirm={() => this.newUser()}
                    />
                </div>
            );
        } else {
            return (
                <Loader />
            );
        }
    }
}

//export
export default EditRightTab;