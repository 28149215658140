import React from "react";
import {Collapse} from "react-collapse";
import CustomTranslation from "../../../Components/CustomTranslation";
import {Badge, Button} from "react-bootstrap";
import CustomCheckBox from "../../../Components/CustomCheckBox";
import Uuid from "uuid/v1";
import CustomConfirm from "../../../Components/CustomConfirm";

class UserRight extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {
            id: this.props.data.id,
            jobTitle: this.props.data.jobTitle,
            adminRights: this.props.data.adminRights,
            managementRights: this.props.data.managementRights,
            default: this.props.data.default,
            active: this.props.data.active,
            uniqueId: Uuid(),
            licenseModal: null
        }
    }

    //on change
    onValueChange = async (key, value) => {

        //clear interval
        clearInterval(this.roleWaiting);

        //set timeout
        this.roleWaiting = setInterval(() => {
            //set data
            let data = {};
            data[key] = value;

            //send alert if user has no license
            if (
                (!this.props.data.license) &&
                (key === 'managementRights' || key === 'adminRights') &&
                (value === true)
            ) {
                //create modal
                let licenseModal =
                    <CustomConfirm
                        show={true}
                        variant={'success'}
                        title={<CustomTranslation value={'KEY.Warning'} />}
                        body={
                            <p>
                                <CustomTranslation value={'KEY.This user needs a license to activate this functionality. Do you want to request a license with your company\'s administrator?'} />
                            </p>
                        }
                        cancelLabel={<CustomTranslation value={'KEY.No'} />}
                        onCancel={() => {this.cancel()}}
                        confirmLabel={<CustomTranslation value={'KEY.Yes'} />}
                        onConfirm={() => {this.sendUpdate(data)}}
                    />;

                //set state
                this.setState({licenseModal: licenseModal});
            }
            else {
                this.sendUpdate(data);
            }

            // clear interval
            clearInterval(this.roleWaiting);
        }, 500);
    }

    //change rights and send as update
    sendUpdate = async (data) => {
        //set in state
        this.setState({...data, ...{licenseModal: null}}, () => {
            //if given
            if (this.props.onValueChange) {
                this.props.onValueChange(this.state);
            }
        });
    }

    //cancel rights change
    cancel = async () => {
        //reload
        this.setState({
            uniqueId: Uuid(),
            licenseModal: null
        });
    }

    //render view
    render = () => {

        //set badge
        let badge = null;
        if (this.props.data.license) {
            badge = <Badge key={Uuid()} pill variant={'success'}><CustomTranslation value={'KEY.Licensed'} /></Badge>;
        }
        else if (this.props.data.licenseRequested) {
            badge = <Badge key={Uuid()} pill variant={'primary'}><CustomTranslation value={'KEY.License requested'} /></Badge>;
        }
        else {
            badge = <Badge key={Uuid()} pill variant={'danger'}><CustomTranslation value={'KEY.Unlicensed'} /></Badge>;
        }

        //render
        return (
            <div className={this.props.className} style={this.props.style} id={this.props.id}>
                <div
                    className={'bordered rounded'}
                >
                    {/* Title */}
                    <div
                        className={'touchable vertical-padding-2 standard-padding-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        onClick={() => {
                            this.props.onCollapse(!this.props.collapsed);
                        }}
                    >
                        <div
                            style={{
                                flexGrow: 1,
                                flexShrink: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <h3
                                style={{
                                    wordBreak: 'break-word'
                                }}
                            >
                                {(this.props.data.user.firstName || this.props.data.user.lastName) ?
                                    (this.props.data.user.firstName || '') + ' ' + (this.props.data.user.lastName || '')
                                    :
                                    this.props.data.user.email
                                }
                            </h3>
                        </div>
                        <div className={'standard-padding-2'}>
                            {badge}
                        </div>
                        <div>
                            <i style={{fontWeight: '900', fontSize: '1.25rem'}}
                               className={this.props.collapsed ? 'las la-chevron-down' : 'las la-chevron-up'}/>
                        </div>
                    </div>
                    <Collapse isOpened={!this.props.collapsed}>
                        <div key={this.state.uniqueId} className={'standard-margin-2'}>
                            <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                                <CustomCheckBox
                                    disabled={!this.props.userCompanyRights.adminRights}
                                    label={<CustomTranslation value={'KEY.Administrator'}/>}
                                    value={this.state.adminRights}
                                    onValueChange={(value) => this.onValueChange('adminRights', value)}
                                    variant={this.props.data.license ? 'success' : 'primary'}
                                />
                            </div>
                            <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                                <CustomCheckBox
                                    disabled={!this.props.userCompanyRights.adminRights}
                                    label={<CustomTranslation value={'KEY.Management'}/>}
                                    value={this.state.managementRights}
                                    onValueChange={(value) => this.onValueChange('managementRights', value)}
                                    variant={this.props.data.license ? 'success' : 'primary'}
                                />
                            </div>
                            <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                                <CustomCheckBox
                                    disabled={true}
                                    label={<CustomTranslation value={'KEY.View'}/>}
                                    value={true}
                                    variant={this.props.data.license ? 'success' : 'primary'}
                                />
                            </div>
                            {this.props.userCompanyRights.adminRights ?
                                <div className={'bordered-top vertical-padding-2'} style={{paddingBottom: 0}}>
                                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                                        <CustomCheckBox
                                            disabled={!this.props.userCompanyRights.adminRights}
                                            label={<CustomTranslation value={'KEY.Own employee'}/>}
                                            value={this.state.default}
                                            onValueChange={(value) => this.onValueChange('isEmployee', value)}
                                        />
                                    </div>
                                    <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                                        <Button
                                            block
                                            variant={'outline-primary'}
                                            onClick={() => this.onValueChange('active', false)}
                                        >
                                                <span className={'font-weight-bold'}>
                                                    <CustomTranslation value={'KEY.Remove'} />
                                                </span>
                                        </Button>
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </Collapse>
                    {/* Modal */}
                    {this.state.licenseModal}
                </div>
            </div>
        );
    }
}

//export
export default UserRight;