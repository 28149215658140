import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import CustomImageInput from "../../Components/CustomImageInput";
import CustomInput from "../../Components/CustomInput";
import {Button} from "react-bootstrap";
import UserAction from "../../../Actions/UserAction";

class PersonalPreferencesTab extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            mobile: this.props.user.mobile,
            imageId: this.props.user.picture ? this.props.user.picture.id : null, // Used to display
            imageApiId: this.props.user.picture ? this.props.user.picture['@id'] : null, // Used to save data
        };
    }

    //send update request
    updatePersonalForm = async () => {
        //update user
        let formData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mobile: this.state.mobile,
            picture: this.state.imageApiId,
        };
        //make call
        let response = await UserAction.editUser(this.props.user.id, formData);
    }

    //handle image upload
    handleUpload = async (file) => {
        this.setState(
            {
                imageId: file.id,
                imageApiId: file['@id']
            }
        );
    }

    //render
    render = () => {
        return (
            <div style={this.props.style} className={this.props.className} key={this.props.key}>
                {/* Title */}
                <div className={'vertical-margin-2'} style={{marginTop: 0}}>
                    <h2 className={'text-left'}><CustomTranslation value={'KEY.Personal Info'} /></h2>
                    <p><CustomTranslation value={'KEY.Works for'} /> {this.props.defaultCompany.name}</p>
                </div>
                {/* Form */}
                <div className={'vertical-margin-2'}>
                    <CustomImageInput
                        iconName={'las la-image'}
                        label={<CustomTranslation value={'KEY.Upload image'} />}
                        onUpload={this.handleUpload}
                        imageId={this.state.imageId ? this.state.imageId : null}
                    />
                </div>
                <div
                    className={'vertical-margin-2'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: '-3px',
                        marginRight: '-3px'
                    }}
                >
                    <CustomInput
                        className={'standard-margin-3'}
                        style={{flex: 1}}
                        iconName={'las la-quote-left'}
                        label={<CustomTranslation value={'KEY.First Name'} />}
                        value={this.state.firstName}
                        onTextChange={(text) => this.setState({firstName: text})}
                    />
                    <CustomInput
                        className={'standard-margin-3'}
                        style={{flex: 1}}
                        iconName={'las la-quote-left'}
                        label={<CustomTranslation value={'KEY.Last Name'} />}
                        value={this.state.lastName}
                        onTextChange={(text) => this.setState({lastName: text})}
                    />
                </div>
                <div className={'vertical-margin-2'}>
                    <CustomInput
                        inputProps={{
                            type: 'tel'
                        }}
                        iconName={'las la-mobile'}
                        label={<CustomTranslation value={'KEY.Mobile'} />}
                        value={this.state.mobile}
                        onChangeText={(text) => {
                            this.setState({mobile: text});
                        }}
                    />
                </div>
                <div className={'vertical-margin-2'}>
                    <Button
                        block
                        variant={'dark'}
                        onClick={() => {
                            this.updatePersonalForm();
                        }}
                    >
                        <CustomTranslation value={'KEY.Update Personal Info'} />
                    </Button>
                </div>
            </div>
        );
    }
}

//export
export default PersonalPreferencesTab;