import React from 'react';
import CustomHeader from './CustomHeader';

class CustomContainer extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        this.state = {
            suprise: false
        };
    }

    render = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                {(this.props.noHeader) ? null :
                    <CustomHeader extraWide={this.props.extraWide} />
                }
                <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, overflowY: 'auto'}} id='scroll-frame'>
                    <div style={{maxWidth: this.props.extraWide ? 1500 : 1000, width: '100%', padding: '2rem'}} id='content'>
                        {this.props.children}
                    </div>
                </div>
                <div style={{width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', backgroundColor: '#fff'}} className={'shadow'}>
                    <div style={{maxWidth: this.props.extraWide ? 1500 : 1000, width: '100%'}}>
                        <div className={'standard-padding vertical-padding-2'}>
                            {this.props.footer}
                            <p
                                style={{margin: 0, userSelect: 'none', display: 'inline-block'}}
                                onClick={() => this.setState({suprise: !this.state.suprise})}
                            >
                                &copy; Risk Minimizer {new Date().getFullYear()} {this.state.suprise && '😎'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default CustomContainer;