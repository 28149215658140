import React from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Button, Spinner } from "react-bootstrap";
import CustomTranslation from "./CustomTranslation";
import blurImage from "../../Images/blur.svg";
import APIAction from "../../Actions/APIAction";
import ImageViewer from "react-simple-image-viewer";

class CustomImageInput extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            showCamera: false,
            imageId: this.props.imageId,
            spinner: this.props.imageId ? true : false,
            isLoaded: false,
            imageViewerOpen: false
        }
    }

    //on mount
    componentDidMount() {
        this.updateElements();
    }

    //on update
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.isLoaded) {
            this.updateElements();
        }
    }

    //update elements
    updateElements = async () => {
        //if image id is provided
        if (this.props.imageId) {
            let file = await APIAction.request('/files/' + this.props.imageId, 'GET', true);

            let filePath = '';
            if (file.resizedPath) {
                filePath = file.resizedPath;
            }

            //append image data to state
            this.setState({
                imageSrc: filePath,
                spinner: false,
                isLoaded: true
            });
        }
    }

    //convert
    dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //upload file
    uploadFile = async (file) => {
        //set spinner
        this.setState({ spinner: true });

        //create form data
        let formData = new FormData();
        formData.append('file', file, file.name);

        //send data
        let response = await APIAction.request('/files', 'POST', true, formData, true, true, 'formdata');

        //if handler is provided
        if (typeof this.props.onUpload === 'function') {
            this.props.onUpload(response);
        }

        //set state
        this.setState({
            imageId: response.id,
            isLoaded: false
        });
    }

    //empty
    empty = () => {
        if (typeof this.props.onUpload === 'function') {
            this.props.onUpload({
                id: null,
                '@id': null,
            });
        }
        this.setState({
            imageSrc: null,
            spinner: null
        });
    }

    render = () => {

        //set button active/disbaled
        let btnDisabled = {};
        if (this.props.disabled) {
            btnDisabled = { 'disabled': true };
        }

        //render
        return (
            <div className={this.props.className} style={this.props.style}>
                <div>
                    {/* Label */}
                    <div
                        style={{
                            display: 'flex',
                            flex: 'row',
                            marginBottom: '0.5rem'
                        }}
                    >
                        {this.props.iconName &&
                            <div
                                className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                            >
                                <i className={this.props.iconName + ' icon'} />
                            </div>
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')} style={{ paddingBottom: 0 }}>{this.props.label}{this.props.labelAppend}</span>
                        </div>
                    </div>
                    {/* SHOW BUTTONS */}
                    {(!this.state.spinner && !this.state.imageSrc) &&
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '-3px', marginRight: '-3px' }}>
                            <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-3'}>
                                <Button
                                    block
                                    variant={'dark'}
                                    {...btnDisabled}
                                    onClick={(event) => {
                                        this.fileInput.click();
                                    }}
                                >
                                    <CustomTranslation value={'KEY.Upload image'} />
                                </Button>
                            </div>
                            <div style={{ flexGrow: 1, flexShrink: 1 }} className={'standard-padding-3'}>
                                <Button
                                    block
                                    variant={'dark'}
                                    {...btnDisabled}
                                    onClick={() => {
                                        this.setState({ showCamera: true })
                                    }}
                                >
                                    <CustomTranslation value={'KEY.Take photo'} />
                                </Button>
                            </div>
                            {/* Hidden file input */}
                            <div style={{ width: 0, height: 0, opacity: 0, position: 'absolute' }}>
                                <input ref={fileInput => this.fileInput = fileInput} type={'file'} accept={'image/*'} style={{ width: '100%', height: '100%' }}
                                    onChange={(event) => {
                                        let file = event.target.files[0];
                                        this.uploadFile(file);
                                    }} />
                            </div>
                        </div>
                    }
                    {/* SHOW IMAGE OR SPINNER */}
                    {(this.state.imageSrc || this.state.spinner) &&
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    maxHeight: 200
                                }}
                            >
                                {this.state.spinner ?
                                    <div
                                        className={'rounded bordered'}
                                        style={{
                                            height: 200,
                                            width: 200,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Spinner animation={'border'} variant={'primary'} />
                                    </div>
                                    :
                                    <div
                                        className={'rounded touchable'}
                                        style={{
                                            maxHeight: 200,
                                        }}
                                        onClick={() => {
                                            this.setState({ imageViewerOpen: true });
                                        }}
                                    >
                                        <img
                                            style={{
                                                maxHeight: 200
                                            }}
                                            className={'rounded'}
                                            src={this.state.imageSrc}
                                        />
                                    </div>
                                }
                                {/* cancel button */}
                                {this.props.disabled ?
                                    null
                                    :
                                    <div
                                        className={'rounded bg-dark touchable'}
                                        style={{
                                            position: 'absolute',
                                            top: 3,
                                            right: 3,
                                            height: '1.5rem',
                                            width: '1.5rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.empty();
                                        }}
                                    >
                                        <i className={'las la-times text-white'} />
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {/* SHOW CAMERA */}
                    <div>
                        {this.state.showCamera &&
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0, height: '100vh',
                                    overflow: 'hidden',
                                    zIndex: 11,
                                    backgroundImage: 'url(' + blurImage + ')',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    className={'bg-light standard-padding vertical-padding rounded bordered'}
                                >
                                    <Camera
                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        isImageMirror={false}
                                        isFullscreen={false}
                                        onTakePhoto={(dataUri) => {
                                            let photo = this.dataURLtoFile(dataUri, 'image.png');
                                            this.uploadFile(photo);
                                            this.setState({ showCamera: false });
                                        }}
                                    />
                                    <Button
                                        block
                                        variant={'dark'}
                                        onClick={() => {
                                            this.setState({ showCamera: false });
                                        }}
                                    >
                                        <CustomTranslation value={'KEY.Close'} />
                                    </Button>
                                </div>
                            </div>
                        }
                        {(this.state.imageViewerOpen && this.state.imageSrc) &&
                            <div style={{ zIndex: 11, position: 'absolute', width: '100vw', height: '100vh', top: 0, bottom: 0, left: 0, right: 0 }}>
                                <ImageViewer src={[this.state.imageSrc]} onClose={() => { this.setState({ imageViewerOpen: false }) }} />
                            </div>
                        }
                    </div>


                </div>
            </div>
        );
    }
}

//export
export default CustomImageInput;