//imports
import AuthenticationAction from './AuthenticationAction';
import CacheRepository from '../Database/Repositories/CacheRepository';
import Config from '../Config.json';
import {toast} from "react-hot-toast";
import CustomTranslation from "../Views/Components/CustomTranslation";
import {generatePath} from "react-router-dom";

//class definition
let APIAction = {

    //function for generic request
    request: async function(url, method, authentication, body = null, baseUrl = true, errorRedirect = true, contentType = 'json', responseBlob = false) {

        //format method
        method = method.trim().toUpperCase();

        //set url start
        let urlStart = '';
        if (baseUrl) {
            urlStart = Config.API_URL;
        }

        //check authentication
        let token = null;

        //use cache
        let cacheRepository = new CacheRepository();
        if (
            ((method === 'GET') || (method === 'SEARCH')) &&
            (!responseBlob)
        ) {
            let requestRecord = await cacheRepository.get(url, 'GET', authentication, body, baseUrl, errorRedirect, contentType);
            if (requestRecord) {
                //if locked wait 10 ms
                if (requestRecord.lock) {
                    return this.request(url, 'GET', authentication, body, baseUrl, errorRedirect, contentType);

                } else {
                    //give cached response
                    return  requestRecord.response;
                }
            } else {
                await cacheRepository.lock(url, 'GET', authentication, body, baseUrl, errorRedirect, contentType);
            }
        //invalidate previous cache in other cases
        } else {
            await cacheRepository.reset();
        }

        //check if authentication token
        if (authentication) {
            //get token
            token = await AuthenticationAction.getAuthenticationToken();
        }

        //construct contentType
        let contentTypeHeader;
        let bodyString;
        switch (contentType) {
            case 'formdata':
                bodyString = body;
                contentTypeHeader = null;
                break;

            case 'json':
            default:
                bodyString = JSON.stringify(body);
                contentTypeHeader = 'application/json';
                break;
        }

        //check if body available
        if (body === null || body === 'null') {

            //headers
            let headers = {
                'Cache-Control': 'no-cache'
            };

            //add authentication if required
            if (authentication) {
                headers['Authorization'] = 'Bearer ' + token.token;
            }

            //add accept if required
            if (!responseBlob) {
                headers['Accept'] = 'application/ld+json';
            }

            //get data
            let failure = false;
            let data = await fetch(urlStart + url, {
                method: method,
                headers:  headers,
            }).then(
                response => {
                    //set response for processing
                    if (method === 'DELETE' && response.status === 204) {
                        return response = {status: 204};
                    } else if (responseBlob) {
                        return response.blob();
                    } else {
                        return response.json();
                    }
                }
            ).catch(error=> {
                console.log(error);
                if (errorRedirect) {
                    toast.error(<CustomTranslation value={'KEY.An error occurred.'} />);
                    failure = true;
                    setTimeout(() => {
                        window.location.href = generatePath('/');
                    }, 2000);
                }
            });

            //if response is not blob

            if (!responseBlob) {
                //check if error
                if (errorRedirect) {
                    if ('@type' in data) {
                        if (data['@type'] === 'hydra:Error') {
                            toast.error(<CustomTranslation value={'KEY.An error occurred.'} />);
                            failure = true;
                            setTimeout(() => {
                                window.location.href = generatePath('/');
                            }, 2000);
                        }
                    }
                }

                //check if is list
                if ('hydra:member' in data) {
                    data = data['hydra:member'];
                }
            }

            //only cache or bundle GET requests
            if (
                ((method === 'GET') || (method === 'SEARCH')) &&
                (failure === false) &&
                (!responseBlob)
            ) {
                //create request record
                await cacheRepository.save(url, 'GET', authentication, null, baseUrl, errorRedirect, contentType, data);
            }

            //return
            return data;

        //other request methods
        } else {

            //check if method is get, if so, change to SEARCH, for javascript limitations
            if (method === 'GET') method = 'SEARCH';

            //headers
            let headers = {
                'Cache-Control': 'no-cache'
            };

            //check if content type is required
            if (contentTypeHeader !== null) {
                headers['Content-type'] = contentTypeHeader;
            }

            //add authentication if required
            if (authentication) {
                headers['Authorization'] = 'Bearer ' + token.token
            }

            //add accept if required
            if (!responseBlob) {
                headers['Accept'] = 'application/ld+json';
            }

            //fetch data
            let failure = false;
            let data = await fetch(urlStart+ url, {
                'method': method,
                'headers': headers,
                'body': bodyString,
            }).then(
                response => {
                    //set response for processing
                    if (method === 'DELETE' && response.status === 204) {
                        return response = {status: 204};
                    } else if (responseBlob) {
                        return response.blob();
                    } else {
                        return response.json();
                    }
                }
            ).catch(error => {
                console.log(error);
                if (errorRedirect) {
                    toast.error(<CustomTranslation value={'KEY.An error occurred.'} />);
                    failure = true;
                    setTimeout(() => {
                        window.location.href = generatePath('/');
                    }, 2000);
                }
            });

            //if response is not blob
            if (!responseBlob) {
                //check if error
                if (errorRedirect) {
                    if ('@type' in data) {
                        if (data['@type'] === 'hydra:Error') {
                            toast.error(<CustomTranslation value={'KEY.An error occurred.'}/>);
                            failure = true;
                            setTimeout(() => {
                                window.location.href = generatePath('/');
                            }, 2000);
                        }
                    }
                }

                //check if is list
                if ('hydra:member' in data) {
                    data = data['hydra:member'];
                }
            }

            //only cache GET requests
            if (
                ((method === 'GET') || (method === 'SEARCH')) &&
                (failure === false) &&
                (!responseBlob)
            ) {
                //create request record
                await cacheRepository.save(url, 'GET', authentication, body, baseUrl, errorRedirect, contentType, data);
            }

            //return
            return data;
        }

    },


    //wait for lock function
    sleep: function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
};

//export
export default APIAction;
