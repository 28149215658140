import React from 'react';
import {generatePath, useParams} from 'react-router-dom';
import CustomContainer from "../Components/CustomContainer";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import CustomTranslation from "../Components/CustomTranslation";
import Loader from "../Components/Loader";
import objectImage from "../../Images/object.png";
import {Breadcrumb, Button, Image, Modal, Spinner} from "react-bootstrap";
import CustomInput from "../Components/CustomInput";
import RiskAnalysisAction from "../../Actions/RiskAnalysisAction";
import T from "../../Actions/TranslationAction";
import RiskAnalysisResult from "./Components/RiskAnalysisResult";
import Uuid from "uuid/v1";

class CreateRiskAnalysisScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            title: null,
            riskAnalysesResults: [],
            waitModal: false
        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    //get data
    updateElements = async () => {

        //get project
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //set state
        this.setState({
            data: {
                constructionSite: cs
            }
        });
    }

    //create new risk analysis
    createRiskAnalysis = async () => {

        //get current language
        let language = await T.currentLanguage();

        //create with call
        let result = await RiskAnalysisAction.create(this.state.data.constructionSite, this.state, language);

        //check result and go to next page
        window.location.href = generatePath('/risk_analysis/:id/edit', {id: result.id});
    }

    //clone risk analysis
    cloneRiskAnalysis = async (id) => {
        //clone with call
        this.setState({waitModal: true});
        let result = await RiskAnalysisAction.clone(id, this.state.data.constructionSite.id);
        this.setState({waitModal: false});

        //go to next page
        window.location.href = generatePath('/risk_analysis/:id/edit', {id: result.id});
    }

    //search for risk analysis
    searchRiskAnalysis = async (value) => {
        //init elements
        let elements = [];

        //only if at least three characters have been entered
        if (value.length > 2) {
            //get results from call
            let result = await RiskAnalysisAction.searchRiskAnalysis(this.state.data.constructionSite.company.id, value);
            //loop result
            result.forEach(element => {
                elements.push(
                    <RiskAnalysisResult
                        key={Uuid()}
                        title={element.title}
                        constructionSiteName={element.name}
                        date={element.createdAt}
                        onPress={() => {
                            this.cloneRiskAnalysis(element.id);
                        }}
                    />
                )
            });
            //set in state
            this.setState({riskAnalysesResults: elements});
        }
    }

    //check is fields are filled in
    isReady = () => {
        if (this.state.title) {
            return true;
        }
        return false;
    }

    render = () => {
        if (this.state.data) {
            return (
                <CustomContainer
                    footer={
                        <Button
                            block
                            variant={'dark'}
                            disabled={!this.isReady()}
                            onClick={() => this.createRiskAnalysis()}
                        >
                            <CustomTranslation value={'KEY.Start Risk Analysis'} />
                        </Button>
                    }
                >
                    <div>
                        {/* Breadcrumb */}
                        <Breadcrumb className={'vertical-margin-2'} style={{marginTop: 0}}>
                            <Breadcrumb.Item
                                href={generatePath('/')}
                            >
                                <CustomTranslation value={'KEY.Home'} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={generatePath('/construction_site/:id', {id: this.state.data.constructionSite.id})}
                            >
                                {this.state.data.constructionSite.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <CustomTranslation value={'KEY.New risk analysis'} />
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        {/* Title */}
                        <div>
                            <h1>
                                <CustomTranslation value={'KEY.New risk analysis'} />
                            </h1>
                        </div>

                        {/* Header */}
                        <div
                            className={'bg-light rounded standard-padding vertical-padding-3'}
                            style={{position: 'relative', marginTop: '4.25rem', marginBottom: '1.25rem'}}
                        >
                            <div style={{position: 'relative', height: '3.0rem'}} />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    src={objectImage}
                                    style={{position: 'absolute', objectFit: 'contain', height: '9rem', width: '9rem', top: '-4.5rem'}}
                                />
                            </div>

                            <div style={{
                                position: 'relative'
                            }}>
                                <h2><CustomTranslation value={'KEY.Project'} /> {this.state.data.constructionSite.name}</h2>
                            </div>
                        </div>

                        {/* Form */}
                        <div>
                            <div className={'vertical-margin-3'}>
                                <CustomInput
                                    label={<CustomTranslation value={'KEY.For which task do you make a Risk Analysis?'} />}
                                    iconName={'las la-comment'}
                                    onChangeText={(value) => {
                                        this.setState({title: value});
                                    }}
                                />
                            </div>
                            <div className={'vertical-margin-3'}>
                                <CustomInput
                                    label={<CustomTranslation value={'KEY.OR: Copy a Risk Analysis'} />}
                                    iconName={'las la-comment'}
                                    onChangeText={(value) => {
                                        this.searchRiskAnalysis(value);
                                    }}
                                />
                                {(this.state.riskAnalysesResults.length > 0) &&
                                <div className={'bordered rounded'}>
                                    {this.state.riskAnalysesResults}
                                </div>
                                }
                            </div>
                        </div>

                        {/* Modal */}
                        <Modal
                            show={this.state.waitModal}
                            onHide={null}
                            backdrop={'static'}
                            keyboard={false}
                        >
                            <Modal.Body>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <div>
                                        <Spinner animation={'border'} variant={'primary'} />
                                    </div>
                                    <div>
                                        <CustomTranslation value={'KEY.Cloning risk analysis please wait...'} />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <CreateRiskAnalysisScreen {...props} params={params} />;
}