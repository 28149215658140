import db from '../Dexie';
import Config from '../../Config.json';

//class definition
 export default class TokenRepository {
 
    //save function
    save = async (token) => {

        //delete all previous
        await db.token.clear();

        //insert new
        await db.token.add({token: token, createdAt: new Date()});
    }
 
    //get function
    get = async () => {

        //get date
        let date = new Date();
        date.setSeconds(date.getSeconds() - Config.TOKEN_TIMEOUT);

        //get tokens
        let tokens = await db.token.where("createdAt").above(date).toArray();

        //set token
        let token = null;

        //check if token exists
        if (tokens.length > 0) {
            token = tokens[0];
        }

        //return
        return token;
    }
 
    //empty token collection
    empty = async () => {
        
        //delete all tokens
        await db.token.clear();
    }
 }