import React from "react";
import {Form} from "react-bootstrap";
import Uuid from "uuid/v1";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";
import TranslationAction from "../../Actions/TranslationAction";

class CustomDate extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {
            selected: this.props.value || new Date()
        };

        //set interval
        this.waitInterval = null;

        //register needed locales
        registerLocale('nl', nl);
    }

    //on mount
    componentDidMount() {
        this.updateElements();
    }

    //update elements
    updateElements = async() => {
        //get locale
        let locale = await TranslationAction.currentLanguage();

        //set in state
        this.setState({
            locale: locale
        });
    }

    //on date change
    onChange = (selectedDate) => {
        //if selected date
        let currentDate = selectedDate || this.state.selected;

        //set state
        this.setState({
            selected: currentDate
        });

        //bubble
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(currentDate);
        }
    }

    render = () => {

        if (this.state.locale) {
            //set defaults
            let inputProps = {
                type: 'text'
            };

            //combine
            if (typeof this.props.inputProps === 'object') {
                inputProps = {...inputProps, ...this.props.inputProps};
            }

            //id
            let id = 'form_' + Uuid();

            return (
                <div className={this.props.className} style={this.props.style}>
                    <Form.Group controlId={id} style={{margin: 0}}>
                        <Form.Label>
                            <div style={{
                                display: 'flex',
                                flex: 'row'
                            }}>
                                {this.props.iconName &&
                                <div
                                    className={(this.props.iconBg ? this.props.iconBg : 'bg-light') + ' icon-container'}
                                >
                                    <i className={this.props.iconName + ' icon'}/>
                                </div>
                                }
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <span className={'label ' + (this.props.iconName ? 'standard-margin-2' : '')}
                                          style={{paddingBottom: 0}}>{this.props.label}{this.props.labelAppend}</span>
                                </div>
                            </div>
                        </Form.Label>
                        <DatePicker
                            id={id}
                            className={'form-control'}
                            locale={this.state.locale.code}
                            dateFormat={'dd/MM/yyyy'}
                            minDate={('noMinDate' in this.props)? null : (new Date())}
                            selected={this.state.selected}
                            onChange={(value) => {this.onChange(value)}}
                            {...inputProps}
                        />
                    </Form.Group>
                </div>
            );
        } else {
            return null;
        }
    }
}

//export
export default CustomDate;