//imports
import React from 'react';
import CustomTranslation from '../../Components/CustomTranslation';
import {generatePath} from 'react-router-dom';

//define class
class Project extends React.Component {

    //render view
    render() {
        let floatStyles = {};
        if ('float' in this.props) floatStyles = {boxShadow: '0px 1px 3.85px rgba(0,0,0,0.25)'};

        return (
            <div style={this.props.style}>
                <div style={floatStyles} className={'rounded'}>
                    <div style={{width: '100%', textAlign: 'left', padding: '1rem', display: 'flex', flexDirection: 'row'}} className={'bg-light rounded' + (('inactive' in this.props) ? '' : ' touchable')}
                         onClick={() => {
                             if (!('inactive' in this.props)) {
                                 window.location.href = generatePath('/construction_site/:id', {id: this.props.data.id});
                             }
                         }}
                    >
                        <div
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                        >
                            {/* Title */}
                            <h3>{this.props.data.name}</h3>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <div style={{flex: 1, minWidth: '15rem'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.5rem', alignItems: 'center'}}>
                                        {/* Icon */}
                                        {((this.props.data.activeAssignmentCount !== null) && (this.props.data.activeAssignmentCount > 0)) &&
                                            <div
                                                className={'primary-bg'}
                                                style={{
                                                    width: '2.2rem',
                                                    minWidth: '2.2rem',
                                                    height: '2.2rem',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginRight: '1rem'
                                                }}
                                            >
                                                <i
                                                    className="las la-bell"
                                                    style={{
                                                        fontSize: '1rem',
                                                        color: '#fff'
                                                    }}
                                                />
                                            </div>
                                        }
                                        <div>
                                            {/* General info */}
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <h5 className={'unbold'}>
                                                    {this.props.data.addressCompany}
                                                </h5>
                                                <h5 className={'unbold'}>
                                                    {this.props.data.street} {this.props.data.number}
                                                </h5>
                                                <h5 className={'unbold'}>
                                                    {this.props.data.zip} {this.props.data.city}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex: 1, minWidth: '15rem'}}>
                                    {/* Workmanager */}
                                    {this.props.data.workManager &&
                                        <div style={{marginTop: '0.5rem'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                {this.props.data.workManager.picture ?
                                                    <div
                                                        style={{
                                                            backgroundImage: 'url("' + this.props.data.workManager.picture.resizedPath + '")',
                                                            height: '2.2rem',
                                                            width: '2.2rem',
                                                            minWidth: '2.2rem',
                                                            backgroundSize: 'cover',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                            borderRadius: '50%'
                                                        }}
                                                    /> :
                                                    <div
                                                        style={{
                                                            height: '2.2rem',
                                                            width: '2.2rem',
                                                            minWidth: '2.2rem',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                        className={'dark-bg'}
                                                    >
                                                        <p style={{color: '#fff', fontWeight: 'bold', margin: 0, fontSize: '0.75rem'}}>
                                                            {
                                                                (this.props.data.workManager.firstName && this.props.data.workManager.lastName)
                                                                ?
                                                                (this.props.data.workManager.firstName.charAt(0)) + (this.props.data.workManager.lastName.charAt(0))
                                                                :
                                                                this.props.data.workManager.email.charAt(0) + this.props.data.workManager.email.charAt(1)
                                                            }
                                                        </p>
                                                    </div>
                                                }

                                                <div style={{marginLeft: '1rem'}}>
                                                    <p className={'label unbold'} style={{paddingBottom: '0rem'}}>
                                                        {
                                                            this.props.data.country.countryCode.toUpperCase() === 'NL' ?
                                                            <CustomTranslation value={'KEY.Hoofduitvoerder'} /> :
                                                            <CustomTranslation value={'KEY.Work manager'} />
                                                        }
                                                    </p>
                                                    <h4 style={{marginBottom: 0}}>
                                                        {
                                                            (this.props.data.workManager.firstName || this.props.data.workManager.lastName)
                                                            ?
                                                            (this.props.data.workManager.firstName || '') + ' ' + (this.props.data.workManager.lastName || '')
                                                            :
                                                            this.props.data.workManager.email
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* Projectmanager */}
                                    {this.props.data.projectManager &&
                                        <div style={{marginTop: '0.5rem'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                {this.props.data.projectManager.picture ?
                                                    <div
                                                        style={{
                                                            backgroundImage: 'url("' + this.props.data.projectManager.picture.resizedPath + '")',
                                                            height: '2.2rem',
                                                            width: '2.2rem',
                                                            minWidth: '2.2rem',
                                                            backgroundSize: 'cover',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center',
                                                            borderRadius: '50%'
                                                        }}
                                                    /> :
                                                    <div
                                                        style={{
                                                            height: '2.2rem',
                                                            width: '2.2rem',
                                                            minWidth: '2.2rem',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                        className={'dark-bg'}
                                                    >
                                                        <p style={{color: '#fff', fontWeight: 'bold', margin: 0, fontSize: '0.75rem'}}>
                                                            {
                                                                (this.props.data.projectManager.firstName && this.props.data.projectManager.lastName)
                                                                ?
                                                                (this.props.data.projectManager.firstName.charAt(0)) + (this.props.data.projectManager.lastName.charAt(0))
                                                                :
                                                                this.props.data.projectManager.email.charAt(0) + this.props.data.projectManager.email.charAt(1)
                                                            }
                                                        </p>
                                                    </div>
                                                }

                                                <div style={{marginLeft: '1rem'}}>
                                                    <p className={'label unbold'} style={{paddingBottom: '0rem'}}>
                                                        {
                                                            this.props.data.country.countryCode.toUpperCase() === 'NL' ?
                                                            <CustomTranslation value={'KEY.Projectmanager'} /> :
                                                            <CustomTranslation value={'KEY.Project manager'} />
                                                        }
                                                    </p>
                                                    <h4 style={{marginBottom: 0}}>
                                                        {
                                                            (this.props.data.projectManager.firstName || this.props.data.projectManager.lastName)
                                                            ?
                                                            (this.props.data.projectManager.firstName || '') + ' ' + (this.props.data.projectManager.lastName || '')
                                                            :
                                                            this.props.data.projectManager.email
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Show distance */}
                        {this.props.data.distance !== null &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    className={'touchable'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(
                                            'https://www.google.com/maps/dir/?api=1'
                                            + '&destination=' + encodeURIComponent(this.props.data.street + ' ' + this.props.data.number + ', ' + this.props.data.zip + ' ' + this.props.data.city)
                                            , '_blank');
                                    }}
                                >
                                    <i style={{fontSize: '1.6rem'}} className={'las la-location-arrow'}/>
                                    <span className={'label'} style={{paddingBottom: 0}}>{Math.round(this.props.data.distance)}km</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

//export
export default Project;